import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type G2ReviewState = {
  leaderBoardTipVisibilityState: boolean;
  setLeaderBoardTipVisibilityState: (value: boolean) => void;
};

export const useLeaderBoardTipsState = create<G2ReviewState>()(
  persist(
    immer<G2ReviewState>((set) => ({
      leaderBoardTipVisibilityState: true,
      setLeaderBoardTipVisibilityState: (val: boolean) => {
        set((state) => {
          state.leaderBoardTipVisibilityState = val;
        });
      },
    })),
    {
      name: 'leaderBoardTips',
      storage: createJSONStorage(() => zustandLocalStorage),
    }
  )
);
