import {
  isTruthy,
  type PinnedCollection,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { AnnouncementCenterModal, useDeviceInfo } from '@assembly-web/ui';
import {
  type InfiniteData,
  type QueryState,
  useQueryClient,
} from '@tanstack/react-query';
import { type ReactNode, Suspense, useContext, useEffect } from 'react';
import { useMatches } from 'react-router-dom';
import type { Primitive } from 'type-fest';

import {
  type HiddenFoldersAPIResponse,
  hiddenFoldersQueryOptions,
} from '../../hooks/folder/useHiddenFolders';
import { AnnouncementsInsights } from '../../modules/discover/components/modals/AnnouncementsInsights';
import { ShareCollectionModal } from '../../modules/discover/components/modals/ShareCollectionModal';
import { navItemsQueryKey } from '../../modules/discover/hooks/nav/useNavData';
import { AnnouncementsContext } from '../../modules/discover/hooks/useAnnouncements';
import { trackSessionLoaded } from '../../modules/discover/services/analytics';
import { DesktopLayout } from './DesktopLayout';
import { MobileLayout } from './MobileLayout';
import { TabletLayout } from './TabletLayout';

export function AppLayout({ children }: { children: ReactNode }) {
  const { deviceType, isMobileDevice } = useDeviceInfo();
  const { data: userDetails } = useSuspenseUserDetails();

  const isParticipationPage = useMatches().some(
    (match) =>
      (match.handle as undefined | Record<string, Primitive>)?.route ===
      'participation'
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    let hiddenFolders: PinnedCollection[] | undefined;
    let folders: PinnedCollection[] | undefined;
    const unsubscribe = queryClient
      .getQueryCache()
      .subscribe(({ type, query }) => {
        if (
          query.queryKey === hiddenFoldersQueryOptions.queryKey &&
          type === 'updated' &&
          query.state.status === 'success' &&
          query.state.dataUpdateCount === 1
        ) {
          const result: QueryState<InfiniteData<HiddenFoldersAPIResponse>> =
            query.state;
          hiddenFolders = result.data?.pages
            .flatMap((x) => x.data)
            .sort((a, b) => a.name.localeCompare(b.name));
        }
        if (
          query.queryKey === navItemsQueryKey &&
          type === 'updated' &&
          query.state.status === 'success' &&
          query.state.dataUpdateCount === 1
        ) {
          const result: QueryState<InfiniteData<HiddenFoldersAPIResponse>> =
            query.state;
          folders = result.data?.pages
            .flatMap((x) => x.data)
            .sort((a, b) => a.name.localeCompare(b.name));
        }
        if (isTruthy(folders) && isTruthy(hiddenFolders)) {
          const isAnyFolderShared = folders.some((folder) => folder.isShared);
          const isAnyHiddenFolderShared = hiddenFolders.some(
            (folder) => folder.isShared
          );
          // session event - to be logged only once
          trackSessionLoaded({
            numFolders: folders.length,
            numHiddenFolders: hiddenFolders.length,
            isSharedFolder: isAnyFolderShared || isAnyHiddenFolderShared,
          });
          unsubscribe();
        }
      });
    return () => unsubscribe();
  }, [queryClient]);

  const {
    isSaving,
    closeAnnouncementModal,
    announcementFormValues,
    disableResponseSettings,
    isAnnouncementsModalOpen,
    handleOnGetHelpButtonClick,
    handleOnSaveAnnouncementClick,
    selectedAnnouncementId,
    closeAnnouncementsInsightsModal,
  } = useContext(AnnouncementsContext);

  if (isParticipationPage) {
    return <>{children}</>;
  }

  return (
    <Suspense fallback={null}>
      {isMobileDevice ? (
        <MobileLayout>{children}</MobileLayout>
      ) : deviceType === 'tablet' ? (
        <TabletLayout>{children}</TabletLayout>
      ) : (
        <DesktopLayout>{children}</DesktopLayout>
      )}

      <AnnouncementsInsights
        announcementId={selectedAnnouncementId}
        onClose={closeAnnouncementsInsightsModal}
      />

      <AnnouncementCenterModal
        isSavingAnnouncement={isSaving}
        onClose={closeAnnouncementModal}
        isOpen={isAnnouncementsModalOpen}
        onSave={handleOnSaveAnnouncementClick}
        memberID={userDetails.member.memberId}
        announcementFormValues={announcementFormValues}
        onGetHelpButtonClick={handleOnGetHelpButtonClick}
        disableResponseSettings={disableResponseSettings}
        memberFullName={userDetails.member.profile.fullName}
        imageUrl={userDetails.member.profile.image}
      />

      <ShareCollectionModal currentUserDetails={userDetails} />
    </Suspense>
  );
}
