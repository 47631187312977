import {
  APIEndpoints,
  assemblyAPI,
  type PlanFeaturesAPIResponse,
} from '@assembly-web/services';
import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

export const planFeaturesQueryOptions = queryOptions({
  queryKey: ['planFeatures'],
  queryFn: async () => {
    const response = await assemblyAPI.get<PlanFeaturesAPIResponse>(
      APIEndpoints.getPlanFeatures
    );
    return response.data;
  },
});

export function useGetPlanFeaturesQuery() {
  return useQuery(planFeaturesQueryOptions);
}

export function useGetPlanFeatures() {
  return useSuspenseQuery(planFeaturesQueryOptions);
}
