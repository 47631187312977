import { FlowFrequencyOptions } from '@assembly-web/services';
import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export type FrequencyOption = {
  label: string;
  id: FlowFrequencyOptions;
};

const messages = defineMessages({
  daily: {
    defaultMessage: 'Every day (Mon - Sun)',
    id: 'EKuPht',
  },
  weeklyOn: {
    defaultMessage: 'Weekly',
    id: '/clOBU',
  },
  biweeklyOn: {
    defaultMessage: 'Biweekly',
    id: 'GZ66QA',
  },
  quarterlyOnThe: {
    defaultMessage: 'Quarterly',
    id: 'b1Ou36',
  },
  annuallyOn: {
    defaultMessage: 'Annually',
    id: 'ZSCbZA',
  },
  everyWeekday: {
    defaultMessage: 'Every weekday (Mon - Fri)',
    id: 'WEmabh',
  },
  monthlyOnThe: {
    defaultMessage: 'Monthly',
    id: 'wYsv4Z',
  },
  firstOrdinal: {
    defaultMessage: 'first',
    id: 'f92A29',
  },
  secondOrdinal: {
    defaultMessage: 'second',
    id: 'k4Hppn',
  },
  thirdOrdinal: {
    defaultMessage: 'third',
    id: 'qpPIhK',
  },
  fourthOrdinal: {
    defaultMessage: 'fourth',
    id: 'lCcRW+',
  },
  lastOrdinal: {
    defaultMessage: 'last',
    id: 'mPCn9j',
  },
});

function getWeekDetails(date: Date) {
  const selectedDay = date.getDay();
  const startDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  let tempDate = new Date(startDayOfMonth);

  while (tempDate.getDay() !== selectedDay) {
    tempDate.setDate(tempDate.getDate() + 1);
  }

  const arrayOfSelectedDays = [];
  while (tempDate.getMonth() === date.getMonth()) {
    arrayOfSelectedDays.push(tempDate.getDate().toString());
    tempDate.setDate(tempDate.getDate() + 7);
  }

  const dateString = date.getDate().toString();
  return {
    weekNumber: arrayOfSelectedDays.indexOf(dateString) + 1,
    isLastWeek:
      arrayOfSelectedDays.indexOf(dateString) + 1 ===
      arrayOfSelectedDays.length,
  };
}

export function useFlowFrequencyOptions(date: Date): FrequencyOption[] {
  const intl = useIntl();
  const { formatMessage } = intl;

  return useMemo(() => {
    const day = date.toLocaleString(intl.locale, { weekday: 'long' });
    const monthAndDate = date.toLocaleString(intl.locale, {
      month: 'long',
      day: 'numeric',
    });

    const weekDetails = getWeekDetails(date);

    const frequencyOptions: FrequencyOption[] = [
      {
        label: formatMessage(messages.everyWeekday),
        id: FlowFrequencyOptions.EveryWeekday,
      },
      {
        label: formatMessage(messages.daily),
        id: FlowFrequencyOptions.Daily,
      },
      {
        label: formatMessage(messages.weeklyOn, { day }),
        id: FlowFrequencyOptions.Weekly,
      },
      {
        label: formatMessage(messages.biweeklyOn, { day }),
        id: FlowFrequencyOptions.BiWeekly,
      },
    ];

    const ordinals = [
      formatMessage(messages.firstOrdinal),
      formatMessage(messages.secondOrdinal),
      formatMessage(messages.thirdOrdinal),
      formatMessage(messages.fourthOrdinal),
      formatMessage(messages.lastOrdinal),
    ];

    const addMonthlyQuarterly = (index: number, isLast = false) => {
      const ordinal = ordinals[isLast ? 4 : index - 1];
      frequencyOptions.splice(index + 3, 0, {
        label: formatMessage(messages.monthlyOnThe, { ordinal, day }),
        id: isLast
          ? FlowFrequencyOptions.MonthlyLast
          : FlowFrequencyOptions.Monthly,
      });
      frequencyOptions.splice(index + 4, 0, {
        label: formatMessage(messages.quarterlyOnThe, { ordinal, day }),
        id: isLast
          ? FlowFrequencyOptions.QuarterlyLast
          : FlowFrequencyOptions.Quarterly,
      });
    };

    if (weekDetails.weekNumber <= 4) {
      addMonthlyQuarterly(weekDetails.weekNumber);
    }

    if (weekDetails.isLastWeek && weekDetails.weekNumber > 4) {
      addMonthlyQuarterly(weekDetails.weekNumber, true);
    }

    frequencyOptions.push({
      label: formatMessage(messages.annuallyOn, { monthAndDate }),
      id: FlowFrequencyOptions.Annually,
    });

    return frequencyOptions;
  }, [date, formatMessage, intl.locale]);
}
