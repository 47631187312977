import type { FlowFeedParams } from '@assembly-web/services';
import dayjs from 'dayjs';
import duration, { type DurationUnitType } from 'dayjs/plugin/duration';
import { useCallback, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import type { FlowTimePeriodOption, PostsCreatedRange } from '../types';
import { useGetFlowFeed } from './useGetFlowFeed';
import { useGetFlowSummaryMaxTimeInterval } from './useGetFlowSummaryMaxTimeInterval';

dayjs.extend(duration);

type ShortHandDurationUnitType = Extract<
  DurationUnitType,
  'y' | 'M' | 'w' | 'd' | 'h' | 'm' | 's'
>;

type TimeDuration = [number, ShortHandDurationUnitType];

type TimePeriodOption = {
  duration: TimeDuration;
  range: PostsCreatedRange;
};

const messages = defineMessages({
  noPostsPastDay: {
    defaultMessage: 'Tell me about the past day - No Posts',
    id: 'V09Wcl',
  },
  noPostsPastDays: {
    defaultMessage: 'Tell me about the past {value} days - No Posts',
    id: 'XkTL7W',
  },
  noPostsPastHour: {
    defaultMessage: 'Tell me about the past hour - No Posts',
    id: 'VBc6Ha',
  },
  noPostsPastHours: {
    defaultMessage: 'Tell me about the past {value} hours - No Posts',
    id: 'FzsqWb',
  },
  noPostsPastMinute: {
    defaultMessage: 'Tell me about the past minute - No Posts',
    id: 'cY/UF/',
  },
  noPostsPastMinutes: {
    defaultMessage: 'Tell me about the past {value} minutes - No Posts',
    id: 'ND8HBN',
  },
  noPostsPastMonth: {
    defaultMessage: 'Tell me about the past month - No Posts',
    id: '+PRs+Q',
  },
  noPostsPastMonths: {
    defaultMessage: 'Tell me about the past {value} months - No Posts',
    id: 'U2QLU8',
  },
  noPostsPastSecond: {
    defaultMessage: 'Tell me about the past second - No Posts',
    id: '+0H/lX',
  },
  noPostsPastSeconds: {
    defaultMessage: 'Tell me about the past {value} seconds - No Posts',
    id: 'OswjPP',
  },
  noPostsPastWeek: {
    defaultMessage: 'Tell me about the past week - No Posts',
    id: 'JzwS1l',
  },
  noPostsPastWeeks: {
    defaultMessage: 'Tell me about the past {value} weeks - No Posts',
    id: 'dEUb7W',
  },
  noPostsPastYear: {
    defaultMessage: 'Tell me about the past year - No Posts',
    id: 'JsS15Q',
  },
  noPostsPastYears: {
    defaultMessage: 'Tell me about the past {value} years - No Posts',
    id: 'BpclJc',
  },
  noPostsToday: {
    defaultMessage: 'Tell me about today - No Posts',
    id: 'zUfGIl',
  },
  noPostsYesterday: {
    defaultMessage: 'Tell me about yesterday - No Posts',
    id: 'PdcUAT',
  },
  pastDay: {
    defaultMessage: 'Tell me about the past day',
    id: 'WLABPW',
  },
  pastDays: {
    defaultMessage: 'Tell me about the past {value} days',
    id: 'QkFzLn',
  },
  pastHour: {
    defaultMessage: 'Tell me about the past hour',
    id: 'tTKjth',
  },
  pastHours: {
    defaultMessage: 'Tell me about the past {value} hours',
    id: '5HYo/2',
  },
  pastMinute: {
    defaultMessage: 'Tell me about the past minute',
    id: 'qfbocV',
  },
  pastMinutes: {
    defaultMessage: 'Tell me about the past {value} minutes',
    id: 'MyiBqB',
  },
  pastMonth: {
    defaultMessage: 'Tell me about the past month',
    id: 'E266BK',
  },
  pastMonths: {
    defaultMessage: 'Tell me about the past {value} months',
    id: 'eUCd+K',
  },
  pastSecond: {
    defaultMessage: 'Tell me about the past second',
    id: '0tGlWU',
  },
  pastSeconds: {
    defaultMessage: 'Tell me about the past {value} seconds',
    id: 'VJxO8q',
  },
  today: {
    defaultMessage: 'Tell me about today',
    id: 'x8In3j',
  },
  pastWeek: {
    defaultMessage: 'Tell me about the past week',
    id: 'YK4M4r',
  },
  pastWeeks: {
    defaultMessage: 'Tell me about the past {value} weeks',
    id: '3bkUh4',
  },
  pastYear: {
    defaultMessage: 'Tell me about the past year',
    id: 'LzjYct',
  },
  pastYears: {
    defaultMessage: 'Tell me about the past {value} years',
    id: '6m+ekA',
  },
  yesterday: {
    defaultMessage: 'Tell me about yesterday',
    id: 'Cwo31/',
  },
});

const constructInterval = (
  endDate: string,
  duration: number,
  units: DurationUnitType
): PostsCreatedRange => {
  return {
    endDate,
    startDate: dayjs(endDate).subtract(duration, units).toISOString(),
  };
};

const getRoundedDuration = (
  intervalDuration: duration.Duration
): TimeDuration => {
  const years = intervalDuration.years();

  if (years >= 1) {
    return [years, 'y'];
  }

  const months = intervalDuration.months();

  if (months >= 1) {
    return [months, 'M'];
  }

  const weeks = intervalDuration.weeks();

  if (weeks >= 1) {
    return [weeks, 'w'];
  }

  const days = intervalDuration.days();

  if (days >= 1) {
    return [days, 'd'];
  }

  const hours = intervalDuration.hours();

  if (hours >= 1) {
    return [hours, 'h'];
  }

  const minutes = intervalDuration.minutes();

  if (minutes >= 1) {
    return [minutes, 'm'];
  }

  const seconds = intervalDuration.seconds();

  return [seconds, 's'];
};

const getTimePeriodForDay = (nowIso: string, offsetDaysFromToday: number) => {
  const beginningOfDay = new Date(nowIso);

  beginningOfDay.setDate(beginningOfDay.getDate() - offsetDaysFromToday);
  beginningOfDay.setHours(0, 0, 0, 0);

  const endOfDay = new Date(beginningOfDay);

  endOfDay.setHours(23, 59, 59, 999);

  return {
    endDate: endOfDay.toISOString(),
    startDate: beginningOfDay.toISOString(),
  };
};

export const useGetPredefinedTimePeriods = (
  flowId: string,
  filterParams: FlowFeedParams['filter'],
  isFocusSet: boolean,
  isPredefinedTimePeriodSet: boolean
) => {
  const { formatMessage } = useIntl();
  const { current: currentDate } = useRef(new Date().toISOString());
  const { blockIds, isAnonymous, respondedBy } = filterParams;

  const {
    data: maxTimeInterval,
    isError: isMaxTimeIntervalError,
    isLoading: isMaxTimeIntervalLoading,
    refetch: refetchMaxTimeInterval,
  } = useGetFlowSummaryMaxTimeInterval(
    {
      blockIds: blockIds?.join(','),
      endDate: currentDate,
      flowId,
      isAnonymous,
      respondedBy: respondedBy?.join(','),
    },
    isFocusSet && !isPredefinedTimePeriodSet
  );

  const estimatedRanges = useMemo(() => {
    if (maxTimeInterval) {
      const maxTimeIntervalDuration = dayjs.duration(
        new Date(maxTimeInterval.endDate).getTime() -
          new Date(maxTimeInterval.startDate).getTime()
      );

      const roundedMaxDuration = getRoundedDuration(maxTimeIntervalDuration);

      const [roundedMaxDurationValue, roundedMaxDurationUnits] =
        roundedMaxDuration;

      const roundedHalfMaxDuration = getRoundedDuration(
        dayjs.duration(roundedMaxDurationValue / 2, roundedMaxDurationUnits)
      );

      const [roundedHalfMaxDurationValue, roundedHalfMaxDurationUnits] =
        roundedHalfMaxDuration;

      const isRoundedHalfSameAsRoundedMax =
        roundedHalfMaxDurationValue === roundedMaxDurationValue &&
        roundedHalfMaxDurationUnits === roundedMaxDurationUnits;

      const ranges: {
        halfMax?: TimePeriodOption;
        max: TimePeriodOption;
      } = {
        max: {
          duration: roundedMaxDuration,
          range: constructInterval(
            maxTimeInterval.endDate,
            ...roundedMaxDuration
          ),
        },
      };

      // It's unlikely to happen for the max time interval to be less than a second, but if it does, we don't want to show the same option twice.
      if (!isRoundedHalfSameAsRoundedMax) {
        ranges.halfMax = {
          duration: roundedHalfMaxDuration,
          range: constructInterval(
            maxTimeInterval.endDate,
            ...roundedHalfMaxDuration
          ),
        };
      }

      return ranges;
    }
  }, [maxTimeInterval]);

  const {
    data: postsFromMaxRangeResponse,
    isError: isPostsFromMaxRangeError,
    isLoading: isPostsFromMaxRangeLoading,
    refetch: refetchPostsFromMaxRange,
  } = useGetFlowFeed(
    flowId,
    {
      filter: {
        ...estimatedRanges?.max.range,
        blockIds,
        isAnonymous,
        respondedBy,
      },
      limit: 1,
    },
    Boolean(estimatedRanges?.max.range) && !isPredefinedTimePeriodSet
  );

  const hasPostsFromMaxRange = Boolean(postsFromMaxRangeResponse?.data.length);

  const {
    data: postsFromHalfMaxRangeResponse,
    isError: isPostsFromHalfMaxRangeError,
    isLoading: isPostsFromHalfMaxRangeLoading,
    refetch: refetchPostsFromHalfMaxRange,
  } = useGetFlowFeed(
    flowId,
    {
      filter: {
        ...estimatedRanges?.halfMax?.range,
        blockIds,
        isAnonymous,
        respondedBy,
      },
      limit: 1,
    },
    Boolean(estimatedRanges?.halfMax?.range) && !isPredefinedTimePeriodSet
  );

  const hasPostsFromHalfMaxRange = Boolean(
    postsFromHalfMaxRangeResponse?.data.length
  );

  const todayRange = getTimePeriodForDay(currentDate, 0);

  const {
    data: postsFromTodayResponse,
    isError: isPostsFromTodayError,
    isLoading: isPostsFromTodayLoading,
    refetch: refetchPostsFromToday,
  } = useGetFlowFeed(
    flowId,
    {
      filter: {
        ...todayRange,
        blockIds,
        isAnonymous,
        respondedBy,
      },
      limit: 1,
    },
    isFocusSet && !isPredefinedTimePeriodSet
  );

  const hasPostsFromToday = Boolean(postsFromTodayResponse?.data.length);
  const yesterdayRange = getTimePeriodForDay(currentDate, 1);

  const {
    data: postsFromYesterdayResponse,
    isError: isPostsFromYesterdayError,
    isLoading: isPostsFromYesterdayLoading,
    refetch: refetchPostsFromYesterday,
  } = useGetFlowFeed(
    flowId,
    {
      filter: {
        ...yesterdayRange,
        blockIds,
        isAnonymous,
        respondedBy,
      },
      limit: 1,
    },
    isFocusSet && !isPredefinedTimePeriodSet
  );

  const hasPostsFromYesterday = Boolean(
    postsFromYesterdayResponse?.data.length
  );

  const data = useMemo(() => {
    const getOptionLabelForDuration = (
      duration: TimeDuration,
      hasPosts: boolean
    ) => {
      const [value, units] = duration;

      switch (units) {
        case 'y': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastYear : messages.noPostsPastYear
              ),
              raw: 'Tell me about the past year',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastYears : messages.noPostsPastYears,
              { value }
            ),
            raw: `Tell me about the past ${value} years`,
          };
        }
        case 'M': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastMonth : messages.noPostsPastMonth
              ),
              raw: 'Tell me about the past month',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastMonths : messages.noPostsPastMonths,
              { value }
            ),
            raw: `Tell me about the past ${value} months`,
          };
        }
        case 'w': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastWeek : messages.noPostsPastWeek
              ),
              raw: 'Tell me about the past week',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastWeeks : messages.noPostsPastWeeks,
              { value }
            ),
            raw: `Tell me about the past ${value} weeks`,
          };
        }
        case 'd': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastDay : messages.noPostsPastDay
              ),
              raw: 'Tell me about the past day',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastDays : messages.noPostsPastDays,
              { value }
            ),
            raw: `Tell me about the past ${value} days`,
          };
        }
        case 'h': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastHour : messages.noPostsPastHour
              ),
              raw: 'Tell me about the past hour',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastHours : messages.noPostsPastHours,
              { value }
            ),
            raw: `Tell me about the past ${value} hours`,
          };
        }
        case 'm': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastMinute : messages.noPostsPastMinute
              ),
              raw: 'Tell me about the past minute',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastMinutes : messages.noPostsPastMinutes,
              { value }
            ),
            raw: `Tell me about the past ${value} minutes`,
          };
        }
        case 's': {
          if (value === 1) {
            return {
              formatted: formatMessage(
                hasPosts ? messages.pastSecond : messages.noPostsPastSecond
              ),
              raw: 'Tell me about the past second',
            };
          }

          return {
            formatted: formatMessage(
              hasPosts ? messages.pastSeconds : messages.noPostsPastSeconds,
              { value }
            ),
            raw: `Tell me about the past ${value} seconds`,
          };
        }
      }
    };

    const options: FlowTimePeriodOption[] = [
      {
        formattedLabel: formatMessage(
          hasPostsFromToday ? messages.today : messages.noPostsToday
        ),
        hasPosts: hasPostsFromToday,
        range: todayRange,
        rawLabel: 'Tell me about today',
      },
      {
        formattedLabel: formatMessage(
          hasPostsFromYesterday ? messages.yesterday : messages.noPostsYesterday
        ),
        hasPosts: hasPostsFromYesterday,
        range: yesterdayRange,
        rawLabel: 'Tell me about yesterday',
      },
    ];

    if (estimatedRanges?.halfMax) {
      const { formatted: formattedLabel, raw: rawLabel } =
        getOptionLabelForDuration(
          estimatedRanges.halfMax.duration,
          hasPostsFromHalfMaxRange
        );

      options.push({
        hasPosts: hasPostsFromHalfMaxRange,
        formattedLabel,
        range: estimatedRanges.halfMax.range,
        rawLabel,
      });
    }

    if (estimatedRanges?.max) {
      const { formatted: formattedLabel, raw: rawLabel } =
        getOptionLabelForDuration(
          estimatedRanges.max.duration,
          hasPostsFromMaxRange
        );

      options.push({
        hasPosts: hasPostsFromMaxRange,
        formattedLabel,
        range: estimatedRanges.max.range,
        rawLabel,
      });
    }

    return options;
  }, [
    estimatedRanges,
    formatMessage,
    hasPostsFromHalfMaxRange,
    hasPostsFromMaxRange,
    hasPostsFromToday,
    hasPostsFromYesterday,
    todayRange,
    yesterdayRange,
  ]);

  const refetch = useCallback(() => {
    if (isMaxTimeIntervalError) {
      refetchMaxTimeInterval();
    }

    if (isPostsFromTodayError) {
      refetchPostsFromToday();
    }

    if (isPostsFromYesterdayError) {
      refetchPostsFromYesterday();
    }

    if (isPostsFromMaxRangeError) {
      refetchPostsFromMaxRange();
    }

    if (isPostsFromHalfMaxRangeError) {
      refetchPostsFromHalfMaxRange();
    }
  }, [
    isMaxTimeIntervalError,
    isPostsFromHalfMaxRangeError,
    isPostsFromMaxRangeError,
    isPostsFromTodayError,
    isPostsFromYesterdayError,
    refetchMaxTimeInterval,
    refetchPostsFromHalfMaxRange,
    refetchPostsFromMaxRange,
    refetchPostsFromToday,
    refetchPostsFromYesterday,
  ]);

  return {
    data,
    isError:
      isMaxTimeIntervalError ||
      isPostsFromTodayError ||
      isPostsFromYesterdayError ||
      isPostsFromMaxRangeError ||
      isPostsFromHalfMaxRangeError,
    isLoading:
      isMaxTimeIntervalLoading ||
      isPostsFromTodayLoading ||
      isPostsFromYesterdayLoading ||
      isPostsFromMaxRangeLoading ||
      (Boolean(estimatedRanges?.halfMax?.range) &&
        isPostsFromHalfMaxRangeLoading),
    refetch,
  };
};
