import { ChatContext, type ChatUser } from '@assembly-web/ui';
import { type ReactNode, useCallback } from 'react';

import { useCreateChatWindow } from '../hooks/chat/useCreateChatWindow';
import { useIsChatEnabled } from '../hooks/chat/useIsChatEnabled';

export function ChatProvider({ children }: { children: ReactNode }) {
  const isChatEnabled = useIsChatEnabled();
  const openChatWindow = useCreateChatWindow();

  const onSendMessageButtonClick = useCallback(
    (user: ChatUser) => {
      openChatWindow(user);
    },
    [openChatWindow]
  );

  return (
    <ChatContext.Provider value={{ isChatEnabled, onSendMessageButtonClick }}>
      {children}
    </ChatContext.Provider>
  );
}
