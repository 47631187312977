import {
  convertToMinutes,
  type FlowBuilderState,
  KnownErrorKeys,
} from '@assembly-web/services';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  useFlowBuilderStore,
  useGetDeadlineType,
  useGetFlowResponseType,
  useGetResponseTimeUnit,
  useGetResponseTimeValue,
  useSetErrors,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';

export const MinimumDeadlineTime = 5; // Minimum Deadline 5 Minutes
export const MaximumDeadlineTime = 12 * 7 * 24 * 60; // Maximum Deadline 12 Weeks (3 Months)

const messages = defineMessages({
  deadlineMinutesTooShort: {
    defaultMessage: 'Time cannot be less than 5 mins',
    id: 'Jv9gPe',
  },
  deadlineMinutesTooLong: {
    defaultMessage: 'Time should be less than 13 weeks',
    id: 'zLxXe8',
  },
});

export function useValidateDistribution() {
  const { id } = useEditorDataContext();
  const { formatMessage } = useIntl();

  const setErrors = useSetErrors(id);

  const responseTimeUnit = useGetResponseTimeUnit(id);
  const responseTimeValue = useGetResponseTimeValue(id);

  const deadlineType = useGetDeadlineType(id);
  const flowResponseType = useGetFlowResponseType(id);

  return useCallback(() => {
    const flow = useFlowBuilderStore.getState().flows[id];
    const errors: NonNullable<FlowBuilderState['errors']> = {};

    if (
      !flow ||
      deadlineType !== 'schedule' ||
      flowResponseType !== 'deadline'
    ) {
      return false;
    }

    const minutes = convertToMinutes(responseTimeValue, responseTimeUnit);

    if (minutes < MinimumDeadlineTime || minutes > MaximumDeadlineTime) {
      errors[KnownErrorKeys.Schedule] = {
        message: formatMessage(
          minutes < MinimumDeadlineTime
            ? messages.deadlineMinutesTooShort
            : messages.deadlineMinutesTooLong
        ),
      };
    } else {
      delete errors.Schedule;
    }

    setErrors(errors);

    return errors;
  }, [
    id,
    setErrors,
    deadlineType,
    formatMessage,
    responseTimeUnit,
    flowResponseType,
    responseTimeValue,
  ]);
}
