import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { defineMessages, useIntl } from 'react-intl';
import type { RequireAtLeastOne } from 'type-fest';

import { rearrangePinnedCollections } from './useNavData';

type Payload = {
  collectionId: string;
} & RequireAtLeastOne<
  {
    beforeId: string;
    afterId: string;
  },
  'afterId' | 'beforeId'
>;

const messages = defineMessages({
  failedToReorderCollection: {
    defaultMessage: 'Failed to reorder collection. Please try again later.',
    id: 'LgYC8X',
  },
  failedToReorderFolder: {
    defaultMessage: 'Failed to reorder folder. Please try again later.',
    id: 'vbpZJT',
  },
});

export function useNavCollectionReorderMutation() {
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastStore();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const { beforeId, afterId, collectionId } = payload;
      await assemblyAPI.put(
        APIEndpoints.updateNavItems(collectionId),
        omitBy({ beforeId, afterId, type: 'collection' }, isNil)
      );
    },

    onMutate: (payload: Payload) => {
      rearrangePinnedCollections(queryClient, payload);
    },

    onError: (payload: Payload) => {
      showErrorToast(formatMessage(messages.failedToReorderFolder));
      logger.error('Failed to reorder collection.', payload);
    },
  });
}
