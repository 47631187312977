import type { ListWorkspacesAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  config,
  getAPIErrorCode,
  getUserDetailsQuery,
  logger,
  SSOProvider,
  unauthenticatedAssemblyAPI,
  userAuthStore,
} from '@assembly-web/services';
import {
  Banner,
  Button,
  HorizontalRule,
  RouterForm,
  TextField,
  useAssemblyNavigate,
  validateForm,
} from '@assembly-web/ui';
import { app, authentication } from '@microsoft/teams-js';
import type { QueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useActionData,
  useNavigation,
  useSearchParams,
} from 'react-router-dom';
import { z } from 'zod';

import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../../services/analytics';
import type { ReactRouterActionResponse } from '../../../../types/libs';
import { OnboardingContainer } from '../../components/OnboardingContainer';
import { SSOButton } from '../../components/SSOButton';
import { getSSODetailsFromError } from '../../services/sso';

const loginFormText = defineMessages({
  title: {
    defaultMessage: 'Welcome back! Get ready to work smarter, not harder.',
    id: '3W+NAx',
  },
  description: {
    defaultMessage: 'Log in below to enter a workspace.',
    id: 'dtCJTJ',
  },
  footer: {
    defaultMessage: "Don't have an Assembly yet? <cta>Sign up</cta>",
    id: 'mvJrR5',
  },
  emailSubmissionButton: {
    defaultMessage: 'Next',
    id: '9+Ddtu',
  },
  emailErrorMessage: {
    defaultMessage: 'Enter a valid email',
    id: 'PFfzPv',
  },
  inputPlaceholder: {
    defaultMessage: 'Enter your email address',
    id: 'NgCT/u',
  },
  horizontalSeparator: {
    defaultMessage: 'OR',
    id: 'INlWvJ',
  },
  loginWithSSOProvider: {
    defaultMessage: 'Log in with {provider}',
    id: 'BXwisy',
  },
});

const loginFormErrors = defineMessages({
  popup_closed_by_user: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Google",
    id: '+ONxsm',
  },
  slack_access_denied: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Slack",
    id: 'tDrT7b',
  },
  defaultMessage: {
    defaultMessage:
      'Whoops, some wires got crossed. Sorry for the error, please try again now or wait a bit and try later.',
    id: 'E5BeoJ',
  },
  user_not_found: {
    defaultMessage: 'We couldn’t find a workspace associated to your email. ',
    id: 'QdvBwh',
  },
  too_many_requests_per_user: {
    defaultMessage:
      'Maximum number of attempts reached. Please try again in 30 minutes.',
    id: 'hM+Sro',
  },
  code_generation_blocked: {
    defaultMessage: 'You can request a new code in 30 seconds',
    id: '8eFffF',
  },
  user_already_exists: {
    defaultMessage:
      'Account has already been created. Log in below to access your account.',
    id: 'vD/zi7',
  },
  invalid_parameter: {
    defaultMessage:
      'Your session has expired for security reasons. Please log in again to continue.',
    id: 'PjIbAh',
  },
});

export function MSTeams() {
  const { formatMessage } = useIntl();

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof msTeamsAction
  >;
  const navigation = useNavigation();
  const [isLoggingIn, setIsLoggingInFlag] = useState(false);

  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get('error');

  const isPageLoading = navigation.state === 'loading';
  const isSubmissionInProgress = navigation.state === 'submitting';

  if (errorCode) {
    logger.error('MS teams login error', { errorCode });
    trackRegistrationError('ssoClicked', {});
  }

  const navigate = useAssemblyNavigate();

  const handleOffice365SSOLoginButtonClick = async () => {
    setIsLoggingInFlag(true);
    try {
      await authentication.authenticate({
        url: `${config.domains.app}/ms-teams/auth`,
        width: 600,
        height: 535,
      });
      setIsLoggingInFlag(false);
      return navigate('/workspaces');
    } catch (error) {
      if (userAuthStore.getState().msTeamsContext) {
        logger.error('Unable to authenticate successfully', {
          email: userAuthStore.getState().msTeamsContext,
          error,
        });
      }
      setIsLoggingInFlag(false);
      return navigate('/ms-teams/login?error=server_error');
    }
  };

  return (
    <OnboardingContainer
      title={formatMessage(loginFormText.title)}
      description={formatMessage(loginFormText.description)}
    >
      {errorCode ? (
        errorCode === 'user_already_exists' ? (
          <Banner status="info">
            {formatMessage(loginFormErrors.user_already_exists)}
          </Banner>
        ) : (
          <Banner status="error">
            {Object.keys(loginFormErrors).includes(errorCode)
              ? formatMessage(
                  loginFormErrors[errorCode as keyof typeof loginFormErrors]
                )
              : formatMessage(loginFormErrors.defaultMessage)}
          </Banner>
        )
      ) : null}
      <section className="grid gap-3 md:grid-cols-2">
        <SSOButton
          flow="login"
          isLoading={isLoggingIn}
          provider={SSOProvider.Office365}
          onClick={handleOffice365SSOLoginButtonClick}
          className="col-span-3 whitespace-nowrap"
        />
      </section>

      <HorizontalRule>
        {formatMessage(loginFormText.horizontalSeparator)}
      </HorizontalRule>

      <RouterForm>
        <TextField
          name="email"
          type="email"
          label={formatMessage(loginFormText.inputPlaceholder)}
          hideLabel
          placeholder={formatMessage(loginFormText.inputPlaceholder)}
          autoComplete="email"
          spellCheck={false}
          invalidTextTestId="email-error"
          isInvalid={Boolean(actionData?.error)}
          invalidText={formatMessage(loginFormText.emailErrorMessage)}
          required
        />
        <Button
          type="submit"
          isFullWidth
          isLoading={isSubmissionInProgress}
          disabled={isSubmissionInProgress || isPageLoading}
        >
          {formatMessage(loginFormText.emailSubmissionButton)}
        </Button>
      </RouterForm>
    </OnboardingContainer>
  );
}

export function msTeamsLoader(queryClient: QueryClient) {
  return async function msTeamsLoader({
    request: { signal },
  }: LoaderFunctionArgs) {
    try {
      await app.initialize();
      app.notifyAppLoaded();
      app.notifySuccess();
    } catch (err) {
      logger.error('MS teams initialization failed for user', {
        email: userAuthStore.getState().msTeamsContext,
      });
    }

    try {
      const {
        data: { allowList, invited, partOf },
      } = await assemblyAPI.post<ListWorkspacesAPIResponse>(
        APIEndpoints.listWorkspaces,
        null,
        { signal }
      );

      try {
        if (
          allowList.length === 0 &&
          invited.length === 0 &&
          partOf.length === 1
        ) {
          const userDetailsQuery = getUserDetailsQuery();
          await queryClient.refetchQueries(userDetailsQuery);
          await queryClient.fetchQuery({
            queryKey: userDetailsQuery.queryKey,
            queryFn: userDetailsQuery.queryFn,
          });
          // We do this to avoid creating a new login session for a user with only one workspace

          return redirect('/a/discover');
        } else {
          const userDetailsQuery = getUserDetailsQuery();
          const { assembly } = await queryClient.fetchQuery({
            queryKey: userDetailsQuery.queryKey,
            queryFn: userDetailsQuery.queryFn,
          });

          return assembly.assemblyId
            ? redirect('/a/discover')
            : redirect('/workspaces');
        }
      } catch (error) {
        return redirect('/workspaces');
      }
    } catch {
      // Carry on, we don't have a valid user here
      return null;
    }
  };
}

export async function msTeamsAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const invalidEmailCode = 'invalid_email';
  const formSchema = z.object({
    email: z.string().email(invalidEmailCode),
  });

  try {
    const { email } = validateForm(formSchema, formData);

    await unauthenticatedAssemblyAPI.post(
      APIEndpoints.generateEmailVerificationCode,
      { email, type: 'login' },
      { signal: request.signal }
    );

    trackRegistrationAction('emailEntered', {
      email,
    });

    return redirect(`/verify-email/login?email=${encodeURIComponent(email)}`);
  } catch (error) {
    trackRegistrationError('emailEntered', {
      email: formData.get('email')?.toString(),
    });
    const enforcedLoginDetails = getSSODetailsFromError(error);

    if (enforcedLoginDetails) {
      return redirect(
        `/login/${enforcedLoginDetails.workspaceSlug}/${enforcedLoginDetails.provider}`
      );
    }

    const errorCode = getAPIErrorCode(error);

    if (['assembly_not_active', 'forbidden'].includes(errorCode)) {
      return redirect(`/create-account?error=${errorCode}`);
    }

    if (
      ['too_many_requests_per_user', 'code_generation_blocked'].includes(
        errorCode
      )
    ) {
      return redirect(`${new URL(request.url).pathname}?error=${errorCode}`);
    } else if (errorCode === 'user_not_found') {
      return { error: 'user_not_found' };
    }

    return { error: invalidEmailCode };
  }
}
