import {
  config,
  GlobalFilterOption,
  logger,
  useCurrency,
  useSearchIndex,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { type MentionedUser, QuickParticipation } from '@assembly-web/ui';
import { useDeferredValue, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { Block } from './Block';

const messages = defineMessages({
  blockedWords: {
    defaultMessage: 'Your message contains restricted language.',
    id: 'd7QuGt',
  },
  minLength: {
    defaultMessage: 'Your message has not met the minimum character amount',
    id: 'XN6/lm',
  },
});

function Editor() {
  const { formatMessage } = useIntl();

  const { control } = useFormContext();

  const [searchTerm, setSearchTerm] = useState('');
  const differedSearchTerm = useDeferredValue(searchTerm);

  const {
    hasNextPage: hasNextPageInMembersList,
    fetchNextPage: fetchNextPageInMembersList,
    isFetchingNextPage: isFetchingNextPageInMembersList,
    status: membersQueryStatus,
    fetchStatus: membersQueryFetchStatus,
    data: membersData,
  } = useSearchIndex<MentionedUser[]>(
    {
      searchTerm: differedSearchTerm,
      enabled: true,
      selectedFilters: [GlobalFilterOption.People],
      populateCardDetails: true,
      secondaryFilters: {
        state: ['ACTIVE', 'INACTIVE'],
        memberState: ['ACTIVE', 'PENDING'],
      },
    },
    {
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => {
            return page.data.data
              .filter((searchResult) => searchResult.type === 'member')
              .map((searchResult) => {
                return {
                  applyBoostToAll: false,
                  id: searchResult.id,
                  isCurrentUserAnon: false,
                  pointsHidden: false,
                  selectedReply: null,
                  department: searchResult._meta.department,
                  email: searchResult._meta.email,
                  image: searchResult._meta.profileImageUrl,
                  memberId: searchResult.id,
                  memberID: searchResult.id,
                  name: searchResult.name,
                  role: searchResult._meta.jobTitle,
                  status: searchResult._meta.profileStatus,
                  firstName: searchResult._meta.firstName,
                  lastName: searchResult._meta.lastName,
                  memberState: searchResult._meta.state,
                  jobTitle: searchResult._meta.jobTitle,
                  pronouns: searchResult._meta.pronouns,
                  isFlowViewer: true,
                };
              });
          }),
        };
      },
    }
  );
  const members = useMemo(
    () => membersData?.pages.flatMap((x) => x) ?? [],
    [membersData]
  );

  const currency = useCurrency();

  const userDetails = useSuspenseUserDetails();
  const blockWordsMessage =
    userDetails.data.assembly.settings.blockWordsMessage;
  const blockedWords = blockWordsMessage.enabled ? blockWordsMessage.value : [];

  const minLength = userDetails.data.assembly.settings.minMessageChar;
  const minLengthValue = minLength.enabled ? minLength.value : undefined;

  return (
    <Controller
      control={control}
      name="message"
      defaultValue={{ json: control._defaultValues.message?.json }}
      rules={{
        validate: {
          blockedWords: (value?: { plainText: string }) => {
            const regExp = new RegExp(`(?:${blockedWords.join('|')})`, 'gi');

            return blockedWords.length === 0 || !value?.plainText
              ? true
              : value.plainText.match(regExp)
                ? formatMessage(messages.blockedWords)
                : true;
          },
          minLength: (value?: { plainText: string }) => {
            if (!value?.plainText) {
              return formatMessage(messages.minLength);
            }
            if (!minLengthValue) {
              return true;
            }
            return value.plainText.length >= minLengthValue
              ? true
              : formatMessage(messages.minLength);
          },
        },
      }}
      render={({
        field: { value, onChange, disabled, onBlur },
        fieldState: { error },
      }) => (
        <QuickParticipation.Editor
          currency={currency}
          onBlur={onBlur}
          gifRating={userDetails.data.assembly.settings.gifAccessibility.value}
          giphyAPIKey={config.giphyKey}
          error={error?.message}
          disabled={disabled}
          onChange={onChange}
          draftState={value.json}
          hasNextPage={hasNextPageInMembersList}
          isFetchingNextPage={isFetchingNextPageInMembersList}
          isMembersFetching={membersQueryFetchStatus === 'fetching'}
          isMembersLoading={membersQueryStatus === 'pending'}
          members={members}
          onNextPageScrolled={fetchNextPageInMembersList}
          onSearchQueryChange={(query) => setSearchTerm(query ?? '')}
          onError={(error, editor) => {
            logger.error(
              'Participation Open ended Editor Error',
              {
                editor,
              },
              error
            );
          }}
        />
      )}
    />
  );
}

export function OpenEnded() {
  return (
    <Block
      label={
        <FormattedMessage defaultMessage="Message to recipients" id="Xz1B8v" />
      }
    >
      <Editor />
    </Block>
  );
}
