import {
  APIEndpoints,
  assemblyAPI,
  type ImportantActivitiesApiResponse,
} from '@assembly-web/services';
import { useInfiniteQuery } from '@tanstack/react-query';

export function useGetImportantActivitiesQuery(
  type: 'all' | 'unreads' = 'unreads'
) {
  return useInfiniteQuery<ImportantActivitiesApiResponse>({
    queryKey: ['importantCards', type],
    queryFn: async ({ pageParam }) => {
      const params: Record<string, unknown> = {};

      if (pageParam) {
        params.cursor = pageParam;
      }

      if (type === 'all') {
        params.type = 'all';
      }

      return await assemblyAPI.get(APIEndpoints.getImportantActivities, {
        params,
      });
    },
    initialPageParam: undefined,
    getNextPageParam: (page: ImportantActivitiesApiResponse) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page: ImportantActivitiesApiResponse) =>
      page.data.metadata?.pagination.cursor.previous,
  });
}
