import { IconButton, TextStyle } from '@assembly-web/ui';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

const labels = defineMessages({
  postFeed: {
    defaultMessage: 'Post Feed',
    id: 'L0AV4V',
  },
});

export function RecognitionFilterContainer() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isFilterExpanded = searchParams.get('filterExpanded') === 'true';
  const { formatMessage } = useIntl();
  return (
    <>
      <TextStyle variant="lg-regular">
        {formatMessage(labels.postFeed)}
      </TextStyle>
      <div className="flex flex-col">
        <IconButton
          variation="secondaryLite"
          className={twJoin(isFilterExpanded && 'bg-gray-4 hover:bg-gray-4')}
          size="small"
          onClick={() => {
            searchParams.set(
              'filterExpanded',
              isFilterExpanded ? 'false' : 'true'
            );
            setSearchParams(searchParams, { replace: true });
          }}
        >
          <AdjustmentsHorizontalIcon className="h-4 w-4 text-gray-8" />
        </IconButton>
      </div>
    </>
  );
}
