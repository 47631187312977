import {
  type CollectionItem,
  copyToClipboard,
  type Folder,
} from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  LinkIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { useCollectionItemReorderMutation } from '../../modules/discover/hooks/useCollectionItemReorderMutation';
import { useCollectionItemsMutation } from '../../modules/discover/hooks/useCollectionItemsMutation';
import { getReorderingInfo } from '../../services/dragDropUtils';
import { useFolderItemDetails } from './useFolderItemDetails';

enum FolderMenuItemActions {
  CopyLink = 'copy-link',
  RemoveItem = 'remove-item',
  MoveUp = 'move-up',
  MoveDown = 'move-down',
}

const messages = defineMessages({
  copyLink: {
    defaultMessage: 'Copy link',
    id: 'lbr3Lq',
  },
  removeItem: {
    defaultMessage: 'Remove item',
    id: '3dwSlf',
  },
  removedItemMessage: {
    defaultMessage: 'Removed {itemName} from {folderName}',
    id: '+kmgIR',
  },
  copiedLink: {
    defaultMessage: 'Link copied to clipboard',
    id: '2yCGR2',
  },
  moveUp: {
    defaultMessage: 'Move up',
    id: 'wmFdws',
  },
  moveDown: {
    defaultMessage: 'Move down',
    id: 'H/r5m6',
  },
});

export function useFolderItemMenu({
  folder,
  isHiddenFolder,
}: {
  folder: Folder;
  isHiddenFolder: boolean;
}) {
  const { formatMessage } = useIntl();

  const { mutate: removeFromFolder } = useCollectionItemsMutation();

  const { showSuccessToast } = useToastStore();

  const getFolderDetails = useFolderItemDetails();

  const { mutate: reorderFolderItemMutation } =
    useCollectionItemReorderMutation();

  const { allowedEditing } = folder;

  const reorderCollectionItem = useCallback(
    (direction: 'up' | 'down', item: CollectionItem) => {
      const newPositionInfo = getReorderingInfo({
        items: folder.listItems,
        direction,
        itemId: item.id,
      });

      invariant(newPositionInfo, 'newPositionInfo must be defined');

      const { beforeId, afterId, newLocationIndex } = newPositionInfo;

      reorderFolderItemMutation({
        collectionId: folder.id,
        itemId: item.id,
        beforeId,
        afterId,
        newLocationIndex,
      });
    },
    [folder.id, folder.listItems, reorderFolderItemMutation]
  );

  const getFolderItemMenuOptions = useCallback(
    (item: CollectionItem) => {
      const itemPosition = folder.listItems.findIndex(
        (folderItem) => folderItem.id === item.id
      );

      return [
        {
          id: FolderMenuItemActions.CopyLink,
          text: formatMessage(messages.copyLink),
          icon: LinkIcon,
        },
        allowedEditing && {
          id: FolderMenuItemActions.RemoveItem,
          text: formatMessage(messages.removeItem),
          icon: XMarkIcon,
        },
        allowedEditing &&
          !isHiddenFolder &&
          itemPosition !== 0 && {
            id: FolderMenuItemActions.MoveUp,
            text: formatMessage(messages.moveUp),
            icon: ArrowUpIcon,
          },
        allowedEditing &&
          !isHiddenFolder &&
          itemPosition !== folder.listItems.length - 1 && {
            id: FolderMenuItemActions.MoveDown,
            text: formatMessage(messages.moveDown),
            icon: ArrowDownIcon,
          },
      ].filter(Boolean);
    },
    [allowedEditing, folder.listItems, formatMessage, isHiddenFolder]
  );

  const handleFolderItemMenuAction = useCallback(
    (item: CollectionItem, action: string) => {
      if (action === FolderMenuItemActions.CopyLink) {
        const linkToCopy = getFolderDetails(item)?.url;

        if (linkToCopy) {
          let url = new URL(window.location.href);

          if (linkToCopy.startsWith('/')) {
            const [path, search] = linkToCopy.split('?');
            url.pathname = path;
            url.search = new URLSearchParams(search).toString();
          } else {
            url = new URL(linkToCopy);
          }

          copyToClipboard(url.toString());
          showSuccessToast(formatMessage(messages.copiedLink));
        }
      } else if (action === FolderMenuItemActions.RemoveItem) {
        removeFromFolder({
          action: 'remove',
          collectionName: folder.name,
          collectionId: folder.id,
          name: item.name,
          type: item.type,
          entityId: item.id,
          ...(item.type === 'flow' ? { icon: item._meta.icon } : {}),
        });
        showSuccessToast(
          formatMessage(messages.removedItemMessage, {
            itemName: item.name,
            folderName: folder.name,
          })
        );
      } else if (action === FolderMenuItemActions.MoveUp) {
        reorderCollectionItem('up', item);
      } else if (action === FolderMenuItemActions.MoveDown) {
        reorderCollectionItem('down', item);
      }
    },
    [
      folder.id,
      folder.name,
      formatMessage,
      getFolderDetails,
      removeFromFolder,
      reorderCollectionItem,
      showSuccessToast,
    ]
  );

  return { getFolderItemMenuOptions, handleFolderItemMenuAction };
}
