import type { AnnouncementsInsightsViewersApiResponse } from '@assembly-web/services';
import { useInfiniteQuery } from '@tanstack/react-query';

import type { AnnouncementInsightViewersPayload } from '../queries/getAnnouncementInsightsQuery';
import { getAnnouncementInsightsViewersQuery } from '../queries/getAnnouncementInsightsQuery';

export function useAnnouncementInsightsViewersQuery(
  payload: AnnouncementInsightViewersPayload
) {
  return useInfiniteQuery<AnnouncementsInsightsViewersApiResponse>(
    getAnnouncementInsightsViewersQuery({
      announcementId: payload.announcementId,
      searchValue: payload.searchValue,
      filterType: payload.filterType,
    })
  );
}
