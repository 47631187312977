import type { DoraReportingCategory } from '@assembly-web/services';
import { useSuspenseUserDetails } from '@assembly-web/services';
import { DropdownCategoryOption, Popover, TextStyle } from '@assembly-web/ui';
import {
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  GiftIcon,
  TableCellsIcon,
  TrophyIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useChatDisplayText } from '../../../hooks/dora/useChatDisplayText';
import { isAdminMember } from '../../../services/member';

const toggleTitle = defineMessage({
  defaultMessage: 'Choose a category to ask about:',
  id: 'mPpGKx',
});

type ReportingInsightsChatSwitcherProps = {
  currentCategory: DoraReportingCategory;
  onCategoryChange: (category: DoraReportingCategory) => void;
};

export function ReportingInsightsChatSwitcher({
  currentCategory,
  onCategoryChange,
}: ReportingInsightsChatSwitcherProps) {
  const { formatMessage } = useIntl();

  const { data: userDetails } = useSuspenseUserDetails();

  const [isChatSwitcherOpen, setIsChatSwitcherOpen] = useState(false);

  const displayText = useChatDisplayText(userDetails);

  const onSwitch = useCallback(
    (category: DoraReportingCategory) => {
      setIsChatSwitcherOpen(false);
      onCategoryChange(category);
    },
    [onCategoryChange]
  );

  const isAdmin = isAdminMember(userDetails.member);

  const isRecognition = currentCategory === 'recognition';
  const isFlows = currentCategory === 'flows';
  const isRewardsRedemption = currentCategory === 'reward_redemption';

  const selectedCategoryTitle = isRecognition
    ? displayText.recognitionTitle
    : isFlows
      ? displayText.participationTitle
      : isRewardsRedemption
        ? displayText.rewardRedemptionsTitle
        : displayText.milestonesTitle;

  return (
    <Popover
      collisionBoundary={[]}
      contentClassName="p-0"
      trigger={
        <div
          className={twMerge(
            'inline-flex h-6 items-center justify-start gap-2 rounded bg-gradient-to-bl px-2 py-1',
            isRecognition
              ? 'from-error-2 to-magenta-3'
              : isFlows
                ? 'from-cyan-3 to-blue-2'
                : isRewardsRedemption
                  ? 'from-purple-3 to-primary-2'
                  : 'from-success-3 to-cyan-2'
          )}
        >
          <div className="flex items-start justify-start gap-0.5">
            <div className="text-center text-sm font-medium leading-snug">
              {selectedCategoryTitle}
            </div>
          </div>

          {isChatSwitcherOpen ? (
            <ChevronUpIcon className="h-4 w-4" />
          ) : (
            <ChevronDownIcon className="h-4 w-4" />
          )}
        </div>
      }
      open={isChatSwitcherOpen}
      onOpenChange={setIsChatSwitcherOpen}
      onOpenAutoFocus={function (event: Event) {
        event.preventDefault();
        setTimeout(() => {
          document
            .querySelector<HTMLButtonElement>(
              `[data-category-id="chat-switcher-${currentCategory}"]`
            )
            ?.focus();
        });
      }}
    >
      <div className="w-[320px] rounded-lg bg-gray-1 p-3 shadow-md-down">
        <TextStyle subdued variant="xs-regular">
          {formatMessage(toggleTitle)}
        </TextStyle>
        <div className="mt-1 flex flex-col gap-y-2">
          <DropdownCategoryOption.Root
            dataCategoryId="chat-switcher-recognition"
            className={twMerge(
              'border-error-2 hover:from-error-2/20 hover:to-magenta-3/20 focus:from-error-2/40 focus:to-magenta-3/40'
            )}
            description={displayText.recognitionDescription}
            icon={
              <DropdownCategoryOption.Icon className="from-error-2 to-magenta-3">
                <TrophyIcon className="h-4 w-4 stroke-magenta-9 stroke-2" />
              </DropdownCategoryOption.Icon>
            }
            title={displayText.recognitionTitle}
            onClick={() => onSwitch('recognition')}
          />
          {Boolean(isAdmin) && (
            <DropdownCategoryOption.Root
              dataCategoryId="chat-switcher-reward-redemptions"
              className="border-purple-3 hover:from-cyan-3/20 hover:to-primary-2/20 focus:from-purple-3/40 focus:to-primary-2/40"
              description={displayText.rewardRedemptionsDescription}
              icon={
                <DropdownCategoryOption.Icon className="from-purple-3 to-primary-2">
                  <GiftIcon className="h-4 w-4 stroke-purple-9 stroke-2" />
                </DropdownCategoryOption.Icon>
              }
              title={displayText.rewardRedemptionsTitle}
              onClick={() => onSwitch('reward_redemption')}
            />
          )}
          <DropdownCategoryOption.Root
            dataCategoryId="chat-switcher-flows"
            className="border-cyan-3 hover:from-cyan-3/20 hover:to-blue-2/20 focus:from-cyan-3/40 focus:to-blue-2/40"
            description={displayText.participationDescription}
            icon={
              <DropdownCategoryOption.Icon className="from-cyan-3 to-blue-2">
                <ChatBubbleLeftRightIcon className="h-4 w-4 stroke-cyan-9 stroke-2" />
              </DropdownCategoryOption.Icon>
            }
            title={displayText.participationTitle}
            onClick={() => onSwitch('flows')}
          />
          <DropdownCategoryOption.Root
            dataCategoryId="chat-switcher-users"
            className="border-success-3 hover:from-success-3/20 hover:to-cyan-2/20 focus:from-success-3/40 focus:to-cyan-2/40"
            description={displayText.milestonesDescription}
            icon={
              <DropdownCategoryOption.Icon className="from-success-3 to-cyan-2">
                <TableCellsIcon className="h-4 w-4 stroke-success-9 stroke-2" />
              </DropdownCategoryOption.Icon>
            }
            title={displayText.milestonesTitle}
            onClick={() => onSwitch('users')}
          />
        </div>
      </div>
    </Popover>
  );
}
