import { postIcon } from '@assembly-web/assets';
import {
  canUserDeletePost,
  checkIfFlowPostHasTrophies,
  useSuspenseUserDetails,
  useUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import { Button, useAssemblyNavigate } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { RichPostPreview } from '../../../../components/RichPostPreview';
import { usePostEmbed } from '../../hooks/usePostEmbed';
import { Card } from './Card';
import { messages } from './messages';
import type { CommonCardProps } from './type';
import { getToolbarMenuItemsForResponseCard, ToolbarItemId } from './utils';

export type PostCardProps = {
  flow: string;
  postId: string;
  authorId: string;
  postType: string;
  description: string;
  onClick?: () => void;
  hasReplyDraft: boolean;
  celebratedTeammate: string;
  celebratedTeammateId: string;
  plainCelebratedTeammate: string;
  author: undefined | string | string[];
  plainAuthor: undefined | string;
  urlSlug: string;
  isReplyPreviewCard?: boolean;
} & CommonCardProps;

const cardType = 'post';

export function PostCard({
  flow,
  cardId,
  cardPosition,
  colorName,
  author,
  plainAuthor,
  postId,
  onClick,
  authorId,
  isAdmin,
  isCollectionItem,
  isFirstItem,
  isLastItem,
  canEditCollection,
  postType,
  searchTerm,
  sortMethod,
  description,
  workspaceName,
  celebratedTeammate,
  plainCelebratedTeammate,
  celebratedTeammateId,
  currentUserId,
  onToolbarMenuItemClick,
  trackCardActionEvent,
  postResponse,
  lazyLoadPostDetailsOnScroll,
  onPostClick,
  workspaceSlugPath,
  urlSlug,
  hasReplyDraft,
  disableNavigation,
  filter,
  isReplyPreviewCard,
}: PostCardProps) {
  const navigate = useAssemblyNavigate();
  const { formatMessage } = useIntl();
  const urlPath = `/a/${workspaceSlugPath}${urlSlug}`;

  const { cardDetailsContainerRef, cardWrapperRef, embed } = usePostEmbed({
    cardType,
    flowId: 'recognition',
    isNotification: false,
    responseId: postId,
    searchTerm,
    sortMethod,
    urlPath: urlPath,
    postResponse,
    hasReplyDraft,
    lazyLoadPostDetailsOnScroll: Boolean(lazyLoadPostDetailsOnScroll),
    filter,
  });

  const {
    data: {
      assembly: {
        settings: {
          postPermission: {
            canHidePoints: { enabled: canHidePoints },
          },
        },
      },
    },
  } = useSuspenseUserDetails();

  let text: unknown = formatMessage(messages.postByLabel, {
    flow,
    workspaceName,
    author: currentUserId === authorId ? 'self' : author,
  });

  let plainText: unknown = formatMessage(messages.postByLabel, {
    flow,
    workspaceName,
    author: currentUserId === authorId ? 'self' : plainAuthor,
  });

  const icon = (
    <img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />
  );

  if (postType === 'birthday') {
    [text, plainText] = [celebratedTeammate, plainCelebratedTeammate].map(
      (celebratedTeammate) =>
        formatMessage(messages.birthDayPostLabel, {
          flow,
          workspaceName,
          celebratedTeammate:
            celebratedTeammateId === currentUserId
              ? 'self'
              : celebratedTeammate,
        })
    );
  }

  if (postType === 'anniversary') {
    text = formatMessage(messages.anniversaryDayPostLabel, {
      flow,
      author,
      workspaceName,
      celebratedTeammate:
        celebratedTeammateId === currentUserId ? 'self' : celebratedTeammate,
    });
    plainText = formatMessage(messages.anniversaryDayPostLabel, {
      flow,
      author: plainAuthor,
      workspaceName,
      celebratedTeammate:
        celebratedTeammateId === currentUserId
          ? 'self'
          : plainCelebratedTeammate,
    });
  }

  const { data: userDetails } = useUserDetails();
  invariant(userDetails);

  const toolbarMenuItems =
    !isReplyPreviewCard &&
    getToolbarMenuItemsForResponseCard({
      formatMessage,
      isAuthor: currentUserId === authorId,
      isAdmin,
      isFollowingThread: false,
      isCollectionItem,
      isFirstItem,
      isLastItem,
      canEditCollection,
      canDeletePost:
        authorId === currentUserId ||
        canUserDeletePost(userDetails) ||
        postResponse?.canDelete,
      postNotificationPreference: {
        isMuted: postResponse?.isMuted ?? false,
      },
    });

  const onToolbarItemClick = (args: ToolbarItem): void => {
    onToolbarMenuItemClick?.({
      ...args,
      cardId,
      postId,
      url: urlPath,
      cardIcon: icon,
      entityId: postId,
      title: plainText as string,
      type: cardType,
      hasTrophies: postResponse?.responses
        ? checkIfFlowPostHasTrophies(postResponse.responses)
        : false,
      isMuted: postResponse?.isMuted ?? false,
      firstName: postResponse?.respondent?.firstName,
    });
    if (args.text === formatMessage(messages.unfollowPost)) {
      args.id = ToolbarItemId.Unfollow;
    } else if (args.text === formatMessage(messages.followPost)) {
      args.id = ToolbarItemId.Follow;
    }
    trackCardActionEvent?.(args.id, {
      cardType,
      cardPosition,
    });
  };

  if (!isCollectionItem) {
    const props = {
      analyticsProps: {
        cardType,
        isNotification: false,
        searchTerm,
        sortMethod,
        filter,
      },
      isReplyPreviewCard: isReplyPreviewCard,
      className: 'hover:shadow-base-down',
      flowId: 'recognition',
      responseId: postId,
      urlPath: urlPath,
      postResponse: postResponse,
      hasReplyDraft: hasReplyDraft,
      lazyLoadPostDetailsOnScroll: Boolean(lazyLoadPostDetailsOnScroll),
      ...(toolbarMenuItems && {
        primaryToolbarItems: toolbarMenuItems.primaryToolbarItems,
      }),
      ...(toolbarMenuItems && {
        secondaryToolbarItems: toolbarMenuItems.secondaryToolbarItems,
      }),
      onToolbarMenuItemClick: onToolbarItemClick,
      canHidePoints: canHidePoints,
    };
    return <RichPostPreview {...props} />;
  }

  return (
    <Card
      cardId={cardId}
      url={disableNavigation ? undefined : urlPath}
      icon={icon}
      text={text as ReactNode}
      subText={description}
      connectedRight={
        <Button
          size="small"
          className="max-md:ml-0 max-md:mr-3"
          variation="secondaryLite"
          onClick={withoutDefaultEventBehavior(() => {
            if (!disableNavigation) {
              navigate(urlPath);
            } else {
              onPostClick?.();
            }
          })}
        >
          {formatMessage(messages.viewPostLabel)}
        </Button>
      }
      onToolbarItemClick={onToolbarItemClick}
      onClick={() => {
        onClick?.();
        onPostClick?.();
      }}
      detailsRef={cardDetailsContainerRef}
      preview={embed}
      wrappedRef={cardWrapperRef}
      {...toolbarMenuItems}
      isCollectionItem={isCollectionItem}
      colorName={colorName}
    />
  );
}
