import {
  adpLogo,
  gSuiteLogo,
  lifionLogo,
  office365Logo,
  slackLogo,
} from '@assembly-web/assets';
import {
  buildSafeUrl,
  config,
  logger,
  lookupSSOProvider,
  SSOProvider,
  userAuthStore,
} from '@assembly-web/services';
import { app, authentication } from '@microsoft/teams-js';
import axios from 'axios';

export function handleSSOForAccountCreation(provider: SSOProvider) {
  const redirectURL = `${
    config.domains.app
  }/sso-account-creation/${provider.toLowerCase()}`;

  switch (provider) {
    case SSOProvider.Slack:
      {
        window.location.href = buildSafeUrl(
          'https://slack.com/openid/connect/authorize',
          {
            redirect_uri: redirectURL,
            client_id: config.sso.slack,
            scope: 'openid,email,profile',
            response_type: 'code',
          }
        );
      }
      break;
    case SSOProvider.Office365:
      {
        window.location.href = buildSafeUrl(
          'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
          {
            redirect_uri: redirectURL,
            client_id: config.sso.office365,
            scope: 'User.Read',
            response_type: 'code',
            response_mode: 'query',
            prompt: 'consent',
          }
        );
      }
      break;

    default:
      console.error(`signing up with ${provider} isn't supported yet`);
      break;
  }
}

async function handleLoginForO365ThroughModal() {
  try {
    await app.initialize();
    await authentication.authenticate({
      url: `${config.domains.app}/ms-teams/auth`,
      width: 600,
      height: 535,
    });
    window.location.href = `${config.domains.app}/workspaces`;
  } catch (err) {
    logger.error('MS teams initialization failed for user', {
      email: userAuthStore.getState().msTeamsContext,
    });
    window.location.href = `${config.domains.app}/login?error=server_error`;
  }
}

export function handleSSOForLogin(provider: SSOProvider, autoLogin = false) {
  const baseURL = `${config.domains.app}/sso-sign-in/${provider.toLowerCase()}`;

  let redirectURL = baseURL;

  switch (provider) {
    case SSOProvider.Slack:
      {
        redirectURL = autoLogin ? `${baseURL}?auto=${autoLogin}` : baseURL;
        window.location.replace(
          buildSafeUrl('https://slack.com/openid/connect/authorize', {
            redirect_uri: redirectURL,
            client_id: config.sso.slack,
            scope: 'openid,email,profile',
            response_type: 'code',
          })
        );
      }
      break;
    case SSOProvider.ADP:
      {
        const successUri = buildSafeUrl(
          'https://accounts.adp.com/auth/oauth/v2/authorize',
          {
            client_id: config.sso.adp.clientId,
            response_type: 'code',
            scope: 'openid',
            redirect_uri: redirectURL,
          }
        );

        window.location.replace(
          buildSafeUrl(
            'https://adpapps.adp.com/consent-manager/pending/direct',
            {
              consumerApplicationID: config.sso.adp.consumerApplicationId,
              successUri: successUri.toString(),
            }
          )
        );
      }
      break;
    case SSOProvider.Office365:
      {
        if (userAuthStore.getState().msTeamsContext) {
          handleLoginForO365ThroughModal();
        } else {
          window.location.replace(
            buildSafeUrl(
              'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
              {
                redirect_uri: redirectURL,
                client_id: config.sso.office365,
                scope: 'User.Read',
                response_type: 'code',
                response_mode: 'query',
              }
            )
          );
        }
      }
      break;
    case SSOProvider.Lifion:
      {
        const successUri = buildSafeUrl(
          'https://accounts.adp.com/auth/oauth/v2/authorize',
          {
            client_id: config.sso.lifion.clientId,
            response_type: 'code',
            scope: 'openid',
            redirect_uri: redirectURL,
          }
        );

        window.location.replace(
          buildSafeUrl('https://lyric.adp.com/auth/login', {
            consumerApplicationID: config.sso.lifion.consumerApplicationId,
            successUri: successUri.toString(),
          })
        );
      }
      break;
    default:
      console.error(`login with ${provider} isn't supported yet`);
      break;
  }
}

type EnforcedLoginError = {
  message: string;
  body: {
    workspaceSlug: {
      name: string;
      shortCode: string;
    };
  };
};

export function getSSODetailsFromError(error: unknown) {
  if (!axios.isAxiosError(error)) {
    return null;
  }

  const data = error.response?.data as EnforcedLoginError;

  const authProvider = data.message.toLowerCase().replace('signin_via_', '');
  const provider = lookupSSOProvider(authProvider);
  if (provider) {
    return {
      provider,
      workspaceSlug: `${data.body.workspaceSlug.name}-${data.body.workspaceSlug.shortCode}`,
    };
  } else if (authProvider.startsWith('saml-')) {
    const samlDetails = data.message.replace('SIGNIN_VIA_SAML-', '').split('-');
    const samlID = samlDetails.pop();
    const samlButtonText = samlDetails.join('-');

    if (samlID) {
      userAuthStore.getState().setSAMLDetails(samlID, samlButtonText);

      return {
        provider: 'saml',
        workspaceSlug: `${data.body.workspaceSlug.name}-${data.body.workspaceSlug.shortCode}`,
      };
    }
  }
}

export function getLogoForSSOProvider(provider: SSOProvider) {
  return {
    [SSOProvider.Google]: gSuiteLogo,
    [SSOProvider.Office365]: office365Logo,
    [SSOProvider.Slack]: slackLogo,
    [SSOProvider.ADP]: adpLogo,
    [SSOProvider.Lifion]: lifionLogo,
  }[provider];
}
