import {
  logger,
  useSuspenseUserDetails,
  type WorkspaceSettings,
} from '@assembly-web/services';
import {
  $createTextChipNode,
  Combobox,
  ComboboxPlugin,
  QuickParticipation,
  type SerializedTextChipNode,
  TextChipNode,
  TextTypeaheadOption,
} from '@assembly-web/ui';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useDeferredValue, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { Block } from './Block';

const config = { ...Combobox.initialConfig, nodes: [TextChipNode] };

const messages = defineMessages({
  label: {
    defaultMessage: 'Core value',
    id: '+hmbqD',
  },
  requiredValidation: {
    defaultMessage: 'You are required to select a core value.',
    id: '+cid17',
  },
});

function Selector({
  coreValues,
}: {
  coreValues: WorkspaceSettings['coreValues'];
}) {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();

  const [searchTerm, setSearchTerm] = useState('');
  const differedSearchTerm = useDeferredValue(searchTerm);

  const options = useMemo(() => {
    const options: TextTypeaheadOption[] = [];
    for (const value of coreValues.value) {
      if (
        value
          .toLocaleLowerCase()
          .includes(differedSearchTerm.toLocaleLowerCase())
      ) {
        options.push(new TextTypeaheadOption(value));
      }
    }
    return options.sort((a, b) => a.__text.localeCompare(b.__text));
  }, [coreValues.value, differedSearchTerm]);

  const defaultValue = useMemo(() => {
    const coreValue = control._defaultValues.coreValue as string | undefined;

    if (!coreValue) {
      return undefined;
    }

    return ComboboxPlugin.$generateInitialState([
      {
        text: coreValue,
        type: 'combobox-chip',
        version: 1,
      } satisfies SerializedTextChipNode,
    ]);
  }, [control._defaultValues.coreValue]);

  return (
    <Controller
      name="coreValue"
      control={control}
      rules={{
        validate: {
          required: (value?: string) => {
            if (coreValues.isRequired && (!value || value.length === 0)) {
              return formatMessage(messages.requiredValidation);
            }
            return true;
          },
        },
      }}
      render={({ field: { onBlur, onChange }, fieldState: { error } }) => (
        <Combobox<TextTypeaheadOption, TextChipNode>
          error={error?.message}
          label={formatMessage(messages.label)}
          config={config}
          onBlur={onBlur}
          onError={(error, editor) => {
            logger.error(
              'Participation Core Value Editor Error',
              {
                editor,
              },
              error
            );
          }}
          placeholderVariant="purple"
          draftValue={defaultValue}
          ComboboxProps={{
            onSelectedNodesChange(nodes) {
              onChange(nodes.map((node) => node.__text)[0]);
            },
            $createChipNode: $createTextChipNode,
            type: 'single',
            getKey(option) {
              return option.__text;
            },
            getChipKey(chip) {
              return chip.__text;
            },
            onSearchQueryChange(query) {
              if (query == null || query === ' ') {
                setSearchTerm('');
                return;
              }
              setSearchTerm(query.trim());
            },
            options,
            children({
              isSelected,
              options,
              selectOptionAndCleanUp,
              selectedIndex,
              setHighlightedIndex,
            }) {
              return (
                <ComboboxPlugin.Root
                  className={twJoin(
                    'max-h-[288px] overflow-auto',
                    options.length > 5 && 'h-full'
                  )}
                >
                  <ComboboxPlugin.List>
                    {options.map((option, index) => {
                      const isHighlighted = selectedIndex === index;

                      const onClick = () => {
                        selectOptionAndCleanUp(option);
                      };

                      const onMouseEnter = () => {
                        setHighlightedIndex(index);
                      };

                      return (
                        <ComboboxPlugin.ListItem
                          key={option.__text}
                          onClick={onClick}
                          onMouseEnter={onMouseEnter}
                          index={index}
                          isSelected={isSelected(option)}
                          option={option}
                          renderOption={({ option, isSelected }) => (
                            <QuickParticipation.ItemWrapper
                              isSelected={isSelected}
                              highlighted={isHighlighted}
                            >
                              <span className="text w-full truncate">
                                {option.__text}
                              </span>
                              {Boolean(isSelected) && (
                                <CheckIcon className="h-6 w-6" />
                              )}
                            </QuickParticipation.ItemWrapper>
                          )}
                        />
                      );
                    })}
                  </ComboboxPlugin.List>
                </ComboboxPlugin.Root>
              );
            },
          }}
        />
      )}
    />
  );
}

export function CoreValue() {
  const {
    data: {
      assembly: {
        settings: { coreValues },
      },
    },
  } = useSuspenseUserDetails();

  if (!coreValues.enabled) {
    return null;
  }

  return (
    <Block
      label={
        <FormattedMessage defaultMessage="Select core value" id="j8r6H0" />
      }
    >
      <Selector coreValues={coreValues} />
    </Block>
  );
}
