import {
  EntityType,
  fetchRecognitionDraftKey,
  useFetchRecognitionDraft,
  useGetRecognitionPost,
  useSaveDrafts,
} from '@assembly-web/services';
import { QuickParticipation } from '@assembly-web/ui';
import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Draft } from '../components/Draft';
import { useRecognitionForm } from '../hooks/useRecognitionForm';
import { useParticipationModalContext } from '../Provider';
import { Blocks } from './blocks/Blocks';
import { EditBadge } from './blocks/Editbadge';
import { Footer } from './footer/Footer';
import { Title } from './header/Title';
import { DraftModal } from './modals/DraftModal';
import { EditConfirmationModal } from './modals/EditConfirmationModal';

export function RecognitionParticipation() {
  const { closeModal, isOpen, reset, editPostId } =
    useParticipationModalContext();
  const {
    getParsedFormDataForDraft,
    getPostData,
    getSaveDraftPayload,
    getDraftData,
  } = useRecognitionForm();
  const [isDraftOpen, setIsDraftOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const isEditFlow = Boolean(editPostId);

  const { mutate: saveDraft } = useSaveDrafts({
    onMutate: () => {
      reset();
    },
  });

  const { data } = useFetchRecognitionDraft();
  const { data: postData } = useGetRecognitionPost({
    postId: editPostId,
  });

  const draft = useMemo(
    () => ('postData' in data.draft ? data.draft.postData : {}),
    [data.draft]
  );

  const generateDefaultValues = useCallback(() => {
    if (isEditFlow) {
      return getPostData(postData.post);
    } else {
      return getDraftData(draft);
    }
  }, [isEditFlow, draft, postData, getPostData, getDraftData]);

  const methods = useForm({
    reValidateMode: 'onChange',
    defaultValues: generateDefaultValues(),
  });

  const handleCloseModal = useCallback(() => {
    if (isEditFlow) {
      setIsConfirmationOpen(true);
    } else {
      const values = methods.getValues();
      const draft = getParsedFormDataForDraft(values);
      closeModal();
      if (Object.keys(draft).length) {
        setIsDraftOpen(true);
      } else {
        saveDraft({
          queryCacheToUpdate: fetchRecognitionDraftKey,
          payload: getSaveDraftPayload(values),
        });
      }
    }
  }, [
    closeModal,
    methods,
    saveDraft,
    isEditFlow,
    getParsedFormDataForDraft,
    getSaveDraftPayload,
  ]);

  const handleDraftModalClose = useCallback(
    (param?: { isDeleteDraft: boolean }) => {
      const { isDeleteDraft } = param || {};
      if (isDeleteDraft) {
        saveDraft({
          payload: {
            toCache: {
              entityType: EntityType.Recognition,
              postData: {},
            },
            toServer: {
              entityType: EntityType.Recognition,
              postData: {},
            },
          },
          queryCacheToUpdate: fetchRecognitionDraftKey,
        });
      } else {
        saveDraft({
          payload: getSaveDraftPayload(methods.getValues()),
          queryCacheToUpdate: fetchRecognitionDraftKey,
        });
      }
      methods.reset();
      setIsDraftOpen(false);
    },
    [methods, saveDraft, setIsDraftOpen, getSaveDraftPayload]
  );

  const discardChanges = useCallback(() => {
    closeModal();
    setIsConfirmationOpen(false);
  }, [closeModal]);

  const onContinueEdit = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  return (
    <FormProvider {...methods}>
      <QuickParticipation.Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        title={<Title />}
      >
        <EditBadge />
        <Blocks />
        <Footer />
        {!isEditFlow && (
          <Draft
            getPayloadForSavingDraft={(fields) => {
              return {
                payload: getSaveDraftPayload(fields),
              };
            }}
          />
        )}
      </QuickParticipation.Modal>
      {isEditFlow ? (
        <EditConfirmationModal
          isOpen={isConfirmationOpen}
          onDiscard={discardChanges}
          onContinueEdit={onContinueEdit}
        />
      ) : (
        <DraftModal
          isOpen={isDraftOpen}
          onClose={(param) => handleDraftModalClose(param)}
        />
      )}
    </FormProvider>
  );
}
