import {
  APIEndpoints,
  assemblyAPI,
  getMemberDetailsFromUserDetails,
  type GetRepliesResponse,
  type MemberAPIResponse,
  type ReplyData,
  useUserDetails,
} from '@assembly-web/services';
import {
  type InfiniteData,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import invariant from 'tiny-invariant';

import type { AddReplyPayload } from '../replies/types';
import {
  addReplyToEmptyPostReplies,
  addReplyToPostReplies,
} from '../replies/utils';

type AddChatPayload = AddReplyPayload & {
  toMemberId: string;
};

function generateOptimisticReply(
  payload: AddReplyPayload,
  userDetails: MemberAPIResponse,
  responseId: string
): ReplyData {
  return {
    gifURL: '',
    unread: true,
    imageURL: '',
    pointsEach: 0,
    kind: 'DIRECT_MESSAGE',
    taggedUsers: [],
    isDeleted: false,
    responseId: responseId,
    messageHtml: payload.messageHtml,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    messageTokens: payload.messageTokens,
    commentID: `chat-${new Date().getTime()}`,
    fromMember: {
      ...getMemberDetailsFromUserDetails(userDetails.member),
    },
    version: 2,
  };
}

export function useAddChatMutation() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();
  invariant(userDetails, 'User details should be available');

  return useMutation({
    mutationKey: ['messages'],
    mutationFn: async (payload: AddChatPayload) => {
      return await assemblyAPI.post<unknown>(
        APIEndpoints.addChatMessage,
        payload
      );
    },
    onSuccess: async (_, payload) => {
      const { toMemberId } = payload;
      const queryKey = ['messages', toMemberId];

      await queryClient.invalidateQueries({ queryKey });
    },
    onMutate: async (payload) => {
      const { toMemberId } = payload;
      const queryKey = ['messages', toMemberId];

      await queryClient.cancelQueries({ queryKey });

      const previousReplies =
        queryClient.getQueryData<InfiniteData<GetRepliesResponse>>(queryKey);

      const optimisticReply = generateOptimisticReply(payload, userDetails, '');

      let optimisticReplies: InfiniteData<GetRepliesResponse>;
      if (previousReplies) {
        optimisticReplies = addReplyToPostReplies(
          optimisticReply,
          previousReplies
        );
      } else {
        optimisticReplies = addReplyToEmptyPostReplies(optimisticReply);
      }

      queryClient.setQueryData(queryKey, optimisticReplies);

      return {
        previousReplies,
      };
    },
    onError: (error, payload, context) => {
      if (!context) {
        return;
      }

      const { toMemberId } = payload;
      const queryKey = ['messages', toMemberId];

      queryClient.setQueryData(queryKey, context.previousReplies);
    },
  });
}
