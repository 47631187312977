import {
  config,
  logger,
  MemberStatus,
  type Nullable,
  removeNewLinesAndTabs,
  sanitizeHtml,
  useUserDetails,
} from '@assembly-web/services';
import { useGetMembers } from '@assembly-web/services';
import {
  type EditorData,
  RepliesEditor,
  useToastStore,
} from '@assembly-web/ui';
import { type MutableRefObject, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useLayoutStore } from '../../../../../stores/useLayoutStore';
import { useAddChatMutation } from '../../../hooks/chat/useAddChatMutation';
import type { AddReplyPayload } from '../../../hooks/replies/types';

const messages = defineMessages({
  chatLabel: {
    defaultMessage: 'Chat',
    id: 'WTrOy3',
  },
  messagePlaceholder: {
    defaultMessage: `Message to {toName}`,
    id: 'hJYTUS',
  },
});

type ChatEditorContainerProps = {
  toName: string;
  toMemberId: string;
  containerRef: MutableRefObject<HTMLElement | null>;
};

export function ChatEditorContainer({
  toName,
  toMemberId,
  containerRef,
}: ChatEditorContainerProps) {
  const { error, isPending, data: currentUser } = useUserDetails();

  const { formatMessage } = useIntl();
  const { showToast } = useToastStore();

  const [membersSearchQuery, setMembersSearchQuery] = useState('');
  const { isFormattingToolbarVisible, setIsFormattingToolbarVisible } =
    useLayoutStore();

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const handleOnMentionsSearchQueryChange = useCallback(
    (query: Nullable<string>) => {
      setMembersSearchQuery(query ?? '');
    },
    []
  );

  const { mutate: addChatMutation } = useAddChatMutation();

  const handleOnPostChatClick = useCallback(
    ({ html, json, mentionIds, plainText, boost }: EditorData) => {
      const payload: AddReplyPayload = {
        boost,
        isAnonymous: false,
        messageTokens: json,
        mentions: mentionIds,
        kind: 'DIRECT_MESSAGE',
        toMemberId: toMemberId,
        messageHtml: sanitizeHtml(html),
        plainText: removeNewLinesAndTabs(plainText),
      };

      addChatMutation(payload);

      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    },
    [addChatMutation, containerRef, toMemberId]
  );

  const {
    members,
    isPending: isMembersListPending,
    hasNextPage: hasNextPageInMembersList,
    fetchNextPage: fetchNextPageInMembersList,
    isFetchingNextPage: isFetchingNextPageInMembersList,
  } = useGetMembers({
    searchTerm: membersSearchQuery,
    options: {
      placeholderData: (previousData) => previousData,
      enabled: true,
    },
  });

  if (isPending || isMembersListPending) {
    return null;
  }

  if (error) {
    return null;
  }

  return (
    <RepliesEditor
      replyType={'user'}
      selectedReply={null}
      validator={() => {
        return [];
      }}
      onChange={() => {}}
      currency={currentUser.assembly.currency}
      onViewProfileClick={navigateToUserFeed}
      mentionedUsersInPost={null}
      isAnonymousPost={false}
      onMentionsMenuOpenChange={() => {}}
      isFormattingToolbarVisible={isFormattingToolbarVisible}
      setIsFormattingToolbarVisible={setIsFormattingToolbarVisible}
      onNextPageScrolledInMentionsDropdown={async () => {
        if (hasNextPageInMembersList) {
          await fetchNextPageInMembersList();
        }
      }}
      isFetchingNextPageForMentions={isFetchingNextPageInMembersList}
      currentUserDetails={{
        id: currentUser.member.memberID,
        image: currentUser.member.profile.image,
        currency: currentUser.assembly.currency,
        allowance: currentUser.member.pointsLeftThisCycle,
        name: `${currentUser.member.profile.firstName} ${currentUser.member.profile.lastName}`.trim(),
      }}
      onError={(error, editor) => {
        logger.error(
          'Replies Editor Error',
          {
            editor,
          },
          error
        );
      }}
      onCancelClick={() => {}}
      mentionedUsers={members.map((member) => ({
        ...member,
        id: member.memberID,
        currency: currentUser.assembly.currency,
        role: '',
        isFlowViewer: true,
        pointsHidden: false,
        applyBoostToAll: false,
        selectedReply: null,
        currentUserID: currentUser.member.memberId,
        isCurrentUserAnon: false,
        canReceivePoints:
          member.status === MemberStatus.Receiver ||
          member.status === MemberStatus.Normal,
      }))}
      onReplyClick={handleOnPostChatClick}
      hideToolbarButtons={false}
      onMentionsSearchQueryChange={handleOnMentionsSearchQueryChange}
      gifRating={currentUser.assembly.settings.gifAccessibility.value}
      giphyAPIKey={config.giphyKey}
      placeholder={formatMessage(messages.messagePlaceholder, {
        toName,
      })}
      disableAnonymousReplyOption={true}
      boostOptions={{
        hideBoost: true,
        disableBoost: false,
        maxGivingPointsPerPost: 0,
      }}
      showToast={showToast}
    />
  );
}
