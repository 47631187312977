import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type JobStatus, useJobStatusQuery } from '../useJobStatusQuery';

type DownloadInitiatedJob = {
  job: {
    id: string;
    status: JobStatus;
  };
};

export type DownloadDataJob = {
  url: string;
};

const messages = defineMessages({
  downloadFailed: {
    defaultMessage: 'Download failed. Retry later.',
    id: '7LPql5',
  },
  downloadSuccess: {
    defaultMessage: 'Download success',
    id: 'E9nYXm',
  },
});

function useDownloadLeaderBoardData({
  onSuccess,
  onError,
  options,
}: {
  onSuccess?: (data: DownloadInitiatedJob | DownloadDataJob) => void;
  onError?: (error: unknown) => void;
  options?: UseMutationOptions<DownloadInitiatedJob | DownloadDataJob>;
}) {
  return useMutation({
    mutationKey: ['downloadLeaderBoard'],
    mutationFn: async function () {
      const { data } = await assemblyAPI.post<
        DownloadDataJob | DownloadInitiatedJob
      >(APIEndpoints.downloadLeaderBoard);
      return data;
    },
    onSuccess: (data) => {
      onSuccess?.(data);
    },
    onError: (error) => {
      onError?.(error);
    },
    ...options,
  });
}

export function useDownloadLeaderBoardDataJobPolling({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: DownloadDataJob) => void;
  onError?: (error: unknown) => void;
}) {
  const [jobId, setJobId] = useState<string | null>(null);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { showErrorToast } = useToastStore();

  const downloadLeaderBoardData = useDownloadLeaderBoardData({
    onSuccess: (data) => {
      if ('job' in data) {
        setJobId(data.job.id);
      } else {
        if (onSuccess) {
          onSuccess(data);
        }
      }
    },
    onError: (error) => {
      if (onError) {
        onError(error);
      }
    },
  });

  const { data: jobStatusQuery, isSuccess: isJobStatusSuccess } =
    useJobStatusQuery({
      jobId: jobId ?? '',
      options: {
        enabled: Boolean(jobId),
        refetchInterval: (query) => {
          const status = query.state.data?.data.status;
          return status === 'QUEUED' || status === 'PROCESSING' ? 3000 : false;
        },
      },
    });

  if (isJobStatusSuccess) {
    if (jobStatusQuery.data.status === 'SUCCESS') {
      setJobId(null);
      queryClient.cancelQueries({
        queryKey: ['jobStatus', jobId],
      });
      downloadLeaderBoardData.mutate();
    } else if (jobStatusQuery.data.status === 'ERROR') {
      setJobId(null);
      queryClient.cancelQueries({
        queryKey: ['jobStatus', jobId],
      });
      showErrorToast(formatMessage(messages.downloadFailed));
    }
  }

  const { mutate: downloadMutate, isPending } = downloadLeaderBoardData;

  const initiateDownload = useCallback(() => {
    setJobId(null);
    downloadMutate();
  }, [downloadMutate]);

  return {
    initiateDownload,
    jobStatus: jobStatusQuery,
    isDownloading:
      isPending || Boolean(jobId) || downloadLeaderBoardData.isPending,
  };
}
