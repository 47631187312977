import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  adjustAnswerSettings: {
    defaultMessage: 'Adjust answer settings',
    id: 'U9j7se',
  },
  header: {
    defaultMessage:
      'Dora AI - Get an answer from your files for {quote}{question}{quote}',
    id: 'OkfDX/',
  },
  subHeader: {
    defaultMessage:
      'Dora AI uses shared company knowledge to give you simple answers to complex questions.',
    id: 'iOE3pa',
  },
  learnMoreLabel: {
    defaultMessage: 'Learn more',
    id: 'TdTXXf',
  },
  getAnswerLabel: {
    defaultMessage: 'Get answer',
    id: 'plrEVt',
  },
  answeringNowLabel: {
    defaultMessage: 'Answering now',
    id: '8h6QdP',
  },
  upgradeToViewAnswerLabel: {
    defaultMessage: 'Upgrade to view answer',
    id: 't/Oa2N',
  },
  clearAnswerLabel: {
    defaultMessage: 'Clear answer',
    id: 'ldpxyA',
  },
  sourcesLabel: {
    defaultMessage: 'Sources:',
    id: 'U59/R/',
  },
  feedbackLabel: {
    defaultMessage: 'Did this answer your question?',
    id: 'AbifY2',
  },
  assemblyLabel: {
    defaultMessage: 'Assembly',
    id: 'ueYqTh',
  },
  sendFeedbackLabel: {
    defaultMessage: 'Send Feedback',
    id: 'b11hht',
  },
  doraInformationLabel: {
    defaultMessage:
      'Dora AI is a work in progress. Currently, it can answer questions about the contents of files uploaded to a flow or through connected apps.',
    id: 'qadjyp',
  },
  doraFooterLabel: {
    defaultMessage:
      'Remember, for now, I can only answer questions about the contents of files uploaded to flows. Happy asking!',
    id: '+EGG9f',
  },
  feedbackSubmittedForAnswerMessage: {
    defaultMessage:
      'Thanks for the feedback! Keep rating your answers to improve their quality.',
    id: 'Thhw3M',
  },
  feedbackSubmittedForDraftMessage: {
    defaultMessage:
      'Thanks for the feedback! Keep rating your drafts to improve their quality.',
    id: 'mksVXP',
  },
  doraError: {
    defaultMessage:
      'Hm...sorry, something went wrong! It might be a connection issue. Can you ask me again or refresh your page?',
    id: 'nYjrwr',
  },
  responseCopiedMessage: {
    defaultMessage: 'Response copied to clipboard',
    id: 'Qji3A0',
  },
  postByInFlowLabel: {
    defaultMessage: 'Post by {authorName} in {emoji} {flowName}',
    id: '7O9XfW',
  },
  postedInFlowLabel: {
    defaultMessage: '{emoji} {flowName}',
    id: 'tQahNH',
  },
  clearAnswers: {
    defaultMessage: 'Clear answers',
    id: 'iDadiC',
  },
  tryQuestions: {
    defaultMessage: 'Try one of these questions:',
    id: 'JhLDU+',
  },
  tryAgain: {
    defaultMessage: 'Try again to get an answer',
    id: 'PZQRU7',
  },
  askAdmin: {
    defaultMessage: 'Ask your admin to upgrade to view answer',
    id: 'lzdVVY',
  },
  upgradeToGetMoreAnswers: {
    defaultMessage: 'Upgrade to get more answers',
    id: 'WR7B2d',
  },
  askDora: {
    defaultMessage: 'Ask DoraAI',
    id: '71+qWd',
  },
});

export const doraFeedbackMessages = defineMessages({
  title: {
    defaultMessage:
      'We’re sorry that this answer wasn’t up to snuff. Why was that?',
    id: 'KgovZ9',
  },
  subTitle: {
    defaultMessage:
      'We’re always working on improving our model based on the information you have access to. If you let us know what’s missing, we’ll work to fix it.',
    id: 'kIuLr9',
  },
  inputPlaceholder: {
    defaultMessage:
      'How could we improve this search? e.g. It wasn’t specific enough, or it was missing information from my chat tool',
    id: '+ptlw1',
  },
  harmful: {
    defaultMessage: 'This is harmful / unsafe',
    id: 'eXcUs1',
  },
  notTrue: {
    defaultMessage: 'This isnt true',
    id: '5etdwW',
  },
  notHelpful: {
    defaultMessage: 'This isnt helpful',
    id: 'wuHs3l',
  },
});

export const aiRecognitionFeedbackMessages = defineMessages({
  title: {
    defaultMessage:
      "We noticed you didn't complete your AI recognition. Could you share why?",
    id: 'mdegta',
  },
  subtitle: {
    defaultMessage:
      "We're constantly improving our AI products based on your feedback. Let us know so we can work on it.",
    id: 'WFoYQ/',
  },
  inputPlaceholder: {
    defaultMessage:
      'Tell us why you didn\'t use AI Recognition on this post.. e.g. "I wasn\'t sure what to prompt" or "the draft wasn\'t in my voice"',
    id: '+FwIWl',
  },
  exploringNewFeature: {
    defaultMessage: "I was just exploring (I didn't plan to post)",
    id: 'fRDgpr',
  },
  unsureHowToPrompt: {
    defaultMessage: "I wasn't sure how to prompt it",
    id: 'cSZMcK',
  },
  notHelpful: {
    defaultMessage: "The AI draft wasn't relevant or well written",
    id: 'wig68Y',
  },
  preferManualMethod: {
    defaultMessage: 'I prefer to write my own recognition',
    id: 'EYRi6Z',
  },
  other: {
    defaultMessage: 'Other',
    id: '/VnDMl',
  },
});

export const managerInsightsFeedbackMessages = defineMessages({
  title: {
    defaultMessage:
      'Your AI Insights must have missed the mark. What went wrong?',
    id: 'BC/xhu',
  },
  subTitle: {
    defaultMessage:
      'We’re constantly improving our AI products based on your feedback. Let us know what was wrong so we can work on it.',
    id: 'YpVcim',
  },
  inputPlaceholder: {
    defaultMessage:
      'How could we improve AI Reporting & Insights? E.g., the response didn’t answer my question effectively',
    id: '5Z+TFN',
  },
  tookTooLong: {
    defaultMessage: 'Took too long',
    id: '8r81Aj',
  },
  irrelevant: {
    defaultMessage: 'Response wasn’t relevant to my prompt',
    id: 'dmxYMq',
  },
  incorrectDataOrAnalysis: {
    defaultMessage: 'Incorrect data or analysis',
    id: '/29M58',
  },
  technicalIssue: {
    defaultMessage: 'Technical issues',
    id: 'nFTv9Y',
  },
  other: {
    defaultMessage: 'Other',
    id: '/VnDMl',
  },
});
