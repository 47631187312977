import { zustandLocalStorage } from '@assembly-web/services';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type NavBarStoreState = {
  sectionOpenState: {
    main: boolean;
    flows: boolean;
    folders: boolean;
    hiddenFolders: boolean;
  };
  toggleSection: (section: keyof NavBarStoreState['sectionOpenState']) => void;
};

export const useNavBarStore = create<NavBarStoreState>()(
  persist(
    immer<NavBarStoreState>((set /**, get*/) => ({
      sectionOpenState: {
        main: false,
        flows: false,
        folders: true,
        hiddenFolders: false,
      },
      toggleSection: (section: keyof NavBarStoreState['sectionOpenState']) => {
        set((state) => {
          state.sectionOpenState[section] = !state.sectionOpenState[section];
        });
      },
    })),
    {
      name: 'navBarStore',
      storage: createJSONStorage(() => zustandLocalStorage),
      version: 1,
    }
  )
);
