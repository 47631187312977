import type { SelectedOption as DateRangeSelectedOption } from '@assembly-web/ui';
import { useSearchParams } from 'react-router-dom';

import { trackDiscoverAction } from '../../services/analytics';

export function useGetDateRangeFilters() {
  const [params, setSearchParams] = useSearchParams();

  // Due Date Filter
  const dueDate = params.get('dueDate') ?? '';
  const dueDateStart = params.get('dueDateStart') ?? '';
  const dueDateEnd = params.get('dueDateEnd') ?? '';

  const getSelectedDueDateOption = () => {
    if (!dueDate) return null;

    const value =
      dueDate === 'custom'
        ? { start: dueDateStart, end: dueDateEnd }
        : undefined;

    return { id: dueDate, value };
  };

  const onDueDateChange = (selectedOption: DateRangeSelectedOption | null) => {
    let updatedParams = { ...Object.fromEntries(params) };

    if (selectedOption) {
      const { id, value } = selectedOption;

      const trackEventProps = {
        secondaryFilterType: 'dueDate',
        selectedValues: id,
      };

      trackDiscoverAction('secondaryFilterApplied', trackEventProps);

      updatedParams.dueDate = id;

      if (id === 'custom' && value) {
        updatedParams.dueDateStart = value.start;
        updatedParams.dueDateEnd = value.end;
      }
    } else {
      delete updatedParams.dueDate;
      delete updatedParams.dueDateStart;
      delete updatedParams.dueDateEnd;
    }

    setSearchParams(updatedParams);
  };

  // Date Created Filter
  const onDateCreatedChange = (
    selectedOption: DateRangeSelectedOption | null
  ) => {
    let updatedParams = { ...Object.fromEntries(params) };

    if (selectedOption) {
      const { id, value } = selectedOption;
      const trackEventProps = {
        secondaryFilterType: 'dateCreated',
        selectedValues: id,
      };

      trackDiscoverAction('secondaryFilterApplied', trackEventProps);

      updatedParams.dateCreated = id;

      if (id === 'custom' && value) {
        updatedParams.dateCreatedStart = value.start;
        updatedParams.dateCreatedEnd = value.end;
      }
    } else {
      delete updatedParams.dateCreated;
      delete updatedParams.dateCreatedStart;
      delete updatedParams.dateCreatedEnd;
    }

    setSearchParams(updatedParams);
  };

  const dateCreated = params.get('dateCreated') ?? '';
  const dateCreatedEnd = params.get('dateCreatedEnd') ?? '';
  const dateCreatedStart = params.get('dateCreatedStart') ?? '';

  const getDateCreatedOptions = () => {
    if (!dateCreated) return null;

    const value =
      dateCreated === 'custom'
        ? { start: dateCreatedStart, end: dateCreatedEnd }
        : undefined;

    return { id: dateCreated, value };
  };

  const dueDateProps = {
    onDueDateChange,
    hasSelectedDueDate: Boolean(dueDate),
    getSelectedDueDateOption: getSelectedDueDateOption(),
  };

  const dateCreatedProps = {
    onDateCreatedChange,
    hasSelectedDateCreated: Boolean(dateCreated),
    dateCreatedOptions: getDateCreatedOptions(),
  };

  return { dueDateProps, dateCreatedProps };
}
