import { useGetImportantActivitiesQuery } from '../modules/activity/hooks/useGetImportantActivitiesQuery';

export function useUnreadNotificationsCount() {
  const { data: importantActivities } = useGetImportantActivitiesQuery();

  try {
    return importantActivities
      ? Number.parseInt(importantActivities.pages[0].data.total)
      : 0;
  } catch {
    return 0;
  }
}
