import type {
  GroupOrUserChipData,
  Recipients,
  RecognitionRecipientsCriteria,
} from '@assembly-web/services';

function groupDataByFiled(data: GroupOrUserChipData[]): {
  department: Recipients['department'];
  member: Recipients['individuals'];
} {
  return data.reduce(
    (acc, item) => {
      if (item.type === 'department') {
        acc.department.push(item);
      } else {
        acc.member.push(item);
      }
      return acc;
    },
    {
      department: [] as Recipients['department'],
      member: [] as Recipients['individuals'],
    }
  );
}

export function generateCriteriaRules(
  data: GroupOrUserChipData[],
  includeRecipientMeta = false
): RecognitionRecipientsCriteria {
  const everyoneSelected = data.some(
    (item) => item.type === 'department' && item.id === 'everyone'
  );
  data = everyoneSelected
    ? data.filter(
        (item) =>
          (item.type === 'department' && item.id !== 'everyone') ||
          item.type === 'member'
      )
    : data;
  const groupedData = groupDataByFiled(data);

  const formattedCriteria = Object.entries(groupedData).map(
    ([field, values]) => {
      if (field === 'department') {
        return {
          field: 'department',
          values: values.map((value) => ({
            value: value.name,
            operator: 'is',
            perm: 'recipient',
          })),
        } satisfies RecognitionRecipientsCriteria['include'][number];
      }
      return {
        field: 'member',
        values: values.map((value) => ({
          ...(includeRecipientMeta &&
            value.type === 'member' && {
              meta: {
                email: value.email,
                firstName: value.firstName,
                lastName: value.lastName,
                memberId: value.memberId,
                memberID: value.memberID,
                memberState: value.memberState,
                name: value.name,
                role: value.role,
                status: value.status,
                type: 'member',
                department: value.department,
                image: value.image,
                jobTitle: value.jobTitle,
                pronouns: value.pronouns,
                username: value.username,
              },
            }),
          value: value.type === 'member' ? value.memberID : value.name,
          operator: 'is',
          perm: 'recipient',
        })),
      } satisfies RecognitionRecipientsCriteria['include'][number];
    }
  );

  return {
    include: [
      ...formattedCriteria,
      ...(everyoneSelected
        ? [
            {
              field: 'everyone',
              value: true,
              operator: 'is',
              perm: 'recipient',
            } satisfies RecognitionRecipientsCriteria['include'][number],
          ]
        : []),
    ],
  };
}
