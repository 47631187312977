import {
  type SearchPayload,
  SearchSortType,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useSearchParams } from 'react-router-dom';

import { useGetSelectedRewardsSecondaryFilters } from '../modules/discover/hooks/rewards/useGetSelectedRewardsSecondaryFilters';
import { useGetSecondaryFilters } from '../modules/discover/hooks/secondaryFilters/useGetSecondaryFilters';
import { useGlobalFilter } from '../modules/discover/hooks/useGlobalFilter';

export function useGetSearchResultPayload(): SearchPayload {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') ?? '';

  const filter = useGlobalFilter();

  const { data: userDetails } = useSuspenseUserDetails();

  const {
    fromRef,
    entityIn,
    postContentType,
    mentionedMemberIds,
    flowStatus,
    assignedToRef,
    taskState,
    fileMimeTypes,
    department,
    workLocation,
    homeLocation,
    jobTitle,
    dateCreated,
    dueDate,
    managers,
    challengeStatus,
  } = useGetSecondaryFilters();

  const { rewardsSecondaryFilters } = useGetSelectedRewardsSecondaryFilters();

  const searchResultPayload = {
    searchTerm,
    selectedFilters: [filter],
    sortBy: SearchSortType.LexicographicalAscending,
    from: 0,
    excludeRecognitionFlow: userDetails.member.status === 'observer',
    populateCardDetails: true,
    secondaryFilters: {
      dueDate,
      fromRef,
      entityIn,
      taskState,
      jobTitle,
      flowStatus,
      department,
      dateCreated,
      workLocation,
      managers,
      homeLocation,
      fileMimeTypes,
      assignedToRef,
      postContentType,
      mentionedMemberIds,
      challengeStatus,
    },
    rewardsSecondaryFilters,
  };

  return searchResultPayload;
}
