import type {
  CriteriaRuleType,
  ShareCriteria,
  UserDetails,
} from '@assembly-web/services';
import { PermissionType, useDebounce } from '@assembly-web/services';
import type { CriteriaItemProps } from '@assembly-web/ui';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import type { SharingRulesType } from '../../components/modals/ChallengeDefinition';
import { getCriteriaPayloadValue } from '../../services/criteriaUtil';
import { messages } from '../shareModal/messages';
import { usePollPreviewAPIForMembers } from '../shareModal/usePollPreviewAPIForMembers';

export type GetMembersForPreviewRequest = {
  criteriaType: 'simple';
  criteria: {
    include: ShareCriteria['include'];
    exclude: ShareCriteria['exclude'];
  };
};

const groupDataByField = (
  data: CriteriaItemProps[],
  isMetaRequired?: boolean
) => {
  const groupedData: Partial<
    Record<
      string,
      {
        value: string | boolean;
        operator: string;
        perm?: string | undefined;
      }[]
    >
  > = {};

  data.forEach((item) => {
    const currentMetaData = item.metaData as {
      field: CriteriaRuleType;
      value: string | boolean;
      operator: string;
    };

    const currentRulePayload = {
      value: getCriteriaPayloadValue(
        currentMetaData.field,
        currentMetaData.value
      ),
      operator: currentMetaData.operator,
      ...(item.permission?.id !== PermissionType.Excluded && {
        perm: item.permission?.id,
      }),
      ...(isMetaRequired && {
        meta: {
          email: item.subtitle,
          memberId: item.id,
          name: item.title,
          role: item.role,
          state: item.state,
          image: item.avatar?.image,
        },
      }),
    };

    if (groupedData[currentMetaData.field]) {
      groupedData[currentMetaData.field]?.push(currentRulePayload);
    } else {
      groupedData[currentMetaData.field] = [currentRulePayload];
    }
  });
  return groupedData;
};

export function generateSaveCriteriaPayloadFromRules(
  rules: CriteriaItemProps[],
  isMetaRequired = false
): SharingRulesType {
  const includedRules = rules.filter(
    (rule) => rule.permission?.id !== PermissionType.Excluded
  );
  const excludedRules = rules.filter(
    (rule) => rule.permission?.id === PermissionType.Excluded
  );

  return {
    sharingRules: {
      include: Object.keys(groupDataByField(includedRules, isMetaRequired)).map(
        (key) => {
          return key === 'everyone'
            ? {
                field: key as CriteriaRuleType,
                value: true,
                perm: groupDataByField(includedRules, isMetaRequired)[key]?.[0]
                  ?.perm,
                operator: groupDataByField(includedRules, isMetaRequired)[
                  key
                ]?.[0]?.operator,
              }
            : {
                field: key as CriteriaRuleType,
                values: groupDataByField(includedRules, isMetaRequired)[key],
              };
        }
      ),
      exclude: Object.keys(groupDataByField(excludedRules, isMetaRequired)).map(
        (key) => {
          return {
            field: key as CriteriaRuleType,
            values: groupDataByField(excludedRules, isMetaRequired)[key],
          };
        }
      ),
    },
  } as SharingRulesType;
}

export function usePreviewShareChallengeRules({
  rules,
  enabled,
  ownerId,
  currentUserDetails,
}: {
  rules: CriteriaItemProps[];
  enabled: boolean;
  ownerId: string;
  currentUserDetails: UserDetails;
}) {
  const {
    sharingRules: { include, exclude },
  } = generateSaveCriteriaPayloadFromRules(rules);

  const [previewMemberSearch, setPreviewMemberSearch] = useState('');
  const [previewMemberFilter, setPreviewMemberFilter] = useState('all');

  const debouncedPreviewMemberSearch = useDebounce(previewMemberSearch, 500);

  const { formatMessage } = useIntl();

  const previewFilterDefaultOptions = [
    {
      value: 'all',
      label: formatMessage(messages.all),
    },
    {
      value: 'permission:participant',
      label: formatMessage(messages.participant),
    },
    {
      value: 'permission:approver',
      label: formatMessage(messages.approver),
    },
  ];

  const previewCriteria: GetMembersForPreviewRequest = {
    criteriaType: 'simple',
    criteria: {
      include,
      exclude,
    },
  };

  const {
    data: members,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    totalMembersCount,
  } = usePollPreviewAPIForMembers({
    previewCriteria,
    previewMemberFilter: {
      search: debouncedPreviewMemberSearch,
      filter: previewMemberFilter,
    },
    enabled,
    flow: 'challenge',
  });

  useEffect(() => {
    if (!enabled) {
      setPreviewMemberFilter('all');
      setPreviewMemberSearch('');
    }
  }, [enabled]);

  const previewFilterOptions = enabled
    ? previewFilterDefaultOptions.map((option) => {
        const isSelected = previewMemberFilter === option.value;
        const count = isSelected
          ? formatMessage(messages.filterCount, {
              count: totalMembersCount,
            })
          : '';
        const label = `${option.label} ${
          isSelected && !isLoading && totalMembersCount > 0 ? count : ''
        }`;
        return { ...option, label };
      })
    : previewFilterDefaultOptions;

  const onSearchOrFilterList = ({
    searchTerm,
    filter,
  }: {
    searchTerm: string;
    filter: string;
  }) => {
    setPreviewMemberSearch(searchTerm);
    setPreviewMemberFilter(filter);
  };

  const displayRules = members.map((member) => {
    if (member.id === currentUserDetails.member.memberId) {
      return {
        ...member,
        title: formatMessage(messages.currentUserLabel, {
          currentUser: member.title,
        }),
        ...(member.id === ownerId
          ? {
              subtitle: formatMessage(messages.ownerSubtitle, {
                subTitle: member.subtitle,
              }),
            }
          : {}),
      };
    } else if (member.id === ownerId) {
      return {
        ...member,
        subtitle: formatMessage(messages.ownerSubtitle, {
          subTitle: member.subtitle,
        }),
      };
    } else {
      return member;
    }
  });

  return {
    displayRules,
    previewFilterOptions,
    onSearchOrFilterList,
    isPending: isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}
