import {
  getFeatureFlagTreatmentAsync,
  getUserDetailsQuery,
  type MemberAPIResponse,
  SplitNames,
} from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import { defer } from 'react-router-dom';

import { isAdminMember } from '../../discover/services/member';
import { getConnectionsQuery } from '../queries/getConnectionsQuery';

export async function connectionsLoader(queryClient: QueryClient) {
  return async function loader() {
    const { queryKey, queryFn } = getUserDetailsQuery();
    const userDetailsResponse = await queryClient.fetchQuery<MemberAPIResponse>(
      { queryKey, queryFn }
    );

    const treatment = await getFeatureFlagTreatmentAsync(
      SplitNames.AddMergeApps,
      userDetailsResponse.assembly.assemblyId
    );

    const isAdmin = isAdminMember(userDetailsResponse.member);
    if (treatment !== 'on' || !isAdmin) {
      return null;
    }

    queryClient.prefetchQuery(getConnectionsQuery());

    return defer({
      userDetailsResponse,
    });
  };
}
