import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQuery } from '@tanstack/react-query';

export const getLinkTokenQueryKey = ['linkToken'];
export function useGetLinkTokenQuery() {
  return useQuery({
    enabled: false,
    queryFn: async () => {
      const response = await assemblyAPI.get<{ linkToken: string }>(
        APIEndpoints.linkToken,
        {
          params: {
            category: 'filestorage',
          },
        }
      );

      return response.data.linkToken;
    },
    queryKey: getLinkTokenQueryKey,
    retry: false,
  });
}
