import { SVGReactComponents } from '@assembly-web/assets';
import { config, useSuspenseUserDetails } from '@assembly-web/services';
import {
  Button,
  IconButton,
  Modal,
  TextField,
  TextStyle,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ArrowUturnLeftIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { useAIRecognitionModal } from '../../hooks/useAIRecognitionModal';
import { trackDoraAction } from '../../services/analytics';
import { convertMarkDownResponseToHTML } from '../../services/dora';
import { DoraFeedbackSection } from '../dora/DoraFeedbackSection';
import { DoraLoadingLabel } from '../dora/DoraLoadingLabel';
import { useGetRecognitionWriterResponse } from './useGetRecognitionWriterResponse';

const messages = defineMessages({
  modalTitle: {
    defaultMessage: '🎉 Give Recognition',
    id: 'bcrMdz',
  },
  instructionsTitle: {
    defaultMessage: 'Welcome to AI Recognition Writer!',
    id: 'ebkNVf',
  },
  instructionsDescription: {
    defaultMessage:
      "Just tell me <b>who</b> you want to recognize and <b>why</b> in a few words, and I'll draft a thoughtful post for you.",
    id: '4Zkr8s',
  },
  instructionsDisclaimer: {
    defaultMessage:
      'Remember, this is just a draft - you can review and edit before posting.',
    id: 'WdUdOW',
  },
  defaultPromptPlaceholder: {
    defaultMessage: 'e.g. Recognize Tom for his demo',
    id: 'SADIps',
  },
  addEditsPlaceholder: {
    defaultMessage: 'Add edits here: e.g. Make it funnier, make it longer',
    id: 'jJ+pLs',
  },
  useDraftButton: {
    defaultMessage: 'Use draft',
    id: 'CQ++fE',
  },
  undoButton: {
    defaultMessage: 'Undo',
    id: 'JkS37H',
  },
});

export function AiRecognitionModal() {
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();

  const setAiRecognitionModalOpen = useAIRecognitionModal(
    (state) => state.setIsOpen
  );

  const { data: userDetails } = useSuspenseUserDetails();
  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const [prompt, setPrompt] = useState('');
  const [promptPlaceholder, setPromptPlaceholder] = useState(
    formatMessage(messages.defaultPromptPlaceholder)
  );
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPrompt(e.target.value);
  };

  const [searchParams] = useSearchParams();
  const triggerDraftOnOpenRef = useRef(false);

  const {
    getRecognitionDraft,
    recognitionDraft,
    isLoading,
    isStreaming,
    getPreviousDraft,
    currentDraftIndex,
    errorMessage,
    threadId,
  } = useGetRecognitionWriterResponse();

  const onSubmit = useCallback(() => {
    const recipientIds = searchParams.get('userId') || undefined;
    setPromptPlaceholder(formatMessage(messages.addEditsPlaceholder));
    getRecognitionDraft(prompt, recipientIds);
    setPrompt('');
    trackDoraAction('aiRecognitionPromptSent', {
      prompt,
      threadId,
      recipientIds,
    });
  }, [prompt, threadId, formatMessage, getRecognitionDraft, searchParams]);

  useEffect(() => {
    const userId = searchParams.get('userId');
    if (userId && !triggerDraftOnOpenRef.current) {
      triggerDraftOnOpenRef.current = true;
      const defaultPrompt = 'Write a nice post for this user';
      getRecognitionDraft(defaultPrompt, userId);
      trackDoraAction('aiRecognitionPromptSent', {
        prompt: defaultPrompt,
        recipientIds: userId,
      });
    }
  }, [searchParams, getRecognitionDraft]);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
  };

  return (
    <Modal
      isOpen
      title={
        <TextStyle variant="2xl-bold">
          {formatMessage(messages.modalTitle)}
        </TextStyle>
      }
      className="rounded-[8px] sm:max-w-[560px]"
      headerClassName="py-4 pl-6 pr-4"
      closeButtonSize="xLarge"
      onClose={() => {
        setAiRecognitionModalOpen(false);
        if (searchParams.get('useAIDraft')) {
          const updatedSearchParams = new URLSearchParams(searchParams);
          updatedSearchParams.delete('useAIDraft');
          navigate(
            { search: updatedSearchParams.toString() },
            { replace: true }
          );
        }
      }}
    >
      <div>
        <p className="mb-5">{formatMessage(messages.instructionsTitle)}</p>
        <TextStyle className="mb-5">
          {formatMessage(messages.instructionsDescription, {
            b: (str) => <strong>{str}</strong>,
          })}
        </TextStyle>
        <TextStyle className="mb-5">
          {formatMessage(messages.instructionsDisclaimer)}
        </TextStyle>
        <div className="rounded-lg bg-primary-1 p-4 align-bottom">
          <div className="flex gap-2">
            <TextField
              value={prompt}
              placeholder={promptPlaceholder}
              onChange={handleChange}
              onKeyDown={handleOnKeyDown}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />

            <IconButton variation="secondaryEmphasized" onClick={onSubmit}>
              <SVGReactComponents.SendIcon className="h-6 w-6" />
            </IconButton>
          </div>
          {Boolean(isLoading || isStreaming || recognitionDraft?.content) && (
            <hr className="mb-4 mt-6 border-gray-5" />
          )}

          {Boolean(isLoading) && <DoraLoadingLabel label="Writing" />}

          <TextStyle
            as="span"
            html={
              convertMarkDownResponseToHTML(recognitionDraft?.content ?? '', {
                showCursor: isStreaming,
              }) as string
            }
          />
          {Boolean(recognitionDraft?.content) && Boolean(!isStreaming) && (
            <div className="mt-4">
              <DoraFeedbackSection
                feedbackSource="aiRecognition"
                markdownResponse={recognitionDraft?.content ?? null}
                promptId={recognitionDraft?.promptId ?? null}
                displayCopyButton={false}
              />
            </div>
          )}
          {Boolean(errorMessage) && (
            <p className="mt-4 text-error-6">{errorMessage}</p>
          )}

          {!isStreaming && Boolean(recognitionDraft) && (
            <div className="flex gap-2 pt-8">
              <Button
                size="small"
                variation="primary"
                onClick={() => {
                  trackDoraAction('useDraftClicked', {
                    promptId: recognitionDraft?.promptId,
                    threadId,
                  });
                  const params = new URLSearchParams();
                  params.append('redirectUrl', config.domains.app);
                  params.append('fromWeb', 'aiRecognitionModal');
                  params.append('threadId', threadId ?? '');
                  params.append('postMessage', recognitionDraft?.content ?? '');
                  let recognitionUrl = `/a/${workspaceSlugPath}/flows/recognition/answer?${params.toString()}`;
                  if (
                    recognitionDraft?.users &&
                    recognitionDraft.users.length > 0
                  )
                    recognitionUrl += `&userId=${recognitionDraft.users.join(',')}`;

                  window.location.href = recognitionUrl;
                }}
              >
                <CheckIcon className="h-4 w-4" />
                {formatMessage(messages.useDraftButton)}
              </Button>
              {currentDraftIndex > 0 && (
                <Button
                  size="small"
                  variation="secondaryLite"
                  onClick={() => {
                    trackDoraAction('undoDraftClicked', {
                      promptId: recognitionDraft?.promptId,
                      threadId,
                    });
                    getPreviousDraft();
                  }}
                >
                  <ArrowUturnLeftIcon className="h-4 w-4" />
                  {formatMessage(messages.undoButton)}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
