import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export type TimeOption = {
  id: string;
  label: string;
};

function useFormatTime() {
  const { formatTime } = useIntl();

  return (date: Date): string => {
    return formatTime(date, {
      hour12: true,
      hour: 'numeric',
      minute: '2-digit',
    });
  };
}

export function useGetTimeOptions(): TimeOption[] {
  const formatTime = useFormatTime();

  return useMemo(() => {
    const options: TimeOption[] = [];
    const date = new Date(0);
    date.setHours(0, 0, 0, 0);

    for (let i = 0; i < 24 * 4; i++) {
      const formattedTime = formatTime(date);
      options.push({
        id: formattedTime,
        label: formattedTime,
      });
      date.setMinutes(date.getMinutes() + 15);
    }

    return options;
  }, [formatTime]);
}
