import {
  config,
  type Icon,
  isTruthy,
  mapHexCodeToEmoticon,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { OverflowText, useAssemblyNavigate } from '@assembly-web/ui';
import { useCallback } from 'react';
import { twJoin } from 'tailwind-merge';

import { NavItem } from './NavItem';

export type FlowNavItemDetails = {
  id: string;
  icon: Icon;
  name: string;
  hasUnreadPosts: boolean;
  hasUnreadMentions: boolean;
};

function FlowIcon({ icon }: { icon: string }) {
  return (
    <div
      className="flex h-4 w-4 items-center justify-start gap-2 py-1"
      aria-hidden="true"
    >
      {mapHexCodeToEmoticon(icon)}
    </div>
  );
}

function useFlowNavItemClickHandler() {
  const { data: userDetails } = useSuspenseUserDetails();
  const navigate = useAssemblyNavigate();
  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  return useCallback(
    ({ id }: { id: string }) => {
      const params = new URLSearchParams();
      params.append('redirectUrl', `${config.domains.app}/a/`);
      const urlToNavigate = `/${workspaceSlugPath}/flows/${id}?${params.toString()}`;

      navigate(urlToNavigate);

      // This is a hack to reload the legacy embeds when navigating between flows in the sidebar
      setTimeout(() => {
        document.dispatchEvent(new CustomEvent('reload-legacy-embed'));
      }, 100);
    },
    [navigate, workspaceSlugPath]
  );
}

export function FlowNavItem({ flow }: { flow: FlowNavItemDetails }) {
  const onClick = useFlowNavItemClickHandler();

  return (
    <NavItem.Button
      key={flow.id}
      textClassName="flex flex-1 min-w-0 justify-between gap-1"
      className="h-auto py-1"
      onClick={() => onClick({ id: flow.id })}
      icon={<FlowIcon icon={flow.icon.value} />}
      text={
        <div className="flex items-center justify-between">
          <OverflowText
            tooltipText={flow.name}
            className={twJoin(
              '!block truncate',
              flow.hasUnreadPosts && '!font-bold'
            )}
          >
            {flow.name}
          </OverflowText>
          {isTruthy(flow.hasUnreadMentions) && (
            <span className="font-bold">@</span>
          )}
        </div>
      }
    />
  );
}
