import { useParticipationModalContext } from '@assembly-web/participation';
import {
  SplitNames,
  useFeatureSplit,
  useRecognitionParticipationRoute,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useEffect } from 'react';

import { LegacyRoute } from '../discover/pages/legacy-path';

export function RecognitionParticipationRoute() {
  const { isTreatmentActive: isRecognitionParticipationV3Enabled } =
    useFeatureSplit(SplitNames.RecognitionParticipationV3);
  const navigate = useAssemblyNavigate();
  const { isEditFlow, editPostId } = useRecognitionParticipationRoute();
  const { openModal } = useParticipationModalContext();
  const { data } = useSuspenseUserDetails();
  const workspacePath = data.assembly.workspaceSlugPath;

  useEffect(() => {
    if (!isRecognitionParticipationV3Enabled) {
      return;
    }

    if (isEditFlow) {
      openModal(editPostId);
    } else {
      openModal();
    }
    navigate(`/a/${workspacePath}/flows/recognition`, { replace: true });
  }, []);

  if (isRecognitionParticipationV3Enabled) {
    return null;
  } else {
    return <LegacyRoute />;
  }
}
