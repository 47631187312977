import { assemblyLogo } from '@assembly-web/assets';
import { useSuspenseUserDetails } from '@assembly-web/services';
import {
  DynamicHeaderText,
  Tooltip,
  TooltipEnabledContext,
  useAssemblyNavigate,
  useDeviceInfo,
} from '@assembly-web/ui';
import { useCallback } from 'react';

export function NavHeader() {
  const { data: userDetails } = useSuspenseUserDetails();
  const navigate = useAssemblyNavigate();
  const { isMobileDevice } = useDeviceInfo();

  const handleHeaderClick = useCallback(() => {
    navigate('/');

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [navigate]);

  const { name: assemblyName, logo } = userDetails.assembly;
  const isAssemblyNameTooLong = assemblyName.length > 60;

  const isTooltipEnabled = isAssemblyNameTooLong || !isMobileDevice;

  return (
    <TooltipEnabledContext.Provider value={isTooltipEnabled}>
      <Tooltip tooltipText={assemblyName}>
        <button
          className="mr-4 inline-flex cursor-pointer items-center gap-2 md:min-h-16 md:gap-6"
          onClick={handleHeaderClick}
        >
          <img src={logo ? logo : assemblyLogo} alt="" className="size-8" />
          <DynamicHeaderText text={assemblyName} />
        </button>
      </Tooltip>
    </TooltipEnabledContext.Provider>
  );
}
