import {
  APIEndpoints,
  assemblyAPI,
  type ChallengeProof,
  type ChallengeState,
  type ClaimState,
  type MemberDetails,
  type Proof,
} from '@assembly-web/services';
import type { UseMutationOptions } from '@tanstack/react-query';

export type ClaimDetailsResponse = {
  claimId: string;
  claimDate: string;
  points: number;
  state: ClaimState;
  proof?: Proof;
  challenge: {
    challengeId: string;
    title: string;
    state: ChallengeState;
    proof: ChallengeProof;
  };
  claimedBy: MemberDetails;
  actionTakenBy?: MemberDetails;
  actionDate?: string;
};

export function getClaimDetailsQuery(
  claimId: string,
  options?: UseMutationOptions<ClaimDetailsResponse>
): UseMutationOptions<ClaimDetailsResponse> {
  return {
    ...options,
    mutationKey: ['claimDetails', claimId],
    mutationFn: async () => {
      const response = await assemblyAPI.get<ClaimDetailsResponse>(
        APIEndpoints.getClaimDetails(claimId)
      );
      return response.data;
    },
  };
}
