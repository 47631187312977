import { mapHexCodeToEmoticon } from '@assembly-web/services';
import { FormattedMessage } from 'react-intl';

import { useGiveRecognitionFlowDetails } from '../../hooks/useGiveRecognitionFlowDetails';

export function Title() {
  const {
    data: {
      flowDetails: { name, icon },
    },
  } = useGiveRecognitionFlowDetails();

  return (
    <FormattedMessage
      defaultMessage="{icon} {name}"
      id="4OjfWu"
      values={{ icon: mapHexCodeToEmoticon(icon.value), name }}
    />
  );
}
