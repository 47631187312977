import { useCallback } from 'react';

import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import type { ChatData } from '../../components/Drawers/types';

export const useCreateChatWindow = () => {
  const createDrawer = useMultiDrawerStore((state) => state.createDrawer);

  return useCallback(
    (data: ChatData) => {
      createDrawer({
        type: 'chat',
        title: 'Chat',
        data: { ...data, memberId: data.memberId },
      });
    },
    [createDrawer]
  );
};
