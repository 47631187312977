import { useFetchRecognitionDraft } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { defineMessage, useIntl } from 'react-intl';

const draftLabel = defineMessage({
  defaultMessage: 'Draft',
  id: 'W6nwjo',
});

export function DraftBadge() {
  const { data } = useFetchRecognitionDraft();
  const isDraftAvailable = 'postData' in data.draft && data.draft.postData;
  const { formatMessage } = useIntl();
  return (
    Boolean(isDraftAvailable) && (
      <TextStyle
        className="mx-4 rounded-lg bg-upgrade-2 p-1 uppercase text-gray-10"
        variant="sm-medium"
      >
        {formatMessage(draftLabel)}
      </TextStyle>
    )
  );
}
