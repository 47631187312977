import {
  type DraftPayload,
  isTruthy,
  useSaveDrafts,
} from '@assembly-web/services';
import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

export function Draft({
  getPayloadForSavingDraft,
}: {
  getPayloadForSavingDraft: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fields: Record<string, any>
  ) => DraftPayload | null;
}) {
  const { mutate: saveDraft } = useSaveDrafts();
  const { watch, getFieldState } = useFormContext();
  const getPayloadForSavingDraftRef = useRef(getPayloadForSavingDraft);

  useEffect(() => {
    getPayloadForSavingDraftRef.current = getPayloadForSavingDraft;
  }, [getPayloadForSavingDraft]);

  useEffect(() => {
    const { unsubscribe } = watch(
      debounce((fields) => {
        const keys = Object.keys(fields);
        const isChanged = keys.some(
          (key) => getFieldState(key).isDirty && getFieldState(key).isTouched
        );
        if (isChanged) {
          const payload = getPayloadForSavingDraftRef.current(fields);
          if (isTruthy(payload)) {
            saveDraft(payload);
          }
        }
      }, 750)
    );

    return () => {
      unsubscribe();
    };
  }, [saveDraft, getFieldState, watch]);
  return null;
}
