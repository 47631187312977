import { APIEndpoints, type RedeemInfoResponse } from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import { useQueries } from '@tanstack/react-query';

export function useRedeemRewardDetailsQuery() {
  return useQueries({
    queries: [
      {
        queryKey: ['redeemRewardDetails'],
        queryFn: async () => {
          const response = await assemblyAPI.get<RedeemInfoResponse>(
            APIEndpoints.redeem
          );
          return response.data;
        },
      },
    ],
  });
}
