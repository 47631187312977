import {
  APIEndpoints,
  getAPIErrorCode,
  getFeatureFlagTreatmentAsync,
  getProviderName,
  lookupSSOProvider,
  SplitNames,
  SSOProvider,
  unauthenticatedAssemblyAPI,
  userAuthStore,
} from '@assembly-web/services';
import {
  AppLink,
  Banner,
  Button,
  HorizontalRule,
  RouterForm,
  TextField,
  validateForm,
} from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { defineMessages, useIntl } from 'react-intl';
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom';
import {
  redirect,
  useActionData,
  useNavigation,
  useSearchParams,
  useSubmit,
} from 'react-router-dom';
import { z } from 'zod';

import {
  trackRegistrationAction,
  trackRegistrationError,
} from '../../../services/analytics';
import type { ReactRouterActionResponse } from '../../../types/libs';
import { OnboardingContainer } from '../components/OnboardingContainer';
import { SSOButton } from '../components/SSOButton';
import { useGooglePlatformScriptLoader } from '../hooks/useGooglePlatformScriptLoader';
import { handleSSOForAccountCreation } from '../services/sso';

const accountCreationFormText = defineMessages({
  title: {
    defaultMessage: 'Work smarter, not harder.',
    id: '22KtHx',
  },
  description: {
    defaultMessage:
      'Enter your email or choose your preferred authenticator to get started with Assembly. Get ready to save your team up to 1 day per week with our easy-to-use flows.',
    id: 'bnbjsR',
  },
  footer: {
    defaultMessage:
      'Already using Assembly? <cta>Log into an existing workspace</cta>',
    id: 'gWiQ1v',
  },
  emailSubmissionButton: {
    defaultMessage: 'Continue',
    id: 'acrOoz',
  },
  emailErrorMessage: {
    defaultMessage: 'Enter a valid email',
    id: 'PFfzPv',
  },
  inputPlaceholder: {
    defaultMessage: 'Enter your email address',
    id: 'NgCT/u',
  },
  horizontalSeparator: {
    defaultMessage: 'OR',
    id: 'INlWvJ',
  },
  createAccountPageTitle: {
    defaultMessage: 'Create a new workspace',
    id: 'DNFOtk',
  },
});

const accountCreationFormErrors = defineMessages({
  popup_closed_by_user: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Google",
    id: '+ONxsm',
  },
  google_access_denied: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Google",
    id: '+ONxsm',
  },
  slack_access_denied: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Slack",
    id: 'tDrT7b',
  },
  office365_consent_required: {
    defaultMessage:
      "Whoops, you declined Assembly's permission to connect with Office365",
    id: 'ByNMYS',
  },
  account_creation_failed: {
    defaultMessage: 'Whoops! Some wires got crossed. Please try again.',
    id: 'vfZhnr',
  },
  too_many_requests_per_user: {
    defaultMessage:
      'Maximum number of attempts reached. Please try again in 30 minutes.',
    id: 'hM+Sro',
  },
  code_generation_blocked: {
    defaultMessage: 'You can request a new code in 30 seconds',
    id: '8eFffF',
  },
  assembly_not_active: {
    defaultMessage:
      'We couldn’t find this workspace. Create a new one by signing up with Google, Slack, Office 365, or entering your email.',
    id: 'E74jRZ',
  },
  assembly_not_found: {
    defaultMessage:
      'We couldn’t find this workspace. Create a new one by signing up with Google, Slack, Office 365, or entering your email.',
    id: 'E74jRZ',
  },
  user_deactivated: {
    defaultMessage:
      'We couldn’t find this workspace. Create a new one by signing up with Google, Slack, Office 365, or entering your email.',
    id: 'E74jRZ',
  },
  user_not_found: {
    defaultMessage: 'We couldn’t find a workspace associated to your email. ',
    id: 'QdvBwh',
  },
  forbidden: {
    defaultMessage:
      'We couldn’t find a workspace associated to your email. Create a new one by signing up with Google, Slack, Office 365, or entering your email.',
    id: 'cfCfRR',
  },
});

export function CreateAccountRoute() {
  const { formatMessage } = useIntl();

  const actionData = useActionData() as ReactRouterActionResponse<
    typeof createAccountAction
  >;
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const errorCode = actionData?.error ?? searchParams.get('error');

  const isKnownEmailError =
    errorCode && Object.keys(accountCreationFormErrors).includes(errorCode);

  if (errorCode) {
    trackRegistrationError('ssoClicked', {});
  }

  const isPageLoading = navigation.state === 'loading';
  const isSubmissionInProgress = navigation.state === 'submitting';

  const submitFn = useSubmit();
  useGooglePlatformScriptLoader();

  const createAccountWithSSO = useCallback(
    function createAccountWithSSO(ssoProvider: SSOProvider) {
      const formData = new FormData();
      formData.append('provider', ssoProvider.toLowerCase());

      trackRegistrationAction('ssoClicked', {
        ssoType: getProviderName(ssoProvider).toLowerCase(),
        ssoEnforced: userAuthStore.getState().isEnforcedAuth,
      });

      return submitFn(formData);
    },
    [submitFn]
  );

  const infoCodes = [
    'assembly_not_active',
    'user_not_found',
    'user_deactivated',
    'assembly_not_found',
    'forbidden',
  ];

  return (
    <OnboardingContainer
      title={formatMessage(accountCreationFormText.title)}
      description={formatMessage(accountCreationFormText.description)}
      footer={formatMessage(accountCreationFormText.footer, {
        cta: (text: ReactNode[]) => (
          <AppLink
            to="/login"
            testId="loginToExistingWorkspaceLink"
            onClick={() => trackRegistrationAction('signUpInsteadClicked')}
          >
            {text}
          </AppLink>
        ),
      })}
    >
      <Helmet>
        <title>
          {formatMessage(accountCreationFormText.createAccountPageTitle)}
        </title>
      </Helmet>

      {isKnownEmailError && errorCode ? (
        infoCodes.includes(errorCode) ? (
          <Banner status="info">
            {formatMessage(
              accountCreationFormErrors[
                errorCode as keyof typeof accountCreationFormErrors
              ]
            )}
          </Banner>
        ) : (
          <Banner status="error">
            {formatMessage(
              accountCreationFormErrors[
                errorCode as keyof typeof accountCreationFormErrors
              ]
            )}
          </Banner>
        )
      ) : null}
      <section className="grid grid-flow-col grid-cols-1 grid-rows-3 gap-3">
        <div
          id="google-signin-button"
          className="col-span-1 rounded-md border border-gray-9"
        ></div>

        <SSOButton
          flow="signup"
          provider={SSOProvider.Slack}
          onClick={() => createAccountWithSSO(SSOProvider.Slack)}
          className="col-span-1 whitespace-nowrap"
        />

        <SSOButton
          flow="signup"
          provider={SSOProvider.Office365}
          onClick={() => createAccountWithSSO(SSOProvider.Office365)}
          className="col-span-1 whitespace-nowrap"
        />
      </section>

      <HorizontalRule>
        {formatMessage(accountCreationFormText.horizontalSeparator)}
      </HorizontalRule>

      <RouterForm>
        <TextField
          name="email"
          type="email"
          label={formatMessage(accountCreationFormText.inputPlaceholder)}
          hideLabel
          placeholder={formatMessage(accountCreationFormText.inputPlaceholder)}
          autoComplete="email"
          spellCheck={false}
          invalidTextTestId="email-error"
          isInvalid={Boolean(actionData?.error) && !isKnownEmailError}
          invalidText={formatMessage(accountCreationFormText.emailErrorMessage)}
          required
        />
        <Button
          type="submit"
          isFullWidth
          isLoading={isSubmissionInProgress}
          disabled={isSubmissionInProgress || isPageLoading}
        >
          {formatMessage(accountCreationFormText.emailSubmissionButton)}
        </Button>
      </RouterForm>
    </OnboardingContainer>
  );
}

export async function createAccountLoader({
  request: { url },
}: LoaderFunctionArgs) {
  const provider = new URL(url).searchParams.get('provider');
  const allowAssemblyCreation = await getFeatureFlagTreatmentAsync(
    SplitNames.AllowAssemblyCreation
  );

  if (allowAssemblyCreation === 'off') {
    return redirect('/login');
  }

  if (!provider) {
    return null;
  }

  const ssoProvider = lookupSSOProvider(provider);

  if (ssoProvider) {
    userAuthStore.getState().updateUserAuthFlow('create-account');
    handleSSOForAccountCreation(ssoProvider);
    return null;
  }
}

export async function createAccountAction({ request }: ActionFunctionArgs) {
  const formData = await request.formData();

  const provider = formData.get('provider');

  if (provider) {
    return null;
  }

  userAuthStore.getState().updateUserAuthFlow('create-account');

  const invalidEmailCode = 'invalid_email';
  const formSchema = z.object({
    email: z.string().email(invalidEmailCode),
  });

  try {
    const { email } = validateForm(formSchema, formData);

    await unauthenticatedAssemblyAPI.post(
      APIEndpoints.generateEmailVerificationCode,
      { email, type: 'signup' },
      { signal: request.signal }
    );

    trackRegistrationAction('emailEntered', { email });

    return redirect(`/verify-email/signup?email=${encodeURIComponent(email)}`);
  } catch (error) {
    const errorCode = getAPIErrorCode(error);

    trackRegistrationError('emailEntered', {
      email: formData.get('email')?.toString(),
    });

    if (
      ['too_many_requests_per_user', 'code_generation_blocked'].includes(
        errorCode
      )
    ) {
      return { error: errorCode };
    }

    return { error: invalidEmailCode };
  }
}
