import {
  APIEndpoints,
  type Member,
  type MetadataProps,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  type InfiniteData,
  type PlaceholderDataFunction,
  type UndefinedInitialDataInfiniteOptions,
  useInfiniteQuery,
} from '@tanstack/react-query';

export type GetMembersForChallengeResponse = {
  data: {
    total: number;
    metadata: MetadataProps;
    data: (Member & { isChallengeViewer: boolean })[];
  };
};

function getChallengeMembersSearchQuery({
  challengeId,
  searchTerm = null,
  placeholderData,
}: {
  challengeId: string;
  searchTerm: string | null;
  placeholderData?: PlaceholderDataFunction<
    InfiniteData<GetMembersForChallengeResponse>
  >;
}): UndefinedInitialDataInfiniteOptions<GetMembersForChallengeResponse> {
  return {
    queryKey: ['getMembersForChallenge', challengeId, searchTerm],
    queryFn: async ({ pageParam }) =>
      await assemblyAPI.get(APIEndpoints.searchChallengeMembers(challengeId), {
        params: {
          ...(pageParam ? { cursor: pageParam } : {}),
          ...(searchTerm ? { keyword: searchTerm, limit: 5 } : {}),
        },
      }),
    initialPageParam: 0,
    getNextPageParam: (page) =>
      page.data.metadata.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page) =>
      page.data.metadata.pagination.cursor.previous ?? undefined,
    placeholderData,
  };
}

export function useChallengeMembersSearchQuery({
  placeholderData,
  challengeId,
  searchTerm = null,
}: {
  challengeId: string;
  searchTerm: string | null;
  placeholderData?: PlaceholderDataFunction<
    InfiniteData<GetMembersForChallengeResponse>
  >;
}) {
  const result = useInfiniteQuery<GetMembersForChallengeResponse>(
    getChallengeMembersSearchQuery({ placeholderData, challengeId, searchTerm })
  );

  const members = result.data?.pages.flatMap((x) => x.data.data) ?? [];

  return {
    ...result,
    members,
  };
}
