import {
  doraAPI,
  DoraAPIEndpoints,
  type DoraChatHistoryAPIResponse,
  logger,
} from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { defineMessages, useIntl } from 'react-intl';

import { getDoraChatHistoryQueryKey } from '../../queries/getDoraChatHistoryQuery';
import { trackDoraAction } from '../../services/analytics';
import {
  type DoraSavedPrompt,
  useSavedReportsQueryKey,
} from './useSavedReports';

const messages = defineMessages({
  errorMessage: {
    defaultMessage: 'There was an error while saving prompt. Please try again.',
    id: 'o/t76b',
  },
});

export function useDoraSavedPrompt({ onSuccess }: { onSuccess: () => void }) {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const { showErrorToast } = useToastStore();

  return useMutation({
    mutationFn: async (payload: { messageId: string; threadId: string }) => {
      trackDoraAction('savePromptClicked');
      const { data } = await doraAPI.post(DoraAPIEndpoints.savePrompt, payload);
      return data;
    },
    onError: (error: Error, payload) => {
      logger.error('error saving dora prompt', payload, error);
      showErrorToast(formatMessage(messages.errorMessage));
    },

    onSuccess: async function (data, { messageId, threadId }) {
      await queryClient.invalidateQueries({
        queryKey: useSavedReportsQueryKey,
      });

      const doraChatHistoryQueryKey = getDoraChatHistoryQueryKey(threadId);
      const reportMessages =
        queryClient.getQueryData<DoraChatHistoryAPIResponse>(
          doraChatHistoryQueryKey
        );

      const updatedDoraChatHistory = reportMessages?.map((message) => {
        if (message.messageId === messageId) {
          return { ...message, isSavedPrompt: true };
        }
        return message;
      });

      queryClient.setQueryData(doraChatHistoryQueryKey, updatedDoraChatHistory);

      onSuccess();

      const {
        data: { reportName },
      } = await doraAPI.post(DoraAPIEndpoints.generateSavedReportTitle, {
        reportId: data.id,
      });

      const savedMessages = queryClient.getQueryData<DoraSavedPrompt[]>(
        useSavedReportsQueryKey
      );

      if (savedMessages) {
        queryClient.setQueryData(
          useSavedReportsQueryKey,
          produce(savedMessages, (draft) => {
            return draft.map((message) => {
              if (message.id === data.id) {
                return {
                  ...message,
                  reportName,
                };
              }
              return message;
            });
          })
        );
      } else {
        queryClient.invalidateQueries({
          queryKey: useSavedReportsQueryKey,
        });
      }
    },
  });
}
