import {
  CriteriaRuleType,
  type ManagerCriteriaAPIResponse,
  type MemberAPIResponse,
  type MemberCriteriaAPIResponse,
  MemberState,
  type NonMemberCriteriaAPIResponse,
  Operator,
  PermissionType,
  type SelectablePeopleTypes,
  type ShareCriteria,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import {
  BlockParticipants,
  type CriteriaItemProps,
  type PeopleWithAccessProps,
  type PreviewMembersProps,
  ShareModal,
  ShareModalContent,
} from '@assembly-web/ui';
import { useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { useImmer } from 'use-immer';

import {
  useGetBlockParticipants,
  useGetCustomCriteria,
  useGetCustomPersonSelectorCount,
  useGetCustomPersonSelectorCountInitialized,
  useInitializeCustomParticipantsCount,
  useSetBlockParticipants,
  useSetCustomParticipants,
} from '../../../../../../stores/useFlowBuilderStore';
import { usePreviewRules } from '../../../../hooks/shareModal/usePreviewRules';
import {
  operatorLabelMap,
  useShareSheet,
} from '../../../../hooks/shareModal/useShareSheet';
import { useShareSheetCriteriaRules } from '../../../../hooks/shareModal/useShareSheetCriteriaRules';
import { useShareSheetMemberSearch } from '../../../../hooks/shareModal/useShareSheetMemberSearch';
import {
  trackFlowEditorAction,
  trackSharesheetAction,
} from '../../../../services/analytics';
import { useBlockIdContext } from '../context/BlockIdContext';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';
import { useFormattedBlockName } from '../hooks/useFormattedBlockName';

const messages = defineMessages({
  tabTitle: {
    defaultMessage:
      'Choose who is selectable in this Person Selection question',
    id: '7UHp9V',
  },
  peopleWithAccess: {
    defaultMessage: 'Selectable people',
    id: 'eBzg+H',
  },
  previewSubTitle: {
    defaultMessage:
      'See who will be available for selection in this Person Selection dropdown',
    id: 'afRfHc',
  },
});

function groupedCriteriaData(items: CriteriaItemProps[]): ShareCriteria {
  return {
    exclude: [],
    include: (() => {
      const group: Record<
        string,
        (
          | MemberAPIResponse
          | NonMemberCriteriaAPIResponse
          | ManagerCriteriaAPIResponse
        )[]
      > = {};

      for (const item of items) {
        if (item.permission?.id === PermissionType.Excluded) {
          continue;
        }

        let data:
          | MemberCriteriaAPIResponse
          | NonMemberCriteriaAPIResponse
          | ManagerCriteriaAPIResponse
          | undefined;
        const currentMetaData = item.metaData as {
          field: CriteriaRuleType;
          value: string | boolean;
          operator: Operator;
          name?: string;
          email?: string;
        };

        if (currentMetaData.field === CriteriaRuleType.Everyone) {
          data = {
            operator: currentMetaData.operator,
            perm: PermissionType.Custom,
            value: true as unknown as string,
          } satisfies NonMemberCriteriaAPIResponse;
        }
        if (
          currentMetaData.field === CriteriaRuleType.Email ||
          currentMetaData.field === CriteriaRuleType.Member
        ) {
          data = {
            operator: currentMetaData.operator,
            value: currentMetaData.value as string,
            meta: {
              email: currentMetaData.email ?? '',
              image: item.avatar?.image ?? '',
              memberId: currentMetaData.value as string,
              name: item.title,
              state: item.state ?? MemberState.Active,
              role: item.role,
            },
            perm: PermissionType.Custom,
          } satisfies MemberCriteriaAPIResponse;
        } else if (currentMetaData.field === CriteriaRuleType.ManagerStatus) {
          data = {
            operator: currentMetaData.operator,
            perm: PermissionType.Approver,
            value: currentMetaData.value === 'true',
          } satisfies ManagerCriteriaAPIResponse;
        } else {
          data = {
            operator: currentMetaData.operator,
            perm: PermissionType.Custom,
            value: currentMetaData.value as string,
          } satisfies NonMemberCriteriaAPIResponse;
        }

        group[currentMetaData.field] = [
          ...(group[currentMetaData.field] ?? []),
          data,
        ];
      }

      return Object.entries(group).map(([field, values]) => ({
        field: field as CriteriaRuleType,
        values,
      })) as ShareCriteria['include'];
    })(),
  };
}

function ParticipantsModal({
  isOpen,
  onClose,
  onDone,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDone: (args: {
    criteriaItems: ShareCriteria | null;
    newUsersToInvite: string[];
    total: number;
  }) => void;
}) {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const customCriteriaGroup = useGetCustomCriteria(id, blockId);
  const [initialCustomCriteriaGroup] = useState(() => customCriteriaGroup);

  const [emailsToInvite, setEmailsToInvite] = useState<string[]>([]);
  const [rules, setRules] = useImmer<CriteriaItemProps[]>([]);
  const [view, setView] = useState<'peopleWithAccess' | 'previewMembers'>(
    'peopleWithAccess'
  );
  const [, setHasRulesChanged] = useState<boolean>(false);

  const { formatMessage } = useIntl();

  const { data: userDetails } = useUserDetails();
  invariant(userDetails, 'User details should be available');

  const customCriteriaCountInitialized =
    useGetCustomPersonSelectorCountInitialized(id, blockId);
  const initialize = useInitializeCustomParticipantsCount(id, blockId);

  const {
    customRules,
    customRuleOptions,
    onCustomRuleSelect,
    onCustomRulesAction,
    selectedCustomRule,
    onRulesPermissionChange,
    onRulesSelectionChange,
    handleOnEmailInvite: onEmailInvite,
  } = useShareSheet({
    enabled: view === 'peopleWithAccess',
    rules,
    setRules,
    type: 'peopleSelector',
    onEmailInvite() {
      trackSharesheetAction({
        actionName: 'inviteMemberClicked',
        props: {
          criteriaSelected: `${CriteriaRuleType.Email}${
            operatorLabelMap[Operator.Is]
          }`,
          roleSelected: PermissionType.Viewer,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'peopleSelector',
        },
      });
    },
    onRemoveRule({ currentRule }) {
      trackSharesheetAction({
        actionName: 'removeMemberClicked',
        props: {
          criteriaSelected: `${currentRule.metaData?.field}${
            currentRule.metaData?.operator &&
            operatorLabelMap[currentRule.metaData.operator]
          }`,
          roleSelected: currentRule.permission?.id,
          ruleType: 'simple',
          isV3sharesheet: true,
          sharesheetType: 'peopleSelector',
        },
      });
    },
  });

  useShareSheetCriteriaRules({
    rules,
    setRules,
    type: 'peopleSelector',
    initialRules: initialCustomCriteriaGroup,
    setHasRulesChanged,
    owner: {
      ownerId: '',
    },
  });

  const {
    isLoadingMembers,
    hasNextPage,
    isFetchingNextPage,
    searchTerm,
    members,
    onMemberSearch,
    onLoadMore,
    canInviteCurrentUser,
    hasUserInvitePermission,
  } = useShareSheetMemberSearch({
    enabled: view === 'peopleWithAccess',
  });

  const {
    displayRules,
    onSearchOrFilterList,
    isLoading: isLoadingPreview,
    isFetchingNextPage: isFetchingNextPageForPreview,
    hasNextPage: hasNextPageForPreview,
    fetchNextPage: fetchNextPageForPreview,
    totalMembersCount,
  } = usePreviewRules({
    rules,
    enabled: rules.length > 0,
    flow: 'generic',
  });

  const handleOnEmailInvite = (email: string) => {
    setEmailsToInvite((prev) => [...prev, email]);
    onEmailInvite(email);
  };

  const handleClose = () => {
    onClose();
  };

  const handleDone = () => {
    if (rules.length === 0) {
      onDone({ criteriaItems: null, newUsersToInvite: [], total: 0 });
      return;
    }

    const payload = groupedCriteriaData(rules);
    const newUsersToInvite = (() => {
      const emailCriteria = payload.include.find(
        (member) => member.field === CriteriaRuleType.Email
      );
      return emailsToInvite.filter(
        (email) =>
          emailCriteria?.field === CriteriaRuleType.Email &&
          emailCriteria.values.some((value) => value.value === email)
      );
    })();

    onDone({
      criteriaItems: payload,
      newUsersToInvite,
      total: totalMembersCount,
    });
  };

  useEffect(() => {
    if (
      !customCriteriaCountInitialized &&
      rules.length > 0 &&
      !isLoadingPreview &&
      totalMembersCount
    ) {
      initialize(totalMembersCount);
    }
  }, [
    customCriteriaCountInitialized,
    initialize,
    isLoadingPreview,
    rules.length,
    totalMembersCount,
  ]);

  const props: PeopleWithAccessProps | PreviewMembersProps =
    view === 'peopleWithAccess'
      ? {
          view: 'peopleWithAccess',
          customRuleSelectorProps: {
            customRuleOptions,
            onCustomRulesAction,
            onCustomRuleSelect,
            ruleOptions: customRuleOptions,
            selectedCustomRule,
          },
          rulesListProps: {
            hasNextPageForRules: false,
            onRulesPermissionChange,
            rules,
          },
          searchableRulesDropdownProps: {
            canInviteEmail: canInviteCurrentUser ?? false,
            handleOnEmailInvite,
            customRules,
            hasNextPageForMembers: hasNextPage,
            hasUserInvitePermission,
            members: canInviteCurrentUser ? [] : members,
            onLoadMore,
            onMemberSearch,
            onRulesSelectionChange,
            searchTerm,
            isFetchingMoreMembers: isFetchingNextPage,
            isLoadingMembers,
          },
          peopleWithAccessHeaderProps: {
            title: formatMessage(messages.peopleWithAccess),
            disablePreviewButton: rules.length === 0,
            onPreviewButtonClick: () => {
              setView('previewMembers');
              trackSharesheetAction({
                actionName: 'previewListClicked',
                props: {
                  ruleType: 'simple',
                  isV3sharesheet: true,
                  sharesheetType: 'peopleSelector',
                },
              });
            },
          },
        }
      : {
          view: 'previewMembers',
          options: [],
          onPreviewModalBackButtonClick: () => setView('peopleWithAccess'),
          rules: displayRules,
          onLoadMore: fetchNextPageForPreview,
          hasNextPage: hasNextPageForPreview,
          onSearchOrFilterList,
          isLoadingPreview,
          isFetchingMoreMembers: isFetchingNextPageForPreview,
          subTitle: formatMessage(messages.previewSubTitle),
        };

  return (
    <ShareModal
      isOpen={isOpen}
      onClose={handleClose}
      onDoneAction={handleDone}
      isSubmitDisabled={rules.length > 0 && isLoadingPreview}
      showCTASection={view === 'peopleWithAccess'}
      onGetHelpClick={() => {
        trackSharesheetAction({
          actionName: 'getHelpClicked',
          props: {
            ruleType: 'simple',
            isV3sharesheet: true,
            sharesheetType: 'peopleSelector',
          },
        });
        window.open(
          'https://www.joinassembly.com/articles/flows-builder',
          '_blank',
          'noopener,noreferrer'
        );
      }}
    >
      <ShareModalContent
        tab={{
          title: formatMessage(messages.tabTitle),
        }}
        {...props}
      />
    </ShareModal>
  );
}

export function Participants() {
  const { id } = useEditorDataContext();
  const blockId = useBlockIdContext();

  const [modalOpen, setModalOpen] = useState(false);

  const setBlockParticipants = useSetBlockParticipants(id, blockId);
  const setCustomParticipants = useSetCustomParticipants(id, blockId);

  const { isTreatmentActive: isSimplifiedSelectionCriteria } = useFeatureSplit(
    SplitNames.FlowSimplifiedSelectionCriteria
  );

  const participants = useGetBlockParticipants(id, blockId);
  const customPersonSelectorCount = useGetCustomPersonSelectorCount(
    id,
    blockId
  );

  const formattedBlockName = useFormattedBlockName();

  const isOccurrenceInProgress = useAnyOccurrenceInProgress();

  const handleValueChange = useCallback(
    (value: string) => {
      if (value === 'CUSTOM') {
        setModalOpen(true);
        return;
      }
      setBlockParticipants(value as SelectablePeopleTypes);
      trackFlowEditorAction('blockDetailClicked', {
        blockType: formattedBlockName,
        detailType: `${formattedBlockName}${((): string => {
          return (
            {
              CUSTOM: 'Custom',
              EVERYONE: 'Everyone',
              PARTICIPANTS: 'SameAsParticipants',
              VIEWERS: 'SameAsViewers',
            } satisfies Record<SelectablePeopleTypes, string>
          )[value as SelectablePeopleTypes];
        })()}`,
      });
    },
    [formattedBlockName, setBlockParticipants]
  );

  const handleCustomPeopleSelectorClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOnDone = ({
    criteriaItems,
    newUsersToInvite,
    total,
  }: {
    criteriaItems: ShareCriteria | null;
    newUsersToInvite: string[];
    total: number;
  }) => {
    setModalOpen(false);

    if (!criteriaItems) {
      setBlockParticipants('EVERYONE');
      return;
    }
    setCustomParticipants({
      criteria: criteriaItems,
      customSelectionCount: total,
      newUsersToInvite,
    });
  };

  return (
    <>
      <BlockParticipants
        disabled={Boolean(isOccurrenceInProgress)}
        onValueChange={handleValueChange}
        value={participants}
        selectedCount={customPersonSelectorCount}
        onCustomPeopleSelectorClick={handleCustomPeopleSelectorClick}
        onClickCustomSelection={handleCustomPeopleSelectorClick}
        simplifiedSelectionCriteria={isSimplifiedSelectionCriteria}
      />
      <ParticipantsModal
        key={participants + String(modalOpen)}
        isOpen={modalOpen}
        onDone={handleOnDone}
        onClose={handleCloseModal}
      />
    </>
  );
}
