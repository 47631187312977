import type { ReactionDetails } from '@assembly-web/services';
import {
  ConversationCard,
  EmptyOrErrorStateTemplate,
  FetchMore,
  getConversionCardVariant,
  getReplyCardToolbarItems,
  RepliesLoader as ChatsLoader,
  type Reply,
} from '@assembly-web/ui';
import {
  EmptyChats,
  PostOrRepliesError as ChatError,
} from '@assembly-web/ui/lib/UI/assets/images';
import { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';

export const messages = defineMessages({
  chatsLoadingError: {
    defaultMessage: 'Whoops! We had trouble loading these messages',
    id: 'OF8dGl',
  },
  refreshToTryAgain: {
    defaultMessage: 'Please refresh the page or wait a bit to try again.',
    id: 'j2AMOY',
  },
  conversationLabel: {
    defaultMessage: 'This is the beginning of the conversation with',
    id: 'wZNS/x',
  },
  conversationToLabel: {
    defaultMessage: '{author}',
    id: 'XCZZ38',
  },
  viewMoreMessages: {
    defaultMessage: 'View older messages',
    id: 'eciQch',
  },
});

type ChatListProps = {
  total: number;
  chats: Reply[];
  toName: string;
  isError: boolean;
  hasNextPage: boolean;
  currentUserId: string;
  hasPreviousPage: boolean;
  fetchNextPage: () => void;
  isInitialLoading: boolean;
  isFetchingNextPage: boolean;
  fetchPreviousPage: () => void;
  handleResetScroll?: () => void;
  isFetchingPreviousPage: boolean;
  onMemberClick: (memberId: string) => void;
  containerRef: React.MutableRefObject<HTMLElement | null>;
  handleReactionClick: (emoji: ReactionDetails, reply: Reply) => void;
};

export function ChatList({
  chats,
  total,
  toName,
  isError,
  hasNextPage,
  containerRef,
  currentUserId,
  fetchNextPage,
  onMemberClick,
  hasPreviousPage,
  isInitialLoading,
  handleResetScroll,
  fetchPreviousPage,
  isFetchingNextPage,
  handleReactionClick,
  isFetchingPreviousPage,
}: ChatListProps) {
  const { formatMessage } = useIntl();

  const effectRunRef = useRef(false);

  useEffect(() => {
    if (containerRef.current && !effectRunRef.current) {
      effectRunRef.current = true;
      containerRef.current.scrollTo({
        behavior: 'instant',
        top: containerRef.current.scrollHeight,
      });
    }
  }, [containerRef]);

  if (isError && !isInitialLoading) {
    return (
      <EmptyOrErrorStateTemplate
        image={ChatError}
        altText="chats-loading-error"
        heading={formatMessage(messages.chatsLoadingError)}
        subHeading={formatMessage(messages.refreshToTryAgain)}
      />
    );
  }

  if (!isError && !isInitialLoading && chats.length === 0) {
    return (
      <EmptyOrErrorStateTemplate
        image={EmptyChats}
        altText="no-chats-found"
        heading={formatMessage(messages.conversationLabel)}
        subHeading={formatMessage(messages.conversationToLabel, {
          author: toName,
        })}
      />
    );
  }

  const canShowChatMessages = !isError && !isInitialLoading && chats.length > 0;

  const getPreviousChatUserId = (
    filteredChats: Reply[],
    currentIndex: number
  ) => {
    if (currentIndex === 0) {
      return '';
    }

    const previousItem = filteredChats[currentIndex - 1];

    return previousItem.memberDetails.memberID;
  };

  return (
    <div className="flex flex-col items-start pt-4">
      {Boolean(isInitialLoading) && <ChatsLoader />}
      {Boolean(hasNextPage) && (
        <div className="-mt-4">
          <FetchMore
            hideCount={true}
            hasMore={hasNextPage}
            totalReplyCount={total}
            fetchMore={fetchNextPage}
            isFetching={isFetchingNextPage}
            fetchedReplyCount={chats.length}
            label={formatMessage(messages.viewMoreMessages)}
          />
        </div>
      )}
      {Boolean(canShowChatMessages) &&
        chats.map((chat, idx) => {
          return (
            <ConversationCard
              index={idx}
              boost={chat.boost}
              variant={getConversionCardVariant({
                currentReplyMemberId: chat.memberDetails.memberID,
                previousReplyMemberId: getPreviousChatUserId(chats, idx),
              })}
              key={chat.cardId}
              cardId={chat.cardId}
              canShowToolbar={false}
              onMemberClick={onMemberClick}
              memberDetails={chat.memberDetails}
              messageContent={chat.messageContent}
              reactions={chat.reactions}
              onReactionClick={(emoji: ReactionDetails) => {
                handleReactionClick(emoji, chat);
              }}
              currentMemberId={currentUserId}
              isEdited={chat.isEdited}
              gifURL={chat.gifURL}
              messageNode={chat.messageNode}
              resetScroll={handleResetScroll}
              containerRef={containerRef}
              taggedUsers={chat.taggedUsers}
              toolbarItems={getReplyCardToolbarItems({
                formatMessage,
                hasPoints: false,
                canEditReply: false,
                canDeleteReply: false,
              })}
              onMenuItemClick={() => {}}
            />
          );
        })}
      <FetchMore
        hasMore={hasPreviousPage}
        fetchMore={fetchPreviousPage}
        totalReplyCount={total}
        fetchedReplyCount={chats.length}
        isFetching={isFetchingPreviousPage}
        label={formatMessage(messages.viewMoreMessages)}
      />
    </div>
  );
}
