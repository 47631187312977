import { MemberStatus } from '@assembly-web/services';

export const useActionBarVisibility = ({
  memberStatus,
  activeFlowsCount,
  isRecognitionOnly,
}: {
  memberStatus?: MemberStatus;
  activeFlowsCount: number;
  isRecognitionOnly: boolean;
}) => {
  const showGiveRecognitionButton =
    memberStatus !== MemberStatus.Observer &&
    memberStatus !== MemberStatus.Receiver;

  const showActionBar = !(
    (memberStatus === MemberStatus.Observer ||
      memberStatus === MemberStatus.Receiver) &&
    (isRecognitionOnly || activeFlowsCount === 0)
  );

  return {
    showGiveRecognitionButton,
    showActionBar,
  };
};
