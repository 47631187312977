import { FilePreviewerContext } from '@assembly-web/ui';
import { useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useChallengeDrawer } from '../modules/discover/hooks/challenges/useChallengeDrawer';
import { useGetClaimDetailsQuery } from '../modules/discover/hooks/useGetClaimDetailsQuery';

export const useOpenChallengesPreviewer = ({
  claimId,
  internalFileName,
}: {
  claimId?: string;
  internalFileName: string;
}) => {
  const { openModal: openV3Modal } = useContext(FilePreviewerContext);

  const { openChallengeDrawer } = useChallengeDrawer();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { mutate: openChallengeFileInPreviewer } = useGetClaimDetailsQuery({
    claimId: claimId ?? '',
    options: {
      onSuccess: (res) => {
        const selectedFile = res.proof?.files.find((file) => {
          return file.originalName
            ? file.originalName === internalFileName
            : file.name === internalFileName;
        });

        if (selectedFile) {
          openV3Modal({
            file: {
              fileName: selectedFile.originalName,
              locationUrl: selectedFile.location,
              fileType: selectedFile.type,
              memberID: res.claimedBy.memberID,
              memberName: res.claimedBy.name,
              memberImage: res.claimedBy.image,
              handleGoToPost: () => {
                if (claimId) {
                  searchParams.set('claimProofId', claimId);
                  setSearchParams(searchParams.toString());
                }
              },
              sharedIn: res.challenge.title,
              dateShared: res.claimDate,
              handleSharedInClick: () => {
                navigate('/a/challenges?claimStatus=PENDING');
                openChallengeDrawer(res.challenge.challengeId);
              },
            },
            isChallengeClaim: true,
            showGoToPost: true,
          });
        }
      },
    },
  });
  return { openChallengeFileInPreviewer };
};
