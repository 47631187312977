import { Icon, type IconNode, TextStyle } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';

import { TextButton } from '../TextButton';

const containerStyles = twJoin(
  'inline-flex w-full items-center !justify-start gap-2 rounded-lg px-2 no-underline h-10 theme-interaction text-gray-9 transition-all'
);

const iconStyles = twJoin('h-4 w-4 text-gray-8');

const textStyles = twJoin('text-gray-9 line-clamp-1 truncate text-left');

type BaseNavItemProps = {
  icon: IconNode;
  text: ReactNode;
  children?: ReactNode;
  connectedRight?: ReactNode;

  className?: string;
  textClassName?: string;

  onClick?: () => void;
};

function Button({
  icon,
  text,
  className,
  textClassName,
  onClick,
  children,
}: BaseNavItemProps) {
  return (
    <TextButton
      onClick={onClick}
      className={twMerge(containerStyles, className)}
      data-closable-element
    >
      <Icon icon={icon} className={iconStyles} />
      <TextStyle
        as="span"
        variant="base-medium"
        subdued
        className={twMerge(textStyles, textClassName)}
      >
        {text}
      </TextStyle>
      {children}
    </TextButton>
  );
}

function ExternalLink({
  className,
  textClassName,
  icon,
  url,
  text,
  onClick,
}: {
  url: string;
} & BaseNavItemProps) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={twMerge(containerStyles, className)}
      onClick={onClick}
      data-closable-element
    >
      <Icon icon={icon} className={iconStyles} />
      <TextStyle
        variant="base-medium"
        subdued
        className={twMerge(textStyles, textClassName)}
      >
        {text}
      </TextStyle>
    </a>
  );
}

function InternalLink({
  isActive,
  icon,
  children,
  url,
  text,
  onClick,
  className,
  textClassName,
}: {
  url: string;
  isActive?: boolean;
} & BaseNavItemProps) {
  return (
    <NavLink
      to={url}
      end
      className={({ isActive: isLinkActive }) =>
        twMerge(
          containerStyles,
          (isLinkActive || isActive) && 'active border',
          className
        )
      }
      data-closable-element
      onClick={onClick}
    >
      <Icon icon={icon} className={iconStyles} />
      <TextStyle
        variant="base-medium"
        subdued
        className={twMerge(textStyles, textClassName)}
      >
        {text}
      </TextStyle>
      {children}
    </NavLink>
  );
}
function Skeleton() {
  return (
    <div className={containerStyles}>
      <div className="h-4 w-4 animate-pulse rounded bg-gradient-to-r from-gray-3 to-gray-5" />
      <div className="h-8 w-full animate-pulse rounded-lg bg-gradient-to-r from-gray-3 to-gray-5" />
    </div>
  );
}

export const NavItem = {
  Button,
  ExternalLink,
  InternalLink,
  Skeleton,
};
