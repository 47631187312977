import {
  getUserMaxGivingPointsPerPost,
  hasPermissionToGiveRecognition,
  useSuspenseUserDetails,
} from '@assembly-web/services';

export function usePointsConfig() {
  const { data: currentUser } = useSuspenseUserDetails();
  const showPointsBlock = hasPermissionToGiveRecognition(currentUser.member);

  const getMaxPoints = (memberCount: number) => {
    const remainingPoints = getUserMaxGivingPointsPerPost(currentUser);
    const pointsToGive = remainingPoints / memberCount;
    if (!memberCount) {
      return remainingPoints;
    }
    return Math.floor(pointsToGive);
  };

  return {
    showPointsBlock,
    getMaxPoints,
  };
}
