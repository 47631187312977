import type { LegacyUserDetailsAPIResponse } from '@assembly-web/services';
import {
  APIEndpoints,
  config,
  getAPIErrorCode,
  logger,
  lookupSSOProvider,
  MobileJWTTokenQueryParamKey,
  MobileRefreshTokenQueryParam,
  SSOProvider,
  unauthenticatedAssemblyAPI,
  userAuthStore,
} from '@assembly-web/services';
import { AssemblyLoadingIndicator } from '@assembly-web/ui';
import { app, authentication } from '@microsoft/teams-js';
import isEmpty from 'lodash/isEmpty';
import { Suspense } from 'react';
import type { LoaderFunctionArgs } from 'react-router-dom';
import { Await, defer, Navigate, useLoaderData } from 'react-router-dom';
import type { RequireAllOrNone } from 'type-fest';

import { waitForRedirection } from '../../../../services/waitForRedirection';
import { getSSODetailsFromError } from '../../services/sso';

export function SSOLoginRoute() {
  const data = useLoaderData() as ReturnType<typeof ssoLoginLoader>;

  return (
    <Suspense fallback={<AssemblyLoadingIndicator />}>
      <Await
        resolve={
          /* @ts-expect-error react router can't give us proper types for this */
          data.targetURL
        }
      >
        {(targetURL) => <Navigate to={targetURL} />}
      </Await>
    </Suspense>
  );
}

export async function ssoLoginLoader({ request, params }: LoaderFunctionArgs) {
  try {
    if (userAuthStore.getState().msTeamsContext) {
      await app.initialize();
    }
  } catch (err) {
    logger.error('MS teams initialization failed', {
      email: userAuthStore.getState().msTeamsContext,
    });
  }

  return defer({
    targetURL: (async function resolveSSOLoginTargetURL() {
      const { searchParams } = new URL(request.url);
      const code = searchParams.get('code');
      const error = searchParams.get('error');
      const autoLogin = searchParams.get('auto');
      const idToken = searchParams.get('idToken');

      if (userAuthStore.getState().msTeamsContext) {
        app.notifySuccess();
      }
      const provider = lookupSSOProvider(params.provider);

      if (!(code || error || idToken) || !provider) {
        return '/login';
      }

      if (error) {
        return `/login?error=${params.provider}_${error}`;
      }

      const baseRedirectUri = `${
        config.domains.app
      }/sso-sign-in/${provider.toLowerCase()}`;

      const redirectUri = autoLogin
        ? `${baseRedirectUri}?auto=${autoLogin}`
        : baseRedirectUri;

      const { assemblyId, isEnforcedAuth, workspaceSlug, userAuthFlow } =
        userAuthStore.getState();

      userAuthStore.persist.clearStorage();

      try {
        const isMobileApp = userAuthStore.getState().isLoginViaMobileApp;
        const verifySSO = (
          flow: 'login' | 'signup' = 'login',
          isSSOEnforced = false
        ) => {
          const isGoogleSSO = provider === SSOProvider.Google;
          if (isGoogleSSO && !isSSOEnforced) {
            return unauthenticatedAssemblyAPI.post<
              RequireAllOrNone<
                LegacyUserDetailsAPIResponse,
                'user' | 'jwtToken'
              >
            >(APIEndpoints.verifyGoogleSSO, {
              idToken,
              type: flow,
            });
          } else {
            return unauthenticatedAssemblyAPI.post<
              RequireAllOrNone<
                LegacyUserDetailsAPIResponse,
                'user' | 'jwtToken'
              >
            >(
              APIEndpoints.verifyEmailWithSSO(
                provider,
                autoLogin ? 'login' : flow,
                Boolean(isEnforcedAuth)
              ),
              { code: code ?? idToken, redirectUri, assemblyId }
            );
          }
        };

        if (isEnforcedAuth) {
          if (userAuthFlow === 'join' || userAuthFlow === 'create-account') {
            await verifySSO('signup', isEnforcedAuth);
            return `/join/${workspaceSlug}/user-details`;
          } else if (userAuthFlow === 'login') {
            const { data } = await verifySSO(userAuthFlow, isEnforcedAuth);
            if (userAuthStore.getState().msTeamsContext) {
              authentication.notifySuccess();
            }

            if (isMobileApp && data.jwtToken) {
              window.location.href = `${config.domains.mobileApp}home?${new URLSearchParams(
                [
                  [MobileJWTTokenQueryParamKey, data.jwtToken],
                  ...(data.refreshToken
                    ? [[MobileRefreshTokenQueryParam, data.refreshToken]]
                    : []),
                ]
              )}`;
            } else {
              window.location.replace(
                `/a${
                  !isEmpty(userAuthStore.getState().msTeamsContext)
                    ? '?fromMSTeams=true'
                    : ''
                }`
              );
            }

            await waitForRedirection();
          }
        }

        const { data } = await verifySSO();
        if (userAuthStore.getState().msTeamsContext) {
          authentication.notifySuccess();
        }

        if (
          [SSOProvider.Lifion, SSOProvider.ADP].includes(provider) &&
          data.jwtToken
        ) {
          if (isMobileApp) {
            window.location.href = `${config.domains.mobileApp}home?${new URLSearchParams(
              [
                [MobileJWTTokenQueryParamKey, data.jwtToken],
                ...(data.refreshToken
                  ? [[MobileRefreshTokenQueryParam, data.refreshToken]]
                  : []),
              ]
            )}`;
          } else {
            window.location.replace('/a');
          }

          return await waitForRedirection();
        }

        return '/workspaces';
      } catch (error) {
        const errorCode = getAPIErrorCode(error);
        let redirectURL = '/login';

        if (userAuthStore.getState().msTeamsContext) {
          authentication.notifyFailure(errorCode);
        }

        if (userAuthStore.getState().msTeamsContext) {
          redirectURL = '/ms-teams/login';
        }

        if (errorCode === 'workspace_mismatch') {
          return `${redirectURL}/${workspaceSlug}/${provider.toLowerCase()}?error=${errorCode}`;
        }

        const enforcedLoginDetails = getSSODetailsFromError(error);

        if (enforcedLoginDetails) {
          return `${redirectURL}/${enforcedLoginDetails.workspaceSlug}/${enforcedLoginDetails.provider}`;
        }
        return `${redirectURL}?error=${errorCode}`;
      }
    })(),
  });
}
