import {
  checkForProfanity,
  config,
  MemberStatus,
  type Nullable,
  type ReplyData,
  type UserDetails,
} from '@assembly-web/services';
import {
  RepliesEditor,
  type RepliesValidatorError,
  TextStyle,
} from '@assembly-web/ui';
import {
  type FC,
  type MutableRefObject,
  type ReactNode,
  useCallback,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useLayoutStore } from '../../../../../stores/useLayoutStore';
import { useChallengeMembersSearchQuery } from '../../../hooks/challenges/useChallengeMembersSearchQuery';
import { useBoostOptions } from '../shared/hooks/useBoostOptions';
import { useChallengeRepliesEditor } from './hooks/useChallengeRepliesEditor';

type ChallengeRepliesEditorContainerProps = {
  commentId?: string;
  disabled?: boolean;
  challengeId: string;
  userDetails: UserDetails;
  selectedReply?: Nullable<ReplyData>;
  containerRef: MutableRefObject<HTMLElement | null>;
  onSelectedReplyChange: (
    reply: Nullable<ReplyData>,
    commentId: string
  ) => void;
};

const MaxCharactersLimit = 5000;

const messages = defineMessages({
  replyLabel: {
    defaultMessage: 'Reply...',
    id: '/dm2sj',
  },
  saveReplyError: {
    defaultMessage: "You're posting a lot of replies, try again in a bit",
    id: 'DTgrc0',
  },
  replyLimitMessage: {
    defaultMessage:
      "You've reached the maximum 5,000 character limit and cannot add more",
    id: '+ueERL',
  },
  profanityWarning: {
    defaultMessage:
      'Your message contains inappropriate language. Please remove it to post.',
    id: 'zM2kzT',
  },
});

const Error: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="flex-shrink-0">
      <TextStyle variant="xs-regular" className="text-error-5">
        {children}
      </TextStyle>
    </div>
  );
};

export function ChallengeRepliesEditorContainer({
  disabled,
  commentId,
  userDetails,
  challengeId,
  containerRef,
  selectedReply,
  onSelectedReplyChange,
}: ChallengeRepliesEditorContainerProps) {
  const [membersSearchQuery, setMembersSearchQuery] = useState('');

  const { formatMessage } = useIntl();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const boostOptions = useBoostOptions({ challengeId });

  const { assembly, member } = userDetails;
  const {
    currency,
    settings: { gifAccessibility },
  } = assembly;

  const { isFormattingToolbarVisible, setIsFormattingToolbarVisible } =
    useLayoutStore();

  const {
    members,
    isPending: isMembersListPending,
    hasNextPage: hasNextPageInMembersList,
    fetchNextPage: fetchNextPageInMembersList,
    isFetchingNextPage: isFetchingNextPageInMembersList,
  } = useChallengeMembersSearchQuery({
    challengeId,
    searchTerm: membersSearchQuery,
    placeholderData: (previousData) => previousData,
  });

  const {
    hasProfanity,
    initialDraft,
    isDraftsPending,
    characterLength,
    isAddReplyError,
    handleCancelClick,
    isUpdateReplyError,
    handleOnEditorChange,
    setIsMentionsMenuOpen,
    handleOnPostReplyClick,
  } = useChallengeRepliesEditor({
    commentId,
    challengeId,
    containerRef,
    selectedReply,
    isMembersListPending,
    onSelectedReplyChange,
  });

  const handleOnMentionsSearchQueryChange = useCallback(
    (query: Nullable<string>) => {
      setMembersSearchQuery(query ?? '');
    },
    []
  );

  const handleOnNextPageScrolledInMentionsDropdown = useCallback(async () => {
    if (hasNextPageInMembersList) {
      await fetchNextPageInMembersList();
    }
  }, [fetchNextPageInMembersList, hasNextPageInMembersList]);

  const validator = useCallback(
    (text: string) => {
      let errors: RepliesValidatorError[] = [];

      const shouldCheckForProfanity =
        userDetails.member.permissions.profanitySetting.enabled;
      if (shouldCheckForProfanity) {
        const hasProfaneWords =
          text.length > 0 &&
          checkForProfanity(
            text,
            userDetails.member.permissions.profanitySetting.wordsToCheck
          );
        if (hasProfaneWords) {
          errors.push('HAS_PROFANITY');
        }
      }

      return errors;
    },
    [userDetails]
  );

  return (
    <>
      <RepliesEditor
        replyType="user"
        currency={currency}
        disabled={isDraftsPending}
        mentionedUsers={members.map((memberData) => ({
          ...memberData,
          role: '',
          pointsHidden: false,
          applyBoostToAll: false,
          id: memberData.memberID,
          isCurrentUserAnon: false,
          currency: assembly.currency,
          selectedReply: selectedReply,
          currentUserID: member.memberId,
          isFlowViewer: memberData.isChallengeViewer,
          canReceivePoints:
            memberData.status === MemberStatus.Receiver ||
            memberData.status === MemberStatus.Normal,
        }))}
        validator={validator}
        isAnonymousPost={false}
        mentionedUsersInPost={[]}
        giphyAPIKey={config.giphyKey}
        draftState={initialDraft}
        boostOptions={boostOptions}
        selectedReply={selectedReply}
        onChange={handleOnEditorChange}
        onCancelClick={handleCancelClick}
        gifRating={gifAccessibility.value}
        onReplyClick={handleOnPostReplyClick}
        hideToolbarButtons={Boolean(disabled)}
        onViewProfileClick={navigateToUserFeed}
        editState={selectedReply?.messageTokens}
        placeholder={formatMessage(messages.replyLabel)}
        onMentionsMenuOpenChange={setIsMentionsMenuOpen}
        isFormattingToolbarVisible={isFormattingToolbarVisible}
        setIsFormattingToolbarVisible={setIsFormattingToolbarVisible}
        onMentionsSearchQueryChange={handleOnMentionsSearchQueryChange}
        isFetchingNextPageForMentions={isFetchingNextPageInMembersList}
        currentUserDetails={{
          id: member.memberId,
          image: member.profile.image,
          currency: assembly.currency,
          allowance: member.pointsLeftThisCycle,
          name: `${member.profile.firstName} ${member.profile.lastName}`.trim(),
        }}
        onNextPageScrolledInMentionsDropdown={
          handleOnNextPageScrolledInMentionsDropdown
        }
        disablePostButton={
          characterLength >= MaxCharactersLimit || hasProfanity
        }
        hasError={characterLength >= MaxCharactersLimit || hasProfanity}
      />
      {Boolean(isAddReplyError) ||
        (Boolean(isUpdateReplyError) && (
          <Error>{formatMessage(messages.saveReplyError)}</Error>
        ))}
      {hasProfanity ? (
        <Error>{formatMessage(messages.profanityWarning)}</Error>
      ) : characterLength >= MaxCharactersLimit ? (
        <Error>{formatMessage(messages.replyLimitMessage)}</Error>
      ) : null}
    </>
  );
}
