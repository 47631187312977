import {
  APIEndpoints,
  getMemberDetailsFromUserDetails,
  type GetRepliesResponse,
  type RepliesResponse,
} from '@assembly-web/services';
import { assemblyAPI, useUserDetails } from '@assembly-web/services';
import type {
  ConversationCardMemberDetails,
  MemberDetailsForViewProfile,
  Reply,
} from '@assembly-web/ui';
import {
  type InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { produce } from 'immer';

import type { AddReplyPayload, UpdateReplyPayload } from '../replies/types';
import {
  addReplyToEmptyPostReplies,
  addReplyToPostReplies,
  generateOptimisticReply,
} from '../replies/utils';

type GetChallengeRepliesPayload = {
  challengeId: string;
  commentId: string | null;
};

export function useGetRepliesSummaryForChallenge(
  challengeId: string,
  enabled?: boolean
) {
  return useQuery({
    enabled,
    queryKey: ['repliesSummary', challengeId],
    queryFn: async () => {
      const { data } = await assemblyAPI.get<RepliesResponse>(
        APIEndpoints.getReplySummaryForChallenge(challengeId)
      );

      return data;
    },
  });
}

export function useAddChallengeReplyMutation({
  challengeId,
}: {
  challengeId: string;
}) {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  return useMutation({
    mutationFn: async (payload: AddReplyPayload) => {
      return await assemblyAPI.post(APIEndpoints.addReply, payload);
    },
    onMutate: async (payload: AddReplyPayload) => {
      const repliesQueryKey = ['replies', challengeId];
      const repliesSummaryQueryKey = ['repliesSummary', challengeId];

      await queryClient.cancelQueries({
        queryKey: repliesQueryKey,
      });
      await queryClient.cancelQueries({
        queryKey: repliesSummaryQueryKey,
      });

      const previousReplies =
        queryClient.getQueryData<InfiniteData<GetRepliesResponse>>(
          repliesQueryKey
        );

      if (userDetails) {
        const optimisticReply = generateOptimisticReply(
          payload,
          userDetails,
          '',
          challengeId
        );

        let optimisticReplies: InfiniteData<GetRepliesResponse>;
        if (previousReplies) {
          optimisticReplies = addReplyToPostReplies(
            optimisticReply,
            previousReplies
          );
        } else {
          optimisticReplies = addReplyToEmptyPostReplies(optimisticReply);
        }

        queryClient.setQueryData(repliesQueryKey, optimisticReplies);

        const previousRepliesSummary =
          queryClient.getQueryData<RepliesResponse>(repliesSummaryQueryKey);

        if (previousRepliesSummary) {
          queryClient.setQueryData<RepliesResponse>(
            repliesSummaryQueryKey,
            produce(previousRepliesSummary, (draft) => {
              draft.count = previousRepliesSummary.count + 1;
            })
          );
        } else {
          queryClient.setQueryData<RepliesResponse>(repliesSummaryQueryKey, {
            count: 1,
            lastRepliedAt: new Date().toISOString(),
            respondentsCount: 1,
            initialRespondents: [
              {
                name: userDetails.member.name,
                image: userDetails.member.image,
                memberID: userDetails.member.memberID,
              },
            ],
          });
        }
      }
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['userDetails'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['replies', challengeId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['repliesSummary', challengeId],
        }),
      ]);
    },
  });
}

export function useUpdateChallengeReplyMutation({
  challengeId,
}: {
  challengeId: string;
}) {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  return useMutation({
    mutationFn: async (payload: UpdateReplyPayload) => {
      return await assemblyAPI.put(
        APIEndpoints.updateReply(payload.replyId),
        payload
      );
    },
    onMutate: async (payload: UpdateReplyPayload) => {
      const queryKey = ['replies', challengeId];
      await queryClient.cancelQueries({
        queryKey: queryKey,
      });
      const previousReplies =
        queryClient.getQueryData<InfiniteData<GetRepliesResponse>>(queryKey);

      if (!previousReplies || !userDetails) {
        return;
      }

      const updatedReplies: InfiniteData<GetRepliesResponse> = produce(
        previousReplies,
        (draft) => {
          return {
            ...draft,
            pages: draft.pages.map((page) => {
              return {
                ...page,
                data: page.data.map((reply) => {
                  if (reply.commentID === payload.replyId) {
                    return {
                      ...reply,
                      isSaving: true,
                      isEditing: true,
                      editedAt: new Date().toISOString(),
                      updatedAt: new Date().toISOString(),
                      messageHtml: payload.messageHtml,
                      messageTokens: payload.messageTokens,
                      fromMember: payload.isAnonymous
                        ? {
                            anonymousIdentifier:
                              userDetails.member.anonymousIdentifier ?? '',
                          }
                        : {
                            ...getMemberDetailsFromUserDetails(
                              userDetails.member
                            ),
                          },
                    };
                  }
                  return { ...reply };
                }),
              };
            }),
          };
        }
      );

      queryClient.setQueryData(queryKey, updatedReplies);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['userDetails'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['replies', challengeId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['repliesSummary', challengeId],
        }),
      ]);
    },
  });
}

export function useGetChallengeRepliesInfiniteQuery({
  commentId,
  challengeId,
}: GetChallengeRepliesPayload) {
  const result = useInfiniteQuery<GetRepliesResponse>({
    queryKey: ['replies', challengeId],
    queryFn: async ({ pageParam }) => {
      const { data } = await assemblyAPI.get<GetRepliesResponse>(
        APIEndpoints.getRepliesForChallenge(challengeId),
        {
          params: {
            ...(pageParam ? { cursor: pageParam, limit: 10 } : { limit: 10 }),
            ...(Boolean(!pageParam) && commentId ? { commentId } : {}),
          },
        }
      );
      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (page) => page.metadata.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata.pagination.cursor.previous,
    refetchOnWindowFocus: false,
  });

  const getReplies = () => {
    if (!result.data) {
      return [];
    }

    return result.data.pages
      .flatMap((page) =>
        page.data.map((reply) => {
          const isEdited = Boolean(reply.editedAt);
          const memberDetails: ConversationCardMemberDetails<
            MemberDetailsForViewProfile & {
              isAnonymous: boolean;
              name?: string;
            }
          > =
            'anonymousIdentifier' in reply.fromMember
              ? {
                  isAnonymous: true,
                  createdAt: reply.createdAt,
                  memberID: reply.fromMember.anonymousIdentifier,
                }
              : {
                  isAnonymous: false,
                  createdAt: reply.createdAt,
                  name: reply.fromMember.name,
                  image: reply.fromMember.image,
                  email: reply.fromMember.email,
                  memberID: reply.fromMember.memberID,
                  jobTitle: reply.fromMember.jobTitle,
                  lastName: reply.fromMember.lastName,
                  pronouns: reply.fromMember.pronouns,
                  firstName: reply.fromMember.firstName,
                  department: reply.fromMember.department,
                  memberState: reply.fromMember.memberState,
                };

          return {
            cardId: reply.commentID,
            reactions: reply.reactions,
            pointsEach: reply.pointsEach,
            messageContent: reply.messageHtml ?? '',
            memberDetails,
            isEdited,
            version: reply.version,
            gifURL: reply.gifURL,
            canEdit: reply.canEdit,
            taggedUsers: reply.taggedUsers,
            boost: reply.boost,
            messageNode: undefined,
          };
        })
      )
      .sort(
        (a, b) =>
          new Date(a.memberDetails.createdAt).getTime() -
          new Date(b.memberDetails.createdAt).getTime()
      ) as Reply[];
  };

  return {
    ...result,
    replies: getReplies(),
  };
}
