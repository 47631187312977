import { useMutation, type UseMutationOptions } from '@tanstack/react-query';

import {
  type ClaimDetailsResponse,
  getClaimDetailsQuery,
} from '../queries/getClaimDetailsQuery';

export function useGetClaimDetailsQuery({
  claimId,
  options,
}: {
  claimId: string;
  options?: UseMutationOptions<ClaimDetailsResponse>;
}) {
  return useMutation(getClaimDetailsQuery(claimId, options));
}
