import type {
  FileDownloadRequest,
  FlowPostResponse,
  GlobalFilterOption,
  MemberDetails,
  ReactionDetails,
  SearchPayload,
  UserActivitySortType,
} from '@assembly-web/services';
import {
  config,
  copyToClipboard,
  useRecognitionRoute,
  useUserDetails,
} from '@assembly-web/services';
import type {
  AnnouncementBannerProps,
  PostCardToolbarProps,
} from '@assembly-web/ui';
import {
  getRespondentForAnonymousFlowPost,
  PostCardActions,
  PostCardUI,
  useAssemblyNavigate,
  useToastStore,
} from '@assembly-web/ui';
import { useQuery } from '@tanstack/react-query';
import {
  type MouseEvent,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useIsRecognitionOnlyCustomer } from '../hooks/useIsRecognitionOnlyCustomer';
import { useNavigateToUserFeed } from '../hooks/useNavigateToUserFeed';
import { useOpenFlowsPreviewer } from '../hooks/useOpenFlowsPreviewer';
import { useReactionMutation } from '../hooks/useReactionMutation';
import { useReplies } from '../hooks/useReplies';
import { AnnouncementsContext } from '../modules/discover/hooks/useAnnouncements';
import { useDismissImportantActivityMutationQuery } from '../modules/discover/hooks/useDismissImportantActivityMutationQuery';
import type { GetUserFeedPayload } from '../modules/discover/queries/getUserFeedQuery';
import {
  getSortMethodValue,
  trackCardAction,
} from '../modules/discover/services/analytics';
import {
  getPostQuery,
  getPostRepliesQuery,
} from '../queries/getAssemblyPostQuery';
import { downloadFeedFile } from '../services/file';
import { getURLForAssemblyPostActions } from '../services/post';
import type { PostData } from '../types/postsAndReplies';
import { PostCardLoader } from './PostCardLoader';

const messages = defineMessages({
  copiedToClipboardText: {
    defaultMessage: 'File Link Copied to clipboard',
    id: 'Hj9In1',
  },
  downloadingFileText: {
    defaultMessage: 'Downloading file',
    id: '2vpW3T',
  },
  fileDownloadedText: {
    defaultMessage: 'File downloaded successfully',
    id: 'griv1/',
  },
  errorDownloadingFileText: {
    defaultMessage: 'Error while downloading the file',
    id: 'hDCJX3',
  },
});

type RichPostPreviewProps = {
  analyticsProps?: {
    cardType: string;
    isNotification: boolean;
    searchTerm?: string;
    sortMethod?: UserActivitySortType;
    filter?: GlobalFilterOption;
  };
  flowId: string;
  isIFrameEnv?: boolean;
  responseId: string;
  urlPath: string;
  hasReplyDraft: boolean;
  postResponse?: FlowPostResponse;
  lazyLoadPostDetailsOnScroll: boolean;
  payload?: GetUserFeedPayload | SearchPayload;
  cardId?: string;
  className?: string;
  isReplyPreviewCard?: boolean;
  canHidePoints?: boolean;
  handleInternalLinkClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
} & PostCardToolbarProps;

export function RichPostPreview({
  analyticsProps,
  flowId,
  hasReplyDraft,
  isIFrameEnv = false,
  responseId,
  urlPath,
  postResponse,
  lazyLoadPostDetailsOnScroll,
  onToolbarMenuItemClick,
  primaryToolbarItems,
  secondaryToolbarItems,
  cardId,
  className,
  isReplyPreviewCard,
  canHidePoints,
  handleInternalLinkClick,
}: RichPostPreviewProps) {
  const isRecognitionRoute = useRecognitionRoute();
  const isRecognitionOnlyCustomer = useIsRecognitionOnlyCustomer();

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();
  const { formatMessage } = useIntl();
  const { showSuccessToast, showErrorToast, showInfoToast } = useToastStore();
  const { isPending: isUserDetailsLoading, data: userDetails } =
    useUserDetails();
  const { onReplyClicked } = useReplies();
  const { mutate: dismissCard } = useDismissImportantActivityMutationQuery();
  const openPreviewer = useOpenFlowsPreviewer();

  const { isInitialLoading: isPostCardDataLoading, data: postCardDetails } =
    useQuery({
      ...getPostQuery({
        flowId,
        responseId,
        currencyName: userDetails?.assembly.currency.pluralName ?? '',
        asEmbed: true,
      }),
      enabled: lazyLoadPostDetailsOnScroll,
    });

  const currentUser = useMemo(() => {
    return userDetails
      ? ({
          memberID: userDetails.member.memberId,
          name: userDetails.member.profile.firstName,
          image: userDetails.member.profile.image,
          firstName: userDetails.member.profile.firstName,
          lastName: userDetails.member.profile.lastName,
          username: userDetails.member.profile.username,
          pointsGiven: userDetails.member.pointsGiven,
          totalPointsGiven: userDetails.member.totalPointsGiven,
          memberState: userDetails.member.memberState,
          role: userDetails.member.role,
          email: userDetails.member.email,
        } as MemberDetails)
      : undefined;
  }, [userDetails]);

  const {
    isLoading: isPostCardRepliesDataLoading,
    data: postCardRepliesDetails,
  } = useQuery(
    getPostRepliesQuery({
      flowId,
      responseId,
      enabled: lazyLoadPostDetailsOnScroll,
    })
  );

  const reactionMutate = useReactionMutation({
    flowId,
    responseId,
    currentUser,
  });

  const assemblyPostCardDetails = lazyLoadPostDetailsOnScroll
    ? postCardDetails
    : postResponse;

  const replySummaryDetails = lazyLoadPostDetailsOnScroll
    ? postCardRepliesDetails
    : postResponse?.replySummary;

  const flowResponse: FlowPostResponse | undefined = useMemo(() => {
    return assemblyPostCardDetails
      ? {
          ...assemblyPostCardDetails,
          respondent:
            assemblyPostCardDetails.respondent ??
            getRespondentForAnonymousFlowPost(),
          instanceId:
            assemblyPostCardDetails.respondent === null
              ? ''
              : assemblyPostCardDetails.instanceId,
        }
      : undefined;
  }, [assemblyPostCardDetails]);

  const navigate = useAssemblyNavigate();
  const slug = `${userDetails?.assembly.workspaceSlug.name}-${userDetails?.assembly.workspaceSlug.shortCode}`;

  const handleAssemblyPostRedirection = useCallback(
    ({ action, id }: { action: PostCardActions; id: string }) => {
      const redirectionURL = getURLForAssemblyPostActions({
        action,
        slug,
        id,
        flowId: flowResponse?.flow.flowId,
        responseId: flowResponse?.responseId,
      });
      if (redirectionURL) navigate(redirectionURL);
    },
    [flowResponse, navigate, slug]
  );

  const { openAnnouncementsInsightsModal } = useContext(AnnouncementsContext);

  useLayoutEffect(() => {
    if (
      isIFrameEnv &&
      flowResponse &&
      currentUser &&
      assemblyPostCardDetails &&
      !isUserDetailsLoading &&
      !isPostCardRepliesDataLoading
    ) {
      const element = document.getElementById(`${flowId}-${responseId}`);
      if (element) {
        window.parent.postMessage(
          {
            type: 'iframeHeight',
            height: element.clientHeight,
            urlPath,
          },
          '*'
        );
      }
    }
  }, [
    flowResponse,
    currentUser,
    postCardDetails,
    flowId,
    responseId,
    isUserDetailsLoading,
    isPostCardDataLoading,
    isPostCardRepliesDataLoading,
    urlPath,
    isIFrameEnv,
    assemblyPostCardDetails,
    lazyLoadPostDetailsOnScroll,
  ]);

  if (
    isUserDetailsLoading ||
    !flowResponse ||
    (isPostCardDataLoading && lazyLoadPostDetailsOnScroll) ||
    (isPostCardRepliesDataLoading && lazyLoadPostDetailsOnScroll) ||
    !userDetails?.assembly.currency ||
    !replySummaryDetails ||
    !currentUser
  ) {
    return (
      <section>
        <PostCardLoader />
      </section>
    );
  }

  const flowName = flowResponse.flow.name;

  const trackPostPreviewCardClick = (
    action:
      | 'postReactionAdded'
      | 'postReactionRemoved'
      | 'viewRepliesClicked'
      | 'memberClicked'
      | 'fileClicked'
  ) => {
    if (analyticsProps) {
      const { cardType, isNotification, searchTerm, sortMethod } =
        analyticsProps;

      trackCardAction(action, {
        cardType,
        flowId: flowId !== 'recognition' ? flowId : null,
        flowName,
        isNotification,
        searchQuery: searchTerm,
        isAnnouncement: Boolean(flowResponse.activeAnnouncement),
        sortMethod: sortMethod ? getSortMethodValue(sortMethod) : null,
        isRepliesDisabled: postResponse?.hideReplies ?? false,
        isReactionsDisabled: postResponse?.hideReactions ?? false,
      });
    }
  };

  const handleFileClick = (responseBlock: {
    blockId: string;
    fileName: string;
    fileType: string;
  }) => {
    const { blockId, fileName, fileType } = responseBlock;

    openPreviewer({
      blockId,
      flowId,
      workspaceSlug: slug,
      fileName,
      responseId,
      fileType,
      memberID: flowResponse.respondent.memberID,
      memberName: flowResponse.respondent.name,
      memberImage: flowResponse.respondent.image,
      sharedIn: flowName,
      dateShared: flowResponse.createdAt,
      sharedInIcon: flowResponse.flow.icon,
    });

    trackPostPreviewCardClick('fileClicked');
  };

  const handleBlockToolbarItemClick = async (responseBlock: {
    blockId: string;
    fileName: string;
    itemId: string;
  }) => {
    const { blockId, itemId, fileName } = responseBlock;
    if (itemId === 'copyLink') {
      const baseUrl = config.domains.app;
      const urlToCopy = `${baseUrl}/a/${slug}/post/flow/flows?flowId=${flowId}&responseId=${responseId}`;
      await copyToClipboard(urlToCopy);
      showSuccessToast(formatMessage(messages.copiedToClipboardText));
    }
    if (itemId === 'downloadFile') {
      const request: FileDownloadRequest = {
        blockId: blockId,
        flowId: flowId,
        responseId: responseId,
        instanceId: flowResponse.instanceId,
        fileName: fileName,
      };
      try {
        showInfoToast(formatMessage(messages.downloadingFileText));
        await downloadFeedFile(request);
        showSuccessToast(formatMessage(messages.fileDownloadedText));
      } catch (e) {
        showErrorToast(formatMessage(messages.errorDownloadingFileText));
      }
    }
  };

  const handlePostCardClick = (action: PostCardActions, id: string) => {
    if (action === PostCardActions.PersonClicked) {
      trackPostPreviewCardClick('memberClicked');
    }

    handleAssemblyPostRedirection({ action, id });
  };

  const handleOnAnnouncementDescriptionHover = (
    args: AnnouncementBannerProps
  ) => {
    trackCardAction('descriptionOpened', {
      title: args.title,
      color: args.color,
      description: args.description,
    });
  };

  return (
    <div id={`${flowId}-${responseId}`}>
      <PostCardUI
        showFlowDetails={!isRecognitionRoute && !isRecognitionOnlyCustomer}
        additionalClassName={className}
        hasReplyDraft={
          lazyLoadPostDetailsOnScroll
            ? (postCardDetails?.hasReplyDraft ?? false)
            : hasReplyDraft
        }
        isReplyPreviewCard={isReplyPreviewCard}
        onMemberClick={navigateToUserFeed}
        repliesData={replySummaryDetails}
        flowResponse={flowResponse}
        reactions={flowResponse.reactions}
        currentMember={currentUser}
        currency={userDetails.assembly.currency}
        canShowAnnouncements={Boolean(flowResponse.activeAnnouncement)}
        onAnnouncementDescriptionHover={handleOnAnnouncementDescriptionHover}
        openAnnouncementsInsightsModal={openAnnouncementsInsightsModal}
        onPostCardHeaderClick={handlePostCardClick}
        onPostCardBodyClick={handlePostCardClick}
        onFileClick={handleFileClick}
        onBlockToolbarItemClick={handleBlockToolbarItemClick}
        primaryToolbarItems={primaryToolbarItems}
        secondaryToolbarItems={secondaryToolbarItems}
        onToolbarMenuItemClick={onToolbarMenuItemClick}
        onReactionClick={(emoji: ReactionDetails) => {
          if (responseId !== '' && flowId !== '') {
            const hasCurrentUserReactedToTheSelectedEmoji =
              flowResponse.reactions.some(
                (reaction) =>
                  reaction.name === emoji.name &&
                  reaction.members.some(
                    (member) => member.memberID === userDetails.member.memberId
                  )
              );
            reactionMutate({
              payload: {
                displayName: emoji.displayName,
                name: emoji.name,
                type: emoji.type,
                value: emoji.type,
              },
              action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
            });

            trackPostPreviewCardClick(
              hasCurrentUserReactedToTheSelectedEmoji
                ? 'postReactionRemoved'
                : 'postReactionAdded'
            );
          }
        }}
        onReplyButtonClick={() => {
          trackPostPreviewCardClick('viewRepliesClicked');
          if (cardId) {
            dismissCard(cardId);
          }

          const viewReplyPayload: PostData =
            flowId === 'recognition'
              ? {
                  type: 'post',
                  postId: responseId,
                  commentId: 'viewLastReply',
                }
              : {
                  type: 'flow',
                  flowId,
                  responseId,
                  commentId: 'viewLastReply',
                };
          onReplyClicked(viewReplyPayload);
        }}
        canHidePoints={canHidePoints}
        handleInternalLinkClick={handleInternalLinkClick}
      />
    </div>
  );
}
