import {
  type GroupOrUserChipData,
  useCriteriaCount,
} from '@assembly-web/services';
import { PersonSelector as PersonSelectorImpl } from '@assembly-web/ui';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { useParticipationModalContext } from '../../Provider';
import { generateCriteriaRules } from '../utils/criteria';
import { Block } from './Block';

const messages = defineMessages({
  label: {
    defaultMessage: 'Add recipients',
    id: 'ZGYPiR',
  },
  requiredValidation: {
    defaultMessage: 'You are required to add at least one recipient.',
    id: 'N4+mQ3',
  },
});

function TotalRecipients() {
  const { watch, setValue, control } = useFormContext();
  const { editPostId } = useParticipationModalContext();
  const isEditFlow = Boolean(editPostId);

  const recipients = (
    isEditFlow ? control._defaultValues.recipients : watch('recipients', [])
  ) as GroupOrUserChipData[];
  const groupedCriteria = generateCriteriaRules(recipients);

  const { data, status, fetchStatus } = useCriteriaCount({
    criteria: groupedCriteria,
    for: 'recognition',
  });

  useEffect(() => {
    if (!recipients.length) {
      setValue('recipientsCount', 0);
    }
  }, [recipients, setValue]);

  useEffect(() => {
    if (status === 'success' && fetchStatus === 'idle') {
      const count = data.data.count;
      setValue('recipientsCount', count);
    }
  }, [data, status, fetchStatus, setValue]);

  return null;
}

function Selector() {
  const { formatMessage } = useIntl();

  const { control } = useFormContext();

  const { editPostId } = useParticipationModalContext();
  const isEditFlow = Boolean(editPostId);

  return (
    <Controller
      name="recipients"
      control={control}
      disabled={isEditFlow}
      rules={{
        validate: {
          required: (value: GroupOrUserChipData[]) => {
            if (!value.length) {
              return formatMessage(messages.requiredValidation);
            }
            return true;
          },
        },
      }}
      render={({ field: { onBlur, onChange }, fieldState: { error } }) => (
        <PersonSelectorImpl
          onChange={onChange}
          defaultRecipients={control._defaultValues.recipients}
          error={error}
          onBlur={onBlur}
          label={formatMessage(messages.label)}
        />
      )}
    />
  );
}

function Label() {
  const { watch } = useFormContext();

  return (
    <FormattedMessage
      defaultMessage="Add recipients ({count})"
      id="9XGQkw"
      values={{ count: watch('recipientsCount', 0) }}
    />
  );
}

export function PersonSelector() {
  return (
    <Block label={<Label />}>
      <Selector />
      <TotalRecipients />
    </Block>
  );
}
