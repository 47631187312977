import { useEffect, useMemo } from 'react';
import { RRule } from 'rrule';

import {
  useGetDueDate,
  useGetFlowBuilderState,
  useGetRepeatFrequency,
  useGetResponseFrequencyTimeZone,
  useGetResponseFrequencyType,
  useSetFlowBuilderState,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import {
  defaultReminderAndStartTime,
  generateRRuleOptions,
} from '../utils/distribution';
import { useGetTimeOptions } from './useGetTimeOptions';

export function useFlowSchedule() {
  const { id } = useEditorDataContext();

  const frequencyTimeOptions = useGetTimeOptions();

  const dueDate = useGetDueDate(id);
  const endDate = useGetFlowBuilderState(id, 'endDate');
  const dueTime = useGetFlowBuilderState(id, 'dueTime');
  const repeatFrequency = useGetRepeatFrequency(id);
  const responseFrequencyType = useGetResponseFrequencyType(id);
  const responseFrequencyTimeZone = useGetResponseFrequencyTimeZone(id);

  const setRRule = useSetFlowBuilderState(id, 'currentSchedule');
  const setAllowMemberLevelOccurrence = useSetFlowBuilderState(
    id,
    'allowMemberLevelOccurrence'
  );

  const rrule = useMemo(() => {
    const selectedTime =
      frequencyTimeOptions.find((option) => option.id === dueTime) ??
      frequencyTimeOptions.find(
        (option) => option.id === defaultReminderAndStartTime
      );

    const rruleOptions = generateRRuleOptions(
      dueDate,
      endDate,
      selectedTime?.id ?? defaultReminderAndStartTime,
      repeatFrequency,
      responseFrequencyType,
      responseFrequencyTimeZone
    );

    return new RRule(rruleOptions).toString();
  }, [
    dueDate,
    dueTime,
    endDate,
    repeatFrequency,
    frequencyTimeOptions,
    responseFrequencyType,
    responseFrequencyTimeZone,
  ]);

  useEffect(() => {
    setAllowMemberLevelOccurrence(!responseFrequencyTimeZone);
    setRRule({ rule: rrule });
  }, [
    rrule,
    setRRule,
    responseFrequencyTimeZone,
    setAllowMemberLevelOccurrence,
  ]);

  return rrule;
}
