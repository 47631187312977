import type {
  EditPostInteractionSettingsPayload,
  ResponseDetails,
} from '@assembly-web/services';
import { mapHexCodeToEmoticon } from '@assembly-web/services';
import {
  Button,
  InteractionSettingItem,
  LoadingSpinner,
  Modal,
  TextStyle,
  useToastStore,
} from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useEditPostInteractionSettings } from '../../hooks/useEditPostInteractionSettingsMutation';

const messages = defineMessages({
  title: {
    defaultMessage: 'Post Interaction Settings',
    id: 'ZeozsG',
  },
  description: {
    defaultMessage:
      'When turned off, existing replies and reactions for <b>{author}’s</b> post in <b>{emoji} {flowName}</b> will be hidden. This change might take a few minutes to reflect in the feed.',
    id: 'NPdBnK',
  },
  applyForThisPost: {
    defaultMessage: 'Apply for this post',
    id: 'P3ddk3',
  },
  allowRepliesText: {
    defaultMessage: 'Allow replies on this post',
    id: 'J456i4',
  },
  allowReactionsText: {
    defaultMessage: 'Allow reactions on this post',
    id: 'lplhM5',
  },
  postInteractionSettingsSuccess: {
    defaultMessage: 'Post interaction settings saved.',
    id: 'SVeIEs',
  },
  postInteractionSettingsError: {
    defaultMessage:
      'Failed to save post interaction settings. Please try again.',
    id: '7UqQ5N',
  },
});

type PostInteractionSettingsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  responseDetails?: ResponseDetails;
  postInteractionSettings: {
    allowReplies: boolean;
    allowReactions: boolean;
  };
  handlePostInteractionSettingsChange: (
    setting: 'Replies' | 'Reactions'
  ) => void;
};

export function PostInteractionSettingsModal(
  props: PostInteractionSettingsModalProps
) {
  const {
    isOpen,
    onClose,
    responseDetails,
    postInteractionSettings,
    handlePostInteractionSettingsChange,
  } = props;
  const { formatMessage } = useIntl();

  const { mutate: editPostInteractionSettings, isPending: isUpdatingSettings } =
    useEditPostInteractionSettings();
  const { showSuccessToast, showErrorToast } = useToastStore();

  const handleApplyForThisPost = () => {
    const editPostInteractionSettingsPayload: EditPostInteractionSettingsPayload =
      {
        flowId: responseDetails?.flowId ?? '',
        responseId: responseDetails?.responseId ?? '',
        hideReplies: !postInteractionSettings.allowReplies,
        hideReactions: !postInteractionSettings.allowReactions,
      };
    editPostInteractionSettings(editPostInteractionSettingsPayload, {
      onSuccess: () => {
        onClose();
        showSuccessToast(
          formatMessage(messages.postInteractionSettingsSuccess)
        );
      },
      onError: () => {
        onClose();
        showErrorToast(formatMessage(messages.postInteractionSettingsError));
      },
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage(messages.title)}
      ctaSection={
        <div className="flex justify-end">
          <Button
            type="button"
            isLoading={isUpdatingSettings}
            onClick={handleApplyForThisPost}
          >
            {Boolean(isUpdatingSettings) && (
              <LoadingSpinner className="text-gray-1 dark:text-primary-9" />
            )}
            {formatMessage(messages.applyForThisPost)}
          </Button>
        </div>
      }
    >
      <div className="grid w-[100%] max-w-[472px] grid-flow-row gap-4">
        <TextStyle variant="sm-regular" className="pr-8">
          {formatMessage(messages.description, {
            author: responseDetails?.author,
            flowName: responseDetails?.flowName,
            emoji: responseDetails?.emoticon
              ? mapHexCodeToEmoticon(responseDetails.emoticon)
              : '',
            b: (text: ReactNode) => <span className="font-bold">{text}</span>,
          })}
        </TextStyle>
        <InteractionSettingItem
          checked={postInteractionSettings.allowReplies}
          setChecked={() => {
            handlePostInteractionSettingsChange('Replies');
          }}
          text={formatMessage(messages.allowRepliesText)}
          disabled={isUpdatingSettings}
        />
        <InteractionSettingItem
          checked={postInteractionSettings.allowReactions}
          setChecked={() => {
            handlePostInteractionSettingsChange('Reactions');
          }}
          text={formatMessage(messages.allowReactionsText)}
          disabled={isUpdatingSettings}
        />
      </div>
    </Modal>
  );
}
