import { copyToClipboard } from '@assembly-web/services';
import {
  IconButton,
  TextStyle,
  Tooltip,
  useToastStore,
} from '@assembly-web/ui';
import {
  AdjustmentsHorizontalIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import {
  HandThumbDownIcon as SolidHandThumbDownIcon,
  HandThumbUpIcon as SolidHandThumbUpIcon,
} from '@heroicons/react/24/solid';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { useDoraFeedbackMutation } from '../../hooks/useDoraFeedbackMutation';
import { trackDiscoverAction } from '../../services/analytics';
import type { DoraFeedbackPromptSource } from '../Drawers/types';
import { DoraFeedbackModal } from '../modals/DoraFeedbackModal';
import { messages } from './messages';

type DoraFeedbackSectionProps = {
  feedbackSource?: DoraFeedbackPromptSource;
  className?: string;
  feedbackLabel?: string;
  filterCount?: number;
  onAdjustAnswerSettings?: () => void;
  onCopy?: () => void;
  promptId: string | null;
  markdownResponse: string | null;
  displayCopyButton?: boolean;
};

export function DoraFeedbackSection({
  className,
  feedbackLabel,
  feedbackSource = 'doraQ',
  filterCount,
  onAdjustAnswerSettings,
  onCopy,
  promptId,
  markdownResponse,
  displayCopyButton = true,
}: DoraFeedbackSectionProps) {
  const [isThumbUpSelected, setIsThumbUpSelected] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isThumbDownSelected, setIsThumbDownSelected] = useState(false);

  const { showSuccessToast } = useToastStore();
  const { formatMessage } = useIntl();

  const { mutate } = useDoraFeedbackMutation();

  const handleOnThumbsUpClick = () => {
    if (promptId) {
      mutate({
        prompt_id: promptId,
        type_is: isThumbUpSelected ? 'unset' : 'positive',
      });
    }

    if (!isThumbUpSelected) {
      if (feedbackSource === 'aiRecognition') {
        showSuccessToast(
          formatMessage(messages.feedbackSubmittedForDraftMessage)
        );
      } else {
        showSuccessToast(
          formatMessage(messages.feedbackSubmittedForAnswerMessage)
        );
      }
      trackDiscoverAction('thumbsUpClicked');
    }

    setIsThumbDownSelected(false);
    setIsThumbUpSelected(!isThumbUpSelected);
  };

  const handleOnThumbsDownClick = () => {
    if (promptId) {
      mutate({
        prompt_id: promptId,
        type_is: isThumbDownSelected ? 'unset' : 'negative',
      });
    }

    if (!isThumbDownSelected) {
      setIsFeedbackModalOpen(true);
      trackDiscoverAction('thumbsDownClicked');
    }

    setIsThumbUpSelected(false);
    setIsThumbDownSelected(!isThumbDownSelected);
  };

  const handleOnCopyButtonClick = useCallback(async () => {
    if (markdownResponse) {
      await copyToClipboard(markdownResponse);
      showSuccessToast(formatMessage(messages.responseCopiedMessage));
      onCopy?.();
    }
  }, [markdownResponse, formatMessage, onCopy, showSuccessToast]);

  return (
    <>
      <section className={twMerge('flex flex-row items-center', className)}>
        {feedbackLabel ? (
          <TextStyle
            className="mr-2 line-clamp-1 text-gray-8"
            html={feedbackLabel}
          />
        ) : null}
        <section className="mt-1 flex items-center md:mt-0">
          <IconButton
            variation="secondaryLite"
            className="mr-2 h-8 w-8"
            onClick={handleOnThumbsUpClick}
          >
            {isThumbUpSelected ? (
              <SolidHandThumbUpIcon className="h-4 w-4 fill-success-7 text-success-7" />
            ) : (
              <HandThumbUpIcon className="h-4 w-4" />
            )}
          </IconButton>

          <IconButton
            className="h-8 w-8"
            variation="secondaryLite"
            onClick={handleOnThumbsDownClick}
          >
            {isThumbDownSelected ? (
              <SolidHandThumbDownIcon className="h-4 w-4 fill-error-6 text-error-6" />
            ) : (
              <HandThumbDownIcon className="h-4 w-4" />
            )}
          </IconButton>

          {displayCopyButton ? (
            <>
              <div className="mx-2 h-4 w-[1px] rounded bg-gray-5"></div>
              <IconButton
                className="h-8 w-8"
                variation="secondaryLite"
                onClick={handleOnCopyButtonClick}
              >
                <Square2StackIcon className="h-4 w-4" />
              </IconButton>
            </>
          ) : null}

          {Boolean(onAdjustAnswerSettings) && (
            <>
              <div className="mx-2 h-4 w-[1px] rounded bg-gray-5"></div>
              <Tooltip
                tooltipText={formatMessage(messages.adjustAnswerSettings)}
              >
                <IconButton
                  className="h-8 w-16 gap-2"
                  variation="secondaryLite"
                  onClick={onAdjustAnswerSettings}
                >
                  <AdjustmentsHorizontalIcon className="h-4 w-4 text-gray-8" />
                  <TextStyle
                    variant="xs-regular"
                    className="flex h-4 w-6 items-center justify-center rounded-3xl bg-gray-3"
                  >
                    {filterCount}
                  </TextStyle>
                </IconButton>
              </Tooltip>
            </>
          )}
        </section>
      </section>
      {Boolean(isFeedbackModalOpen) && (
        <DoraFeedbackModal
          modalSource={feedbackSource}
          promptId={promptId}
          onClose={() => setIsFeedbackModalOpen(false)}
        />
      )}
    </>
  );
}
