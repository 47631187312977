import type {
  ChallengeState,
  DateRange,
  FileType,
  FlowStatusType,
} from '@assembly-web/services';
import { GlobalFilterOption } from '@assembly-web/services';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { getFilesTypeMap } from '../../../../services/file';
import { generateDateRange } from '../../services/secondaryFilters';
import { CHALLENGE_STATUS } from '../../stores/queryKeyStore';
import { useGetFilesSecondaryFilters } from './useGetFilesSecondaryFilters';
import { useGetPeopleSecondaryFilters } from './useGetPeopleSecondaryFilters';
import { useGetSelectedSecondaryFilters } from './useGetSelectedSecondaryFilters';

export const ChallengeStatusEnum = z.nativeEnum(CHALLENGE_STATUS);

export function useGetSecondaryFilters() {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') ?? '';

  const {
    isSecondaryFiltersApplied,
    secondaryFilterParams: {
      flows,
      apps,
      collections,
      postedBy,
      filter,
      dateCreatedValue,
      mentionedBy,
      flowStatusType,
      assignees,
      taskStatus,
      fileTypes,
      jobTitleValue,
      departmentsValue,
      homeLocationValue,
      workLocationValue,
      reportsToValue,
      postContentType,
      dateCreatedEndValue,
      dateCreatedStartValue,
      dueDateValue,
      dueDateEndValue,
      dueDateStartValue,
      excludedPostedByMembers,
      challengeStatusType,
    },
  } = useGetSelectedSecondaryFilters();

  const parsedChallengeStatus = z
    .array(ChallengeStatusEnum)
    .safeParse(challengeStatusType.split(','));

  let entityIn: string[] = [];

  let fromRef: string[] | undefined = undefined;

  let mentionedMemberIds: string[] | undefined = undefined;

  let assignedToRef: string[] | undefined = undefined;

  let taskState: string[] | undefined = undefined;

  let flowStatus: FlowStatusType[] = ['ACTIVE'];

  let fileMimeTypes: string[] | undefined = undefined;

  let department: string[] | undefined = undefined;

  let homeLocation: string[] | undefined = undefined;

  let workLocation: string[] | undefined = undefined;

  let managers: string[] | undefined = undefined;

  let jobTitle: string[] | undefined = undefined;

  let dueDate: DateRange | undefined = undefined;

  let dateCreated: DateRange | undefined = undefined;

  let challengeStatus: ChallengeState[] | undefined = undefined;

  const {
    fileTypesProps: { fileTypeData },
  } = useGetFilesSecondaryFilters({
    secondaryFilterTypes: { fileType: 'FILE_TYPE' },
  });

  const fileTypeOptions: Record<string, string>[] = useMemo(() => {
    if (!fileTypeData) return [];
    return fileTypeData.pages.reduce<Record<string, string>[]>((acc, page) => {
      return [
        ...acc,
        ...page.FILE_TYPE.map((item) => {
          return {
            id: item.key,
            mimeTypes: item.mimeTypes,
          };
        }),
      ];
    }, []);
  }, [fileTypeData]);

  const {
    departmentProps: { departmentOptions },
    workLocationProps: { workLocationOptions },
    homeLocationProps: { homeLocationOptions },
    jobTitleProps: { jobTitleOptions },
  } = useGetPeopleSecondaryFilters({
    secondaryFilterTypes: {
      department: 'DEPARTMENT',
      jobTitle: 'JOB_TITLE',
      workLocation: 'WORK_LOCATION',
      homeLocation: 'HOME_LOCATION',
    },
  });

  if (
    flows &&
    (filter === GlobalFilterOption.All || filter === GlobalFilterOption.Files)
  ) {
    entityIn.push(...flows.split(',').filter(Boolean));
  }

  if (
    collections &&
    (filter === GlobalFilterOption.All || filter === GlobalFilterOption.Files)
  ) {
    entityIn.push(...collections.split(',').filter(Boolean));
  }

  if (
    apps &&
    (filter === GlobalFilterOption.All || filter === GlobalFilterOption.Files)
  ) {
    entityIn.push(...apps.split(',').filter(Boolean));
  }

  if (postedBy && filter !== GlobalFilterOption.People) {
    if (
      excludedPostedByMembers.length > 0 &&
      filter !== GlobalFilterOption.All
    ) {
      fromRef = postedBy
        .split(',')
        .filter(Boolean)
        .filter((memberId) => !excludedPostedByMembers.includes(memberId));
    } else {
      fromRef = postedBy.split(',').filter(Boolean);
    }
  }

  if (
    mentionedBy &&
    (filter === GlobalFilterOption.All ||
      filter === GlobalFilterOption.Recognition)
  ) {
    mentionedMemberIds = mentionedBy.split(',').filter(Boolean);
  }

  if (searchTerm && filter === GlobalFilterOption.Flows) {
    flowStatus = ['ACTIVE', 'INACTIVE'];
  }
  if (flowStatusType && filter === GlobalFilterOption.Flows) {
    const selectedFlowStatus = flowStatusType;
    if (selectedFlowStatus === 'active') {
      flowStatus = ['ACTIVE'];
    } else if (selectedFlowStatus === 'archived') {
      flowStatus = ['INACTIVE'];
    } else if (
      selectedFlowStatus.includes(',') &&
      ['active', 'archived'].every((item) => selectedFlowStatus.includes(item))
    ) {
      flowStatus = ['ACTIVE', 'INACTIVE'];
    }
  }

  if (assignees && filter === GlobalFilterOption.Tasks) {
    assignedToRef = assignees.split(',').filter(Boolean);
  }

  if (taskStatus && filter === GlobalFilterOption.Tasks) {
    const itemToValueMap: Record<string, string> = {
      Incomplete: 'ACTIVE',
      Completed: 'COMPLETED',
      Archived: 'ARCHIVED',
    };
    const selectedTaskStatus = taskStatus.split(',');
    taskState = selectedTaskStatus.map((status) => itemToValueMap[status]);
  }

  if (fileTypes && filter === GlobalFilterOption.Files) {
    const mimeTypes: string[] = [];
    fileTypes.split(',').forEach((item) => {
      const fileToCompare = item.charAt(0).toLowerCase() + item.slice(1);
      const type = (
        fileToCompare === 'others' ? 'genericFile' : fileToCompare
      ) as FileType;
      mimeTypes.push(...getFilesTypeMap(fileTypeOptions)[type]);
    });
    fileMimeTypes = mimeTypes.length ? mimeTypes : undefined;
  }

  if (departmentsValue && filter === GlobalFilterOption.People) {
    const departments: string[] = departmentOptions
      .filter((option) => departmentsValue.split(',').includes(option.id))
      .map((option) => option.value) as string[];
    department = departments;
  }

  if (homeLocationValue && filter === GlobalFilterOption.People) {
    const homeLocations: string[] = homeLocationOptions
      .filter((option) => homeLocationValue.split(',').includes(option.id))
      .map((option) => option.value) as string[];
    homeLocation = homeLocations;
  }

  if (workLocationValue && filter === GlobalFilterOption.People) {
    const workLocations: string[] = workLocationOptions
      .filter((option) => workLocationValue.split(',').includes(option.id))
      .map((option) => option.value) as string[];
    workLocation = workLocations;
  }

  if (reportsToValue && filter === GlobalFilterOption.People) {
    managers = reportsToValue.split(',').filter(Boolean);
  }

  if (jobTitleValue && filter === GlobalFilterOption.People) {
    const jobTitles: string[] = jobTitleOptions
      .filter((option) => jobTitleValue.split(',').includes(option.id))
      .map((option) => option.value) as string[];
    jobTitle = jobTitles;
  }

  if (dateCreatedValue) {
    dateCreated = generateDateRange(
      dateCreatedValue,
      dateCreatedStartValue,
      dateCreatedEndValue
    );
  }

  if (dueDateValue && filter === GlobalFilterOption.Tasks) {
    dueDate = generateDateRange(
      dueDateValue,
      dueDateStartValue,
      dueDateEndValue
    );
  }

  if (
    challengeStatusType &&
    filter === GlobalFilterOption.Challenges &&
    parsedChallengeStatus.success
  ) {
    challengeStatus = parsedChallengeStatus.data;
  }

  return {
    dueDate,
    fromRef,
    entityIn,
    jobTitle,
    taskState,
    flowStatus,
    department,
    dateCreated,
    homeLocation,
    workLocation,
    managers,
    assignedToRef,
    fileMimeTypes,
    postContentType,
    mentionedMemberIds,
    isSecondaryFiltersApplied,
    challengeStatus,
  };
}
