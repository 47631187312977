import {
  type FlowPostResponse,
  type GlobalFilterOption,
  type UserActivitySortType,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { defineMessages, useIntl } from 'react-intl';

import { RichPostPreview } from '../../../components/RichPostPreview';

const POST_PREVIEW_CARD_MAX_WIDTH = 1000;

const msgs = defineMessages({
  hidePreview: {
    defaultMessage: 'Hide preview',
    id: 'pXNgqm',
  },
  showPreview: {
    defaultMessage: 'Show preview',
    id: 'u+VXeI',
  },
});

export function usePostEmbed(props: {
  cardType: 'direct report' | 'post';
  flowId: string;
  isNotification: boolean;
  responseId: string;
  searchTerm?: string;
  sortMethod?: UserActivitySortType;
  urlPath: string;
  postResponse?: FlowPostResponse;
  hasReplyDraft: boolean;
  lazyLoadPostDetailsOnScroll: boolean;
  cardId?: string;
  filter?: GlobalFilterOption;
}) {
  const [isOpen, setIsOpen] = useState(true);
  const cardWrapperRef = useRef<HTMLAnchorElement>(null);
  const cardDetailsContainerRef = useRef<HTMLDivElement>(null);
  const previewContainerRef = useRef<HTMLDivElement>(null);
  const { inViewport, enterCount } = useInViewport(cardWrapperRef);
  const shouldRenderPreview = isOpen && (inViewport || enterCount > 0);
  const handlePreviewToggle = () => setIsOpen(!isOpen);
  const { formatMessage } = useIntl();

  const {
    data: {
      assembly: {
        settings: {
          postPermission: {
            canHidePoints: { enabled: canHidePoints },
          },
        },
      },
    },
  } = useSuspenseUserDetails();

  const richPreviewButton = (
    <button
      className="flex w-full items-center py-2 pr-2 text-xs text-gray-8"
      onClick={handlePreviewToggle}
    >
      {formatMessage(isOpen ? msgs.hidePreview : msgs.showPreview)}

      {isOpen ? (
        <ChevronUpIcon className="h-4 w-4 text-gray-8" />
      ) : (
        <ChevronDownIcon className="h-4 w-4 text-gray-8" />
      )}
    </button>
  );

  const richPreview = shouldRenderPreview ? (
    <div className="border-l-4 border-gray-5 pl-4">
      <RichPostPreview
        analyticsProps={{
          cardType: props.cardType,
          isNotification: props.isNotification,
          searchTerm: props.searchTerm,
          sortMethod: props.sortMethod,
          filter: props.filter,
        }}
        flowId={props.flowId}
        responseId={props.responseId}
        urlPath={props.urlPath}
        postResponse={props.postResponse}
        hasReplyDraft={props.hasReplyDraft}
        lazyLoadPostDetailsOnScroll={props.lazyLoadPostDetailsOnScroll}
        cardId={props.cardId}
        canHidePoints={canHidePoints}
      />
    </div>
  ) : undefined;

  const embed = (
    <div className="absolute max-sm:hidden" ref={previewContainerRef}>
      <div className="col-start-2">{richPreviewButton}</div>
      <div className="col-start-2">{richPreview}</div>
    </div>
  );

  useEffect(() => {
    const cardWrapper = cardWrapperRef.current;
    const previewContainer = previewContainerRef.current;
    const cardDetailsContainer = cardDetailsContainerRef.current;
    const allRefsInitialized =
      previewContainer && cardDetailsContainer && cardWrapper;

    const resizeObserver = new ResizeObserver(() => {
      if (allRefsInitialized) {
        // Set the height of the card so that the grey background covers the preview, too.
        cardWrapper.style.height = `${
          cardDetailsContainer.clientHeight + previewContainer.clientHeight
        }px`;

        const directChildNodes = cardDetailsContainer.children;

        const lastRowInCol = Array.from(directChildNodes)
          .reverse()
          .find((childNode) => {
            const stylesOfChildNode = window.getComputedStyle(childNode);

            return (
              stylesOfChildNode.getPropertyValue('grid-column-start') === '2'
            );
          });

        if (lastRowInCol) {
          const lastRowRect = lastRowInCol.getBoundingClientRect();
          const wrapperRect = cardWrapper.getBoundingClientRect();

          // Position the preview directly below the last row in the second column of the CSS grid.
          previewContainer.style.top = `${
            lastRowRect.top + lastRowRect.height - wrapperRect.top
          }px`;

          previewContainer.style.left = `${
            lastRowRect.left - wrapperRect.left
          }px`;

          previewContainer.style.width = `${
            lastRowInCol.clientWidth < POST_PREVIEW_CARD_MAX_WIDTH
              ? lastRowInCol.clientWidth
              : POST_PREVIEW_CARD_MAX_WIDTH
          }px`;
        }
      }
    });

    if (allRefsInitialized) {
      resizeObserver.observe(cardDetailsContainer);
      resizeObserver.observe(previewContainer);
    }

    return () => {
      if (allRefsInitialized) {
        resizeObserver.unobserve(cardDetailsContainer);
        resizeObserver.unobserve(previewContainer);
      }
    };
  }, []);

  return {
    cardDetailsContainerRef,
    cardWrapperRef,
    embed,
  };
}
