import type {
  FlowPostResponse,
  RecognitionPost,
  UserFeedApiResponse,
} from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import { convertPostDataToFlowPostFormat } from '@assembly-web/ui';
import { type InfiniteData, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

export function useUpdateUserFeedQuery() {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  const { data: userDetails } = useUserDetails();

  const upsertRecognitionPost = (recognition: RecognitionPost) => {
    queryCache.getAll().forEach((query) => {
      const queryKey = query.queryKey;

      if (!Array.isArray(queryKey) || queryKey[0] !== 'userFeed') return;

      const userFeedResponse =
        queryClient.getQueryData<InfiniteData<UserFeedApiResponse>>(queryKey);

      if (!userFeedResponse || !userDetails || !recognition.postDTO) {
        return;
      }

      const feedItemToBeAdded = convertPostDataToFlowPostFormat({
        ...recognition.postDTO,
        from: { ...userDetails.member, ...userDetails.member.profile },
      });

      const updatedFeed = produce(userFeedResponse, (existingFeed) => {
        existingFeed.pages[0].data.unshift({
          type: 'post',
          cardDetails: {
            ...feedItemToBeAdded,
            canEdit: true,
            canDelete: true,
            replySummary: {
              count: 0,
              lastRepliedAt: '',
              respondentsCount: 0,
              initialRespondents: [],
            },
          },
          id: recognition.postDTO?.postID ?? '',
          updatedAt: recognition.postDTO?.updatedAt ?? '',
          createdAt: recognition.postDTO?.createdAt ?? '',
          _meta: {
            to: [
              {
                id: '',
                fullName: '',
              },
            ],
            type: 'recognition',
            fromRef: {
              entityId: userDetails.member.memberId,
            },
            from: {
              id: userDetails.member.memberId,
            },
            message: recognition.postDTO?.message ?? '',
            entityId: recognition.postDTO?.postID ?? '',
          },
          source: 'assembly',
          highlight: {},
          score: 2,
        });
      });

      queryClient.setQueryData(queryKey, updatedFeed);
    });
  };

  const upsertFlowPost = (
    flowResponse: FlowPostResponse & { instanceId: string }
  ) => {
    queryCache.getAll().forEach((query) => {
      const queryKey = query.queryKey;

      if (!Array.isArray(queryKey) || queryKey[0] !== 'userFeed') return;

      const userFeedResponse =
        queryClient.getQueryData<InfiniteData<UserFeedApiResponse>>(queryKey);

      if (!userFeedResponse || !userDetails) return;

      const updatedFeed = produce(userFeedResponse, (existingFeed) => {
        existingFeed.pages[0].data.unshift({
          type: 'response',
          cardDetails: {
            ...flowResponse,
            replySummary: {
              count: 0,
              lastRepliedAt: '',
              respondentsCount: 0,
              initialRespondents: [],
            },
            reactions: [],
            isBoostAllowed: false,
            kind: 'INTERNAL',
            canEdit: true,
            canDelete: true,
            instanceId: flowResponse.instanceId,
            respondent: {
              ...userDetails.member,
              ...userDetails.member.profile,
            },
          },
          _meta: {
            name: flowResponse.flow.name,
            responseBlocks: [],
            responseKind: 'INTERNAL',
            respondent: {
              id: flowResponse.respondent?.memberID ?? '',
              fullName: flowResponse.respondent?.name ?? '',
              ...flowResponse.respondent,
            },
            entityId: flowResponse.responseId,
            icon: flowResponse.flow.icon.value,
            flowId: flowResponse.flow.flowId,
          },
          id: flowResponse.responseId,
          updatedAt: '',
          createdAt: '',
          source: 'assembly',
          highlight: {},
          score: 2,
        });
      });

      queryClient.setQueryData(queryKey, updatedFeed);
    });
  };

  return {
    upsertFlowPost,
    upsertRecognitionPost,
  };
}
