import type { GlobalFilterOption, ItemToAdd } from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';

import { useGetCollectionsQuery } from '../../hooks/useGetCollectionsQuery';
import { BaseCollectionModal } from './BaseCollectionModal';

const messages = defineMessages({
  header: {
    defaultMessage: 'Add to a Pinned Collection',
    id: 'yIwj03',
  },
  content: {
    defaultMessage: 'Add to an existing pinned collection or create a new one',
    id: '9ocWSs',
  },
  chooseCollection: {
    defaultMessage: 'Choose an existing pinned collection',
    id: 'BNxtQu',
  },
  pinToCollection: {
    defaultMessage: 'Add to pinned collection',
    id: 'CYCyrd',
  },
  pinning: {
    defaultMessage: 'Adding to pinned collection',
    id: 'vbXqWB',
  },
});

type AddToPinnedCollectionModalProps = {
  filter: GlobalFilterOption;
  itemToAdd: ItemToAdd | null;
  onClose: () => void;
  onCreateCollection: () => void;
  open: boolean;
  searchTerm: string;
};

export function AddToPinnedCollectionModal(
  props: AddToPinnedCollectionModalProps
) {
  const { onCreateCollection, onClose, open, itemToAdd, filter, searchTerm } =
    props;
  const { formatMessage } = useIntl();

  const { pinnedCollections } = useGetCollectionsQuery({ enabled: open });

  return (
    <BaseCollectionModal
      onCreateCollection={onCreateCollection}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.header)}
      bodyText={formatMessage(messages.content)}
      collectionLabel={formatMessage(messages.chooseCollection)}
      ctaText={formatMessage(messages.pinToCollection)}
      ctaLoadingText={formatMessage(messages.pinning)}
      filter={filter}
      itemToAdd={itemToAdd}
      searchTerm={searchTerm}
      collections={pinnedCollections}
    />
  );
}
