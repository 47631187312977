import { browseTemplateGallery, templatesCube } from '@assembly-web/assets';
import {
  GlobalFilterOption,
  userAuthStore,
  type UserDetails,
} from '@assembly-web/services';
import {
  Button,
  NoRewardDisclaimer,
  TextStyle,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/solid';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { FlowsDropdown } from './FlowsDropdown';

const msgs = defineMessages({
  [GlobalFilterOption.Notifications]: {
    defaultMessage:
      '🍃 Nothing found matching this search. Try adjusting your search.',
    id: 'GcgTgQ',
  },
  [GlobalFilterOption.Files]: {
    defaultMessage:
      'You don’t have any files, yet. Upload one to a post to get started!',
    id: 'UmzYgL',
  },
  [GlobalFilterOption.Tasks]: {
    defaultMessage:
      'You don’t have any tasks, yet. Create a task to get started!',
    id: '7nfn//',
  },
  [GlobalFilterOption.Flows]: {
    defaultMessage: `{canCreateFlow, select,
      true {You don’t have any flows, yet. Flows help you automate your work and facilitate communication with your teammates -- browse our flow templates below:}
      false {You don’t have any flows, yet. Flows help you automate your work and facilitate communication with your teammates -- talk to your administrator about adding flows to your workspace.}
      other {unknown}}`,
    id: 'QJGm62',
  },
  [GlobalFilterOption.Collections]: {
    defaultMessage:
      'You don’t have any collections yet - organize your most important work here.',
    id: 'zhElqS',
  },
  [GlobalFilterOption.People]: {
    defaultMessage:
      'You don’t have any people in your workspace. Add the teammates you collaborate with the most to get more value out of your workspace, together!',
    id: 'RX8S51',
  },
  [GlobalFilterOption.All]: {
    defaultMessage:
      '🍃 Nothing found matching this search. Try adjusting your search.',
    id: 'GcgTgQ',
  },
  [GlobalFilterOption.Rewards]: {
    defaultMessage:
      '🍃 Nothing found matching this search. Try adjusting your search.',
    id: 'GcgTgQ',
  },
  [GlobalFilterOption.Challenges]: {
    defaultMessage:
      '🍃 Nothing found matching this search. Try adjusting your search.',
    id: 'GcgTgQ',
  },
  [GlobalFilterOption.Recognition]: {
    defaultMessage:
      '🍃 Nothing found matching this search. Try adjusting your search.',
    id: 'GcgTgQ',
  },
  [GlobalFilterOption.Department]: {
    defaultMessage:
      '🍃 Nothing found matching this search. Try adjusting your search.',
    id: 'GcgTgQ',
  },
  createCollection: {
    defaultMessage: 'Create a collection',
    id: 'qkFwbV',
  },
  createPost: {
    defaultMessage: 'Create a post',
    id: 'ToUT+W',
  },
  createTask: {
    defaultMessage: 'Create a task',
    id: 'd8DiOp',
  },
  browseTemplateGalleryCTA: {
    defaultMessage: 'Browse templates',
    id: 'jhIaTc',
  },
  browseTemplateGalleryDescription: {
    defaultMessage:
      'Choose from 100s of use cases in our library of templates. Expert suggestions for processes like 1:1s, Weekly Agendas, Sales Wins, and more that you can modify to your specifications.',
    id: 'cvMFzL',
  },
  browseTemplateGalleryHeader: {
    defaultMessage:
      'Flows: Form-based workflows for all of your tasks & processes',
    id: '+ebcfD',
  },
  inviteTeammates: {
    defaultMessage: 'Invite teammates',
    id: '8PIpCw',
  },
});

const secondaryFilterMessages = defineMessages({
  secondaryFiltersEmptyText: {
    defaultMessage: `No results for {secondaryFilterText} you have access to. If you have applied additional filters or search terms, try adjusting to view items you have access to.`,
    id: 'UE3emz',
  },
  items: {
    defaultMessage: 'items',
    id: '5rh4Wi',
  },
  collections: {
    defaultMessage: 'collections',
    id: '1vqZL8',
  },
  flows: {
    defaultMessage: 'flows',
    id: 'N9DJLM',
  },
  files: {
    defaultMessage: 'files',
    id: 'l17U7P',
  },
  tasks: {
    defaultMessage: 'tasks',
    id: 'EPlvd4',
  },
});

const noRewardMessages = defineMessages({
  noRewardsFoundTitle: {
    defaultMessage: 'We didn’t find anything for this search.',
    id: 'trUE1i',
  },
  noRewardsFoundDescription: {
    defaultMessage: 'Try shortening or rephrasing your search.',
    id: 'bNr8sl',
  },
});

type EmptySearchResultsProps = {
  filter: GlobalFilterOption;
  onInviteClick?: () => void;
  searchTerm: string;
  userDetails: UserDetails;
  openCreateCollectionModal?: () => void;
  isSecondaryFiltersApplied?: boolean;
};

export function EmptySearchResults({
  filter,
  onInviteClick,
  openCreateCollectionModal,
  searchTerm,
  userDetails,
  isSecondaryFiltersApplied,
}: EmptySearchResultsProps) {
  const { formatMessage } = useIntl();

  const navigate = useAssemblyNavigate();

  const {
    assembly: { workspaceSlugPath },
    member: {
      permissions: { canCreateFlow },
    },
  } = userDetails;

  const isMobileAppV3 = userAuthStore.getState().isMobileAppV3;

  if (searchTerm) {
    if (filter === GlobalFilterOption.Rewards) {
      return (
        <NoRewardDisclaimer
          title={formatMessage(noRewardMessages.noRewardsFoundTitle)}
          description={formatMessage(
            noRewardMessages.noRewardsFoundDescription
          )}
        />
      );
    }
    return (
      <TextStyle subdued variant="sm-regular">
        {formatMessage(msgs[GlobalFilterOption.All])}
      </TextStyle>
    );
  }

  const renderEmptyState = () => {
    if (filter === GlobalFilterOption.Rewards) {
      return (
        <NoRewardDisclaimer
          title={formatMessage(noRewardMessages.noRewardsFoundTitle)}
          description={formatMessage(
            noRewardMessages.noRewardsFoundDescription
          )}
        />
      );
    }
    return (
      <>
        <TextStyle subdued variant="sm-regular">
          {formatMessage(msgs[filter], { canCreateFlow })}
        </TextStyle>
        {filter === GlobalFilterOption.Files && !isMobileAppV3 && (
          <FlowsDropdown userDetails={userDetails} filterType={filter}>
            <Button variation="primary" size="small" className="w-40 gap-2">
              <PlusIcon className="h-4 w-4" />
              {formatMessage(msgs.createPost)}
              <ChevronDownIcon className="h-4 w-4" />
            </Button>
          </FlowsDropdown>
        )}
        {filter === GlobalFilterOption.Collections && (
          <Button
            size="small"
            variation="primary"
            className="w-[10.5rem] gap-2"
            onClick={openCreateCollectionModal}
          >
            <PlusIcon className="h-4 w-4" />
            {formatMessage(msgs.createCollection)}
          </Button>
        )}
        {filter === GlobalFilterOption.Tasks && (
          <Button
            size="small"
            variation="primary"
            className="w-[8.5rem] gap-2"
            onClick={() =>
              navigate(
                `/${workspaceSlugPath}/notebook/deadline?createTask=true`
              )
            }
          >
            <PlusIcon className="h-4 w-4" />
            {formatMessage(msgs.createTask)}
          </Button>
        )}
        {Boolean(canCreateFlow) && filter === GlobalFilterOption.Flows && (
          <section className="order-1 flex flex-none grow-0 flex-row items-start self-stretch rounded-lg border border-gray-5">
            <img src={browseTemplateGallery} alt="" />
            <div className="order-1 flex grow flex-col items-start justify-center self-stretch px-8 py-3">
              <TextStyle variant="base-medium" className="pb-1">
                {formatMessage(msgs.browseTemplateGalleryHeader)}
              </TextStyle>
              <TextStyle variant="sm-regular">
                {formatMessage(msgs.browseTemplateGalleryDescription)}
              </TextStyle>
              <Button
                variation="primary"
                size="small"
                className="mt-3 w-[164px] gap-2"
                onClick={() => navigate(`/templates`)}
              >
                <img src={templatesCube} alt="" className="h-4 w-4" />
                {formatMessage(msgs.browseTemplateGalleryCTA)}
              </Button>
            </div>
          </section>
        )}
        {filter === GlobalFilterOption.People && (
          <Button
            size="small"
            variation="primary"
            className="w-40 gap-2"
            onClick={onInviteClick}
          >
            <PlusIcon className="h-4 w-4" />
            {formatMessage(msgs.inviteTeammates)}
          </Button>
        )}
      </>
    );
  };

  const secondaryFiltersTextMap = {
    [GlobalFilterOption.All]: formatMessage(secondaryFilterMessages.items),
    [GlobalFilterOption.Collections]: formatMessage(
      secondaryFilterMessages.collections
    ),
    [GlobalFilterOption.Flows]: formatMessage(secondaryFilterMessages.flows),
    [GlobalFilterOption.Files]: formatMessage(secondaryFilterMessages.files),
    [GlobalFilterOption.Tasks]: formatMessage(secondaryFilterMessages.tasks),
    [GlobalFilterOption.People]: '',
    [GlobalFilterOption.Rewards]: '',
    [GlobalFilterOption.Notifications]: '',
    [GlobalFilterOption.Challenges]: '',
    [GlobalFilterOption.Recognition]: '',
    [GlobalFilterOption.Department]: '',
  };

  const renderSecondaryFiltersEmptyState = () => {
    return (
      <TextStyle subdued variant="sm-regular">
        {filter === GlobalFilterOption.People ? (
          <FormattedMessage
            defaultMessage="No results for people in your workspace. If you have applied additional filters or search terms, try adjusting to find the person you're looking for. You can also <invite>invite new members</invite>."
            id="BvyhW3"
            values={{
              invite: (text) => (
                <button
                  className="text-primary-6 underline"
                  onClick={onInviteClick}
                >
                  {text}
                </button>
              ),
            }}
          />
        ) : (
          formatMessage(secondaryFilterMessages.secondaryFiltersEmptyText, {
            secondaryFilterText: secondaryFiltersTextMap[filter],
          })
        )}
      </TextStyle>
    );
  };

  return (
    <section className="flex flex-col gap-2 px-3">
      {isSecondaryFiltersApplied
        ? renderSecondaryFiltersEmptyState()
        : renderEmptyState()}
    </section>
  );
}
