import { Checkbox } from '@assembly-web/ui';

export function Loader({
  showAvatarLoader = true,
}: {
  showAvatarLoader: boolean;
}) {
  return (
    <div
      className="absolute flex animate-pulse items-center gap-2 px-2 py-2"
      role="menuitem"
    >
      <Checkbox disabled name="selectable-option-loader" aria-label="loader" />
      {Boolean(showAvatarLoader) && (
        <div className="h-6 w-6 rounded-full bg-gray-5"></div>
      )}
      <div className="h-[16px] w-[132px] rounded-full bg-gray-5"></div>
    </div>
  );
}
