import { getTodayCalendarDate } from '@assembly-web/services';
import {
  Button,
  DatePicker,
  type DateSegmentType,
  Modal,
  TextStyle,
} from '@assembly-web/ui';
import type { CalendarDate } from '@internationalized/date';
import { type ElementRef, useCallback, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import {
  useGetFlowBuilderState,
  useSetFlowBuilderState,
} from '../../../../../../../stores/useFlowBuilderStore';
import { trackFlowEditorAction } from '../../../../../services/analytics';
import { useEditorDataContext } from '../../context/EditorDataContext';

type EndDateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  minValue: CalendarDate;
};

const messages = defineMessages({
  title: {
    defaultMessage: 'Set an end date',
    id: 'HO09oL',
  },
  description: {
    defaultMessage: `Setting an end date will stop your recurring schedule from running after a certain point in time. If you don't, your flow will keep running until you edit or archive it.`,
    id: 'x62VCm',
  },
  setAnEndDateLabel: {
    defaultMessage: 'Set an end date',
    id: 'HO09oL',
  },
  cancelLabel: {
    defaultMessage: 'Cancel',
    id: '47FYwb',
  },
  removeEndDateLabel: {
    defaultMessage: 'Remove end date',
    id: 'F+Gyka',
  },
  doneLabel: {
    defaultMessage: 'Done',
    id: 'JXdbo8',
  },
});

function CustomDateSegment(
  { segment, onButtonClick }: DateSegmentType & { onButtonClick?: () => void },
  options?: { locale: string }
) {
  const formatSegment = useCallback(() => {
    switch (segment.type) {
      case 'month':
        return new Date(2000, parseInt(segment.text) - 1, 1).toLocaleString(
          options?.locale ?? 'en-US',
          { month: 'long' }
        );
      case 'day':
        return `${parseInt(segment.text)},`;
      case 'year':
        return segment.text;
      default:
        return '';
    }
  }, [segment.text, segment.type, options?.locale]);

  return (
    <TextStyle variant="sm-regular" className="mr-0.5 text-gray-9">
      <button onClick={onButtonClick}>{formatSegment()}</button>
    </TextStyle>
  );
}

export function EndDateModal({ isOpen, onClose, minValue }: EndDateModalProps) {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const todayDate = useMemo(() => getTodayCalendarDate(), []);

  const endDate = useGetFlowBuilderState(id, 'endDate');
  const setEndDate = useSetFlowBuilderState(id, 'endDate');

  const frequencySettingsContainerRef = useRef<ElementRef<'section'>>(null);

  const handleDateChange = useCallback(
    (value: CalendarDate) => {
      setEndDate(value);
    },
    [setEndDate]
  );

  const handleRemoveEndDate = useCallback(() => {
    setEndDate(null);
    onClose();
  }, [onClose, setEndDate]);

  const handleDone = useCallback(() => {
    setEndDate(endDate ?? todayDate);
    setIsDatePickerOpen(false);
    onClose();
    trackFlowEditorAction('setEndDateConfirmed');
  }, [setIsDatePickerOpen, onClose, endDate, setEndDate, todayDate]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      headerClassName="z-50 p-0"
      title={formatMessage(messages.title)}
      bodyClassName="flex flex-col flex-1 px-0 pb-0"
      className="flex h-fit !w-96 flex-col p-6 antialiased"
    >
      <section
        className="flex flex-col gap-4"
        ref={frequencySettingsContainerRef}
      >
        <TextStyle variant="xs-regular" className="text-gray-9">
          {formatMessage(messages.description)}
        </TextStyle>

        <DatePicker
          minValue={minValue}
          popoverPlacement="bottom"
          isOpen={isDatePickerOpen}
          shouldCloseOnSelect={true}
          DateSegment={CustomDateSegment}
          onOpenChange={setIsDatePickerOpen}
          focusedValue={endDate ?? todayDate}
          selectedValue={endDate ?? todayDate}
          onFocusedValueChange={handleDateChange}
          onSelectedValueChange={handleDateChange}
          label={formatMessage(messages.setAnEndDateLabel)}
          portalContainer={frequencySettingsContainerRef.current ?? undefined}
        />
        <footer
          className={twJoin(
            'flex justify-between',
            isDatePickerOpen && 'mt-80'
          )}
        >
          <Button variation="tertiaryLite" onClick={onClose}>
            {formatMessage(messages.cancelLabel)}
          </Button>
          <div className="flex gap-2">
            <Button variation="secondaryLite" onClick={handleRemoveEndDate}>
              {formatMessage(messages.removeEndDateLabel)}
            </Button>
            <Button onClick={handleDone}>
              {formatMessage(messages.doneLabel)}
            </Button>
          </div>
        </footer>
      </section>
    </Modal>
  );
}
