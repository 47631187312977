import {
  SplitNames,
  useFeatureSplit,
  type UserFeedApiResponse,
} from '@assembly-web/services';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import type { GetUserFeedPayload } from '../queries/getUserFeedQuery';
import { getUserFeedQuery } from '../queries/getUserFeedQuery';

export function useGetUserFeed(
  payload: GetUserFeedPayload,
  options?: {
    enabled: boolean;
  }
) {
  const queryClient = useQueryClient();
  const { isTreatmentActive: isExcludePostsWithRepliesEnabled } =
    useFeatureSplit(SplitNames.ExcludePostsWithReplies);

  return useInfiniteQuery<UserFeedApiResponse>(
    getUserFeedQuery({
      payload,
      queryClient,
      options,
      isExcludePostsWithRepliesEnabled,
    })
  );
}
