import { translations as participationTranslations } from '@assembly-web/participation';
import { config } from '@assembly-web/services';
import { translations as uiTranslations } from '@assembly-web/ui';
import dayjs from 'dayjs';
import { type ReactNode, useEffect, useState } from 'react';
import { createIntl, createIntlCache, RawIntlProvider } from 'react-intl';

export function Intl({ children }: { children: ReactNode }) {
  const [isInitialized, setIsInitialized] = useState(false);

  const cache = createIntlCache();
  const [messages, setMessages] = useState({});

  // const { data: userDetails } = useUserDetails();

  // const hasLocalizationCustomization = useHasLocalizationCustomization();

  // const userLocaleSelection = userDetails?.member.profile.language;

  // const locale = useMemo(() => {
  //   let locale = config.locale;

  //   if (hasLocalizationCustomization) {
  //     locale = userLocaleSelection === 'es' ? 'es' : 'en';
  //   }

  //   // For local testing of translations in Pseudo-locale
  //   if (config.locale === 'en-XA') {
  //     locale = config.locale;
  //   }

  //   return locale;
  // }, [hasLocalizationCustomization, userLocaleSelection]);

  const locale = config.locale;

  useEffect(() => {
    const loadTranslations = async () => {
      let localeTranslations;
      switch (locale) {
        case 'es':
          localeTranslations = await import('../translations/es.json');
          await import(`dayjs/locale/es`);
          dayjs.locale(`es`);
          break;
        case 'en-XA':
          localeTranslations = await import('../translations/en-XA.json');
          break;
        case 'en':
        default:
          localeTranslations = await import('../translations/en.json');
          await import(`dayjs/locale/en`);
          dayjs.locale(`en`);
          break;
      }

      setMessages({
        ...localeTranslations.default,
        ...uiTranslations[locale],
        ...participationTranslations[locale],
      });

      if (!isInitialized) {
        setIsInitialized(true);
      }
    };

    loadTranslations();
  }, [isInitialized, locale]);

  const intl = createIntl({ locale, messages }, cache);

  if (!isInitialized) {
    return null;
  }

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}
