import { activitiesIllustration, emptySearch } from '@assembly-web/assets';
import { TextStyle } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

const labels = defineMessages({
  searchText: {
    defaultMessage:
      'We couldn’t find anything matching your search. Please try again.',
    id: '1x1S+y',
  },
});

export function RecognitionFeedEmptySearchState() {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') ?? '';

  return (
    <div className="w-full rounded-lg bg-gray-1 p-4 shadow-md-down">
      <div className="mx-auto flex w-fit flex-col gap-4">
        <img
          alt={formatMessage(labels.searchText)}
          src={searchTerm ? emptySearch : activitiesIllustration}
          className="mx-auto w-fit"
        />
        <TextStyle>{formatMessage(labels.searchText)}</TextStyle>
      </div>
    </div>
  );
}
