import type {
  FlowDetail,
  FlowsToAnswerApiResponse,
  FlowsToDoCountApiResponse,
  FlowsToViewApiResponse,
  Icon,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import { produce } from 'immer';

export const actionBarQueryOptions = queryOptions({
  queryKey: ['actionBar'],
  queryFn: async () => {
    const flowsToDoCountResponse = assemblyAPI.get<FlowsToDoCountApiResponse>(
      APIEndpoints.getFlowsToDoCount,
      { params: { days: 31 } }
    );

    const flowsToAnswerAnytimeResponse =
      assemblyAPI.post<FlowsToAnswerApiResponse>(
        APIEndpoints.getFlowsToAnswerAnytime,
        {},
        { params: { limit: 99 } }
      );

    const flowsToAnswerNowResponse = assemblyAPI.post<FlowsToAnswerApiResponse>(
      APIEndpoints.getFlowsToAnswerNow,
      {},
      { params: { limit: 99 } }
    );

    const flowsToViewResponse = assemblyAPI.post<FlowsToViewApiResponse>(
      APIEndpoints.getActiveFlows,
      {},
      { params: { limit: 99 } }
    );

    const [
      flowsToDoCountData,
      flowsToAnswerNowData,
      flowsToAnswerAnytimeData,
      flowsToView,
    ] = await Promise.all([
      flowsToDoCountResponse,
      flowsToAnswerNowResponse,
      flowsToAnswerAnytimeResponse,
      flowsToViewResponse,
    ]);

    return {
      flowsToDoCount: flowsToDoCountData.data,
      flowsToAnswerNow: flowsToAnswerNowData.data,
      flowsToAnswerAnytime: flowsToAnswerAnytimeData.data,
      flowsToView: flowsToView.data.data,
    };
  },
});

export function updateFlowDetailsInActionBar({
  queryClient,
  flowId,
  flowName,
  flowIcon,
}: {
  queryClient: QueryClient;
  flowId: string;
  flowName: string;
  flowIcon: Icon;
}) {
  const queryKey = actionBarQueryOptions.queryKey;

  const actionBarQueryCache:
    | {
        flowsToDoCount: FlowsToDoCountApiResponse;
        flowsToAnswerNow: FlowsToAnswerApiResponse;
        flowsToAnswerAnytime: FlowsToAnswerApiResponse;
        flowsToView: FlowDetail[];
      }
    | undefined = queryClient.getQueryData(queryKey);

  const updatedCache = produce(actionBarQueryCache, (draft) => {
    draft?.flowsToView.map((flow) => {
      if (flow.flowId === flowId) {
        flow.name = flowName;
        flow.icon = flowIcon;
      }
    });
  });

  queryClient.setQueryData(queryKey, updatedCache);
}

export function useActionBarQuery(
  {
    enabled,
  }: {
    enabled: boolean;
  } = { enabled: true }
) {
  return useQuery({ ...actionBarQueryOptions, enabled });
}

export function useActionBar() {
  return useSuspenseQuery(actionBarQueryOptions);
}
