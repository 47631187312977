import {
  APIEndpoints,
  type FlowFeedParams,
  type FlowPostResponse,
  type MetadataProps,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

export const useGetFlowFeed = (
  flowId: string,
  params: FlowFeedParams,
  enabled?: boolean
) => {
  return useQuery({
    enabled,
    queryKey: ['flowFeed', params],
    queryFn: async () => {
      const response = await assemblyAPI.post<{ data: FlowPostResponse[] }>(
        APIEndpoints.flowFeed(flowId),
        params
      );

      return response.data;
    },
    retry: false,
  });
};

export const useGetFlowFeedInfinite = (flowId: string) => {
  const params = {
    filter: {},
    limit: 20,
    sortBy: 'desc',
  };

  return useInfiniteQuery<{
    data: FlowPostResponse[];
    metadata?: MetadataProps;
  }>({
    queryKey: ['flowFeed', params],
    queryFn: async ({ pageParam }) => {
      const response = await assemblyAPI.post<{
        data: FlowPostResponse[];
        metadata?: MetadataProps;
      }>(APIEndpoints.flowFeed(flowId), {
        ...params,
        ...(pageParam ? { cursor: pageParam } : {}),
      });

      return response.data;
    },
    initialPageParam: 0,
    getNextPageParam: (page) =>
      page.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page) => {
      return page.metadata?.pagination.cursor.previous;
    },
    retry: false,
  });
};
