import {
  type AssistantHistoryItem,
  SplitNames,
  useFeatureSplit,
} from '@assembly-web/services';
import dayjs from 'dayjs';

import { useDoraThreadDetails } from './getDoraThreadQueryDetails';
import { useGetDoraChatHistoryQuery } from './useChatHistoryQuery';
import { useChatThreadsSummary } from './useChatThreadsSummary';

const totalConversationsForMonth = 5;
const totalPromptsPerConversation = 10;
const conversationCutOffPeriodInMinutes = 60;

export function isDoraConversationPastCutOffTime(createdDate: Date | string) {
  return (
    dayjs().diff(dayjs(createdDate), 'minutes') >=
    conversationCutOffPeriodInMinutes
  );
}

export function useDoraChatRestrictions(props?: { threadId: string }) {
  const { threadId } = props ?? { threadId: '' };

  const { data: chatHistoryData } = useGetDoraChatHistoryQuery(threadId);

  const lastMessageCreatedAt =
    (
      chatHistoryData?.findLast(
        (elem) => elem.role === 'assistant'
      ) as AssistantHistoryItem | null
    )?.createdAt ?? new Date();

  const isLastMessagePastCutOffTime =
    isDoraConversationPastCutOffTime(lastMessageCreatedAt);

  const { isTreatmentActive: isDoraReportingInsightsV0Enabled } =
    useFeatureSplit(SplitNames.DoraReportingInsightsV0);

  const isLimitedReportingExperience = !isDoraReportingInsightsV0Enabled;

  const { data: chatThreads } = useChatThreadsSummary(
    isLimitedReportingExperience
  );

  const { refetch: refetchRestrictionDetails, data: threadDetails } =
    useDoraThreadDetails({
      threadId,
      enabled:
        Boolean(threadId) &&
        !isLastMessagePastCutOffTime &&
        isLimitedReportingExperience,
    });

  const remainingPrompts =
    totalPromptsPerConversation - (threadDetails?.promptCount ?? 0);

  const completedConversations =
    chatThreads?.filter(
      (t) => t.state === 'COMPLETED' && t.id !== threadId && t.promptCount >= 1
    ) ?? [];

  const currentConversationCount = completedConversations.length;
  const actualRemainingConversationsForMonth =
    totalConversationsForMonth - currentConversationCount;

  const remainingConversationsForMonth =
    actualRemainingConversationsForMonth < 0
      ? 0
      : actualRemainingConversationsForMonth;

  return {
    isLimitedReportingExperience,
    isLastMessagePastCutOffTime,

    currentConversationCount,
    refetchRestrictionDetails,
    remainingPrompts,
    remainingConversationsForMonth,
    totalConversationsForMonth,
    hasReachedConversationLimit: remainingConversationsForMonth === 0,
  };
}
