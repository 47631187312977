import {
  cleanEditorState,
  EntityType,
  fetchRecognitionDraftKey,
  logger,
  removeNewLinesAndTabs,
  sanitizeHtml,
  useEditRecognitionPost,
  useSaveDrafts,
  useSaveRecognitionPost,
} from '@assembly-web/services';
import {
  Button,
  LoadingSpinner,
  TextStyle,
  useToastStore,
} from '@assembly-web/ui';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

import { useGiveRecognitionFlowDetails } from '../../hooks/useGiveRecognitionFlowDetails';
import { useParticipationModalContext } from '../../Provider';
import { generateCriteriaRules } from '../utils/criteria';

const messages = defineMessages({
  success: {
    defaultMessage: 'Recognition posted successfully!',
    id: 'iWY56R',
  },
  editSuccessFull: {
    defaultMessage: 'Recognition edited successfully!',
    id: 'eo7H2H',
  },
  editError: {
    defaultMessage: 'Sorry, error in editing recognition. Please try again.',
    id: 'mAIgNq',
  },
  error: {
    defaultMessage: 'Sorry, error in posting recognition. Please try again.',
    id: 'I6fOJO',
  },
  editing: {
    defaultMessage: 'Editing...',
    id: '6CJjWk',
  },
  saving: {
    defaultMessage: 'Saving...',
    id: 'TiR/Hq',
  },
});

export function SubmitButton() {
  const { handleSubmit, getValues, control } = useFormContext();
  const { closeModal, editPostId } = useParticipationModalContext();
  const { showSuccessToast, showErrorToast } = useToastStore();
  const { formatMessage } = useIntl();
  const isEditFlow = Boolean(editPostId);

  const {
    data: {
      flowDetails: { name },
    },
  } = useGiveRecognitionFlowDetails();
  const { mutate: clearDraft } = useSaveDrafts();

  const { mutate: savePost, status: saveStatus } = useSaveRecognitionPost({
    options: {
      onSuccess: () => {
        showSuccessToast(formatMessage(messages.success));
        clearDraft({
          payload: {
            toCache: {
              entityType: EntityType.Recognition,
              postData: {},
            },
            toServer: {
              entityType: EntityType.Recognition,
              postData: {},
            },
          },
          queryCacheToUpdate: fetchRecognitionDraftKey,
        });
        closeModal();
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        showErrorToast(formatMessage(messages.error));
        logger.error('Failed to create recognition post', payload, errorInfo);
        closeModal();
      },
    },
  });

  const { mutate: editPost, status: editStatus } = useEditRecognitionPost({
    options: {
      onSuccess: () => {
        showSuccessToast(formatMessage(messages.editSuccessFull));
        closeModal();
      },
      onError: (err, payload) => {
        const errorInfo = err instanceof Error ? err : undefined;
        showErrorToast(formatMessage(messages.editError));
        logger.error('Failed to edit recognition post', payload, errorInfo);
        closeModal();
      },
    },
  });
  const isLoading = editStatus === 'pending' || saveStatus === 'pending';

  const getPayload = useCallback(() => {
    const {
      message: { html, json, plainText, mentionIds },
      points: pointsEach,
      coreValue,
      isPrivate,
      recipients,
    } = getValues();
    const groupedCriteria = generateCriteriaRules(
      isEditFlow ? control._defaultValues.recipients : recipients
    );

    const cleanedState = cleanEditorState({
      html,
      json,
      plainText,
    });

    return {
      message: removeNewLinesAndTabs(plainText),
      messageTokens: cleanedState.json,
      messageHtml: sanitizeHtml(cleanedState.html),
      mention: {
        memberIds: mentionIds,
      },
      pointsEach:
        (isEditFlow ? control._defaultValues.points : pointsEach) ?? 0,
      coreValue,
      isPrivate,
      recipient: {
        criteria: groupedCriteria,
      },
      postId: isEditFlow ? editPostId : '',
    };
  }, [isEditFlow, getValues, control, editPostId]);

  const onSubmit = useMemo(() => {
    return handleSubmit(() => {
      const request = {
        toServer: {
          payload: getPayload(),
        },
        toCache: {
          recipient: isEditFlow
            ? control._defaultValues.recipients
            : getValues().recipients,
        },
      };
      if (isEditFlow) {
        editPost(request);
      } else {
        savePost(request);
      }
    });
  }, [
    getPayload,
    handleSubmit,
    isEditFlow,
    editPost,
    savePost,
    control,
    getValues,
  ]);

  return (
    <Button
      onClick={onSubmit}
      isLoading={isLoading}
      variation="primary"
      size="large"
    >
      {isLoading ? (
        <>
          <LoadingSpinner />
          <TextStyle variant="sm-regular">
            {isEditFlow
              ? formatMessage(messages.editing)
              : formatMessage(messages.saving)}
          </TextStyle>
        </>
      ) : (
        <>
          <span className="h-4 w-4">
            <CheckCircleIcon />
          </span>
          {name}
        </>
      )}
    </Button>
  );
}
