import type {
  CriteriaRuleType,
  Operator,
  PermissionType,
} from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { navItemsQueryKey } from '../../nav/useNavData';

export type MemberCriteriaAPIRequest = {
  operator: Operator;
  perm: PermissionType.Viewer | PermissionType.Collaborator;
  value: string;
};

export type NonMemberCriteriaAPIRequest = {
  operator: Operator;
  perm: PermissionType.Viewer | PermissionType.Collaborator;
  value: string;
};

export type ExcludeCriteriaType =
  | {
      field: CriteriaRuleType.Member | CriteriaRuleType.Email;
      values: Omit<MemberCriteriaAPIRequest, 'perm'>[];
    }
  | {
      field:
        | CriteriaRuleType.Department
        | CriteriaRuleType.JobTitle
        | CriteriaRuleType.WorkLocation
        | CriteriaRuleType.HomeLocation
        | CriteriaRuleType.ManagerStatus;
      values: Omit<NonMemberCriteriaAPIRequest, 'perm'>[];
    };

export type IncludeCriteriaType =
  | {
      field: CriteriaRuleType.Member | CriteriaRuleType.Email;
      values: MemberCriteriaAPIRequest[];
    }
  | {
      field: CriteriaRuleType.Everyone;
      value: boolean;
      perm: string;
      operator: string;
    }
  | {
      field:
        | CriteriaRuleType.Department
        | CriteriaRuleType.JobTitle
        | CriteriaRuleType.WorkLocation
        | CriteriaRuleType.HomeLocation
        | CriteriaRuleType.ManagerStatus;
      values: NonMemberCriteriaAPIRequest[];
    };

export type SaveShareCollectionCriteriaAPIRequest = {
  sharingRules: {
    include: IncludeCriteriaType[];
    exclude: ExcludeCriteriaType[];
  };
  shouldPin: boolean;
};

type SaveShareCollectionCriteriaRequest = {
  collectionId: string;
  payload: SaveShareCollectionCriteriaAPIRequest;
};

export function useSaveCollectionsCriteriaMutation({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      collectionId,
      payload,
    }: SaveShareCollectionCriteriaRequest) => {
      await assemblyAPI.post(
        APIEndpoints.getCollectionShareCriteria(collectionId),
        payload
      );
    },
    onSuccess: (_, { collectionId }: SaveShareCollectionCriteriaRequest) => {
      onSuccess?.();
      queryClient.invalidateQueries({
        queryKey: ['getShareCollectionCriteria', collectionId],
      });
      queryClient.invalidateQueries({
        queryKey: navItemsQueryKey,
      });
      queryClient.invalidateQueries({
        queryKey: ['collections'],
      });
    },
  });
}
