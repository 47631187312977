import type { EntityPermissionApiResponse } from '@assembly-web/services';
import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';

import { getEntityPermissionQuery } from '../queries/getEntityPermissionQuery';

export type EntityPermissionPayload = {
  entityIds: string[];
};

export function useGetEntityPermissionQuery(
  payload: EntityPermissionPayload,
  options?: Partial<UseQueryOptions<EntityPermissionApiResponse>>
): UseQueryResult<EntityPermissionApiResponse> {
  return useQuery(getEntityPermissionQuery(payload, options));
}
