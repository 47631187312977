import {
  type Folder,
  isTruthy,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  ContextMenu,
  getFolderStyles,
  IconButton,
  TextStyle,
  Toolbar,
  type ToolbarItem,
  Tooltip,
} from '@assembly-web/ui';
import { UserGroupIcon } from '@heroicons/react/24/outline';
import noop from 'lodash/noop';
import { useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { useFolderItemDetails } from '../../../hooks/folder/useFolderItemDetails';
import { useFolderItemMenu } from '../../../hooks/folder/useFolderItemMenu';
import { useFolderMenu } from '../../../hooks/folder/useFolderMenu';
import { usePinnedCollectionToggleStateUpdater } from '../../../modules/discover/hooks/nav/usePinnedCollectionToggleStateUpdater';
import { NavCollapsibleSection } from '../NavCollapsibleSection';
import { NavItem } from '../NavItem';
import { FolderItem } from './FolderItem';

const messages = defineMessages({
  emptyFolder: {
    defaultMessage: '{emoji} This folder is empty',
    id: 'yG6QyD',
  },
  addToFolderCTA: {
    defaultMessage: 'Add to folder',
    id: 'O3N3Cb',
  },
  createdByOthersTooltip: {
    defaultMessage: 'Created by {creatorFullName}',
    id: 'JdGiDx',
  },
  createdBySelfTooltip: {
    defaultMessage: 'Created by you',
    id: 'WEhuQZ',
  },
});

export function FolderSectionSkeleton() {
  return (
    <NavCollapsibleSection
      title={
        <div className="h-8 w-52 animate-pulse rounded-lg bg-gradient-to-r from-gray-3 to-gray-5" />
      }
      isSectionOpen
      onSectionToggle={noop}
      menu={
        <IconButton variation="secondaryEmphasized" size="xSmall" disabled>
          <div className="size-4 animate-pulse rounded bg-gradient-to-r from-gray-3 to-gray-5" />
        </IconButton>
      }
    >
      <NavItem.Skeleton />
      <NavItem.Skeleton />
      <NavItem.Skeleton />
      <NavItem.Skeleton />
    </NavCollapsibleSection>
  );
}

export function FolderSection({
  folder,
  isHiddenFolder,
}: {
  folder: Folder;
  isHiddenFolder?: boolean;
}) {
  const { formatMessage } = useIntl();

  const {
    data: { member },
  } = useSuspenseUserDetails();

  const { mutate: updatePinnedCollectionToggleState } =
    usePinnedCollectionToggleStateUpdater();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { folderToolbarItems, onMenuItemClick } = useFolderMenu(folder);

  const { getFolderItemMenuOptions, handleFolderItemMenuAction } =
    useFolderItemMenu({
      folder,
      isHiddenFolder: Boolean(isHiddenFolder),
    });

  const ownerDescription =
    member.memberId === folder.createdBy?.memberID
      ? formatMessage(messages.createdBySelfTooltip)
      : formatMessage(messages.createdByOthersTooltip, {
          creatorFullName: folder.createdBy?.name,
        });

  const getFolderDetails = useFolderItemDetails();

  const handleSectionToggle = useCallback(() => {
    updatePinnedCollectionToggleState({
      expanded: folder.isExpanded ? [] : [folder.id],
      collapsed: folder.isExpanded ? [folder.id] : [],
    });
  }, [folder.id, folder.isExpanded, updatePinnedCollectionToggleState]);

  return (
    <NavCollapsibleSection
      variant="folder"
      triggerClassName={twJoin(
        getFolderStyles(folder.colorName).background,
        isHiddenFolder && 'bg-primary-1 text-gray-7'
      )}
      title={
        <div className="flex items-center gap-1 text-left">
          <span className="truncate">{folder.name}</span>
          {isTruthy(folder.isShared) && isTruthy(folder.createdBy) && (
            <Tooltip tooltipText={ownerDescription}>
              <UserGroupIcon
                className="size-4 cursor-pointer"
                aria-label={ownerDescription}
              />
            </Tooltip>
          )}
        </div>
      }
      isSectionOpen={folder.isExpanded}
      isSubSection
      menu={
        <Toolbar
          className="border-0 bg-transparent hover:bg-transparent focus-visible:bg-transparent"
          triggerClassName="bg-transparent !hover:bg-transparent focus-visible:bg-transparent focus:bg-transparent"
          variant="comfortable"
          contentPlacement="right"
          compact
          hideToolbar={false}
          secondaryToolbarItems={folderToolbarItems}
          onMenuItemClick={(args: ToolbarItem) => {
            onMenuItemClick(args);
            setIsMenuOpen(false);
          }}
          isMenuOpen={isMenuOpen}
          onToggle={() => setIsMenuOpen((prev) => !prev)}
        />
      }
      onSectionToggle={handleSectionToggle}
    >
      {folder.listItems.map((item) => (
        <ContextMenu
          key={item.id}
          trigger={
            <FolderItem key={item.id} folderInfo={getFolderDetails(item)} />
          }
          menuItems={getFolderItemMenuOptions(item)}
          onMenuItemClick={(id) => handleFolderItemMenuAction(item, id)}
        />
      ))}

      {folder.listItems.length === 0 && (
        <TextStyle variant="sm-regular" subdued>
          {formatMessage(messages.emptyFolder, { emoji: '🍃' })}
        </TextStyle>
      )}
    </NavCollapsibleSection>
  );
}
