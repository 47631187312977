import { useDisplayCurrency } from '@assembly-web/services';
import { QuickParticipation } from '@assembly-web/ui';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useParticipationModalContext } from '../../Provider';
import { commonRecognitionParticipationMessages } from '../utils/commonMessages';

function TotalRecipients() {
  const { formatMessage } = useIntl();
  const { watch } = useFormContext();

  return formatMessage(commonRecognitionParticipationMessages.count, {
    count: watch('recipientsCount', 0),
  });
}

export function PointsStatus() {
  const { watch, control } = useFormContext();
  const { editPostId } = useParticipationModalContext();
  const isEditFlow = Boolean(editPostId);
  const pointsEach = isEditFlow
    ? control._defaultValues.points
    : watch('points', 0);
  const recipientsCount = watch('recipientsCount', 0);
  const totalPoints = pointsEach * recipientsCount;
  return (
    <QuickParticipation.Footer.PointsStatus
      currency={useDisplayCurrency()}
      totalPoints={totalPoints}
      totalRecipients={<TotalRecipients />}
    />
  );
}
