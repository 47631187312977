import {
  TextStyle,
  useDeviceInfo,
  useRefSetterContext,
} from '@assembly-web/ui';
import type { FC, ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

const messages = defineMessages({
  searchTerm: {
    defaultMessage: 'Search Results: “{search}"',
    id: '9JSAK0',
  },
});
type Children = { children: ReactNode };

function ContentLayout({ children }: Children) {
  return (
    <section className="space-y-4 px-4 pt-4 md:space-y-6 lg:px-6">
      {children}
    </section>
  );
}

const WidgetContainer = () => {
  const refContext = useRefSetterContext();
  const deviceType = useDeviceInfo().deviceType;

  if (deviceType === 'desktop' || deviceType === 'laptop') {
    return null;
  }

  return <div ref={refContext?.[1]} />;
};

const ActionBar: FC<Children> = ({ children }) => {
  return <>{children}</>;
};

const FilterContainer: FC<Children> = ({ children }) => {
  return <div className="flex items-center justify-between">{children}</div>;
};

const FilterContent: FC<Children> = ({ children }) => {
  return (
    <div className="flex w-full gap-x-4 gap-y-2 overflow-x-scroll md:flex-wrap">
      {children}
    </div>
  );
};

const FeedContainer: FC<Children> = ({ children }) => {
  return <div className="flex flex-col gap-4">{children}</div>;
};

const SearchTitleContainer = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') ?? '';
  return (
    searchTerm && (
      <TextStyle variant="xl-medium">
        {formatMessage(messages.searchTerm, { search: searchTerm })}
      </TextStyle>
    )
  );
};

ContentLayout.ActionBar = ActionBar;
ContentLayout.FilterContainer = FilterContainer;
ContentLayout.FilterContent = FilterContent;
ContentLayout.FeedContainer = FeedContainer;
ContentLayout.WidgetContainer = WidgetContainer;
ContentLayout.SearchTitleContainer = SearchTitleContainer;

export { ContentLayout };
