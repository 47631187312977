import { useDisplayCurrency } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import * as Collapsible from '@radix-ui/react-collapsible';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useLeaderBoardTipsState } from '../../discover/hooks/recognition/useLeaderBoardTipsState';

const messages = defineMessages({
  managerTipsHeaderText: {
    defaultMessage: '💡 Tips',
    id: 'k77XJ1',
  },
  closeButtonText: {
    defaultMessage: 'Close',
    id: 'rbrahO',
  },
  openButtonText: {
    defaultMessage: 'Open',
    id: 'JfG49w',
  },
  content: {
    defaultMessage:
      'Click the +{currency} to <b>add a points boost</b> in your replies!',
    id: 'jeWn4E',
  },
});
export function LeaderBoardTips() {
  const { formatMessage } = useIntl();
  const currency = useDisplayCurrency();
  const { leaderBoardTipVisibilityState, setLeaderBoardTipVisibilityState } =
    useLeaderBoardTipsState();

  return (
    <Collapsible.Root
      open={leaderBoardTipVisibilityState}
      onOpenChange={() => {
        setLeaderBoardTipVisibilityState(!leaderBoardTipVisibilityState);
      }}
      className="w-full rounded-lg bg-primary-1 p-2"
    >
      <div className="flex flex-row items-center justify-between">
        <TextStyle as="span" variant="base-medium">
          {formatMessage(messages.managerTipsHeaderText)}
        </TextStyle>
        <Collapsible.Trigger>
          <div className="flex items-center gap-2 px-3 py-1 text-gray-8">
            {leaderBoardTipVisibilityState ? (
              <>
                <TextStyle>{formatMessage(messages.closeButtonText)}</TextStyle>
                <ChevronUpIcon className="h-4 w-4" />
              </>
            ) : (
              <>
                <TextStyle>{formatMessage(messages.openButtonText)}</TextStyle>
                <ChevronDownIcon className="h-4 w-4" />
              </>
            )}
          </div>
        </Collapsible.Trigger>
      </div>
      <Collapsible.Content className="mt-2">
        <TextStyle variant="sm-regular" className="px-2 py-1">
          {formatMessage(messages.content, {
            currency,
            b: (text: ReactNode) => <span className="font-bold">{text}</span>,
          })}
        </TextStyle>
      </Collapsible.Content>
    </Collapsible.Root>
  );
}
