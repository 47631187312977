import {
  configSessionReplay,
  getFeatureFlagTreatmentAsync,
  getSplitIOFactory,
  isTruthy,
  registerUserForSentry,
  SplitNames,
  useUserDetails,
} from '@assembly-web/services';
import { ToastContainer } from '@assembly-web/ui';
import {
  SplitClient,
  SplitFactoryProvider,
} from '@splitsoftware/splitio-react';
import { useCallback, useMemo } from 'react';

import { isAdminMember } from './modules/discover/services/member';
import { redirectToAmazonAdminForAuthorization } from './modules/onboarding/services/amazonAuth';
import { RouteDefinitions } from './RouteDefinitions';
import { registerUserForTracking } from './services/analytics';

export function Router() {
  const { data: userDetails } = useUserDetails();

  const userId = userDetails?.member.memberId;
  const assemblyId = userDetails?.assembly.assemblyId;
  const email = userDetails?.member.email;

  if (email) {
    registerUserForSentry(email);
  }

  if (userId && assemblyId) {
    registerUserForTracking(userId, assemblyId);

    const isAdmin = isAdminMember(userDetails.member);
    redirectToAmazonAdminForAuthorization(isAdmin, assemblyId);
  }

  const splitConfig = useMemo(
    () => getSplitIOFactory(assemblyId),
    [assemblyId]
  );

  const configureSentrySessionReplay = useCallback(async () => {
    const sentrySessionReplayTreatment = await getFeatureFlagTreatmentAsync(
      SplitNames.SentrySessionReplay,
      assemblyId
    );
    configSessionReplay(sentrySessionReplayTreatment === 'on');
  }, [assemblyId]);

  configureSentrySessionReplay();

  const themePreset = userDetails?.assembly.settings.theme?.preset;

  return (
    <SplitFactoryProvider factory={splitConfig} updateOnSdkUpdate>
      <SplitClient splitKey={assemblyId ?? 'anonymous'} updateOnSdkUpdate>
        <RouteDefinitions
          themePreset={isTruthy(themePreset) ? themePreset : 'neutral'}
        />
        <ToastContainer />
      </SplitClient>
    </SplitFactoryProvider>
  );
}
