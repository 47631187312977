import {
  QuickParticipation,
  RecognitionParticipationLoader,
} from '@assembly-web/ui';
import {
  createContext,
  type PropsWithChildren,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import invariant from 'tiny-invariant';

import { Title } from './RecognitionParticipation/header/Title';
import { RecognitionParticipation } from './RecognitionParticipation/RecognitionParticipation';

type ParticipationModalContextType = {
  openModal: (editPostId?: string) => void;
  closeModal: () => void;
  isOpen: boolean;
  reset: () => void;
  editPostId: string;
};

const ParticipationModalContext =
  createContext<ParticipationModalContextType | null>(null);

export const useParticipationModalContext = () => {
  const context = useContext(ParticipationModalContext);

  invariant(
    context != null,
    'useParticipationModalContext must be used within a ParticipationProvider'
  );

  return context;
};

export function ParticipationProvider({ children }: PropsWithChildren) {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState('');
  const [editPostId, setEditPostId] = useState('');

  const closeModal = useCallback(() => setIsOpen(false), []);
  const reset = useCallback(() => setId(crypto.randomUUID()), []);
  const openModal = useCallback(
    (postId?: string) => {
      if (postId) {
        setEditPostId(postId);
      } else {
        setEditPostId('');
      }
      flushSync(() => {
        reset();
      });
      setIsOpen(true);
    },
    [reset]
  );

  return (
    <ParticipationModalContext.Provider
      value={useMemo(
        () => ({ openModal, closeModal, isOpen, reset, editPostId }),
        [closeModal, isOpen, openModal, reset, editPostId]
      )}
    >
      {children}

      <Suspense
        fallback={
          <QuickParticipation.Modal
            isOpen={isOpen}
            onClose={closeModal}
            title={<Title />}
          >
            <RecognitionParticipationLoader />
          </QuickParticipation.Modal>
        }
      >
        <RecognitionParticipation key={id} />
      </Suspense>
    </ParticipationModalContext.Provider>
  );
}
