import { ParticipationProvider } from '@assembly-web/participation';
import {
  SplitNames,
  useFeatureSplit,
  userAuthStore,
  useUserDetails,
} from '@assembly-web/services';
import { FilePreviewerProvider } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useEffect } from 'react';

import { FilePreviewModalProvider } from '../contexts/FilePreviewModalContext';
import { useOpenPostDrawer } from '../hooks/useOpenPostDrawer';
import { usePostInteractionSettings } from '../hooks/usePostInteractionSettings';
import { DrawerDock } from '../modules/discover/components/Drawers/DrawerDock';
import { ChallengeModals } from '../modules/discover/components/modals/ChallengeModals';
import { CollectionModals } from '../modules/discover/components/modals/CollectionModals';
import { DeletePostOrReplyModal } from '../modules/discover/components/modals/DeletePostOrReplyModal';
import { PostInteractionSettingsModal } from '../modules/discover/components/modals/PostInteractionSettingsModal';
import { WidgetRefProvider } from '../modules/discover/components/WidgetRefProvider';
import { AskDoraProvider } from '../modules/discover/contexts/AskDoraContext';
import { ChallengeModalsProvider } from '../modules/discover/contexts/ChallengeModalsContext';
import { ChatProvider } from '../modules/discover/contexts/ChatsProvider';
import { CollectionModalsProvider } from '../modules/discover/contexts/CollectionModalsContext';
import { FlowStatusProvider } from '../modules/discover/contexts/FlowStatusContext';
import { ModalsProvider } from '../modules/discover/contexts/ModalsContext';
import { useGlobalDomainEvents } from '../modules/discover/hooks/domainEvents/useGlobalDomainEvents';
import { AnnouncementsProvider } from '../modules/discover/hooks/useAnnouncements';
import { useAskDoraStore } from '../modules/discover/stores/useAskDoraStore';
import { RewardsLocalSearchProvider } from '../modules/rewards/context/RewardsLocalSearchContext';
import { useWorkspaceQuery } from '../queries/useWorkspace';
import { useMultiDrawerStore } from '../stores/useMultiDrawerStore';

export function AppWrapper({ children }: { children: ReactNode }) {
  const performWorkspaceCheck = !userAuthStore.getState().isMobileAppV3;

  const { data: workspaces, isLoading: isWorkspacesLoading } =
    useWorkspaceQuery({
      enabled: performWorkspaceCheck,
    });

  // NOTE: we need to use the `useUserDetails` hook to get the user details here in case someone tries to access a logged in route directly when they are signed out
  const { data: userDetails, isLoading: isUserDetailsLoading } =
    useUserDetails();

  const { isTreatmentActive: multiPopoutDrawerEnabled } = useFeatureSplit(
    SplitNames.MultiPopoutDrawer
  );

  const setMemberId = useMultiDrawerStore((store) => store.setMemberId);
  const setMemberState = useAskDoraStore((store) => store.setMemberState);

  useGlobalDomainEvents();
  useOpenPostDrawer();

  useEffect(() => document.querySelector('.grecaptcha-badge')?.remove());

  const {
    responseDetails,
    postInteractionSettings,
    isPostInteractionSettingsModalOpen,
    handlePostInteractionSettingsChange,
    handleClosePostInteractionSettingsModal,
  } = usePostInteractionSettings();

  useEffect(() => {
    if (isWorkspacesLoading || isUserDetailsLoading) {
      return;
    }

    // NOTE: This handles the case where a user tries to access a logged in route directly when they are signed out
    if (performWorkspaceCheck && !workspaces) {
      userAuthStore.getState().setDeepLinkUrl(window.location.href);
      window.location.replace('/login');
    } else if (!userDetails) {
      window.location.replace('/workspaces');
    } else {
      setMemberId(userDetails.member.memberId);
      setMemberState(userDetails.member.memberId);
    }
  }, [
    setMemberState,
    setMemberId,
    userDetails,
    workspaces,
    isWorkspacesLoading,
    isUserDetailsLoading,
    performWorkspaceCheck,
  ]);

  if ((performWorkspaceCheck && !workspaces) || !userDetails) {
    return null;
  }

  return (
    <ParticipationProvider>
      <AnnouncementsProvider>
        <AskDoraProvider>
          <ModalsProvider>
            <CollectionModalsProvider>
              <ChallengeModalsProvider>
                <FlowStatusProvider>
                  <FilePreviewerProvider>
                    <ChatProvider>
                      <FilePreviewModalProvider>
                        <RewardsLocalSearchProvider>
                          <WidgetRefProvider>{children}</WidgetRefProvider>
                        </RewardsLocalSearchProvider>

                        {Boolean(multiPopoutDrawerEnabled) && <DrawerDock />}

                        <PostInteractionSettingsModal
                          responseDetails={responseDetails}
                          isOpen={isPostInteractionSettingsModalOpen}
                          postInteractionSettings={postInteractionSettings}
                          onClose={handleClosePostInteractionSettingsModal}
                          handlePostInteractionSettingsChange={
                            handlePostInteractionSettingsChange
                          }
                        />

                        <CollectionModals />
                        <ChallengeModals />
                        <DeletePostOrReplyModal />
                      </FilePreviewModalProvider>
                    </ChatProvider>
                  </FilePreviewerProvider>
                </FlowStatusProvider>
              </ChallengeModalsProvider>
            </CollectionModalsProvider>
          </ModalsProvider>
        </AskDoraProvider>
      </AnnouncementsProvider>
    </ParticipationProvider>
  );
}
