import {
  APIEndpoints,
  assemblyAPI,
  type ListWorkspacesAPIResponse,
  type Workspace,
} from '@assembly-web/services';
import {
  queryOptions,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';

const options = queryOptions({
  queryKey: ['workspaces'],
  queryFn: async () => {
    const {
      data: { allowList, invited, partOf },
    } = await assemblyAPI.post<ListWorkspacesAPIResponse>(
      APIEndpoints.listWorkspaces
    );

    const workspaceIDs = new Set<Workspace['assemblyId']>();
    allowList.forEach((workspace) => workspaceIDs.add(workspace.assemblyId));
    invited.forEach((workspace) => workspaceIDs.add(workspace.assemblyId));
    partOf.forEach((workspace) => workspaceIDs.add(workspace.assemblyId));

    return { workspaceIDs: Array.from(workspaceIDs) };
  },
});

export function useWorkspaceQuery(
  { enabled }: { enabled: boolean } | undefined = { enabled: true }
) {
  return useQuery({
    ...options,
    enabled,
  });
}

export function useWorkspace() {
  return useSuspenseQuery(options);
}
