import type { AnnouncementsApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query';

export function getAnnouncementsQuery(
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<AnnouncementsApiResponse>
  >
): UndefinedInitialDataInfiniteOptions<AnnouncementsApiResponse> {
  return {
    ...options,
    queryKey: ['announcements'],
    queryFn: async ({ pageParam }) =>
      await assemblyAPI.get(APIEndpoints.getAnnouncements, {
        ...(pageParam ? { params: { cursor: pageParam } } : {}),
      }),
    initialPageParam: 0,
    getNextPageParam: (page: AnnouncementsApiResponse) =>
      page.data.metadata?.pagination.cursor.next ?? undefined,
    getPreviousPageParam: (page: AnnouncementsApiResponse) => {
      return page.data.metadata?.pagination.cursor.previous;
    },
  };
}
