import {
  canInviteMembers,
  useDebounce,
  useGetMembers,
  useUserDetails,
} from '@assembly-web/services';
import { useMemo, useState } from 'react';

import { mapMemberData } from '../../services/shareCollectionsData';

export function useShareSheetMemberSearch({ enabled }: { enabled: boolean }) {
  const [inputValue, setInputValue] = useState('');
  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const {
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    data,
  } = useGetMembers({
    searchTerm: debouncedSearchTerm,
    options: {
      enabled,
    },
  });

  const { data: userDetails } = useUserDetails();

  const hasUserInvitePermission = canInviteMembers(
    userDetails?.member,
    userDetails?.assembly.settings
  );

  const canInviteCurrentUser =
    hasUserInvitePermission && data?.pages[0].data.canEmailInvite;

  const membersDropdownData = useMemo(() => {
    if (!data || !enabled || canInviteCurrentUser) return [];
    return data.pages.flatMap((page) => page.data.members.map(mapMemberData));
  }, [canInviteCurrentUser, data, enabled]);

  const onMemberSearch = (searchText: string) => {
    setInputValue(searchText);
  };

  return {
    isLoadingMembers: isLoading,
    hasNextPage,
    isFetchingNextPage: isFetchingNextPage || isFetching,
    members: membersDropdownData,
    searchTerm: inputValue,
    onMemberSearch,
    onLoadMore: fetchNextPage,
    canInviteCurrentUser,
    hasUserInvitePermission,
  };
}
