import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { defineMessages, useIntl } from 'react-intl';

import { updateExpandedFolderStateInNav } from '../../../../hooks/folder/useHiddenFolders';
import { trackDiscoverError } from '../../services/analytics';
import type { NavItemsResponse } from './useNavData';
import { navItemsQueryKey } from './useNavData';

type PinnedItemToggleStatePayload = {
  expanded: string[];
  collapsed: string[];
};

const messages = defineMessages({
  error: {
    defaultMessage: 'Something went wrong, try again later',
    id: 'K1Csk8',
  },
});

// TODO: modify the implementation to allowed debounced bulk updates
export function usePinnedCollectionToggleStateUpdater() {
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastStore();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: async (payload: PinnedItemToggleStatePayload) => {
      const expandedSections = 'expanded' in payload ? payload.expanded : [];
      const collapsedSections = 'collapsed' in payload ? payload.collapsed : [];

      const navCacheData =
        queryClient.getQueryData<NavItemsResponse>(navItemsQueryKey);

      const modifiedSections = [...expandedSections, ...collapsedSections];

      queryClient.setQueryData(navItemsQueryKey, () => ({
        ...navCacheData,
        pages: navCacheData?.pages.map((page) => {
          if (page.data.some((x) => modifiedSections.includes(x.id))) {
            page.data = produce(page.data, (draft) =>
              draft.map((collection) => {
                if (modifiedSections.includes(collection.id)) {
                  return {
                    ...collection,
                    isExpanded: expandedSections.includes(collection.id),
                  };
                }
                return collection;
              })
            );
          }
          return page;
        }),
      }));

      expandedSections.forEach((section) =>
        updateExpandedFolderStateInNav(queryClient, section, true)
      );

      collapsedSections.forEach((section) =>
        updateExpandedFolderStateInNav(queryClient, section, false)
      );

      return assemblyAPI.put(
        APIEndpoints.updatePinnedCollectionsToggleState,
        omitBy(payload, isEmpty)
      );
    },

    onError: (err: unknown, payload) => {
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Error updating pinned items toggle state in nav',
        { ...payload, error: errorInfo },
        errorInfo
      );

      trackDiscoverError({ action: 'togglePinnedCollectionFromNav' });

      showErrorToast(formatMessage(messages.error));
    },
  });
}
