import { APIEndpoints, type GetDraftsResponse } from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import {
  useQuery,
  useQueryClient,
  type UseQueryOptions,
} from '@tanstack/react-query';
import { useCallback } from 'react';

const getQueryKey = (postId: string) => ['getDrafts', 'REPLY', postId];

export const useSetRepliesDrafts = (postId: string) => {
  const queryClient = useQueryClient();

  return useCallback(
    (draft: GetDraftsResponse) => {
      queryClient.setQueryData(getQueryKey(postId), draft);
    },
    [postId, queryClient]
  );
};

export function useGetRepliesDrafts(
  postId: string,
  options?: Partial<UseQueryOptions<GetDraftsResponse>>
) {
  return useQuery({
    queryKey: getQueryKey(postId),
    queryFn: async () => {
      const response = await assemblyAPI.post<GetDraftsResponse>(
        APIEndpoints.findDraft,
        {
          entityType: 'REPLY',
          postId,
        }
      );

      return response.data;
    },
    refetchOnWindowFocus: 'always',
    refetchOnMount: false,
    ...options,
  });
}
