import { usePusherChannel, usePusherEvent } from '@assembly-web/pusher';
import { logger, useUserDetails } from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

type PostRecognitionUpdatedEvent = {
  postId: string;
};

export function usePostUpdatesEvents() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvent<PostRecognitionUpdatedEvent>(
    assembly,
    'FEED_POST_RECOGNITION_UPDATED',
    async (data) => {
      try {
        if (data) {
          await queryClient.refetchQueries({
            queryKey: ['assemblyFlowPost', 'recognition', data.postId],
          });
        }
      } catch (e) {
        logger.error(
          'Refetch error on feed post recognition updated',
          {},
          e as Error
        );
      }
    }
  );
}
