import { TextStyle } from '@assembly-web/ui';
import {
  HandThumbUpIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import {
  trackDiscoverAction,
  trackNavAction,
} from '../../modules/discover/services/analytics';
import { NavItem } from './NavItem';

export function NavFooter() {
  return (
    <div className="mt-auto flex flex-col gap-6">
      <section className="mt-6">
        <TextStyle variant="sm-bold" subdued className="uppercase">
          <FormattedMessage defaultMessage="Support" id="HqRNN8" />
        </TextStyle>
        <div className="mt-4 flex flex-col items-start gap-2">
          <NavItem.ExternalLink
            className="h-7"
            icon={QuestionMarkCircleIcon}
            text={<FormattedMessage defaultMessage="Help Center" id="HtSDyc" />}
            url="https://help.joinassembly.com/en/"
            onClick={() => {
              trackNavAction('helpCenterClicked');
              trackDiscoverAction('profileMenuItemClicked', {
                profileMenuItem: 'goToHelp',
                v3enabled: true,
              });
            }}
          />
          <NavItem.ExternalLink
            className="h-7"
            icon={HandThumbUpIcon}
            text={
              <FormattedMessage defaultMessage="Give feedback" id="bCmvTY" />
            }
            url="https://my.joinassembly.com/e/flows/6478a909ec762bd84a6b18c1"
            onClick={() => {
              trackNavAction('giveFeedbackClicked');
              trackDiscoverAction('profileMenuItemClicked', {
                profileMenuItem: 'giveUsFeedback',
                v3enabled: true,
              });
            }}
          />
        </div>
      </section>
    </div>
  );
}
