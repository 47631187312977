import { GlobalFilterOption } from '@assembly-web/services';
import { useLocation } from 'react-router-dom';

function getFilterTypeForLocation(pathname: string): GlobalFilterOption {
  if (pathname.endsWith('/collections') || pathname.endsWith('/folders')) {
    return GlobalFilterOption.Collections;
  } else if (pathname.endsWith('/challenges')) {
    return GlobalFilterOption.Challenges;
  } else if (pathname.endsWith('/files')) {
    return GlobalFilterOption.Files;
  } else if (pathname.endsWith('/flows')) {
    return GlobalFilterOption.Flows;
  } else if (pathname.endsWith('/people')) {
    return GlobalFilterOption.People;
  } else if (pathname.endsWith('/rewards')) {
    return GlobalFilterOption.Rewards;
  } else if (pathname.endsWith('/tasks')) {
    return GlobalFilterOption.Tasks;
  } else if (pathname.includes('/activity')) {
    return GlobalFilterOption.Notifications;
  } else if (pathname.includes('/recognition')) {
    return GlobalFilterOption.Recognition;
  }

  return GlobalFilterOption.All;
}

export function useGlobalFilter() {
  const { pathname } = useLocation();

  return getFilterTypeForLocation(pathname);
}
