import {
  useCurrency,
  useDisplayCurrency,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import {
  cardParentDataAttributes,
  Chip,
  handleParentClick,
  NumberInput,
  TextStyle,
  Tooltip,
  useDeviceInfo,
} from '@assembly-web/ui';
import {
  EyeSlashIcon,
  PlusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twJoin } from 'tailwind-merge';

import { usePointsConfig } from '../../hooks/usePointsConfig';
import { useParticipationModalContext } from '../../Provider';
import { Block } from './Block';

const labels = defineMessages({
  chip: {
    id: '6mnUoZ',
    defaultMessage: 'Add {currencyIcon}',
  },
  valueEach: {
    id: 'hMoFGa',
    defaultMessage: '{currencyIcon} {value} each',
  },
  hideAmount: {
    id: 'SAk6zf',
    defaultMessage: 'Points will be hidden',
  },
  noEditTooltip: {
    id: 'Foj9hm',
    defaultMessage: '{currencyName} can’t be edited.',
  },
  pointsError: {
    id: 'IC20A2',
    defaultMessage: 'Points must be a number',
  },
  error: {
    id: 'FmeswJ',
    defaultMessage:
      'You do not have enough points to give, but you can still post. Yay!',
  },
  placeHolder: {
    id: 'SARX44',
    defaultMessage: 'Add up to {pointsToGive} per person',
  },
  placeHolderInShort: {
    id: 'EC/2rw',
    defaultMessage: 'Add up to {pointsToGive} each',
  },
  none: {
    id: '450Fty',
    defaultMessage: 'None',
  },
});

export function PointsSelector() {
  const { formatMessage } = useIntl();
  const { isMobileDevice } = useDeviceInfo();

  const { name: currencyName } = useCurrency();
  const currencyIcon = useDisplayCurrency();
  const {
    data: { assembly },
  } = useSuspenseUserDetails();

  const { editPostId } = useParticipationModalContext();
  const isEditFlow = Boolean(editPostId);

  const [showPointsField, setShowPointsField] = useState(false);

  const { setValue, getValues, control, watch } = useFormContext();
  const memberCount = watch('recipientsCount');

  const { getMaxPoints } = usePointsConfig();

  const pointsToGive = useMemo(() => {
    return getMaxPoints(memberCount);
  }, [memberCount, getMaxPoints]);

  const hasAvailablePoints = Boolean(pointsToGive);
  const disablePointsField = !hasAvailablePoints || isEditFlow;
  const showError = Boolean(!hasAvailablePoints && !isEditFlow);

  useEffect(() => {
    const points = getValues('points');
    if (pointsToGive < points) {
      setValue('points', pointsToGive);
    }
  }, [memberCount, pointsToGive, setValue, getValues]);

  const handleClick = useCallback(() => {
    if (!disablePointsField) {
      setShowPointsField(true);
    }
  }, [disablePointsField]);

  const value = getValues('points');
  const content = useMemo(() => {
    if (isEditFlow && !value) {
      return formatMessage(labels.none);
    }
    if (value) {
      if (memberCount > 1) {
        return formatMessage(labels.valueEach, { value, currencyIcon });
      }
      return `${currencyIcon} ${value}`;
    }

    return formatMessage(labels.chip, { currencyIcon });
  }, [value, currencyIcon, memberCount, formatMessage, isEditFlow]);

  const icon = useMemo(() => {
    return (
      <>
        {!hasAvailablePoints || value ? (
          <>
            {!isEditFlow && (
              <XCircleIcon
                className={twJoin(
                  'h-6 w-6',
                  disablePointsField && 'cursor-not-allowed'
                )}
                onClick={() => {
                  setValue('points', '');
                  setShowPointsField(false);
                }}
              />
            )}
          </>
        ) : (
          <PlusCircleIcon className="h-6 w-6" onClick={handleClick} />
        )}
      </>
    );
  }, [
    hasAvailablePoints,
    value,
    isEditFlow,
    setValue,
    disablePointsField,
    handleClick,
  ]);

  const pointsContainerBlock = useMemo(() => {
    return (
      <Chip.Root
        intent="badge"
        color="gold"
        isDisabled={disablePointsField}
        className={twJoin(!hasAvailablePoints && '!text-gray-7')}
      >
        <Chip.Text>{content}</Chip.Text>
        {!isEditFlow && (
          <Chip.Button className="focus:ring-0" aria-label="button">
            {icon}
          </Chip.Button>
        )}
      </Chip.Root>
    );
  }, [content, disablePointsField, hasAvailablePoints, icon, isEditFlow]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        {showPointsField ? (
          <div className="flex items-center justify-between">
            <div className="w-4/6">
              <Controller
                control={control}
                name="points"
                render={({ field: { onChange, value } }) => (
                  <NumberInput
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    className="w-full text-base ring-0 placeholder:text-gray-7 focus:ring-0"
                    placeholder={
                      isMobileDevice
                        ? formatMessage(labels.placeHolderInShort, {
                            pointsToGive,
                          })
                        : formatMessage(labels.placeHolder, {
                            pointsToGive,
                          })
                    }
                    onBlur={() => setShowPointsField(false)}
                    value={value}
                    dynamicWidth={false}
                    label={''}
                    showStepper={false}
                    leadingIcon={currencyIcon}
                    onChange={(value: number) => {
                      if (typeof value !== 'number') {
                        onChange(0);
                        return;
                      }
                      if (Number(value) < 0) {
                        onChange(0);
                        return;
                      }
                      const val = Math.round(Number(value));
                      if (val > pointsToGive) {
                        onChange(pointsToGive);
                      } else {
                        onChange(val);
                      }
                    }}
                  />
                )}
              />
            </div>

            {Boolean(
              assembly.settings.postPermission.canHidePoints.enabled
            ) && (
              <div className="flex items-center gap-2">
                <EyeSlashIcon className="h-4 w-4" />
                <TextStyle variant="sm-regular">
                  {formatMessage(labels.hideAmount)}
                </TextStyle>
              </div>
            )}
          </div>
        ) : (
          <div
            className={twJoin(
              'flex flex-col gap-2',
              disablePointsField ? 'cursor-not-allowed' : 'cursor-text'
            )}
            onClick={handleParentClick(() => handleClick())}
            onKeyDown={handleParentClick(() => handleClick())}
            {...cardParentDataAttributes}
            tabIndex={0}
            role="button"
          >
            <Tooltip
              tooltipText={
                Boolean(isEditFlow) &&
                formatMessage(labels.noEditTooltip, {
                  currencyName,
                })
              }
            >
              {pointsContainerBlock}
            </Tooltip>
            {Boolean(showError) && (
              <TextStyle variant="sm-regular" className="text-error-7">
                {formatMessage(labels.error)}
              </TextStyle>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function Label() {
  const { name: currencyName } = useCurrency();

  return (
    <FormattedMessage
      defaultMessage="Add {currencyName}"
      id="C/h2hp"
      values={{ currencyName }}
    />
  );
}

export function PointsSelectorBlock() {
  return (
    <Block label={<Label />}>
      <PointsSelector />
    </Block>
  );
}
