import { Banner } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { useEndOccurrence } from '../../../../hooks/flows/useEndOccurrence';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';

export function OccurrenceInProgressBanner() {
  const { formatMessage } = useIntl();
  const { id } = useEditorDataContext();

  const occurrenceInProgress = useAnyOccurrenceInProgress();
  const endOccurrence = useEndOccurrence();

  if (!occurrenceInProgress) {
    return null;
  }

  return (
    <Banner status="alert">
      {formatMessage(
        defineMessage({
          defaultMessage:
            "You have an active flow running on a deadline. You'll have to wait until the deadline has passed to edit your flow. <button>End this flow early</button>",
          id: 'hJLsa7',
        }),
        {
          button: (chunks: ReactNode) => (
            <button
              onClick={() => endOccurrence.mutate({ flowId: id })}
              className="underline"
              disabled={endOccurrence.isPending}
            >
              {chunks}
            </button>
          ),
        }
      )}
    </Banner>
  );
}
