import {
  config,
  type PlanFeaturesAPIResponse,
  sanitizeHtml,
} from '@assembly-web/services';
import { Marked } from 'marked';
import { defineMessages } from 'react-intl';

import { markedTablePlugin } from './markedTablePlugins';

export class DoraRateLimitError extends Error {}

export const streamingTimeout = 120000; // 120 seconds

const blinkingCursorId = 'dora-blinking-cursor';
const domParser = new DOMParser();

function getMarkedOptionsForRawDoraResponse(
  showCursor: boolean,
  showImageBanner: boolean
) {
  return {
    hooks: {
      preprocess(markdown: string) {
        return markdown;
      },
      postprocess(html: string | undefined) {
        if (!html) {
          return '';
        }

        const parsedHtml = domParser.parseFromString(
          sanitizeHtml(html),
          'text/html'
        );
        const lastElement = parsedHtml.body.lastElementChild;

        const createBlinkingCursor = (elem: Element) => {
          const blinkingCursor = parsedHtml.createElement('span');

          blinkingCursor.id = blinkingCursorId;
          blinkingCursor.innerHTML = '|';
          blinkingCursor.className = 'animate-blink bg-gray-9 ml-2';
          elem.append(blinkingCursor);
        };

        if (lastElement && showCursor) {
          if (lastElement.tagName !== 'UL' && lastElement.tagName !== 'OL') {
            createBlinkingCursor(lastElement);
          } else {
            const lastListItem = lastElement.lastElementChild;

            if (lastListItem) {
              createBlinkingCursor(lastListItem);
            }
          }

          return parsedHtml.body.innerHTML;
        }

        return html;
      },
    },
    mangle: false,
    renderer: {
      em(text: string) {
        return `<span class="font-normal italic">${text}</span>`;
      },
      image(href: string, title: string | null | undefined, text: string) {
        const titleAttr = title ? `title="${title}"` : '';
        if (!href.startsWith(config.domains.doraApi)) return '';

        // Do not modify this code as its needed to render react components based on its structure
        return `<!-- start --><img data-dora-chart="" alt="${text}" class="h-full w-full border-gray-1 ${showImageBanner ? 'border-t rounded-b-lg' : 'border rounded-lg'}" src="${href}" ${titleAttr} /><!-- end -->`;
      },
      link(href: string, title: string | null | undefined, text: string) {
        const titleAttr = title ? ` title="${title}"` : '';
        const trackingParam = href.includes('/flows/recognition/answer')
          ? '&fromWeb=aiReportingChat'
          : '';

        return `<a class='text-primary-6 hover:text-primary-5' href="${href}${trackingParam}"${titleAttr} target="_blank">${text}</a>`;
      },
      list(body: string, ordered: boolean) {
        const tag = ordered ? 'ol' : 'ul';
        const listTypeTailwindClass = ordered ? 'list-decimal' : 'list-disc';

        return `<${tag} class="${listTypeTailwindClass} ml-8">${body}</${tag}>`;
      },
      strong(text: string) {
        return `<span class="font-bold">${text}</span>`;
      },
    },
  };
}

export function convertMarkDownResponseToHTML(
  markdown: string,
  {
    showCursor = true,
    showImageBanner = false,
  }: { showCursor?: boolean; showImageBanner?: boolean } = {}
) {
  const markedInstance = new Marked(
    getMarkedOptionsForRawDoraResponse(showCursor, showImageBanner)
  );
  markedInstance.use(markedTablePlugin);
  return markedInstance.parse(markdown);
}

export function removeBlinkingCursor(rawHtml: string) {
  const parsedHtml = domParser.parseFromString(rawHtml, 'text/html');
  const blinkingCursor = parsedHtml.getElementById(blinkingCursorId);

  blinkingCursor?.remove();

  return parsedHtml.body.innerHTML;
}

export function getIdForDoraQuestion(
  question: string,
  options: { locale: string }
) {
  return question.toLocaleLowerCase(options.locale).replace(/['"]+/g, '');
}

export const messages = defineMessages({
  genericError: {
    defaultMessage:
      'Hm...sorry, something went wrong! It might be a connection issue. Please try again.',
    id: 'XlvnUA',
  },
  rateLimitError: {
    defaultMessage:
      "Oops! It's looks like Dora's system is overloaded with requests right now. Please try again, and sorry for the inconvenience!",
    id: 'nnxWAM',
  },
});

export function isDoraIncludedInWorkspacePlan(
  planFeatureDetails?: PlanFeaturesAPIResponse
) {
  return Boolean(
    planFeatureDetails?.features.some(
      (feature) => feature.id === 'ASSEMBLY_AI' && feature.status === 'ENABLED'
    )
  );
}
