import {
  GlobalFilterOption,
  MemberState,
  type SecondaryFiltersType,
  type TopLevelFilter,
  useDebounce,
  useUserDetails,
} from '@assembly-web/services';
import {
  AnonymousAvatarIcon,
  type SelectableOptionProps,
} from '@assembly-web/ui';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getIncludedEntityIds } from '../../services/secondaryFilters';
import { secondaryFiltersStore } from '../../stores/secondaryFiltersStore';
import { useGetSecondaryFilterOptionsQuery } from '../useGetSecondaryFilterOptionsQuery';
import { useGlobalFilter } from '../useGlobalFilter';
import { useSecondaryFilterState } from './useSecondaryFilterState';

export function usePostedBySecondaryFilter({
  topLevelFilter,
  secondaryFilterTypes,
}: {
  topLevelFilter: TopLevelFilter;
  secondaryFilterTypes: Record<string, SecondaryFiltersType>;
}) {
  const { data: userDetails } = useUserDetails();

  const currentUserId = userDetails?.member.memberId ?? '';

  const { searchValues } = useSecondaryFilterState();
  const [params] = useSearchParams();

  const selectedPostedByPeople = useMemo(
    () => params.get('postedBy')?.split(',') ?? [],
    [params]
  );
  const debouncedPostedBySearchValue = useDebounce(
    searchValues.postedBySearchValue,
    500
  );
  const {
    isPending: isPostedByDataLoading,
    hasNextPage: hasMorePostedByMembers,
    fetchNextPage: fetchMorePostedByMembers,
    data: postedByPeopleData,
    isFetchingNextPage: isFetchingMorePostedByMembers,
    isLoading: isInitialPostedByLoading,
  } = useGetSecondaryFilterOptionsQuery({
    topLevelFilter,
    secondaryFilter: {
      filter: secondaryFilterTypes['postedBy'],
      includeEntityIds: getIncludedEntityIds({
        selectedItems: selectedPostedByPeople,
        currentUserId,
      }),
    },
    enabled: Object.keys(secondaryFilterTypes).includes('postedBy'),
    searchTerm: searchValues.postedBySearchValue
      ? debouncedPostedBySearchValue
      : '',
  });

  const postedByOptions: SelectableOptionProps[] = useMemo(() => {
    if (!postedByPeopleData) return [];
    return postedByPeopleData.pages.reduce<SelectableOptionProps[]>(
      (acc, page) => {
        return [
          ...acc,
          ...page.FROM.data.map((item) => {
            return {
              id: item.type === 'member' ? item._meta.entityId : '',
              value:
                item.type === 'member'
                  ? item._meta.entityId === currentUserId
                    ? `${item._meta.fullName} (You)`
                    : item._meta.fullName
                  : '',
              imageUrl:
                item.type === 'member'
                  ? item._meta.state === MemberState.Dummy
                    ? AnonymousAvatarIcon
                    : item._meta.profileImageUrl
                  : '',
              state: item.type === 'member' ? item.state : 'ACTIVE',
            };
          }),
        ];
      },
      []
    );
  }, [currentUserId, postedByPeopleData]);

  const postedBySelectedOptions = postedByOptions.filter((item) =>
    selectedPostedByPeople.includes(item.id)
  );

  const filter = useGlobalFilter();

  const excludedPostedByMembers = useMemo(() => {
    const includedItemsWithoutOwner =
      topLevelFilter === 'MEMBER'
        ? []
        : getIncludedEntityIds({
            currentUserId,
            selectedItems: selectedPostedByPeople,
          }).filter((item) => item !== currentUserId);
    if (postedByOptions.length === 0 || filter === GlobalFilterOption.All)
      return [];
    return includedItemsWithoutOwner.filter(
      (memberId) => !postedByOptions.some((item) => item.id === memberId)
    );
  }, [
    currentUserId,
    filter,
    postedByOptions,
    selectedPostedByPeople,
    topLevelFilter,
  ]);

  const { updateExcludedPostedByMembers } = secondaryFiltersStore();

  useEffect(() => {
    const members =
      excludedPostedByMembers.length && filter !== GlobalFilterOption.All
        ? excludedPostedByMembers
        : [];
    updateExcludedPostedByMembers(members);
    //this condition is to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialPostedByLoading, filter, topLevelFilter]);

  return {
    isPostedByDataLoading,
    hasMorePostedByMembers,
    fetchMorePostedByMembers,
    isFetchingMorePostedByMembers,
    postedByOptions,
    postedBySelectedOptions,
    postedBySearchValue: searchValues.postedBySearchValue,
    hasSelectedPeople:
      selectedPostedByPeople.filter(
        (people) =>
          !excludedPostedByMembers.some((excluded) => people === excluded)
      ).length > 0 && postedByOptions.length,
  };
}
