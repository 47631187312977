import { TextStyle, Tooltip } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  editorHelperText: {
    defaultMessage: 'What would you like to know?',
    id: 'KXSupW',
  },
});

export function DoraChatDisclaimerText({
  toolTipText,
}: {
  toolTipText: string;
}) {
  const { formatMessage } = useIntl();

  return (
    <TextStyle
      variant="sm-regular"
      subdued
      className="inline-flex items-center gap-1 text-center"
    >
      {formatMessage(messages.editorHelperText)}
      <Tooltip tooltipText={toolTipText}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.9898 6.4999L7.9949 6.5H7.625C7.28011 6.5 7 6.78011 7 7.125V11.375C7 11.7199 7.28011 12 7.625 12H8.375C8.71989 12 9 11.7199 9 11.375V7.125C9 6.78011 8.71989 6.5 8.375 6.5H8.0051L8.0102 6.4999C8.33728 6.49322 8.64871 6.3586 8.87766 6.12493C9.10661 5.89125 9.23485 5.57714 9.23485 5.25C9.23485 4.92286 9.10661 4.60875 8.87766 4.37507C8.64871 4.1414 8.33728 4.00678 8.0102 4.0001L8 3.9999L7.9898 4.0001C7.66272 4.00678 7.35129 4.1414 7.12234 4.37507C6.89339 4.60875 6.76515 4.92286 6.76515 5.25C6.76515 5.57714 6.89339 5.89125 7.12234 6.12493C7.35129 6.3586 7.66272 6.49322 7.9898 6.4999ZM1.5 8C1.5 4.41052 4.41052 1.5 8 1.5C11.5895 1.5 14.5 4.41052 14.5 8C14.5 11.5895 11.5895 14.5 8 14.5C4.41052 14.5 1.5 11.5895 1.5 8Z"
            fill="#597EF7"
            stroke="#597EF7"
          />
        </svg>
      </Tooltip>
    </TextStyle>
  );
}
