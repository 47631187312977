import { activitiesIllustration } from '@assembly-web/assets';
import { useSuspenseUserDetails } from '@assembly-web/services';
import { Button, TextStyle, useAssemblyNavigate } from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

const labels = defineMessages({
  recognize: {
    defaultMessage: 'Recognize someone',
    id: '8suNcT',
  },
  nothingToSee: {
    defaultMessage: 'Nothing to see here...yet',
    id: 'qccksC',
  },
});

export function RecognitionFeedEmptyState() {
  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();
  const { data: userDetails } = useSuspenseUserDetails();

  const navigateToGiveRecognition = () => {
    const currentLocation = window.location.href;
    const urlToNavigate = `/a/${userDetails.assembly.workspaceSlugPath}/flows/recognition/answer?${new URLSearchParams([['redirectUrl', currentLocation]])}`;
    navigate(urlToNavigate);
  };
  return (
    <div className="w-full rounded-lg bg-gray-1 p-4 shadow-md-down">
      <div className="mx-auto flex w-fit flex-col gap-4">
        <img
          alt={formatMessage(labels.nothingToSee)}
          src={activitiesIllustration}
          className="w-fit"
        />
        <TextStyle>{formatMessage(labels.nothingToSee)}</TextStyle>
        <Button
          variation="secondaryEmphasized"
          onClick={navigateToGiveRecognition}
        >
          {formatMessage(labels.recognize)}
        </Button>
      </div>
    </div>
  );
}
