import { notFoundImage } from '@assembly-web/assets';
import { useUserDetails } from '@assembly-web/services';
import {
  AppLink,
  Button,
  Heading,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  heading: {
    defaultMessage: 'Looks like you’re lost! Should we circle back?',
    id: 'v5egUn',
  },
  loggedInErrorScreenHeading: {
    defaultMessage: 'You’re currently logged into {assemblyName}',
    id: 'a24nU7',
  },
  body: {
    defaultMessage: 'If you’ve got somewhere to be, these options will help:',
    id: '01kGVR',
  },
  loginCTA: {
    defaultMessage: 'Log in',
    id: 'odXlk8',
  },
  createAccountCTA: {
    defaultMessage: 'Sign up',
    id: '8HJxXG',
  },
  helpCenterCTA: {
    defaultMessage: 'Go to Help Center',
    id: '9G4Z05',
  },
  loginToDifferentAssemblyLabel: {
    defaultMessage: 'Log into a different workspace',
    id: 'jOqv9F',
  },
  goBackToCurrentWorkspaceLabel: {
    defaultMessage: 'Go back to current workspace',
    id: 'hx6kHW',
  },
});

export function NotFoundPage() {
  const { formatMessage } = useIntl();

  const navigate = useAssemblyNavigate();

  const { data: userDetails } = useUserDetails();
  const assemblyName = userDetails?.assembly.name ?? '';
  const isLoggedIn =
    new URLSearchParams(location.search).get('isLoggedIn') === 'true';

  const handlePrimaryButtonClick = () => {
    navigate(isLoggedIn ? '/workspaces' : '/create-account');
  };

  const handleSecondaryButtonClick = () => {
    navigate(isLoggedIn ? '/discover' : '/login');
  };

  return (
    <section className="container mx-auto flex w-fit flex-col items-center justify-center space-y-6 px-4">
      <img src={notFoundImage} role="presentation" alt="" />
      <Heading as={isLoggedIn ? 'h5' : 'h2'}>
        {formatMessage(
          isLoggedIn ? messages.loggedInErrorScreenHeading : messages.heading,
          { assemblyName }
        )}
      </Heading>
      <p className={isLoggedIn ? 'text-base' : 'text-lg'}>
        {formatMessage(messages.body)}
      </p>
      <div className="flex flex-row space-x-10">
        <Button
          variation={isLoggedIn ? 'secondaryEmphasized' : 'secondaryLite'}
          onClick={handlePrimaryButtonClick}
        >
          {formatMessage(
            isLoggedIn
              ? messages.loginToDifferentAssemblyLabel
              : messages.createAccountCTA
          )}
        </Button>

        <Button
          variation={isLoggedIn ? 'secondaryEmphasized' : 'secondaryLite'}
          onClick={handleSecondaryButtonClick}
        >
          {formatMessage(
            isLoggedIn
              ? messages.goBackToCurrentWorkspaceLabel
              : messages.loginCTA
          )}
        </Button>
      </div>
      <AppLink
        to={
          isLoggedIn
            ? 'https://www.joinassembly.com/help-center'
            : 'https://joinassembly.com/faq'
        }
        external
        openInNewTab
      >
        {formatMessage(messages.helpCenterCTA)}
      </AppLink>
    </section>
  );
}
