import { defineMessages } from 'react-intl';

export const commonRecognitionParticipationMessages = defineMessages({
  countWithBracket: {
    defaultMessage: '({count, number})',
    id: 'ZgwNJ8',
  },
  count: {
    defaultMessage: '{count, number}',
    id: 'IAeOwO',
  },
});
