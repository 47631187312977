import type { QueryClient } from '@tanstack/react-query';

import { TemplateList } from '../components/TemplateList';
import { getCategoriesQuery } from '../queries/getCategoriesQuery';

export function templatesRouteLoader(queryClient: QueryClient) {
  return async function loader() {
    const categoriesQuery = getCategoriesQuery();
    queryClient.prefetchQuery(categoriesQuery);
    return null;
  };
}

export function TemplatesRoute() {
  return <TemplateList />;
}
