import {
  type FlowBuilderState,
  getBrowserTimeZone,
} from '@assembly-web/services';
import {
  getBaseEmojiFromIcon,
  getContent,
  getTrigger,
  getVisibility,
  mapCriteriaResponseToBlockData,
} from '@assembly-web/services';

import { useGetFlowBuilderDetailsById } from '../../../../hooks/flows/useGetFlowBuilderDetailsById';
import { useCurrentUserDetail } from '../../../../hooks/useCurrentUserDetail';
import type { FlowCreationDrawer as FlowCreationEditorProps } from '../../types';
import {
  getMilestoneSettings,
  getRemindersSettings,
  getResponseTimeUnitAndValue,
  getSchedulerConfigurationFromKind,
  getScheduleSettings,
} from '../utils/distribution';
import { formatUserToFlowCollaborator } from '../utils/user';

export const useFetchFlow = (
  flowId: string,
  type: FlowCreationEditorProps['data']['type'],
  enabled = true
) => {
  const owner = useCurrentUserDetail();
  const defaultTimeZone = getBrowserTimeZone();

  return useGetFlowBuilderDetailsById(flowId, {
    enabled: enabled && (type === 'edit' || type === 'duplicate'),
    select(data): FlowBuilderState {
      return {
        currentSchedule: data.schedule,
        description: data.description,
        flowId: data.flowId,
        flowName: data.name,
        emoji: getBaseEmojiFromIcon(data.icon.value || ''),
        collaborators: type === 'edit' ? (data.collaborators ?? []) : [],
        inEditMode: type === 'edit',
        templateId: data.templateId,
        isFlowDataChangeConfirmed: false,
        showDataChangeConfirm: false,
        owner:
          type === 'edit'
            ? [formatUserToFlowCollaborator(data.owner)]
            : [formatUserToFlowCollaborator(owner)],
        templateName: '',
        blockData: {
          PARTICIPANTS: {
            errors: null,
            participantsCriteria: data.participation
              ? mapCriteriaResponseToBlockData(data.participation, true)
              : undefined,
          },
          ACTION: {
            errors: null,
            actionType: 'SEND_FORM',
          },
          CONTENT: getContent(data.action ?? {}),
          VISIBILITY: getVisibility(data),
          TRIGGER: getTrigger(data),
        },
        ...getSchedulerConfigurationFromKind(
          data.kind,
          data.metaData?.responseFrequencyType
        ),
        ...getResponseTimeUnitAndValue(
          data.metaData?.isEndTimeInMinutesUpdated && data.endTimeInMinutes
            ? data.endTimeInMinutes + 5
            : data.endTimeInMinutes
        ),
        numberOfResponses: data.numberOfResponses ?? 1,
        canValidate: false,
        errors: null,
        reminderSchedule: null,
        newUsersToInvite: [],
        ...getRemindersSettings({
          ...data.reminder,
          timeZone: defaultTimeZone,
        }),
        ...getScheduleSettings(data.schedule?.rule ?? '', null),
        allowMemberLevelOccurrence: data.allowMemberLevelOccurrence ?? false,
        responseFrequencyTimeZone: data.allowMemberLevelOccurrence
          ? ''
          : getScheduleSettings(data.schedule?.rule ?? '', null)
              .responseFrequencyTimeZone,
        ...getMilestoneSettings(data.milestoneSettings ?? null, data.kind),
      };
    },
  });
};
