import { useEffect, useMemo } from 'react';
import { RRule } from 'rrule';

import {
  useGetFlowBuilderState,
  useGetRemindersStartDate,
  useGetRemindersTimeZone,
  useSetFlowBuilderState,
} from '../../../../../../stores/useFlowBuilderStore';
import { useEditorDataContext } from '../context/EditorDataContext';
import {
  defaultReminderAndStartTime,
  generateRRuleOptions,
} from '../utils/distribution';
import { useGetTimeOptions } from './useGetTimeOptions';

export function useReminderSchedule() {
  const { id } = useEditorDataContext();
  const frequencyTimeOptions = useGetTimeOptions();

  const remindersStartDate = useGetRemindersStartDate(id);
  const timeZone = useGetRemindersTimeZone(id);
  const endDate = useGetFlowBuilderState(id, 'endDate');
  const dueTime = useGetFlowBuilderState(id, 'remindersDueTime');
  const remindersFrequency = useGetFlowBuilderState(id, 'remindersFrequency');

  const setRRule = useSetFlowBuilderState(id, 'reminderSchedule');

  const rrule = useMemo(() => {
    if (!dueTime || !remindersFrequency || !timeZone) {
      return null;
    }

    const selectedTime =
      frequencyTimeOptions.find((option) => option.id === dueTime) ??
      frequencyTimeOptions.find(
        (option) => option.id === defaultReminderAndStartTime
      );

    const rruleOptions = generateRRuleOptions(
      remindersStartDate,
      endDate,
      selectedTime?.id ?? defaultReminderAndStartTime,
      remindersFrequency,
      'recurring',
      timeZone
    );

    return new RRule(rruleOptions).toString();
  }, [
    dueTime,
    endDate,
    timeZone,
    remindersFrequency,
    frequencyTimeOptions,
    remindersStartDate,
  ]);

  useEffect(() => {
    setRRule(rrule);
  }, [rrule, setRRule]);

  return rrule;
}
