import { useMobileLoaded } from '@assembly-web/services';
import { useSearchParams } from 'react-router-dom';

import { ContentLayout } from '../../components/Layout/ContentLayout';
import { AiRecognitionModal } from '../discover/components/modals/AiRecognitionModal';
import { RecognitionFilter } from '../discover/components/SecondaryFilters/RecognitionFilter';
import { useAIRecognitionModal } from '../discover/hooks/useAIRecognitionModal';
import { RecognitionActionBar } from './ActionBar/RecognitionActionBar';
import { RecognitionFeedContainer } from './Feed/RecognitionFeedContainer';
import { RecognitionFilterContainer } from './Filter/RecognitionFilterContainer';

function FilterContent() {
  const [searchParams] = useSearchParams();
  const isFilterExpanded = searchParams.get('filterExpanded') === 'true';

  return (
    Boolean(isFilterExpanded) && (
      <ContentLayout.FilterContent>
        <RecognitionFilter />
      </ContentLayout.FilterContent>
    )
  );
}

export function RecognitionRoute() {
  const aiRecognitionModalOpen = useAIRecognitionModal((state) => state.isOpen);

  useMobileLoaded();

  return (
    <ContentLayout>
      <ContentLayout.ActionBar>
        <RecognitionActionBar />
      </ContentLayout.ActionBar>
      <ContentLayout.WidgetContainer />
      <ContentLayout.SearchTitleContainer />
      <ContentLayout.FilterContainer>
        <RecognitionFilterContainer />
      </ContentLayout.FilterContainer>
      <FilterContent />
      <ContentLayout.FeedContainer>
        <RecognitionFeedContainer />
        {Boolean(aiRecognitionModalOpen) && <AiRecognitionModal />}
      </ContentLayout.FeedContainer>
    </ContentLayout>
  );
}
