import { useGiveRecognitionFlowDetails } from '@assembly-web/participation';
import {
  formatAssemblySearchResult,
  type Icon,
  mapHexCodeToEmoticon,
  type MemberDetails,
  MemberRole,
  type ReactionDetails,
  type RepliesResponse,
  UserActivitySortType,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import type { PostSearchResult } from '@assembly-web/services/lib/types/searchIndex';
import {
  AssemblyLoadingIndicator,
  Button,
  IconButton,
  PostCardUI,
  SelectDropdown,
  Slider,
  TextStyle,
  Tooltip,
  useAssemblyNavigate,
} from '@assembly-web/ui';
import {
  ArrowsPointingInIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useMatches, useParams } from 'react-router-dom';

import { getPostRepliesQuery } from '../../../queries/getAssemblyPostQuery';
import { useActionBarQuery } from '../hooks/useActionBarQuery';
import { useGetUserFeed } from '../hooks/useGetUserFeed';
import { useGlobalFilter } from '../hooks/useGlobalFilter';
import type { GetUserFeedPayload } from '../queries/getUserFeedQuery';
import { trackDiscoverAction } from '../services/analytics';
import { exitFullScreen } from '../services/FullScreenMode';
import { PostCard } from './cards/PostCard';
import { useGetFlowFeedInfinite } from './Drawers/FlowSummaryDrawer/hooks/useGetFlowFeed';

const messages = defineMessages({
  exitFullScreen: {
    id: 'YEAAIt',
    defaultMessage: 'Exit Full screen',
  },
  adjustZoomLevel: {
    id: 'hEhE3v',
    defaultMessage: 'Adjust zoom level',
  },
  zoomLevel: {
    id: '6GminY',
    defaultMessage: 'Zoom level',
  },
  seconds: {
    defaultMessage: '{timeInterval} seconds',
    id: '0NzBr5',
  },
  zoomIn: {
    defaultMessage: 'Zoom In',
    id: 'KQ9L9d',
  },
  zoomOut: {
    defaultMessage: 'Zoom Out',
    id: 'uln7eT',
  },
  resetZoom: {
    defaultMessage: 'Reset Zoom',
    id: '8Ou4hT',
  },
  changeIn: {
    defaultMessage: 'Change post in',
    id: 'niKeNa',
  },
});

const timeIntervals = [5, 10, 15, 20, 25, 30];

const maxZoom = 3;
const minZoom = 0.5;
const zoomStep = 0.25;
const defaultScaleValue = 1;

const defaultTimeInterval = 5;

export function FullScreenPage() {
  const [scaleValue, setScaleValue] = useState(defaultScaleValue);
  const [timeInterval, setTimeInterval] = useState(defaultTimeInterval);
  const [postIndex, setPostIndex] = useState(0);

  const hasTrackedEnteredFullScreen = useRef(false);

  const { flowId } = useParams();
  const filter = useGlobalFilter();

  const { data: userDetails } = useSuspenseUserDetails();

  const { data } = useActionBarQuery();

  const {
    data: {
      flowDetails: { description, name, icon },
    },
  } = useGiveRecognitionFlowDetails();

  const isRecognitionFlow = useMatches().some((match) =>
    match.pathname.includes('recognition')
  );

  const userFeedPayload: GetUserFeedPayload = {
    filters: {
      type: [filter],
    },
    userActivitySortType: UserActivitySortType.Relevance,
    secondaryFilters: {
      flowStatus: ['ACTIVE'],
      entityIn: ['post'],
    },
    excludeRecognitionFlow: userDetails.member.status === 'observer',
    populateCardDetails: true,
  };

  const {
    data: recognitionFeedData,
    isLoading: isRecognitionFeedLoading,
    hasNextPage: hasNextRecognitionFeedPage,
    fetchNextPage: fetchNextRecognitionFeedPage,
  } = useGetUserFeed(userFeedPayload, {
    enabled: isRecognitionFlow,
  });

  const {
    data: newestPostsResponse,
    isLoading: isNewestPostsLoading,
    hasNextPage: hasNextFlowFeedPage,
    fetchNextPage: fetchNextFlowFeedPage,
  } = useGetFlowFeedInfinite(flowId ?? '');

  const postsData = useMemo(() => {
    return newestPostsResponse?.pages.flatMap((page) => page.data) ?? [];
  }, [newestPostsResponse]);

  const recognitionPostsData = useMemo(() => {
    return (
      recognitionFeedData?.pages
        .flatMap((page) => page.data)
        .filter((item) => item.type === 'post')
        .map((item, index) => ({
          ...formatAssemblySearchResult(item as PostSearchResult),
          postResponse: item.cardDetails,
          cardId: item.id,
          cardPosition: index,
        })) ?? []
    );
  }, [recognitionFeedData]);

  const { data: postCardRepliesDetails } = useQuery(
    getPostRepliesQuery({
      flowId: flowId ?? '',
      responseId: isRecognitionFlow
        ? recognitionPostsData[postIndex]?.postId
        : postsData[postIndex]?.responseId,
      enabled: Boolean(postsData[postIndex] || recognitionPostsData[postIndex]),
    })
  );

  const isFetchingPosts = isRecognitionFlow
    ? isRecognitionFeedLoading
    : isNewestPostsLoading;

  const hasNextPageToFetch = isRecognitionFlow
    ? hasNextRecognitionFeedPage
    : hasNextFlowFeedPage;

  const fetchNextPage = isRecognitionFlow
    ? fetchNextRecognitionFeedPage
    : fetchNextFlowFeedPage;

  const fetchedPostsData = isRecognitionFlow ? recognitionPostsData : postsData;

  const reactions: ReactionDetails[] =
    (isRecognitionFlow
      ? recognitionPostsData[postIndex]?.postResponse?.post?.reactions
      : postsData[postIndex]?.reactions) || [];

  const replySummary: RepliesResponse =
    postCardRepliesDetails ||
    ({
      count: postsData[postIndex]?.commentsCount,
      lastRepliedAt: '',
      respondentsCount: 0,
      initialRespondents: [],
    } as const);

  const currentUser = useMemo(() => {
    return {
      memberID: userDetails.member.memberId,
      name: userDetails.member.profile.firstName,
      image: userDetails.member.profile.image,
      firstName: userDetails.member.profile.firstName,
      lastName: userDetails.member.profile.lastName,
      username: userDetails.member.profile.username,
      pointsGiven: userDetails.member.pointsGiven,
      totalPointsGiven: userDetails.member.totalPointsGiven,
      memberState: userDetails.member.memberState,
      role: userDetails.member.role,
      email: userDetails.member.email,
    } as MemberDetails;
  }, [userDetails]);

  useEffect(() => {
    if (!fetchedPostsData[postIndex + 2]) {
      fetchNextPage();
    }
  }, [fetchNextPage, fetchedPostsData, postIndex, postsData]);

  useEffect(() => {
    if (!isFetchingPosts) {
      const timerInMs = timeInterval * 1000;
      const timer = setInterval(() => {
        setPostIndex((prev) => {
          if (!hasNextPageToFetch || !fetchedPostsData[prev + 1]) {
            return prev;
          }
          return (prev += 1);
        });
      }, timerInMs);

      return () => {
        clearInterval(timer);
      };
    }
  }, [fetchedPostsData, hasNextPageToFetch, isFetchingPosts, timeInterval]);

  const currentFlowDetails:
    | {
        name: string;
        description: string;
        icon: Icon;
        templateId?: string;
      }
    | undefined = useMemo(() => {
    return isRecognitionFlow
      ? {
          name,
          description,
          icon,
        }
      : data?.flowsToView
          .filter((flow) => flow.flowId === flowId)
          .map((item) => ({
            name: item.name,
            description: item.description ?? '',
            icon: item.icon,
            templateId: item.templateId,
          }))[0];
  }, [data, description, flowId, icon, isRecognitionFlow, name]);

  const trackEventProps = useMemo(() => {
    return !isRecognitionFlow
      ? {
          flowId,
          flowName: currentFlowDetails?.name,
          ...(currentFlowDetails?.templateId
            ? { templateId: currentFlowDetails.templateId }
            : {}),
        }
      : {};
  }, [currentFlowDetails, flowId, isRecognitionFlow]);

  useEffect(() => {
    if (currentFlowDetails && flowId && !hasTrackedEnteredFullScreen.current) {
      trackDiscoverAction(
        'fullscreenEntered',
        !isRecognitionFlow ? trackEventProps : {}
      );
      hasTrackedEnteredFullScreen.current = true;
    }
  }, [currentFlowDetails, flowId, isRecognitionFlow, trackEventProps]);

  function trackedsetScaleValue(value: number) {
    trackDiscoverAction('fullscreenZoomClicked', trackEventProps);
    setScaleValue(value);
  }

  if (isFetchingPosts || !currentFlowDetails) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <AssemblyLoadingIndicator />
      </div>
    );
  }
  return (
    <div className="flex min-h-screen flex-col justify-between">
      <FullScreenHeader
        trackEventProps={trackEventProps}
        currentFlowDetails={currentFlowDetails}
        isRecognitionFlow={isRecognitionFlow}
        flowId={flowId ?? ''}
        workspaceSlugPath={userDetails.assembly.workspaceSlugPath}
        timeInterval={timeInterval}
        postIndex={postIndex}
      />
      <PostWrapper scaleValue={scaleValue}>
        {isRecognitionFlow ? (
          <PostCard
            onClick={() => {}}
            onReplyClick={() => {}}
            onToolbarItemClick={() => {}}
            onPostClick={() => {}}
            onToolbarMenuItemClick={() => {}}
            isAdmin={
              recognitionPostsData[
                postIndex
              ]?.postResponse?.fromMember?.role.includes(MemberRole.Admin) ??
              false
            }
            workspaceName={userDetails.assembly.name}
            workspaceSlugPath={userDetails.assembly.workspaceSlugPath}
            disableNavigation
            {...recognitionPostsData[postIndex]}
            hasReplyDraft={false}
          />
        ) : (
          <PostCardUI
            hasReplyDraft={false}
            canShowAnnouncements={false}
            repliesData={replySummary}
            flowResponse={postsData[postIndex]}
            reactions={reactions}
            onReactionClick={() => {}}
            onReplyButtonClick={() => {}}
            currentMember={currentUser}
            onPostCardHeaderClick={() => {}}
            onPostCardBodyClick={() => {}}
            currency={userDetails.assembly.currency}
            onFileClick={() => {}}
            onMemberClick={() => {}}
          />
        )}
      </PostWrapper>
      <FullScreenFooter
        trackEventProps={trackEventProps}
        scaleValue={scaleValue}
        trackedsetScaleValue={trackedsetScaleValue}
        timeInterval={timeInterval}
        setTimeInterval={setTimeInterval}
      />
    </div>
  );
}

function FullScreenHeader({
  isRecognitionFlow,
  flowId,
  workspaceSlugPath,
  timeInterval,
  postIndex,
  currentFlowDetails,
  trackEventProps,
}: {
  isRecognitionFlow: boolean;
  flowId: string;
  workspaceSlugPath: string;
  timeInterval: number;
  postIndex: number;
  currentFlowDetails: {
    name: string;
    description: string;
    icon: Icon;
  };
  trackEventProps: Record<string, string>;
}) {
  const timestampOnLoad = useRef(Date.now());

  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();

  return (
    <header className="fixed top-0 z-50 w-full bg-gray-1">
      <div className="flex w-full items-center justify-between p-4">
        <div className="flex items-center justify-between gap-x-4">
          <TextStyle variant="2xl-medium">
            {mapHexCodeToEmoticon(currentFlowDetails.icon.value)}
          </TextStyle>
          <div>
            <TextStyle variant="lg-medium">{currentFlowDetails.name}</TextStyle>
            <TextStyle variant="base-regular" className="text-gray-8">
              {currentFlowDetails.description}
            </TextStyle>
          </div>
        </div>
        <Button
          variation="tertiaryLite"
          onClick={() => {
            navigate(
              `/${workspaceSlugPath}/flows/${isRecognitionFlow ? 'recognition' : flowId}`
            );
            trackDiscoverAction('fullscreenExited', {
              fullscreenDuration: String(
                Math.floor((Date.now() - timestampOnLoad.current) / 1000 / 60)
              ),
              ...trackEventProps,
            });
            exitFullScreen();
          }}
        >
          <ArrowsPointingInIcon className="h-4 w-4" />
          {formatMessage(messages.exitFullScreen)}
        </Button>
      </div>
      <ProgressBar timeInterval={timeInterval} key={postIndex} />
    </header>
  );
}

function ProgressBar({ timeInterval }: { timeInterval: number }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = (timeInterval * 1000) / 100;
    const timer = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          return 100;
        }
        return prev + 1;
      });
    }, interval);
    return () => {
      setProgress(0);
      clearInterval(timer);
    };
  }, [timeInterval]);

  return (
    <div className="h-1 w-full rounded bg-gray-5">
      <div
        className={`h-full bg-primary-5 transition-all`}
        style={{
          width: `${progress}%`,
        }}
      />
    </div>
  );
}

function PostWrapper({
  scaleValue,
  children,
}: {
  scaleValue: number;
  children: React.ReactNode;
}) {
  return (
    <div
      className="h-full w-full flex-1 pt-24"
      style={{
        maxWidth: `${676 * scaleValue}px`,
        margin: '0 auto',
      }}
    >
      <div
        className="h-full"
        style={{
          transform: `scale(${scaleValue})`,
          transformOrigin: '0 0',
          width: `${100 / scaleValue}%`,
        }}
      >
        <div className="h-full p-4">{children}</div>
      </div>
    </div>
  );
}

function FullScreenFooter({
  scaleValue,
  timeInterval,
  setTimeInterval,
  trackEventProps,
  trackedsetScaleValue,
}: {
  trackedsetScaleValue: (value: number) => void;
  scaleValue: number;
  timeInterval: number;
  setTimeInterval: (value: number) => void;
  trackEventProps: Record<string, string>;
}) {
  const { formatMessage } = useIntl();

  function zoomIn() {
    if (scaleValue + zoomStep >= maxZoom) {
      trackedsetScaleValue(maxZoom);
      return;
    }
    trackedsetScaleValue(scaleValue + zoomStep);
  }

  function zoomOut() {
    if (scaleValue - zoomStep < minZoom) {
      trackedsetScaleValue(minZoom);
      return;
    }
    trackedsetScaleValue(scaleValue - zoomStep);
  }

  return (
    <footer className="fixed bottom-0 flex w-full justify-between p-4">
      <SelectDropdown
        options={timeIntervals.map((x) => ({
          value: x.toString(),
          id: x.toString(),
          label: x.toString(),
        }))}
        onSelect={(value) => {
          trackDiscoverAction('fullscreenTimeChanged', {
            timeSelected: value.toString(),
            ...trackEventProps,
          });
          setTimeInterval(Number(value));
        }}
        label={formatMessage(messages.changeIn)}
        renderOption={({ value }) =>
          formatMessage(messages.seconds, { timeInterval: value })
        }
        variant="primary"
        value={formatMessage(messages.seconds, { timeInterval })}
      />
      <div className="flex items-center gap-x-2 rounded-lg bg-gray-4 px-1">
        <Tooltip tooltipText={formatMessage(messages.zoomOut)}>
          <IconButton variation="tertiaryLite" size="xSmall" onClick={zoomOut}>
            <MinusIcon className="h-4 w-4" />
          </IconButton>
        </Tooltip>
        <Slider
          setScaleValue={trackedsetScaleValue}
          scaleValue={scaleValue}
          minZoomLevel={minZoom}
          maxZoomLevel={maxZoom}
          zoomStep={zoomStep}
          variant="secondary"
        />
        <Tooltip tooltipText={formatMessage(messages.zoomIn)}>
          <IconButton variation="tertiaryLite" size="xSmall" onClick={zoomIn}>
            <PlusIcon className="h-4 w-4" />
          </IconButton>
        </Tooltip>
        <Tooltip tooltipText={formatMessage(messages.resetZoom)}>
          <IconButton
            variation="tertiaryLite"
            size="xSmall"
            onClick={() => {
              trackedsetScaleValue(defaultScaleValue);
            }}
          >
            <ArrowsPointingInIcon />
          </IconButton>
        </Tooltip>
      </div>
    </footer>
  );
}
