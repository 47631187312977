import {
  type DeadlineType,
  FlowFrequencyOptions,
  type FlowKind,
  type FlowResponseType,
  formatIsoDateStringToTimeString,
  getCalendarDate,
  getWeekNumberOfMonth,
  type MilestoneFrequencyType,
  type MilestoneType,
  type Nullable,
  type RemindersType,
  type ResponseFrequencyType,
  type TimeUnit,
} from '@assembly-web/services';
import type { CalendarDate } from '@internationalized/date';
import { type Options, RRule, type RRuleSet, rrulestr } from 'rrule';

export const defaultEndTimeInMinutes = 1440;
export const defaultReminderAndStartTime = '8:00 AM';

export function getSchedulerConfiguration({ kind }: { kind: FlowKind }) {
  if (kind === 'SCHEDULED' || kind === 'ONCE') {
    return {
      dueDate: getCalendarDate(new Date()),
    };
  }
}

export function formatCalenderDateToDateString(date: CalendarDate) {
  return new Date(date.year, date.month - 1, date.day).toLocaleDateString(
    'en-US',
    {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }
  );
}

export function getSchedulerConfigurationFromKind(
  kind: FlowKind,
  responseFrequencyType?: ResponseFrequencyType
): {
  deadlineType: DeadlineType;
  flowResponseType: FlowResponseType;
  responseFrequencyType: ResponseFrequencyType;
} {
  if (kind === 'MILESTONE') {
    return {
      deadlineType: 'schedule',
      flowResponseType: 'milestone',
      responseFrequencyType:
        responseFrequencyType === 'once' ? 'once' : 'recurring',
    };
  }

  if (responseFrequencyType && responseFrequencyType === 'once') {
    return {
      deadlineType: 'schedule',
      flowResponseType: 'deadline',
      responseFrequencyType: 'once',
    };
  }

  if (kind === 'NO_TRIGGER') {
    return {
      flowResponseType: 'anytime',
      deadlineType: 'manual',
      responseFrequencyType: 'recurring',
    };
  }

  if (kind === 'ONDEMAND') {
    return {
      deadlineType: 'manual',
      flowResponseType: 'deadline',
      responseFrequencyType: 'recurring',
    };
  }

  if (kind === 'SCHEDULED') {
    return {
      deadlineType: 'schedule',
      flowResponseType: 'deadline',
      responseFrequencyType: 'recurring',
    };
  }

  return {
    deadlineType: 'schedule',
    flowResponseType: 'deadline',
    responseFrequencyType: 'once',
  };
}

export function getResponseTimeUnitAndValue(
  minutes: number | undefined = defaultEndTimeInMinutes
): {
  responseTimeValue: number;
  responseTimeUnit: TimeUnit;
} {
  const minutesPerHour = 60;
  const minutesPerDay = 24 * minutesPerHour;
  const minutesPerWeek = 7 * minutesPerDay;

  if (minutes >= minutesPerWeek && minutes % minutesPerWeek === 0) {
    return {
      responseTimeValue: minutes / minutesPerWeek,
      responseTimeUnit: 'weeks',
    };
  } else if (minutes >= minutesPerDay && minutes % minutesPerDay === 0) {
    return {
      responseTimeValue: minutes / minutesPerDay,
      responseTimeUnit: 'days',
    };
  } else if (minutes >= minutesPerHour && minutes % minutesPerHour === 0) {
    return {
      responseTimeValue: minutes / minutesPerHour,
      responseTimeUnit: 'hours',
    };
  } else {
    return {
      responseTimeValue: minutes,
      responseTimeUnit: 'minutes',
    };
  }
}

function isLastDayOfPeriod(bysetpos: number | number[] | undefined): boolean {
  if (Array.isArray(bysetpos)) {
    return bysetpos.includes(-1);
  }
  return bysetpos === -1;
}

function parseTime(timeString: string): { hours: number; minutes: number } {
  const [time, period] = timeString.split(' ');
  let [hours, minutes] = time.split(':').map(Number);

  if (period === 'PM' && hours !== 12) hours += 12;
  else if (period === 'AM' && hours === 12) hours = 0;

  return { hours, minutes };
}

export function getMilestoneSettings(
  milestoneSettings: {
    count?: number;
    interval?: number;
    afterDays?: number;
    weekendAllowed: boolean;
    triggeredOn: 'onDate' | 'afterDate';
  } | null,
  kind: FlowKind
): {
  skipWeekend: boolean;
  selectedMilestone: MilestoneType;
  flowResponseType?: FlowResponseType;
  selectedMilestoneFrequency: MilestoneFrequencyType;
  numberOfResponseForMilestone: number | null;
} {
  if (!milestoneSettings || kind !== 'MILESTONE') {
    return {
      skipWeekend: true,
      selectedMilestone: 'startDate',
      numberOfResponseForMilestone: null,
      selectedMilestoneFrequency: 'everyDay',
    };
  }

  const { count, interval, afterDays, weekendAllowed, triggeredOn } =
    milestoneSettings;

  const reverseAfterDaysMap: Record<number, MilestoneType> = {
    0: 'startDate',
    7: 'oneWeekAfterStartDate',
    30: 'oneMonthAfterStartDate',
    60: 'twoMonthsAfterStartDate',
    90: 'threeMonthsAfterStartDate',
    365: 'oneYearAfterStartDate',
  };

  const reverseIntervalMap: Record<number, MilestoneFrequencyType> = {
    1: 'everyDay',
    7: 'everyWeek',
    30: 'everyMonth',
    90: 'everyQuarter',
    365: 'everyYear',
  };

  return {
    skipWeekend: !weekendAllowed,
    selectedMilestone: (triggeredOn === 'onDate'
      ? 'startDate'
      : (reverseAfterDaysMap[afterDays ?? 0] ?? 'startDate')) as MilestoneType,
    flowResponseType: 'milestone',
    numberOfResponseForMilestone: count && count > 1 ? count : null,
    selectedMilestoneFrequency: reverseIntervalMap[interval ?? 1] ?? 'everyDay',
  };
}

export function getScheduleSettings(
  rruleString: Nullable<string>,
  timeZone: string | null
) {
  if (!rruleString) {
    return {
      dueTime: null,
      endDate: null,
      responseFrequencyTimeZone: timeZone,
      dueDate: getCalendarDate(new Date()),
      repeatFrequency: FlowFrequencyOptions.EveryWeekday,
    };
  }

  const rrule = rrulestr(rruleString);
  const options = rrule.options;

  const rruleSet = rrulestr(rruleString, { forceset: true }) as RRuleSet;
  const dueDateTime =
    rruleSet.dtstart()?.toISOString() ?? new Date().toISOString();

  const startDate = rruleSet.dtstart();

  const dueDate = startDate
    ? getCalendarDate(
        new Date(
          startDate.getUTCFullYear(),
          startDate.getUTCMonth(),
          startDate.getUTCDate()
        )
      )
    : getCalendarDate(new Date());

  const endDate = options.until ? getCalendarDate(options.until) : null;

  const dueTime = formatIsoDateStringToTimeString(dueDateTime);

  let repeatFrequency: FlowFrequencyOptions;

  if (options.count === 1) {
    repeatFrequency = FlowFrequencyOptions.Once;
  } else {
    switch (options.freq) {
      case RRule.DAILY:
        repeatFrequency = FlowFrequencyOptions.Daily;
        break;
      case RRule.WEEKLY:
        if (options.interval === 2) {
          repeatFrequency = FlowFrequencyOptions.BiWeekly;
        } else if (options.byweekday.length === 5) {
          repeatFrequency = FlowFrequencyOptions.EveryWeekday;
        } else {
          repeatFrequency = FlowFrequencyOptions.Weekly;
        }
        break;
      case RRule.MONTHLY:
        if (options.interval === 3) {
          repeatFrequency = isLastDayOfPeriod(options.bysetpos)
            ? FlowFrequencyOptions.QuarterlyLast
            : FlowFrequencyOptions.Quarterly;
        } else {
          repeatFrequency = isLastDayOfPeriod(options.bysetpos)
            ? FlowFrequencyOptions.MonthlyLast
            : FlowFrequencyOptions.Monthly;
        }
        break;
      case RRule.YEARLY:
        repeatFrequency = FlowFrequencyOptions.Annually;
        break;
      default:
        repeatFrequency = FlowFrequencyOptions.Once;
    }
  }

  return {
    dueTime,
    endDate,
    dueDate,
    repeatFrequency,
    responseFrequencyTimeZone: options.tzid || timeZone,
  };
}

export function getRemindersSettings({
  type,
  rrule,
  numberOfReminders,
  timeZone,
}: {
  rrule?: string;
  timeZone: string;
  type: 'MANUAL' | 'AUTOMATED';
  numberOfReminders?: number | null;
}) {
  if (type === 'MANUAL') {
    return {
      remindersCount: 1,
      remindersStartDate: null,
      remindersDueTime: defaultReminderAndStartTime,
      remindersType: 'manual' as RemindersType,
      remindersFrequency: FlowFrequencyOptions.EveryWeekday,
    };
  }

  const schedule = getScheduleSettings(rrule, timeZone);

  return {
    remindersStartDate: schedule.dueDate,
    remindersCount: numberOfReminders ?? 1,
    remindersType: 'automate' as RemindersType,
    remindersFrequency: schedule.repeatFrequency,
    remindersTimeZone: schedule.responseFrequencyTimeZone,
    remindersDueTime: schedule.dueTime ?? defaultReminderAndStartTime,
  };
}

export function generateRRuleOptions(
  dueDate: CalendarDate,
  endDate: CalendarDate | null,
  dueTime: string,
  repeatFrequency: FlowFrequencyOptions,
  responseFrequencyType: 'once' | 'recurring',
  responseFrequencyTimeZone: string | null
): Partial<Options> {
  const { hours, minutes } = parseTime(dueTime);

  const dtStart = new Date(dueDate.toDate('UTC'));
  dtStart.setUTCHours(hours, minutes, 0, 0);

  const { WEEKLY, DAILY, MONTHLY, YEARLY, SU, MO, TU, WE, TH, FR, SA } = RRule;

  const rruleOptions: Partial<Options> = {
    dtstart: dtStart,
    until: endDate?.toDate('UTC'),
    ...(responseFrequencyTimeZone && { tzid: responseFrequencyTimeZone }),
  };

  if (
    responseFrequencyType === 'once' ||
    repeatFrequency === FlowFrequencyOptions.Once
  ) {
    rruleOptions.count = 1;
    return rruleOptions;
  }

  const dayOfWeek = dtStart.getDay();
  const rruleDay = [SU, MO, TU, WE, TH, FR, SA][dayOfWeek];

  switch (repeatFrequency) {
    case FlowFrequencyOptions.EveryWeekday:
      rruleOptions.freq = WEEKLY;
      rruleOptions.byweekday = [MO, TU, WE, TH, FR];
      break;
    case FlowFrequencyOptions.Daily:
      rruleOptions.freq = DAILY;
      break;
    case FlowFrequencyOptions.Weekly:
      rruleOptions.freq = WEEKLY;
      rruleOptions.byweekday = [rruleDay];
      break;
    case FlowFrequencyOptions.BiWeekly:
      rruleOptions.freq = WEEKLY;
      rruleOptions.interval = 2;
      rruleOptions.byweekday = [rruleDay];
      break;
    case FlowFrequencyOptions.Monthly:
    case FlowFrequencyOptions.MonthlyLast:
      rruleOptions.freq = MONTHLY;
      rruleOptions.byweekday = rruleDay;
      rruleOptions.bysetpos =
        repeatFrequency === FlowFrequencyOptions.MonthlyLast
          ? -1
          : getWeekNumberOfMonth(dtStart);
      break;
    case FlowFrequencyOptions.Quarterly:
    case FlowFrequencyOptions.QuarterlyLast:
      rruleOptions.freq = MONTHLY;
      rruleOptions.interval = 3;
      rruleOptions.byweekday = rruleDay;
      rruleOptions.bysetpos =
        repeatFrequency === FlowFrequencyOptions.QuarterlyLast
          ? -1
          : getWeekNumberOfMonth(dtStart);
      break;
    case FlowFrequencyOptions.Annually:
      rruleOptions.freq = YEARLY;
      break;
  }

  return rruleOptions;
}
