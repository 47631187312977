import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';

import {
  addFolderToNav,
  navItemsQueryKey,
  removeFolderFromNav,
} from '../../modules/discover/hooks/nav/useNavData';
import {
  addHiddenFolderToNav,
  hiddenFoldersQueryOptions,
  removeHiddenFolderFromNav,
} from './useHiddenFolders';

const messages = defineMessages({
  hideSuccess: {
    defaultMessage: '{folderName} successfully hidden.',
    id: 'CpwD+A',
  },
  unhideSuccess: {
    defaultMessage: '{folderName} is no longer hidden.',
    id: 'GiW6Bc',
  },
  hideError: {
    defaultMessage: 'Failed to hide {folderName}. Please try again.',
    id: 'kNCsWg',
  },
  unhideError: {
    defaultMessage: 'Failed to unhide {folderName}. Please try again.',
    id: 'c95Uxf',
  },
});

type FolderVisibilityMutationPayload = {
  id: string;
  hide: boolean;
  folderName: string;
};

export function useFolderVisibility() {
  const { formatMessage } = useIntl();
  const { showErrorToast } = useToastStore();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, hide }: FolderVisibilityMutationPayload) => {
      return assemblyAPI.post(APIEndpoints.pinItemToNav, {
        item: { type: 'collection', id },
        hide,
      });
    },
    onMutate: async ({ id: folderId, hide }) => {
      if (hide) {
        const removedFolder = await removeFolderFromNav(queryClient, folderId);
        if (removedFolder) {
          await addHiddenFolderToNav(queryClient, removedFolder);
        }
      } else {
        const removedFolder = await removeHiddenFolderFromNav(
          queryClient,
          folderId
        );
        if (removedFolder) {
          await addFolderToNav(queryClient, removedFolder);
        }
      }
    },
    onSettled: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: navItemsQueryKey }),
        queryClient.invalidateQueries({
          queryKey: hiddenFoldersQueryOptions.queryKey,
        }),
      ]);
    },
    onError: (err: unknown, payload) => {
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Error hiding folder item',
        { ...payload, error: errorInfo },
        errorInfo
      );

      showErrorToast(
        formatMessage(
          payload.hide ? messages.hideError : messages.unhideError,
          { folder: payload.folderName }
        )
      );
    },
  });
}
