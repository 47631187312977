import type { Folder } from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  EyeIcon,
  EyeSlashIcon,
  LinkIcon,
  MagnifyingGlassPlusIcon,
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useContext, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { AddToFolder } from '../../components/Nav/Folder/AddToFolder';
import { ShareCollectionsMessageKey } from '../../modules/discover/components/modals/ShareCollectionModal';
import { CollectionModalsContext } from '../../modules/discover/contexts/CollectionModalsContext';
import { useNavCollectionReorderMutation } from '../../modules/discover/hooks/nav/useNavCollectionReorderMutation';
import { useNavData } from '../../modules/discover/hooks/nav/useNavData';
import { useCollections } from '../../modules/discover/hooks/useGetCollectionsQuery';
import { trackNavAction } from '../../modules/discover/services/analytics';
import { useFolderVisibility } from './useFolderVisibility';
import { useHiddenFolders } from './useHiddenFolders';

const menuItemsMessages = defineMessages({
  addLink: {
    defaultMessage: 'Add link',
    id: 'JnxkKM',
  },
  addItem: {
    defaultMessage: 'Add item',
    id: 'KDO3hW',
  },
  share: {
    defaultMessage: 'Share',
    id: 'OKhRC6',
  },
  editDetails: {
    defaultMessage: 'Edit details',
    id: 'BVWzJ5',
  },
  hide: {
    defaultMessage: 'Hide',
    id: 'VA/Z1S',
  },
  unhide: {
    defaultMessage: 'Unhide',
    id: 'MQb+Jc',
  },
  delete: {
    defaultMessage: 'Delete',
    id: 'K3r6DQ',
  },
  moveUp: {
    defaultMessage: 'Move up',
    id: 'wmFdws',
  },
  moveDown: {
    defaultMessage: 'Move down',
    id: 'H/r5m6',
  },
});

enum FolderMenuActions {
  AddLink = 'add-link',
  AddItem = 'add-item',
  Share = 'share',
  EditDetails = 'edit-details',
  Hide = 'hide',
  Unhide = 'unhide',
  Delete = 'delete',
  MoveUp = 'move-up',
  MoveDown = 'move-down',
}

export function useFolderMenu(folder: Folder) {
  const { formatMessage } = useIntl();

  const { data: collections } = useCollections();

  const { pinnedCollections: folders } = useNavData();
  const { hiddenFolders } = useHiddenFolders();

  const { mutateAsync: reorderFolderMutation } =
    useNavCollectionReorderMutation();

  const collection = useMemo(
    () => collections.data.find((c) => c.collectionId === folder.id),
    [collections, folder.id]
  );

  const isHiddenFolder = useMemo(
    () => hiddenFolders.some((hiddenFolder) => hiddenFolder.id === folder.id),
    [folder.id, hiddenFolders]
  );

  const folderPosition = useMemo(
    () =>
      isHiddenFolder
        ? hiddenFolders.findIndex((c) => c.id === folder.id)
        : folders.findIndex((c) => c.id === folder.id),
    [folder.id, folders, hiddenFolders, isHiddenFolder]
  );

  const noOfFolders = useMemo(
    () => (isHiddenFolder ? hiddenFolders.length : folders.length),
    [folders.length, hiddenFolders.length, isHiddenFolder]
  );

  const {
    openAddExternalLinkModal,
    openDeleteModal: openDeleteCollectionModal,
    openCreateCollectionModal,
  } = useContext(CollectionModalsContext);

  const { mutate: updateFolderVisibility } = useFolderVisibility();

  const { allowedEditing, allowedSharing } = folder;

  const replacedContent = useCallback(
    (onBack: () => void) => {
      if (!collection?.collectionId || !collection.name) {
        return null;
      }

      return (
        <AddToFolder
          folderId={collection.collectionId}
          folderName={collection.name}
          onBack={onBack}
        />
      );
    },
    [collection]
  );

  const isNotOnlyFolderInSection = noOfFolders >= 1;

  const rearrangeFolder = useCallback(
    function (direction: 'up' | 'down') {
      if (direction === 'up') {
        const afterId = folders[folderPosition - 1]?.id;

        invariant(afterId, 'there must be a folder above it');

        reorderFolderMutation({
          collectionId: folder.id,
          afterId,
        });
        trackNavAction('moveCollectionUp', { isSoftPinnedCollection: true });
      } else {
        const beforeId = folders[folderPosition + 1]?.id;

        invariant(beforeId, 'there must be a folder below it');

        reorderFolderMutation({
          collectionId: folder.id,
          beforeId,
        });
        trackNavAction('moveCollectionDown', {
          isSoftPinnedCollection: true,
        });
      }
    },
    [folder.id, folderPosition, folders, reorderFolderMutation]
  );

  // At present we're sorting hidden folders alphabetically, so we can't move them up or down
  const canShowMoveDownAction =
    allowedEditing &&
    folderPosition !== noOfFolders - 1 &&
    isNotOnlyFolderInSection &&
    !isHiddenFolder;

  const canShowMoveUpAction =
    allowedEditing &&
    folderPosition !== 0 &&
    isNotOnlyFolderInSection &&
    !isHiddenFolder;

  const folderToolbarItems: ToolbarItem[] = useMemo(
    () =>
      [
        allowedEditing && {
          id: FolderMenuActions.AddLink,
          text: formatMessage(menuItemsMessages.addLink),
          icon: LinkIcon,
        },
        allowedEditing && {
          id: FolderMenuActions.AddItem,
          text: formatMessage(menuItemsMessages.addItem),
          icon: MagnifyingGlassPlusIcon,
          replacedContent,
          onReplaceContentOpen: () => {
            trackNavAction('addItemClicked');
          },
        },
        allowedEditing &&
          allowedSharing && {
            id: FolderMenuActions.Share,
            text: formatMessage(menuItemsMessages.share),
            icon: UserPlusIcon,
          },
        allowedEditing && {
          id: FolderMenuActions.EditDetails,
          text: formatMessage(menuItemsMessages.editDetails),
          icon: PencilIcon,
        },
        isHiddenFolder && {
          id: FolderMenuActions.Unhide,
          text: formatMessage(menuItemsMessages.unhide),
          icon: EyeIcon,
        },
        !isHiddenFolder && {
          id: FolderMenuActions.Hide,
          text: formatMessage(menuItemsMessages.hide),
          icon: EyeSlashIcon,
        },
        canShowMoveUpAction && {
          id: FolderMenuActions.MoveUp,
          text: formatMessage(menuItemsMessages.moveUp),
          icon: ArrowUpIcon,
        },
        canShowMoveDownAction && {
          id: FolderMenuActions.MoveDown,
          text: formatMessage(menuItemsMessages.moveDown),
          icon: ArrowDownIcon,
        },
        allowedEditing && {
          id: FolderMenuActions.Delete,
          text: formatMessage(menuItemsMessages.delete),
          icon: TrashIcon,
          isAlertStyle: true,
        },
      ].filter(Boolean),
    [
      allowedEditing,
      formatMessage,
      replacedContent,
      allowedSharing,
      isHiddenFolder,
      canShowMoveUpAction,
      canShowMoveDownAction,
    ]
  );

  const onMenuItemClick = useCallback(
    (args: ToolbarItem) => {
      invariant(collection, 'Collection not found');
      switch (args.id) {
        case FolderMenuActions.AddLink:
          trackNavAction('addLinkClicked');
          openAddExternalLinkModal(collection);
          break;
        case FolderMenuActions.Share:
          trackNavAction('shareFolderClicked');
          window.postMessage(
            {
              type: ShareCollectionsMessageKey,
              payload: {
                collectionId: collection.collectionId,
                createdBy: collection.createdBy ?? undefined,
              },
            },
            window.location.origin
          );
          break;
        case FolderMenuActions.EditDetails:
          trackNavAction('editDetailsClicked');
          openCreateCollectionModal(collection);
          break;
        case FolderMenuActions.Delete:
          trackNavAction('menuDeleteFolderClicked');
          openDeleteCollectionModal(collection);
          break;
        case FolderMenuActions.Hide:
          trackNavAction('hideFolderClicked');
          updateFolderVisibility({
            id: folder.id,
            hide: true,
            folderName: folder.name,
          });
          break;
        case FolderMenuActions.Unhide:
          trackNavAction('unhideFolderClicked');
          updateFolderVisibility({
            id: folder.id,
            hide: false,
            folderName: folder.name,
          });
          break;
        case FolderMenuActions.MoveUp:
          rearrangeFolder('up');
          break;
        case FolderMenuActions.MoveDown:
          rearrangeFolder('down');
          break;
        default:
          console.log('Not implemented', args);
          break;
      }
    },
    [
      collection,
      openAddExternalLinkModal,
      openCreateCollectionModal,
      openDeleteCollectionModal,
      updateFolderVisibility,
      folder.id,
      folder.name,
      rearrangeFolder,
    ]
  );

  return { folderToolbarItems, onMenuItemClick };
}
