import { fileIcons, postIcon } from '@assembly-web/assets';
import {
  checkIfCommentKindMatches,
  type CollectionItem,
  formatAssemblySearchResult,
  formatCollectionItemToSearchResult,
  isChallengeFile,
  type SearchIndexResult,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { getFileIcon } from '../../modules/discover/services/files';

const messages = defineMessages({
  postByLabel: {
    defaultMessage:
      'Post by {author, select, self {you} other {{author}}} in {flow}',
    id: 'd/Wpk/',
  },
  birthDayPostLabel: {
    defaultMessage:
      'Birthday Post for {celebratedTeammate, select, self {you} other {{celebratedTeammate}}} in {flow}',
    id: 'y8Ogzt',
  },
  anniversaryDayPostLabel: {
    defaultMessage:
      'Anniversary Post for {celebratedTeammate, select, self {you} other {{celebratedTeammate}}} in {flow}',
    id: 'aIfTHC',
  },
  challengeComment: {
    defaultMessage:
      'Reply by {author, select, self {you} other {{author}}} on {challenge}',
    id: 'jHDwEn',
  },
  comment: {
    defaultMessage:
      'Reply by {author, select, self {you} other {{author}}} on {post} in {flow}',
    id: 'RiL5JV',
  },
});

export function useFolderItemDetails() {
  const { data: userDetails } = useSuspenseUserDetails();

  const { formatMessage } = useIntl();

  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const currentMember = userDetails.member;
  const currentMemberID = currentMember.memberId;

  const workspaceName = userDetails.assembly.name;

  return useCallback(
    (item: CollectionItem) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const searchResult = formatCollectionItemToSearchResult(
        item
      )! as SearchIndexResult;

      const type = searchResult.type;

      switch (type) {
        case 'flow':
        case 'recognition': {
          const {
            urlSlug,
            emoji: icon,
            flowName: text,
          } = formatAssemblySearchResult(searchResult);

          return {
            url: `/a/${workspaceSlugPath}${urlSlug}`,
            icon,
            text,
            type,
            id: item.id,
          };
        }

        case 'challenge': {
          const { title, challengeId } =
            formatAssemblySearchResult(searchResult);

          return {
            title,
            challengeId,
            type,
            url: `/a/challenges?challengeId=${challengeId}`,
          };
        }

        case 'member': {
          const { memberName, iconUrl, fullName, memberID, urlSlug } =
            formatAssemblySearchResult(searchResult);

          return {
            memberName,
            iconUrl,
            fullName,
            memberID,
            url: `/a/${workspaceSlugPath}${urlSlug}`,
            type,
          };
        }

        case 'weblink': {
          const { isRecognitionFlow, title, url, plainUrl } =
            formatAssemblySearchResult(searchResult, workspaceSlugPath);

          return {
            isRecognitionFlow,
            title: title || url,
            url,
            plainUrl,
            type,
          };
        }

        case 'url': {
          const {
            title,
            type: urlSearchType,
            url,
          } = formatAssemblySearchResult(searchResult);

          return { title, urlSearchType, url, type };
        }

        case 'file': {
          if (searchResult.source !== 'assembly') {
            const {
              plainFileName: text,
              fileType,
              integrationIcon,
              remoteLocation: url,
            } = formatAssemblySearchResult(searchResult);

            return {
              text,
              url,
              imgIcon: getFileIcon(fileType) ?? integrationIcon,
              type: 'externalFile' as const,
            };
          }

          if (isChallengeFile(searchResult)) {
            const { internalFileName: text, iconLabel } =
              formatAssemblySearchResult(searchResult);
            return {
              type: 'challengeFile' as const,
              text,
              imgIcon: fileIcons[iconLabel],
              // TODO: @meghana to add previewer changes for challenges files once PR:#2824 is merged
            };
          }

          const {
            formattedOriginalFileName: text,
            blockId,
            flowId,
            internalFileName,
            responseId,
            iconLabel,
          } = formatAssemblySearchResult(searchResult);

          return {
            type: 'flowFile' as const,
            text,
            imgIcon: fileIcons[iconLabel],
            filePreviewerProps: {
              workspaceSlugPath: userDetails.assembly.workspaceSlugPath,
              blockId,
              flowId,
              fileName: internalFileName,
              responseId,
            },
          };
        }

        case 'comment':
          {
            if (checkIfCommentKindMatches(searchResult, 'challenge')) {
              const { commentId, challengeId } =
                formatAssemblySearchResult(searchResult);

              const { cardDetails } = searchResult;

              return {
                type: 'challengeComment' as const,
                challengeId,
                url: `/a/challenges?commentId=${commentId}&challengeId=${challengeId}`,
                text: formatMessage(messages.challengeComment, {
                  author:
                    cardDetails.fromMember.memberID === currentMemberID
                      ? 'self'
                      : cardDetails.fromMember.name,
                  workspaceName,
                  challenge: searchResult._meta.challengeDetails.title,
                }),
              };
            }

            const {
              authorId,
              author,
              flow,
              post,
              postId,
              responseId,
              flowId,
              commentId,
            } = formatAssemblySearchResult(searchResult);

            return {
              url: `/a/?flowId=${flowId}&responseId=${responseId}&commentId=${commentId}`,
              type: 'comment' as const,
              postId,
              commentId,
              flowId,
              responseId,
              text: formatMessage(messages.comment, {
                author: authorId === currentMemberID ? 'self' : author,
                flow,
                workspaceName,
                post,
              }),
            };
          }
          break;

        case 'response': {
          const { flow, authorId, author, flowId, responseId } =
            formatAssemblySearchResult(searchResult);

          return {
            text: formatMessage(messages.postByLabel, {
              flow,
              workspaceName,
              author: authorId === currentMemberID ? 'self' : author,
            }),
            imgIcon: postIcon,
            type: 'response' as const,
            flowId,
            responseId,
            url: `/a?postId=${flowId}&commentId=${responseId}`,
          };
        }

        case 'post': {
          const {
            flow,
            authorId,
            author,
            postType,
            celebratedTeammate,
            celebratedTeammateId,
            postId,
          } = formatAssemblySearchResult(searchResult);

          let text = formatMessage(messages.postByLabel, {
            flow,
            workspaceName,
            author: authorId === userDetails.member.memberId ? 'self' : author,
          });

          if (postType === 'birthday') {
            text = formatMessage(messages.birthDayPostLabel, {
              flow,
              workspaceName,
              celebratedTeammate:
                celebratedTeammateId === currentMemberID
                  ? 'self'
                  : celebratedTeammate,
            });
          } else if (postType === 'anniversary') {
            text = formatMessage(messages.anniversaryDayPostLabel, {
              flow,
              author,
              workspaceName,
              celebratedTeammate:
                celebratedTeammateId === currentMemberID
                  ? 'self'
                  : celebratedTeammate,
            });
          }

          return {
            text,
            imgIcon: postIcon,
            type: 'post' as const,
            postId,
            url: `/a?postId=${postId}`,
          };
        }
      }
    },
    [
      currentMemberID,
      formatMessage,
      userDetails.assembly.workspaceSlugPath,
      userDetails.member.memberId,
      workspaceName,
      workspaceSlugPath,
    ]
  );
}
