import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  inLabel: {
    defaultMessage: 'In',
    id: 'aAz+kd',
  },
  fromLabel: {
    defaultMessage: 'From',
    id: 'dM+p3/',
  },
  postedByLabel: {
    defaultMessage: 'Posted by',
    id: 'cGCoJe',
  },
  mentionedLabel: {
    defaultMessage: 'Mentioned',
    id: 'ZtuNLP',
  },
  peopleLabel: {
    defaultMessage: 'people',
    id: 'JcjKNA',
  },
  placesLabel: {
    defaultMessage: 'places',
    id: '+FWruu',
  },
  typeLabel: {
    defaultMessage: 'Type',
    id: '+U6ozc',
  },
  contentTypeLabel: {
    defaultMessage: 'Content type',
    id: 'ufD5Jr',
  },
  postsAndRepliesLabel: {
    defaultMessage: 'Posts,Replies',
    id: 'ZcMbOF',
  },
  dateCreatedLabel: {
    defaultMessage: 'Date created',
    id: 'Yjk5Ow',
  },
  dueDateLabel: {
    defaultMessage: 'Due date',
    id: 'l3AfOI',
  },
  clearButtonText: {
    defaultMessage: 'Clear',
    id: '/GCoTA',
  },
  statusLabel: {
    defaultMessage: 'Status',
    id: 'tzMNF3',
  },
  statusesLabel: {
    defaultMessage: 'statuses',
    id: 'OPjnU7',
  },
  assigneeLabel: {
    defaultMessage: 'Assignee',
    id: 'vx8bv3',
  },
  departmentLabel: {
    defaultMessage: 'Department',
    id: 'eXcD4S',
  },
  departmentsLabel: {
    defaultMessage: 'departments',
    id: 'aJbskw',
  },
  titlesLabel: {
    defaultMessage: 'titles',
    id: 'dltHF6',
  },
  jobTitleLabel: {
    defaultMessage: 'Job Title',
    id: 'w7sjvQ',
  },
  workLocationLabel: {
    defaultMessage: 'Work Location',
    id: 'AELJFm',
  },
  homeLocationLabel: {
    defaultMessage: 'Home Location',
    id: 'B2o748',
  },
  fileTypeLabel: {
    defaultMessage: 'File type',
    id: 'PiZbK/',
  },
  fileTypesLabel: {
    defaultMessage: 'file types',
    id: 'UflIDd',
  },
  reportsToLabel: {
    defaultMessage: 'Reports to',
    id: 'rPDUOM',
  },
  roleLabel: {
    defaultMessage: 'Role',
    id: '1ZgrhW',
  },
  rolesLabel: {
    defaultMessage: 'roles',
    id: '7OKpco',
  },
  deactivated: {
    defaultMessage: '(deactivated)',
    id: 'osOx6w',
  },
  prefixLabel: {
    defaultMessage: 'Challenge',
    id: '9MidH4',
  },
  challengesLabel: {
    defaultMessage: '{count, plural, =1 {Challenge} other {Challenges}}',
    id: 'f2S8Po',
  },
  posts: {
    id: 'eR3YIn',
    defaultMessage: 'Posts',
  },
  replies: {
    id: '3/onCd',
    defaultMessage: 'Replies',
  },
  recognition: {
    id: 'sV4Od1',
    defaultMessage: 'Recognition',
  },
  birthday: {
    id: 'yngd9x',
    defaultMessage: 'Birthday',
  },
  anniversary: {
    id: '1MHEps',
    defaultMessage: 'Anniversary',
  },
});
