import {
  canUserDeletePost,
  config,
  copyToClipboard,
  getMemberDetailsFromUserDetails,
  type ReactionDetails,
} from '@assembly-web/services';
import {
  Replies,
  type Reply,
  type ToolbarItem,
  useDeviceInfo,
  useToastStore,
} from '@assembly-web/ui';
import { isAxiosError } from 'axios';
import { useContext } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useGetPostAndRepliesDetails } from '../../../../../hooks/useGetPostAndRepliesDetails';
import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useCommentReactionMutation } from '../../../../../hooks/useReactionMutation';
import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { ModalsContext } from '../../../contexts/ModalsContext';
import { useGetPostRepliesInfiniteQuery } from '../../../hooks/replies/useRepliesQueries';
import {
  trackReplyDrawerAction,
  trackReplyDrawerError,
} from '../../../services/analytics';
import { getFlowOrRecognitionPostLinkToCopy } from '../../../services/common';
import { ToolbarItemId } from '../../cards/utils';
import { useBoostOptions } from '../shared/hooks/useBoostOptions';

const messages = defineMessages({
  copiedToClipboard: {
    defaultMessage: 'Link successfully copied to clipboard',
    id: 'QHwY68',
  },
});

type RepliesContainerProps = {
  flowId: string;
  responseId: string;
  editingReplyId: string | null;
  focusedCommentID: string | null;
  onEditReplyClick: (replyId: string) => void;
  containerRef: React.MutableRefObject<HTMLElement | null>;
};

export function RepliesContainer({
  flowId,
  responseId,
  containerRef,
  editingReplyId,
  onEditReplyClick,
  focusedCommentID,
}: RepliesContainerProps) {
  const kind = flowId === 'recognition' ? 'POST' : 'RESPONSE';

  const { showSuccessToast } = useToastStore();
  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();
  const boostOptions = useBoostOptions({ flowId, responseId });

  const { formatMessage } = useIntl();

  const { onDeleteReplyOrPostClick, selectedPostOrReplyToDeleteDetails } =
    useContext(ModalsContext);

  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const toggleIsOverflowOpen = useMultiDrawerStore(
    (store) => store.toggleIsOverflowOpen
  );
  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const handleMenuItemClick = async (
    args: ToolbarItem & {
      cardId: string;
      isLegacyReply?: boolean;
    }
  ) => {
    const reply = replies.find((reply) => reply.cardId === args.cardId);
    switch (args.id) {
      case ToolbarItemId.CopyReplyLink:
        {
          const baseUrl = config.domains.app;
          const linkToCopy = getFlowOrRecognitionPostLinkToCopy({
            commentId: args.cardId,
            type: kind === 'POST' ? 'post' : 'flow',
            postId: kind === 'POST' ? responseId : '',
            flowId: kind === 'RESPONSE' ? flowId : '',
            responseId: kind === 'RESPONSE' ? responseId : '',
          });
          await copyToClipboard(`${baseUrl}${linkToCopy}`);
          trackReplyDrawerAction('replyLinkCopied', {
            isAnonymousReply: reply?.memberDetails.isAnonymous,
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
            flowId: flowId,
            isRecognition: kind === 'POST',
            flowName: flowResponse?.flow.name ?? 'Recognition',
          });
          showSuccessToast(formatMessage(messages.copiedToClipboard));
        }
        break;
      case ToolbarItemId.DeleteReply:
        {
          const node = replies.find((reply) => reply.cardId === args.cardId);
          const hasBoostNode = node?.messageContent.includes('boost-node');

          onDeleteReplyOrPostClick({
            replyId: args.cardId,
            flowId,
            responseId,
            isLegacyReply: args.isLegacyReply,
            hasTrophies: hasBoostNode,
          });
          trackReplyDrawerAction('replyDeleted', {
            flowId: flowId,
            isRecognition: kind === 'POST',
            flowName: flowResponse?.flow.name ?? 'Recognition',
            isAnnouncement: Boolean(
              flowResponse?.activeAnnouncement?.announcementId
            ),
            isAnonymousReply: reply?.memberDetails.isAnonymous,
          });
        }
        break;
      case ToolbarItemId.EditReply:
        {
          onEditReplyClick(args.cardId);
        }
        break;
    }
  };

  const { currentUser, flowResponse, postCardRepliesDetails } =
    useGetPostAndRepliesDetails({
      flowId,
      responseId,
    });

  const reactionMutate = useCommentReactionMutation({
    currentUser: getMemberDetailsFromUserDetails(currentUser.member),
    onError: (error: unknown) => {
      const errorObj = isAxiosError(error) ? error : undefined;
      trackReplyDrawerError({
        errorType:
          errorObj?.response?.data?.message ?? 'Error on reply reaction update',
      });
    },
  });

  const handleReactionClick = (emoji: ReactionDetails, reply: Reply) => {
    const hasCurrentUserReactedToTheSelectedEmoji = reply.reactions?.some(
      (reaction) =>
        reaction.name === emoji.name &&
        reaction.members.some(
          (member) => member.memberID === currentUser.member.memberId
        )
    );
    const userAction = hasCurrentUserReactedToTheSelectedEmoji
      ? 'replyReactionRemoved'
      : 'replyReactionAdded';
    trackReplyDrawerAction(userAction, {
      flowId: flowId,
      isRecognition: kind === 'POST',
      isAnonymousReply: reply.memberDetails.isAnonymous,
      flowName: flowResponse?.flow.name ?? 'Recognition',
      isAnnouncement: Boolean(flowResponse?.activeAnnouncement?.announcementId),
    });
    reactionMutate({
      payload: {
        displayName: emoji.displayName,
        name: emoji.name,
        type: emoji.type,
        value: emoji.type,
      },
      action: hasCurrentUserReactedToTheSelectedEmoji ? 'unset' : 'set',
      commentId: reply.cardId,
      flowId,
      responseId,
    });
  };

  const {
    replies,
    isLoading,
    isFetchingNextPage,
    isError,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    fetchPreviousPage,
    isFetchingPreviousPage,
  } = useGetPostRepliesInfiniteQuery(
    {
      flowId,
      responseId,
      ...(focusedCommentID &&
        focusedCommentID !== 'viewLastReply' && {
          commentId: focusedCommentID,
        }),
    },
    { enabled: Boolean(flowResponse && !flowResponse.hideReplies) }
  );

  if (!flowResponse || flowResponse.hideReplies) {
    return null;
  }

  const getActiveCardId = () => {
    if (focusedCommentID == null) {
      return null; // when the user has clicked on "view post and replies" button
    }

    return focusedCommentID;
  };

  const handleResetScroll = () => {
    if (focusedCommentID) {
      const postDrawerId =
        flowId === 'recognition' ? responseId : `${flowId}-${responseId}`;
      findAndUpdateDrawerField(postDrawerId, (draft) => {
        if (draft.type === 'postsAndReplies') {
          draft.data = {
            ...draft.data,
            commentId: undefined,
          };
        }
      });
    }
  };

  const handleInternalLinkClick = () => {
    if (!isMobile) {
      return;
    }
    toggleIsOverflowOpen();
  };

  return (
    <Replies
      replies={replies}
      isError={isError}
      boostOptions={boostOptions}
      containerRef={containerRef}
      fetchNextPage={fetchNextPage}
      editingReplyId={editingReplyId}
      activeCardId={getActiveCardId()}
      hasNextPage={Boolean(hasNextPage)}
      isInitialLoading={isLoading}
      onMenuItemClick={handleMenuItemClick}
      onMemberClick={navigateToUserFeed}
      handleResetScroll={handleResetScroll}
      fetchPreviousPage={fetchPreviousPage}
      isFetchingNextPage={isFetchingNextPage}
      handleReactionClick={handleReactionClick}
      hasPreviousPage={Boolean(hasPreviousPage)}
      currentUserId={currentUser.member.memberId}
      postAuthor={flowResponse.respondent.firstName}
      currencyDetails={currentUser.assembly.currency}
      isFetchingPreviousPage={isFetchingPreviousPage}
      canDeleteReplies={canUserDeletePost(currentUser)}
      totalReplyCount={postCardRepliesDetails?.count ?? 0}
      selectedReplyToDelete={selectedPostOrReplyToDeleteDetails.replyId ?? null}
      handleInternalLinkClick={handleInternalLinkClick}
    />
  );
}
