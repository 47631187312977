import type { DrawerConfig } from '../configuration';
import type { ChatData, ChatDrawer as ChatDrawerProps } from '../types';
import { ChatDrawer } from '.';
import { ChatDrawerBody } from './ChatDrawerBody';
import { ChatDrawerHeader } from './ChatDrawerHeader';
import { ChatDrawerOverflowCard } from './ChatDrawerOverflowCard';

export const chatConfiguration = {
  allowMultipleInstance: false,
  body: ChatDrawerBody,
  drawer: ChatDrawer,
  header: ChatDrawerHeader,
  overflowCard: ChatDrawerOverflowCard,
  getId(props) {
    return props.memberId;
  },
} satisfies DrawerConfig<ChatDrawerProps, ChatData>;
