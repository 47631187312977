import { MemberState } from '@assembly-web/services';
import { AvatarSize, type CriteriaItemProps } from '@assembly-web/ui';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { CriteriaCardIconMap } from '../../services/shareSheetData';
import type { GetMembersForPreviewRequest as GetMembersForPreviewRequestForChallenge } from '../shareChallenge/usePreviewShareChallengeRules';
import type { GetMembersForPreviewRequest as GetMembersForPreviewRequestForCollection } from '../shareCollections/queries/usePreviewCriteriaQuery';
import {
  type GetMembersFromCriteriaJobResponse,
  type PreviewIdForCriteriaUnion,
  type PreviewMember,
  useGetMembersPreview,
  usePreviewIDForCriteria,
} from './queries/usePreviewCriteriaQuery';

const messages = defineMessages({
  subTitle: {
    defaultMessage: '{subTitle}',
    id: 'QEGm9T',
  },
  subtitleWithPermission: {
    defaultMessage: '{subTitle} • {permission}',
    id: 'wzFkTl',
  },
});

export const usePollPreviewAPIForMembers = ({
  previewCriteria,
  previewMemberFilter,
  enabled,
  ...rest
}: {
  previewCriteria:
    | GetMembersForPreviewRequestForCollection
    | GetMembersForPreviewRequestForChallenge;
  previewMemberFilter: {
    search: string | undefined;
    filter: string | undefined;
  };
  enabled: boolean;
} & PreviewIdForCriteriaUnion) => {
  const { formatMessage } = useIntl();

  const [jobId, setJobId] = useState<string | undefined>(undefined);

  const { data: getMembersResponse, isLoading: isLoadingGetPreviewJob } =
    usePreviewIDForCriteria({
      payload: previewCriteria,
      enabled,
      ...(rest.flow === 'collection'
        ? {
            flow: 'collection',
            ownerId: rest.ownerId,
          }
        : { flow: rest.flow }),
    });

  const { data, isPending, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useGetMembersPreview(jobId, previewMemberFilter, rest.flow);

  useEffect(() => {
    if (getMembersResponse) {
      setJobId(
        (getMembersResponse.data as GetMembersFromCriteriaJobResponse).previewId
          ? (getMembersResponse.data as GetMembersFromCriteriaJobResponse)
              .previewId
          : undefined
      );
    }
  }, [getMembersResponse]);

  const getPendingMemberAvatar = (member: PreviewMember) => {
    const icon = !member.state ? MemberState.Pending : MemberState.Active;
    return {
      icon: CriteriaCardIconMap[icon.toLowerCase()],
      iconBackgroundColor:
        !member.state || member.state === MemberState.Pending
          ? 'bg-gray-4'
          : '',
    };
  };

  const members: CriteriaItemProps[] =
    data?.pages.flatMap((page) =>
      page.data.map((member) => ({
        id: member.memberId ?? member.email,
        title: `${member.firstName} ${member.lastName ?? ''}`,
        subtitle:
          previewMemberFilter.filter === 'all'
            ? rest.flow === 'generic'
              ? formatMessage(messages.subTitle, { subTitle: member.email })
              : formatMessage(messages.subtitleWithPermission, {
                  subTitle: member.email,
                  permission: capitalize(member.meta?.permission ?? ''),
                })
            : member.email,
        ...(member.state === MemberState.Active
          ? {
              avatar: {
                image: member.image,
                memberID: member.memberId ?? member.email,
                name: `${member.firstName} ${member.lastName ?? ''}`,
                size: AvatarSize.ExtraLarge,
              },
            }
          : {
              customIcon: getPendingMemberAvatar(member),
            }),
      }))
    ) ?? [];

  return {
    data: members,
    totalMembersCount: data?.pages[0]?.total ?? 0,
    pendingMembersCount: data?.pages[0]?.metadata.pendingCount ?? 0,
    isLoading: isPending || isLoadingGetPreviewJob,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
};
