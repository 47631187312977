import type { AnnouncementsApiResponse } from '@assembly-web/services';
import type { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getAnnouncementsQuery } from '../queries/getAnnouncementsQuery';

export function useGetAnnouncementQuery(
  options?: Partial<
    UndefinedInitialDataInfiniteOptions<AnnouncementsApiResponse>
  >
) {
  return useInfiniteQuery<AnnouncementsApiResponse>(
    getAnnouncementsQuery(options)
  );
}
