import type { ImportantActivitiesApiResponse } from '@assembly-web/services';
import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

export function useDismissImportantActivityMutationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (cardId: string) => {
      await assemblyAPI.put(APIEndpoints.dismissImportantActivity(cardId));
    },

    onMutate: async (cardId: string) => {
      const queryCacheKey = ['importantCards', 'unreads'];
      const previousCards =
        queryClient.getQueryData<InfiniteData<ImportantActivitiesApiResponse>>(
          queryCacheKey
        );

      const updatedActivities = produce(previousCards, (draft) => {
        draft?.pages.forEach((page) => {
          page.data.data = page.data.data.map((card) => {
            if (card.cardId === cardId) {
              return {
                ...card,
                state: 'DISMISSED',
              };
            }
            return card;
          });
        });
      });

      queryClient.setQueryData(queryCacheKey, updatedActivities);
    },
  });
}
