import { postIcon } from '@assembly-web/assets';
import {
  canUserDeletePost,
  checkIfFlowPostHasTrophies,
  useSuspenseUserDetails,
  useUserDetails,
  withoutDefaultEventBehavior,
} from '@assembly-web/services';
import type { ToolbarItem } from '@assembly-web/ui';
import { Button, useAssemblyNavigate } from '@assembly-web/ui';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';

import { RichPostPreview } from '../../../../components/RichPostPreview';
import { getPostQuery } from '../../../../queries/getAssemblyPostQuery';
import { usePostEmbed } from '../../hooks/usePostEmbed';
import { Card } from './Card';
import { messages } from './messages';
import type { CommonCardProps } from './type';
import { getToolbarMenuItemsForResponseCard } from './utils';

export type FlowResponseCardProps = {
  flow: string;
  plainFlow: string;
  flowId: string;
  responseId: string;
  description: string;
  authorId: string;
  hasReplyDraft: boolean;
  author: undefined | string | string[];
  plainAuthor: string;
  onClick?: () => void;
  canShareAsAnnouncement: boolean;
  canEndAnnouncement: boolean;
  canEditAnnouncement: boolean;
  urlSlug: string;
  isReplyPreviewCard?: boolean;
} & CommonCardProps;

const cardType = 'response';

export function ResponseCard({
  flow,
  plainFlow,
  cardId,
  cardPosition,
  colorName,
  flowId,
  author,
  plainAuthor,
  onClick,
  isAdmin,
  isCollectionItem,
  isFirstItem,
  isLastItem,
  canEditCollection,
  authorId,
  responseId,
  workspaceName,
  currentUserId,
  hasReplyDraft,
  onToolbarMenuItemClick,
  canShareAsAnnouncement,
  searchTerm,
  sortMethod,
  trackCardActionEvent,
  postResponse,
  showPostInteractionSettings,
  hideReactions,
  hideReplies,
  canEditAnnouncement,
  canEndAnnouncement,
  lazyLoadPostDetailsOnScroll,
  onPostClick,
  workspaceSlugPath,
  urlSlug,
  disableNavigation,
  filter,
  isReplyPreviewCard,
}: FlowResponseCardProps) {
  const navigate = useAssemblyNavigate();
  const { formatMessage } = useIntl();
  const urlPath = `/a/${workspaceSlugPath}${urlSlug}`;

  const { cardDetailsContainerRef, cardWrapperRef, embed } = usePostEmbed({
    cardType: 'post',
    flowId,
    isNotification: false,
    responseId,
    searchTerm,
    sortMethod,
    urlPath: urlPath,
    postResponse,
    hasReplyDraft,
    lazyLoadPostDetailsOnScroll: Boolean(lazyLoadPostDetailsOnScroll),
    filter,
  });

  const {
    data: {
      assembly: {
        settings: {
          postPermission: {
            canHidePoints: { enabled: canHidePoints },
          },
        },
      },
    },
  } = useSuspenseUserDetails();

  const { data: postCardDetails } = useQuery({
    ...getPostQuery({
      flowId,
      responseId,
      currencyName: '',
      asEmbed: false,
    }),
    enabled: Boolean(lazyLoadPostDetailsOnScroll),
  });

  const icon = (
    <img src={postIcon} alt="" className="h-4 w-4 max-md:max-w-none" />
  );
  const title = formatMessage(messages.postByLabel, {
    flow,
    workspaceName,
    author: authorId === currentUserId ? 'self' : author,
  });

  const plainTitle = formatMessage(messages.postByLabel, {
    flow: plainFlow,
    workspaceName,
    author: authorId === currentUserId ? 'self' : plainAuthor,
  });

  const { data: userDetails } = useUserDetails();
  invariant(userDetails);

  const toolbarMenuItems =
    !isReplyPreviewCard &&
    getToolbarMenuItemsForResponseCard({
      formatMessage,
      isAuthor: currentUserId === authorId,
      isAdmin,
      isFollowingThread: false,
      isCollectionItem,
      isFirstItem,
      isLastItem,
      canEditCollection,
      canShareAsAnnouncement,
      canEditAnnouncement,
      canEndAnnouncement,
      canShowViewInsights:
        postResponse?.activeAnnouncement?.showInsights ??
        postCardDetails?.activeAnnouncement?.showInsights,
      showPostInteractionSettings: showPostInteractionSettings,
      canDeletePost: canUserDeletePost(userDetails) || postResponse?.canDelete,
      postNotificationPreference: {
        isMuted: postResponse?.isMuted ?? false,
      },
    });

  const onToolbarItemClick = (args: ToolbarItem): void => {
    onToolbarMenuItemClick?.({
      ...args,
      cardId,
      flowId,
      responseId,
      url: urlPath,
      cardIcon: icon,
      entityId: responseId,
      allowReplies: !hideReplies,
      allowReactions: !hideReactions,
      author: author as string,
      plainFlow,
      title: plainTitle,
      type: cardType,
      activeAnnouncement: lazyLoadPostDetailsOnScroll
        ? postCardDetails?.activeAnnouncement
        : postResponse?.activeAnnouncement,
      hasTrophies: postResponse?.responses
        ? checkIfFlowPostHasTrophies(postResponse.responses)
        : false,
      isMuted: postResponse?.isMuted ?? false,
      firstName: postResponse?.respondent?.firstName,
    });
    trackCardActionEvent?.(args.id, {
      cardType,
      cardPosition,
      flowId,
      flowName: plainFlow,
    });
  };

  if (!isCollectionItem) {
    const props = {
      analyticsProps: {
        cardType: 'post',
        isNotification: false,
        searchTerm,
        sortMethod,
        filter,
      },
      className: 'hover:shadow-base-down',
      flowId: flowId,
      hasReplyDraft: hasReplyDraft,
      responseId: responseId,
      urlPath: urlPath,
      postResponse: postResponse,
      lazyLoadPostDetailsOnScroll: Boolean(lazyLoadPostDetailsOnScroll),
      ...(toolbarMenuItems && {
        primaryToolbarItems: toolbarMenuItems.primaryToolbarItems,
      }),
      ...(toolbarMenuItems && {
        secondaryToolbarItems: toolbarMenuItems.secondaryToolbarItems,
      }),
      onToolbarMenuItemClick: onToolbarItemClick,
      isReplyPreviewCard: isReplyPreviewCard,
      canHidePoints,
    };
    return <RichPostPreview {...props} />;
  }

  return (
    <Card
      cardId={cardId}
      url={disableNavigation ? undefined : urlPath}
      icon={icon}
      text={title}
      connectedRight={
        <Button
          size="small"
          className="max-md:ml-0 max-md:mr-3"
          variation="secondaryLite"
          onClick={withoutDefaultEventBehavior(() => {
            if (!disableNavigation) {
              navigate(urlPath);
            } else {
              onPostClick?.();
            }
          })}
        >
          {formatMessage(messages.viewPostLabel)}
        </Button>
      }
      onToolbarItemClick={onToolbarItemClick}
      onClick={() => {
        onPostClick?.();
        onClick?.();
      }}
      {...toolbarMenuItems}
      isCollectionItem={isCollectionItem}
      colorName={colorName}
      detailsRef={cardDetailsContainerRef}
      preview={embed}
      wrappedRef={cardWrapperRef}
    />
  );
}
