import {
  IconButton,
  PopoutDrawer,
  TextStyle,
  useDeviceInfo,
} from '@assembly-web/ui';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import { type RefObject, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import { CloseDrawerContext } from '../../contexts/CloseDrawerContext';
import { trackPopoutAction } from '../../services/analytics';
import { OverflowMenuContent } from './OverflowMenu/Content';
import { OverflowMenuHeader } from './OverflowMenu/Header';
import { MoreToolbarMenu } from './OverflowMenu/MoreToolbarMenu';
import { DefaultToolbar } from './shared/DefaultToolbar';
import { DrawerHeader } from './shared/DrawerHeader';
import { accessDrawer } from './types';

const messages = defineMessages({
  label: {
    defaultMessage: 'Overflow Menu',
    id: '712Dad',
  },
});

function DrawerContent({
  drawerRef,
}: {
  drawerRef: RefObject<HTMLDivElement>;
}) {
  const drawer = useMultiDrawerStore((store) => store.getOverflowDrawers()[0]);

  return accessDrawer(drawer, drawer.type, (config, props) => {
    const ContentComponent = config.body;
    return <ContentComponent drawerRef={drawerRef} {...props} />;
  });
}

export function MultiViewDrawer() {
  const { formatMessage } = useIntl();
  const drawerRef = useRef<HTMLDivElement>(null);

  const { openModal: openConfirmationCloseModal } =
    useContext(CloseDrawerContext);

  const overflowDrawers = useMultiDrawerStore((store) =>
    store.getOverflowDrawers()
  );
  const shouldDisplayMVContent = useMultiDrawerStore(
    (store) => store.shouldDisplayMVContent
  );
  const setShouldDisplayMVContent = useMultiDrawerStore(
    (store) => store.setShouldDisplayMVContent
  );
  const deleteOverflowDrawer = useMultiDrawerStore(
    (store) => store.deleteOverflowDrawer
  );
  const isOverflowOpen = useMultiDrawerStore((store) => store.isOverflowOpen);
  const moveOverflowItemToTop = useMultiDrawerStore(
    (store) => store.moveOverflowItemToTop
  );
  const toggleIsOverflowOpen = useMultiDrawerStore(
    (store) => store.toggleIsOverflowOpen
  );
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showDelayedDisplayContent, setShowDelayedDisplayContent] =
    useState(false);
  const [shouldAnimateIn, setShouldAnimateIn] = useState(false);

  const { deviceType } = useDeviceInfo();
  const isMobile = deviceType === 'mobile';

  useEffect(() => {
    if (isMobile && isFullScreen) {
      setIsFullScreen(false);
    }
  }, [isMobile, isFullScreen]);

  const numAsText =
    overflowDrawers.length > 9 ? '9+' : overflowDrawers.length.toString();

  useEffect(() => {
    if (shouldDisplayMVContent) {
      if (shouldAnimateIn) {
        setTimeout(() => {
          setShowDelayedDisplayContent(true);
          setShouldAnimateIn(false);
        }, 400);
      } else {
        setShowDelayedDisplayContent(true);
      }
    } else {
      setShowDelayedDisplayContent(false);
    }
  }, [shouldDisplayMVContent, shouldAnimateIn]);

  const shouldShowDotNotification = useMultiDrawerStore((store) =>
    store.getOverflowDrawers().some((drawer) => drawer.hasNewActivity)
  );

  const hasWarningMessage = useMultiDrawerStore((store) =>
    store
      .getOverflowDrawers()
      .some(
        (drawer) =>
          drawer.hasNewActivity && drawer.newActivityType === 'warning'
      )
  );

  if (overflowDrawers.length === 0) {
    return;
  }

  const onOverflowCardClick = (id: string) => {
    setShouldDisplayMVContent(true);
    setShouldAnimateIn(true);
    moveOverflowItemToTop(id);
  };

  if (overflowDrawers.length === 0) {
    return;
  }

  const backContent =
    overflowDrawers.length > 1 && showDelayedDisplayContent ? (
      <div className="flex flex-row items-center">
        <IconButton
          className="mr-1 bg-transparent focus:bg-gray-8 focus:ring-primary-4 focus:ring-offset-gray-9 enabled:hover:bg-gray-8"
          onClick={() => setShouldDisplayMVContent(false)}
          variation="tertiaryLite"
          size="xSmall"
        >
          <ArrowLeftIcon className="h-4 w-4 text-[--foreground] group-hover:text-[--foreground-alt] group-focus:text-[--foreground-alt]" />
        </IconButton>
        <TextStyle
          variant="xs-regular"
          className="mr-4 flex h-4 w-4 items-center justify-center rounded-3xl bg-gray-8 text-gray-1"
        >
          {numAsText}
        </TextStyle>
      </div>
    ) : undefined;

  const onOpenChange = (isNewOpen: boolean) => {
    const { type } = overflowDrawers[0];

    if (overflowDrawers.length > 1) {
      setShouldDisplayMVContent(false);
    }
    if (isOverflowOpen) {
      if (shouldDisplayMVContent) {
        trackPopoutAction('collapsePopoutClicked', {
          popoutType: overflowDrawers[0].type,
        });
      } else {
        trackPopoutAction('collapseOverflowClicked');
      }
    } else {
      if (shouldDisplayMVContent) {
        trackPopoutAction('expandPopoutClicked', {
          popoutType: overflowDrawers[0].type,
        });
      } else {
        trackPopoutAction('expandOverflowClicked', {
          numPopouts: overflowDrawers.length,
        });
      }
    }
    toggleIsOverflowOpen();

    if (shouldDisplayMVContent) {
      accessDrawer(overflowDrawers[0], type, (config, args) => {
        const onOpenChange = config.onOpenChange;
        onOpenChange?.(args, isNewOpen);
      });
    }
  };

  const onClose = () => {
    const { id, type } = overflowDrawers[0];

    accessDrawer(overflowDrawers[0], type, (config, args) => {
      if (config.shouldConfirmOnClose?.(args)) {
        openConfirmationCloseModal(args);
      } else {
        deleteOverflowDrawer(id);
        config.onClose?.(args);
      }
    });

    trackPopoutAction('exitPopoutClicked', {
      popoutType: type,
    });
  };

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      trackPopoutAction('fullScreenModalClicked', {
        popoutType: overflowDrawers[0].type,
      });
    }
    setIsFullScreen(!isFullScreen);
  };

  const displayOverflowMenu = !(
    showDelayedDisplayContent || overflowDrawers.length === 1
  );

  return (
    <PopoutDrawer
      newActivityType={hasWarningMessage ? 'warning' : 'info'}
      hasNewActivity={shouldShowDotNotification}
      ref={drawerRef}
      leftHeaderContent={backContent}
      header={
        showDelayedDisplayContent || overflowDrawers.length === 1 ? (
          <DrawerHeader {...overflowDrawers[0]} />
        ) : (
          <OverflowMenuHeader isMultiView />
        )
      }
      label={formatMessage(messages.label)}
      isOpen={isOverflowOpen}
      isFullScreen={isFullScreen}
      onOpenChange={onOpenChange}
      toolbar={
        <>
          <DefaultToolbar
            onCollapse={() => onOpenChange(false)}
            isOpen={isOverflowOpen}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
            onClose={onClose}
            hideCloseButton={displayOverflowMenu}
          />
          {Boolean(displayOverflowMenu) && <MoreToolbarMenu />}
        </>
      }
      transparentHeader={
        Boolean(shouldDisplayMVContent) &&
        overflowDrawers[0].type === 'flowCreation'
      }
    >
      <div className="relative h-full overflow-hidden">
        <OverflowMenuContent onOverflowCardClick={onOverflowCardClick} />
        <AnimatePresence>
          {Boolean(shouldDisplayMVContent) && (
            <motion.div
              key="drawer-content"
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              initial={Boolean(shouldAnimateIn) && { x: '100%' }}
              transition={{
                duration: 0.4,
              }}
              className="absolute top-0 h-full w-full bg-gray-1"
            >
              <DrawerContent drawerRef={drawerRef} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </PopoutDrawer>
  );
}
