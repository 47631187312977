import {
  type AssemblySearchResult,
  type PlanFeaturesAPIResponse,
  RewardType,
  SwagCardStatuses,
} from '@assembly-web/services';
import { defineMessages, type IntlShape } from 'react-intl';

import type { RewardsRedemptionModalMessagePayload } from '../components/RewardCardRedemptionModal';

const messages = defineMessages({
  selectReward: {
    defaultMessage: 'Select reward',
    id: 'RlbE4+',
  },
  comingSoon: {
    defaultMessage: 'Coming soon',
    id: 'e61Jf3',
  },
  outOfStock: {
    defaultMessage: 'Out of Stock',
    id: 'hiBcId',
  },
  noRewardsSetUpGiftCardCharityDescription: {
    defaultMessage:
      'On any paid plan, you’re able to offer a wide range of gift cards and charities to suit all of your employees interests! Not on a paid plan yet?',
    id: 'jiASzm',
  },
  noRewardsSetUpSwagDescription: {
    defaultMessage:
      'Assembly has partnered with Axomo to cover all of your company’s swag design and distribution needs. Get them set up and have your organization redeem swag with points! Not on a paid plan yet?',
    id: 'yWE0fD',
  },
  noRewardsSetUpCultureDescription: {
    defaultMessage:
      'Culture rewards provide you the flexibility to create rewards outside the scope of our gift card and charity catalog. Want to create some?',
    id: 'z7xi/f',
  },
});

/**
 * Converts a path with params to a path with query params to support v3 redirection.
 * @param basePath the base path to use. example: `/discover` for v3
 * @param paramMappings a map of the param name to the index of the param value in the path
 *
 *  **example:**
 * `const paramMappings = {
 filter: 0,
 tab: 1,
 };`
 * @param path the path to convert
 * @param splitAfter defaults to '/' but can be used to split the path after a certain string. **example:** `workspaceSlugPath` in v3
 * @returns {string} the converted path
 */
export function mapV2ToV3RewardsRoute(
  basePath: string,
  paramMappings: Record<string, number>,
  path: string,
  splitAfter?: string
): string {
  const rewardTypeMap: Record<string, RewardType> = {
    'my-rewards': RewardType.MyRewards,
    'gift-cards': RewardType.GiftCards,
  };
  const queryParams: Record<string, string> = {};

  const splittedPath = splitAfter ? (path.split(splitAfter)[1] ?? []) : path;

  if (!splittedPath) return path;

  const paramValues = splittedPath.split('/').filter(Boolean);

  if (paramValues.length > 1 && paramValues[1] in rewardTypeMap) {
    paramValues[1] = rewardTypeMap[paramValues[1]];
  }

  for (const paramName in paramMappings) {
    const paramIndex = paramMappings[paramName];
    if (paramIndex < paramValues.length) {
      let paramValue = paramValues[paramIndex];

      if (paramName === 'tab' && paramValue in rewardTypeMap) {
        paramValue = rewardTypeMap[paramValue];
      }

      queryParams[paramName] = paramValue;
    }
  }
  const searchParams = new URLSearchParams(queryParams).toString();
  return `${basePath}?${searchParams}`;
}

const getRewardsTypeFromTags = (tag: string) => {
  if (tag === 'gift card') return RewardType.GiftCards;
  else if (tag === 'donation') return RewardType.Charities;
  else if (tag === 'cash equivalent') return RewardType.CashEquivalent;
  else return RewardType.GiftCards;
};

/**
 * Gets the formatted payload to be passed inside the Rewards Redemption Modal
 * @param result Individual search result from getUserFeed/searchIndex API
 */
export function getRedemptionPayload(
  result: AssemblySearchResult
): RewardsRedemptionModalMessagePayload | null {
  if (result.type === 'tangoReward') {
    return {
      brandKey: result._meta.cardDetails.brandKey,
      rewardType: getRewardsTypeFromTags(result.tags[0]),
      brandName: result._meta.cardDetails.brandName,
      currencyCode: result._meta.cardDetails.items[0].currencyCode,
      items: result._meta.cardDetails.items,
      image: result._meta.cardDetails.imageUrls
        ? result._meta.cardDetails.imageUrls['278w-326ppi']
        : '',
      description: result._meta.cardDetails.terms,
      denominations: result._meta.cardDetails.denominations,
    };
  } else if (result.type === 'customReward') {
    return {
      rewardType: RewardType.Culture,
      isActive: true,
      name: result._meta.name,
      description: result._meta.description,
      pointsToRedeem: result._meta.pointsToRedeem.toString(),
      id: result.id,
      image: result._meta.image?.original.relativeUrl ?? '',
    };
  } else if (result.type === 'swag') {
    return {
      rewardType: RewardType.Swag,
      id: result.id,
      kind: result._meta.details.kind,
      name: result.name,
      image: result._meta.details.image,
      colors: result._meta.details.colors,
      sizes: result._meta.details.sizes,
      pointsToRedeem: result._meta.details.points[0]?.toString(),
      description: result._meta.details.desc,
      swagId: result._meta.details.uid,
      storeUID: result._meta.details.storeUID,
      status: result._meta.details.status,
    };
  }
  return null;
}

export const getSwagRewardCardButtonText = (
  status: SwagCardStatuses,
  formatMessage: IntlShape['formatMessage']
) => {
  switch (status) {
    case SwagCardStatuses.REDEEMABLE:
      return formatMessage(messages.selectReward);
    case SwagCardStatuses.COMINGSOON:
      return formatMessage(messages.comingSoon);
    case SwagCardStatuses.OUTOFSTOCK:
      return formatMessage(messages.outOfStock);
    default:
      return formatMessage(messages.selectReward);
  }
};

export const parseCommaSeparatedNumber = (value: string) => {
  if (!value) return 0;
  return parseInt(value.replace(/,/g, ''), 10);
};

export const getRewardType = () => {
  let rewardType = new URLSearchParams(window.location.search).get('tab');
  if (rewardType === RewardType.GiftCards) return 'gift cards';
  if (rewardType === RewardType.Culture) return 'culture rewards';
  if (rewardType === RewardType.Swag) return 'swag';
  if (rewardType === RewardType.Charities) return 'charities';
  if (rewardType === RewardType.Discounts) return 'discounts';
  if (rewardType === RewardType.Amazon) return 'amazon';
};

export const isRewardDisabledForPlan = (
  planFeatureDetails?: PlanFeaturesAPIResponse
) => {
  const rewardType = getRewardType();
  const rewardTypeFeature = (id: string) =>
    planFeatureDetails?.features.find((feature) => feature.id === id);

  if (
    rewardType === 'gift cards' &&
    rewardTypeFeature('GIFT_CARD_REWARDS')?.status === 'DISABLED'
  )
    return true;
  if (
    rewardType === 'charities' &&
    rewardTypeFeature('CHARITY_REWARDS')?.status === 'DISABLED'
  )
    return true;
  if (
    rewardType === 'swag' &&
    rewardTypeFeature('ALLOW_REWARDS_PROVIDER')?.status === 'DISABLED'
  )
    return true;
  if (
    rewardType === 'culture rewards' &&
    rewardTypeFeature('CULTURE_REWARDS')?.status === 'DISABLED'
  )
    return true;
  if (
    rewardType === 'amazon' &&
    rewardTypeFeature('ALLOW_AMAZON_BUSINESS')?.status === 'DISABLED'
  )
    return true;

  return false;
};

export const getNotSetupDescriptionForReward = (
  formatMessage: IntlShape['formatMessage']
) => {
  let rewardType = new URLSearchParams(window.location.search).get('tab');
  if (
    rewardType === RewardType.GiftCards ||
    rewardType === RewardType.Charities
  )
    return formatMessage(messages.noRewardsSetUpGiftCardCharityDescription);
  if (rewardType === RewardType.Swag)
    return formatMessage(messages.noRewardsSetUpSwagDescription);
  return formatMessage(messages.noRewardsSetUpCultureDescription);
};
