import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

type Data = {
  flowId: string;
};

export const useEndOccurrence = (
  options?: UseMutationOptions<unknown, unknown, Data>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: ({ flowId }) => {
      return assemblyAPI.post(APIEndpoints.endOccurrence(flowId));
    },
    onSuccess(data, variables, context) {
      document.dispatchEvent(new CustomEvent('invalidate-flow-details'));
      queryClient.invalidateQueries({
        queryKey: ['flowDetails', variables.flowId, 'builder'],
        type: 'all',
      });

      options?.onSuccess?.(data, variables, context);
    },
  });
};
