import { EditorLoader } from '@assembly-web/ui';

import { useEditorDataContext } from '../../context/EditorDataContext';
import { useFlowSchedule } from '../../hooks/useFlowSchedule';
import { useReminderSchedule } from '../../hooks/useReminderSchedule';
import { DeadlineSettings } from './DeadlineSettings';
import { DistributionConfiguration } from './DistributionConfiguration';
import { FlowResponseSettings } from './FlowResponseSettings';
import { RemindersSettings } from './RemindersSettings';
import { ResponseFrequencySettings } from './ResponseFrequencySettings';

export function Distributions() {
  const { isLoading } = useEditorDataContext();

  useFlowSchedule();
  useReminderSchedule();

  if (isLoading) {
    return <EditorLoader className="h-8 w-28" />;
  }

  return (
    <DistributionConfiguration.Root>
      <FlowResponseSettings />
      <DeadlineSettings />
      <ResponseFrequencySettings />
      <RemindersSettings />
    </DistributionConfiguration.Root>
  );
}
