import { usePusherChannel, usePusherEvents } from '@assembly-web/pusher';
import type { AssemblySearchResult } from '@assembly-web/services';
import { logger, useUserDetails } from '@assembly-web/services';
import type { InfiniteData, QueryClient } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { never, z } from 'zod';

const REACTION_UPDATE_EVENTS = [
  'POST_REACTION_CHANGED',
  'COMMENT_REACTION_CHANGED',
] as const;

const parseEvent = z.enum(REACTION_UPDATE_EVENTS);

const parsePost = z.object({
  flowId: z.string().optional(),
  responseId: z.string().optional(),
  postId: z.string().optional(),
  commentId: z.string().optional(),
});

const parseAction = z.object({
  action: z.enum(['SET', 'UNSET']),
});

const parseMember = z.object({
  fromMember: z.object({
    firstName: z.string(),
    lastName: z.string(),
    memberId: z.string(),
  }),
});

const parseReaction = z.object({
  reaction: z.object({
    displayName: z.string(),
    name: z.string(),
    type: z.string(),
    value: z.string(),
  }),
});

const postReaction = z.object({
  data: z
    .object({})
    .merge(parseAction)
    .merge(parsePost)
    .merge(parseMember)
    .merge(parseReaction),
});

async function invalidateQueryCache<
  TQueryData extends InfiniteData<
    | { data: { data: AssemblySearchResult[] } }
    | { data: AssemblySearchResult[] }
  >,
  TParsedData extends z.infer<typeof postReaction>,
>(queryKey: string[], queryClient: QueryClient, parsedData: TParsedData) {
  return Promise.all(
    queryClient
      .getQueryCache()
      .findAll({
        queryKey,
      })
      .map((query) => query.queryKey)
      .map(async (queryKey) => {
        const searchData = queryClient.getQueryData<TQueryData>(queryKey);

        if (!searchData) {
          return Promise.resolve();
        }

        return Promise.all(
          searchData.pages.map(async (page) => {
            const data = 'data' in page.data ? page.data.data : page.data;
            if (!data.length) {
              return Promise.resolve();
            }

            if (
              data.some(
                (result) =>
                  result.cardDetails?.responseId ===
                    parsedData.data.responseId ||
                  result.cardDetails?.post?.postID === parsedData.data.postId
              )
            ) {
              await queryClient.cancelQueries({
                queryKey: queryKey,
                type: 'all',
              });
              // TODO: add maxPages size
              await queryClient.refetchQueries({
                queryKey,
              });
            }
          })
        );
      })
  );
}

export function usePostReactionsUpdatesEvents() {
  const queryClient = useQueryClient();

  const { data: userDetails } = useUserDetails();

  const assembly = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvents(
    assembly,
    REACTION_UPDATE_EVENTS as unknown as string[],
    async (data) => {
      try {
        const event = parseEvent.parse(data.eventName);
        const parsedData = postReaction.parse(data);
        const isReactionUpdatedByCurrentMember =
          parsedData.data.fromMember.memberId === userDetails?.member.memberId;

        if (
          isReactionUpdatedByCurrentMember &&
          document.visibilityState === 'visible'
        ) {
          return;
        }

        if (event === 'POST_REACTION_CHANGED') {
          await invalidateQueryCache(
            ['searchResults'],
            queryClient,
            parsedData
          );

          await invalidateQueryCache(['userFeed'], queryClient, parsedData);

          if (
            (parsedData.data.flowId && parsedData.data.responseId) ||
            parsedData.data.postId
          ) {
            const queryKey = [
              'assemblyFlowPost',
              parsedData.data.flowId ?? 'recognition',
              parsedData.data.responseId ?? parsedData.data.postId,
            ];
            await queryClient.cancelQueries({
              queryKey: queryKey,
              type: 'all',
            });
            await queryClient.invalidateQueries({
              queryKey: queryKey,
              type: 'all',
            });
          }
        }
        if (event === 'COMMENT_REACTION_CHANGED') {
          const repliesQueryKey = [
            'replies',
            parsedData.data.flowId ?? 'recognition',
            parsedData.data.responseId ?? parsedData.data.postId,
          ];
          queryClient.cancelQueries({
            queryKey: repliesQueryKey,
            type: 'all',
          });
          queryClient.invalidateQueries({
            queryKey: repliesQueryKey,
            type: 'all',
          });
        }
      } catch (e) {
        logger.error(
          'Error in usePusherForPostReactions.ts',
          e instanceof Error ? e : never
        );
      }
    }
  );
}
