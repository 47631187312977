import { QuickParticipation } from '@assembly-web/ui';

import { PointsStatus } from './PointsStatus';
import { SubmitButton } from './SubmitButton';
import { VisibilitySelector } from './VisibilitySelector';

export function Footer() {
  return (
    <QuickParticipation.Footer.Container>
      <PointsStatus />
      <QuickParticipation.Footer.ActionRow>
        <VisibilitySelector />
        <SubmitButton />
      </QuickParticipation.Footer.ActionRow>
    </QuickParticipation.Footer.Container>
  );
}
