import type {
  AssistantHistoryItem,
  DoraReportingCategory,
} from '@assembly-web/services';
import { config, useUserDetails } from '@assembly-web/services';
import { TextStyle } from '@assembly-web/ui';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import dayjs, { duration } from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { type ReactNode, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import ScrollToBottom from 'react-scroll-to-bottom';
import { twJoin, twMerge } from 'tailwind-merge';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { useGetDoraChatHistoryQuery } from '../../../hooks/dora/useChatHistoryQuery';
import { useDoraChatRestrictions } from '../../../hooks/dora/useDoraChatRestrictions';
import { useDoraSavedPrompt } from '../../../hooks/dora/useDoraSavedPrompt';
import { trackDoraAction } from '../../../services/analytics';
import { convertMarkDownResponseToHTML } from '../../../services/dora';
import { setFullScreenDrawerId } from '../../../services/drawers';
import { isAdminMember } from '../../../services/member';
import {
  type createDoraDrawerStore,
  getDoraChatStore,
  getPersistKeyForDoraChatDrawer,
} from '../../../stores/doraChatStore';
import { ChatHistoryError } from '../shared/dora/ChatHistoryError';
import { ChatHistoryLoading } from '../shared/dora/ChatHistoryLoading';
import { DoraMessageBubble } from '../shared/dora/DoraMessageBubble';
import { DoraStackedMessages } from '../shared/dora/DoraStackedMessages';
import type { DoraChatDrawer as DoraChatDrawerProps } from '../types';
import { ChatViewSidebar } from './ChatViewSidebar';
import { DoraChatBottomSheet } from './DoraChatBottomSheet';
import { DoraChatReportAnswer } from './DoraChatReportAnswer';
import { DoraChatReportPrompt } from './DoraChatReportPrompt';
import { IntroChatMessage } from './IntroChatMessage';
import { PromptLimitBanner } from './PromptLimitBanner';
import { QuestionAnswerBlock } from './QuestionAnswerBlock';
import { useDoraReportingSidebar } from './ReportingCollections';
import { ReportingInsightsChatHeader } from './ReportingInsightsChatHeader';
import { ReportingUpgradeBanner } from './ReportingUpgradeBanner';

dayjs.extend(duration);

const messages = defineMessages({
  newDoraChatDrawerTitle: {
    defaultMessage: 'DoraAI Reporting',
    id: 'eE3c8F',
  },
  restartConversationPlaceholder: {
    defaultMessage:
      'This conversation’s data needs to be refreshed. <button>Start new conversation</button>',
    id: 'NJ+F8H',
  },
  savedPromptTextTitle: {
    defaultMessage: 'You have saved an analysis',
    id: 'BA6Kuo',
  },
  savedPromptTextDescription: {
    defaultMessage:
      'You can now generate this report any time from your Saved prompts',
    id: '8diE8O',
  },
});

export function ReportingInsightsChatView(
  props: DoraChatDrawerProps & {
    doraChatStore: ReturnType<typeof createDoraDrawerStore>;
    getDoraResponse: (prompt: string, threadId?: string) => void;
    reportingCategory: DoraReportingCategory;
    shouldAnimateIntroMessage: boolean;
  }
) {
  const {
    doraChatStore,
    getDoraResponse,
    id,
    isFullScreen,
    reportingCategory,
    shouldAnimateIntroMessage,
  } = props;

  const isSidebarOpen = useDoraReportingSidebar((state) => state.isSidebarOpen);
  const setIsSidebarOpen = useDoraReportingSidebar(
    (state) => state.setIsSidebarOpen
  );

  const { formatMessage } = useIntl();

  const closeDrawer = useMultiDrawerStore((store) => store.closeDrawer);
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const findDrawer = useMultiDrawerStore((store) => store.findDrawer);
  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);

  const useDoraChatStore = doraChatStore;

  const isStreaming = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isStreaming ?? false
  );

  const isLoading = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isLoading ?? false
  );

  const isLoadingSeen = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isLoadingSeen ?? false
  );

  const isAnswerSeen = useDoraChatStore(
    (store) => store.reportingInsights?.recentBlock?.isAnswerSeen ?? false
  );

  const [isResponseBubbleVisible, setIsResponseBubbleVisible] = useState(
    isLoadingSeen || isAnswerSeen
  );

  const curPrompt = useDoraChatStore(
    (state) => state.reportingInsights?.recentBlock?.prompt
  );

  const threadId = useDoraChatStore(
    (store) => store.reportingInsights?.threads?.[0].id ?? ''
  );

  const {
    data: chatHistoryData,
    isError: isChatHistoryError,
    isLoading: isChatHistoryLoading,
    refetch: refetchChatHistory,
  } = useGetDoraChatHistoryQuery(threadId);

  const [showSavedPromptToast, setShowSavedPromptToast] = useState(false);

  const { mutate: savePrompt, isPending: isSavingPrompt } = useDoraSavedPrompt({
    onSuccess: () => {
      setShowSavedPromptToast(true);
      setTimeout(() => setShowSavedPromptToast(false), 5000);
    },
  });

  const [currentMessageIDBeingSaved, setCurrentMessageIDBeingSaved] =
    useState<string>();

  const [showUpgradeBanner, setShowUpgradeBanner] = useState(false);

  const {
    isLastMessagePastCutOffTime,
    isLimitedReportingExperience,
    refetchRestrictionDetails,
    hasReachedConversationLimit,
    remainingConversationsForMonth,
    remainingPrompts,
    currentConversationCount,
    totalConversationsForMonth,
  } = useDoraChatRestrictions({ threadId });

  const { data: userDetails } = useUserDetails();

  if (!userDetails) {
    return null;
  }

  function changeCategory(category: DoraReportingCategory) {
    closeDrawer(props.id);

    const drawerId = createDrawer({
      type: 'doraChat',
      title: formatMessage(messages.newDoraChatDrawerTitle),
      data: {},
    });

    const drawer = findDrawer(drawerId);

    if (drawer) {
      const persistKey = getPersistKeyForDoraChatDrawer(drawer);

      const { doraChatStore: newStore } = getDoraChatStore(persistKey);
      newStore.getState().setReportingCategory(category);
      setFullScreenDrawerId(drawerId);
    }
  }

  const isAdmin = isAdminMember(userDetails.member);

  let scrollContent;

  if (isChatHistoryLoading) {
    scrollContent = <ChatHistoryLoading />;
  } else if (isChatHistoryError) {
    scrollContent = (
      <ChatHistoryError
        onTryAgain={() => {
          refetchChatHistory();
          refetchRestrictionDetails();
        }}
      />
    );
  } else {
    const chatBubbles = [];
    const chatMessages = chatHistoryData ?? [];

    for (let i = 0; i < chatMessages.length; i++) {
      const message = chatMessages[i];

      if (message.role === 'user') {
        // To address the edge case that the user manually refreshes the page before Dora's full answer is returned,
        // only show user messages that have a corresponding Dora answer from chat history.
        if (chatMessages[i + 1]?.role === 'assistant') {
          chatBubbles.push(
            <DoraChatReportPrompt
              memberDetails={userDetails.member}
              prompt={message.content}
              shouldAnimate={false}
            />
          );
        }
      } else {
        const responses: AssistantHistoryItem[] = [message];

        while (i + 1 < chatMessages.length) {
          const nextMessage = chatMessages[i + 1];

          if (nextMessage.role === 'assistant') {
            responses.push(nextMessage);
            i++;
          } else {
            break;
          }
        }

        let markdownContent: string[] = [];

        const htmlMessages = responses.map((response) => {
          let fullContent = response.content;

          if (response.imageUrl) {
            if (fullContent) {
              fullContent += '\n\n';
            }

            fullContent += `![](${config.domains.doraApi}${response.imageUrl})`;
          }

          markdownContent.push(fullContent);

          return convertMarkDownResponseToHTML(fullContent, {
            showCursor: false,
            showImageBanner: true,
          }) as string;
        });

        chatBubbles.push(
          <DoraChatReportAnswer
            reportingCategory={reportingCategory}
            showFeedbackControl={!isStreaming}
            promptId={message.promptId}
            rawResponse={markdownContent.join('\n\n')}
            htmlResponses={htmlMessages}
            key={`${props.id}-Assistant-${i}`}
            isAnswerSeen={false}
            isSavedPrompt={message.isSavedPrompt}
            isSavingPrompt={
              currentMessageIDBeingSaved === message.messageId && isSavingPrompt
            }
            isLoading={false}
            shouldAnimate={false}
            onSavePrompt={() => {
              setCurrentMessageIDBeingSaved(message.messageId);
              savePrompt({ threadId, messageId: message.messageId });
            }}
          />
        );
      }
    }

    scrollContent = (
      <>
        <div className="relative flex h-full flex-col justify-end overflow-auto antialiased">
          {isLimitedReportingExperience &&
          showUpgradeBanner &&
          reportingCategory !== 'flow_responses' ? (
            <ReportingUpgradeBanner
              isFullScreen={Boolean(isFullScreen)}
              closeBanner={() => setShowUpgradeBanner(false)}
            />
          ) : (
            <ScrollToBottom
              className="overflow-auto"
              followButtonClassName="hidden"
              initialScrollBehavior="auto"
            >
              <section className="grid grid-cols-[max-content_minmax(0,1fr)] gap-x-2 gap-y-4 overflow-auto p-6">
                <IntroChatMessage
                  isAdmin={isAdmin}
                  reportingCategory={reportingCategory}
                  shouldAnimate={shouldAnimateIntroMessage}
                  userDetails={userDetails}
                  drawerId={props.id}
                />
                {chatBubbles}
                {Boolean(isLastMessagePastCutOffTime) &&
                  !hasReachedConversationLimit && (
                    <DoraStackedMessages shouldAnimateAvatar={false}>
                      <DoraMessageBubble>
                        <TextStyle subdued className="select-none">
                          {formatMessage(
                            messages.restartConversationPlaceholder,
                            {
                              button: (elem: ReactNode) => (
                                <button
                                  className="cursor-pointer underline"
                                  onClick={() => {
                                    trackDoraAction('clickedTimeoutStartNew');
                                    changeCategory(reportingCategory);
                                  }}
                                >
                                  {elem}
                                </button>
                              ),
                            }
                          )}
                        </TextStyle>
                      </DoraMessageBubble>
                    </DoraStackedMessages>
                  )}
                {Boolean(curPrompt) && (
                  <QuestionAnswerBlock
                    reportingCategory={reportingCategory}
                    doraChatStore={doraChatStore}
                    getDoraResponse={getDoraResponse}
                    isFullScreen={isFullScreen}
                    isResponseBubbleVisible={isResponseBubbleVisible}
                    member={userDetails.member}
                  />
                )}
              </section>
              {Boolean(
                isLimitedReportingExperience &&
                  !showUpgradeBanner &&
                  !isLastMessagePastCutOffTime &&
                  !hasReachedConversationLimit &&
                  !isStreaming &&
                  !isLoading
              ) && (
                <PromptLimitBanner
                  remainingPrompts={remainingPrompts}
                  currentConversationCount={currentConversationCount}
                  remainingConversationsForMonth={
                    remainingConversationsForMonth
                  }
                  totalConversationsForMonth={totalConversationsForMonth}
                  onUpgradeCTAClicked={() => setShowUpgradeBanner(true)}
                />
              )}
            </ScrollToBottom>
          )}
          <AnimatePresence>
            {Boolean(showSavedPromptToast) &&
              reportingCategory !== 'flow_responses' && (
                <motion.section
                  key="saved-prompt-toast"
                  className={twJoin(
                    'absolute mb-3 flex w-[30rem] justify-start space-x-2 rounded bg-primary-2 p-2 py-1 drop-shadow-2xl',
                    isFullScreen ? 'left-48' : 'left-4'
                  )}
                  initial={{ opacity: 0, y: '10rem' }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: '10rem' }}
                  transition={{ duration: 0.3 }}
                >
                  <div>
                    <BookmarkIcon className="mt-1 h-4 w-4 fill-primary-6 text-primary-6" />
                  </div>
                  <div>
                    <TextStyle variant="sm-bold">
                      {formatMessage(messages.savedPromptTextTitle)}
                    </TextStyle>
                    <TextStyle variant="sm-regular">
                      {formatMessage(messages.savedPromptTextDescription)}
                    </TextStyle>
                  </div>
                </motion.section>
              )}
          </AnimatePresence>
        </div>
        <DoraChatBottomSheet
          id={id}
          isLoading={isLoading}
          threadId={threadId}
          canUpdateResponseChat={(chatHistoryData ?? []).length === 0}
          getDoraResponse={getDoraResponse}
          doraChatStore={doraChatStore}
          isAdmin={isAdmin}
          isLimitedReportingExperience={isLimitedReportingExperience}
          hasReachedPromptLimit={remainingPrompts === 0}
          remainingConversationsForMonth={remainingConversationsForMonth}
          hasReachedConversationLimit={hasReachedConversationLimit}
          isLastMessagePastCutOffTime={isLastMessagePastCutOffTime}
          reportingCategory={reportingCategory}
          setIsResponseBubbleVisible={setIsResponseBubbleVisible}
          changeCategory={changeCategory}
          showEditorHelpText={Boolean(
            chatHistoryData && chatHistoryData.length > 1
          )}
          onUpgradeClick={() => setShowUpgradeBanner(true)}
        />
      </>
    );
  }

  return (
    <div className="flex h-full flex-col antialiased">
      {reportingCategory !== 'flow_responses' && (
        <ReportingInsightsChatHeader
          id={id}
          reportingCategory={reportingCategory}
          changeCategory={changeCategory}
          isLoading={isLoading}
          isStreaming={isStreaming}
          chatHistoryData={chatHistoryData}
        />
      )}
      <div className="flex h-full w-full overflow-x-hidden antialiased">
        {Boolean(isFullScreen) &&
          !isLimitedReportingExperience &&
          reportingCategory !== 'flow_responses' && (
            <ChatViewSidebar
              isLimitedReportingExperience={isLimitedReportingExperience}
              openUpgradeBanner={() => setShowUpgradeBanner(true)}
              currentDrawerId={id}
              isSidebarOpen={isSidebarOpen}
              toggleSidebar={setIsSidebarOpen}
              closeCurrentDrawer={() => {
                if (!chatHistoryData || chatHistoryData.length === 0) {
                  deleteDrawer(id);
                } else {
                  closeDrawer(id);
                }
              }}
            />
          )}
        <div
          className={twMerge(
            'flex h-full w-full flex-col antialiased',
            isFullScreen &&
              isSidebarOpen &&
              !isLimitedReportingExperience &&
              reportingCategory !== 'flow_responses'
              ? 'w-[calc(100%-22rem)]'
              : 'w-[calc(100%-1rem)]'
          )}
        >
          {scrollContent}
        </div>
      </div>
    </div>
  );
}
