import {
  getCalendarDate,
  getEndOfDay,
  isSameCalendarDate,
} from '@assembly-web/services';
import type { DatePickerProps, SelectedOption } from '@assembly-web/ui';
import {
  type CalendarDate,
  getLocalTimeZone,
  today,
} from '@internationalized/date';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  after: {
    defaultMessage: 'After {date}',
    id: 'Bl8xZP',
  },
  before: {
    defaultMessage: 'Before {date}',
    id: 'U5sjCv',
  },
  end: {
    defaultMessage: 'End',
    id: '3JVa6k',
  },
  invalidDate: {
    defaultMessage: 'This date range is not valid.',
    id: 'YlUATC',
  },
  singleDate: {
    defaultMessage: 'On {date}',
    id: 'KGXk/j',
  },
  start: {
    defaultMessage: 'Start',
    id: 'mOFG3K',
  },
});

type DoubleDatePickerProps = {
  dateHelpText: string;
  duration: string;
  firstDate: DatePickerProps;
  isRangeInvalid: boolean;
  secondDate: DatePickerProps;
  selectedEndIsoDate: string;
  selectedStartIsoDate: string;
};

export const useDoubleDatePicker = (
  selectedDateRange: SelectedOption | null
): DoubleDatePickerProps => {
  const { formatMessage, locale } = useIntl();

  const timezone = getLocalTimeZone();

  const parsedEndDate = selectedDateRange?.value?.end
    ? getCalendarDate(new Date(selectedDateRange.value.end))
    : null;

  const dateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    [locale]
  );

  const maxEndDate = useMemo(() => {
    return today(timezone);
  }, [timezone]);
  const minStartDate = null;
  const [duration, setDuration] = useState<string>('');
  const [dateHelpText, setDateHelpText] = useState<string>('');
  const [isEndInvalid, setIsEndInvalid] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const [focusedEndValue, setFocusedEndValue] = useState<CalendarDate>(
    parsedEndDate ?? maxEndDate
  );

  const parsedStartDate = selectedDateRange?.value?.start
    ? getCalendarDate(new Date(selectedDateRange.value.start))
    : null;

  const [selectedEndDateValue, setSelectedEndDateValue] =
    useState<CalendarDate | null>(parsedEndDate);

  const [selectedEndIsoDate, setSelectedEndIsoDate] = useState<string>(
    selectedDateRange?.value?.end ?? ''
  );

  const [isStartInvalid, setIsStartInvalid] = useState(false);
  const [isStartOpen, setIsStartOpen] = useState(false);

  const [focusedStartValue, setFocusedStartValue] = useState<CalendarDate>(
    parsedStartDate ?? maxEndDate
  );

  const [selectedStartDateValue, setSelectedStartDateValue] =
    useState<CalendarDate | null>(parsedStartDate);

  const [selectedStartIsoDate, setSelectedStartIsoDate] = useState<string>(
    selectedDateRange?.value?.start ?? ''
  );

  const getStartDateForRequest = (newDate: CalendarDate) => {
    const selectedStartDate = newDate.toDate(timezone);

    return selectedStartDate.toISOString();
  };

  const getEndDateForRequest = (newDate: CalendarDate) => {
    const selectedEndDate = getEndOfDay(newDate.toDate(timezone));

    return selectedEndDate.toISOString();
  };

  const isBothDatesSelected =
    Boolean(selectedEndDateValue) && Boolean(selectedStartDateValue);

  const minEndDateValue =
    (!isBothDatesSelected || isEndInvalid) && selectedStartDateValue
      ? selectedStartDateValue
      : minStartDate;

  const reset = useCallback(() => {
    setSelectedStartDateValue(null);
    setSelectedEndDateValue(null);
    setFocusedStartValue(maxEndDate);
    setFocusedEndValue(maxEndDate);
    setSelectedStartIsoDate('');
    setSelectedEndIsoDate('');
    setDateHelpText('');
    setIsEndInvalid(false);
    setIsStartInvalid(false);
    setDuration('');
  }, [maxEndDate]);

  const handleDuration = useCallback(
    (startIsoDate: string, endIsoDate: string) => {
      if (endIsoDate && startIsoDate) {
        const selectedStartDate = new Date(startIsoDate);

        const formattedStartDate = dateFormatter.format(new Date(startIsoDate));
        const selectedEndDate = new Date(endIsoDate);
        const formattedEndDate = dateFormatter.format(selectedEndDate);

        let duration = `${formattedStartDate} - ${formattedEndDate}`;

        if (isSameCalendarDate(selectedEndDate, selectedStartDate)) {
          duration = formatMessage(messages.singleDate, {
            date: formattedStartDate,
          });
        }

        setDuration(duration);
      } else if (endIsoDate) {
        const selectedEndDate = new Date(endIsoDate);
        const formattedEndDate = dateFormatter.format(selectedEndDate);

        const duration = formatMessage(messages.before, {
          date: formattedEndDate,
        });

        setDuration(duration);
      } else if (startIsoDate) {
        const selectedStartDate = new Date(startIsoDate);
        const formattedStartDate = dateFormatter.format(selectedStartDate);
        const duration = formatMessage(messages.after, {
          date: formattedStartDate,
        });

        setDuration(duration);
      }
    },
    [dateFormatter, formatMessage]
  );

  useEffect(() => {
    if (selectedDateRange?.id !== 'custom') {
      reset();
    }
  }, [reset, selectedDateRange?.id]);

  useEffect(() => {
    handleDuration(selectedStartIsoDate, selectedEndIsoDate);
  }, [handleDuration, selectedEndIsoDate, selectedStartIsoDate]);

  const handleSelectedEndValueChange = (
    newSelectedEndCalendarDateValue: CalendarDate | null
  ) => {
    if (!newSelectedEndCalendarDateValue) {
      // User attempts to clear a date segment by pressing backspace on the keyboard.
      return;
    }

    setFocusedEndValue(newSelectedEndCalendarDateValue);
    setSelectedEndDateValue(newSelectedEndCalendarDateValue);
    setSelectedEndIsoDate(
      getEndDateForRequest(newSelectedEndCalendarDateValue)
    );

    const isNewValueOutOfRange =
      (minEndDateValue && newSelectedEndCalendarDateValue < minEndDateValue) ||
      newSelectedEndCalendarDateValue > maxEndDate;

    if (isNewValueOutOfRange) {
      setDateHelpText(formatMessage(messages.invalidDate));
      setIsEndInvalid(true);
      return;
    }

    setIsEndInvalid(false);
    if (!isStartInvalid) {
      setDateHelpText('');
    }

    const isEndLastDateUnselected =
      !selectedEndDateValue && selectedStartDateValue;

    if (isEndLastDateUnselected) {
      return;
    }

    const isCurSelectedStartDateOutofRange = selectedStartDateValue
      ? selectedStartDateValue > newSelectedEndCalendarDateValue
      : false;

    if (isCurSelectedStartDateOutofRange) {
      setSelectedStartDateValue(null); // Reset because the start date is no longer within the updated range.
      setFocusedStartValue(maxEndDate);
      setSelectedStartIsoDate('');
    }
  };

  const endDatePickerProps: DatePickerProps = {
    focusedValue: focusedEndValue,
    isOpen: isEndOpen,
    isInvalid: isEndInvalid,
    label: formatMessage(messages.end),
    maxValue: maxEndDate,
    minValue: minEndDateValue ?? undefined,
    onFocusedValueChange: setFocusedEndValue,
    onOpenChange: setIsEndOpen,
    onSelectedValueChange: handleSelectedEndValueChange,
    selectedValue: selectedEndDateValue,
  };

  const maxStartDateValue =
    !isBothDatesSelected &&
    selectedEndDateValue &&
    selectedEndDateValue < maxEndDate
      ? selectedEndDateValue
      : maxEndDate;

  const handleSelectedStartValueChange = (
    newSelectedStartCalendarDateValue: CalendarDate | null
  ) => {
    if (!newSelectedStartCalendarDateValue) {
      // User attempts to clear a date segment by pressing backspace on the keyboard.
      return;
    }

    setFocusedStartValue(newSelectedStartCalendarDateValue);
    setSelectedStartDateValue(newSelectedStartCalendarDateValue);
    setSelectedStartIsoDate(
      getStartDateForRequest(newSelectedStartCalendarDateValue)
    );

    const isNewValueOutOfRange =
      newSelectedStartCalendarDateValue > maxStartDateValue;

    if (isNewValueOutOfRange) {
      setDateHelpText(formatMessage(messages.invalidDate));
      setIsStartInvalid(true);
      return;
    }

    setIsStartInvalid(false);

    if (!isEndInvalid) {
      setDateHelpText('');
    }

    const isStartLastDateUnselected =
      !selectedStartDateValue && selectedEndDateValue;

    if (isStartLastDateUnselected) {
      return;
    }

    const isCurSelectedEndDateOutofRange = selectedEndDateValue
      ? selectedEndDateValue < newSelectedStartCalendarDateValue
      : false;

    if (isCurSelectedEndDateOutofRange) {
      setSelectedEndDateValue(null); // Reset because the end date is no longer within the updated range.
      setFocusedEndValue(maxEndDate);
      setSelectedEndIsoDate('');
    }
  };

  const startDatePickerProps: DatePickerProps = {
    focusedValue: focusedStartValue,
    isOpen: isStartOpen,
    isInvalid: isStartInvalid,
    label: formatMessage(messages.start),
    maxValue: maxStartDateValue,
    minValue: undefined,
    onFocusedValueChange: setFocusedStartValue,
    onOpenChange: setIsStartOpen,
    onSelectedValueChange: handleSelectedStartValueChange,
    selectedValue: selectedStartDateValue,
  };

  return {
    dateHelpText,
    duration,
    isRangeInvalid: isStartInvalid || isEndInvalid,
    firstDate: startDatePickerProps,
    secondDate: endDatePickerProps,
    selectedEndIsoDate,
    selectedStartIsoDate,
  };
};
