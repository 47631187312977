import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { type JobStatus, useJobStatusQuery } from '../useJobStatusQuery';

const messages = defineMessages({
  downloadFailed: {
    defaultMessage: 'Download failed. Retry later.',
    id: '7LPql5',
  },
});

type DownloadInitiatedJob = {
  job: {
    id: string;
    status: JobStatus;
  };
};

export type DownloadDataJob = {
  url: string;
};

export const useDownloadFlowDataMutation = (
  flowId: string,
  options?: UseMutationOptions<DownloadInitiatedJob | DownloadDataJob>
) => {
  return useMutation({
    ...options,
    mutationKey: ['downloadFlowData', flowId],
    mutationFn: async function () {
      const response = await assemblyAPI.get<
        DownloadInitiatedJob | DownloadDataJob
      >(APIEndpoints.downloadFlowData(flowId));

      return response.data;
    },
  });
};

export const useDownloadFlowData = (
  flowId: string,
  options?: {
    onSuccess?: (data: DownloadDataJob) => void;
    onError?: (error: unknown) => void;
  }
) => {
  const [jobId, setJobId] = useState<string | null>(null);
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();

  const { showErrorToast } = useToastStore();

  const downloadFlowData = useDownloadFlowDataMutation(flowId, {
    onSuccess: (data) => {
      if ('job' in data) {
        setJobId(data.job.id);
      } else {
        if (options?.onSuccess) {
          options.onSuccess(data);
        }
      }
    },
    onError: (error) => {
      if (options?.onError) {
        options.onError(error);
      }
    },
  });

  const { data: jobStatusQuery, isSuccess: isJobStatusSuccess } =
    useJobStatusQuery({
      jobId: jobId ?? '',
      options: {
        enabled: Boolean(jobId),
        refetchInterval: (query) => {
          const status = query.state.data?.data.status;
          return status === 'QUEUED' || status === 'PROCESSING' ? 3000 : false;
        },
      },
    });

  if (isJobStatusSuccess) {
    if (jobStatusQuery.data.status === 'SUCCESS') {
      setJobId(null);
      queryClient.cancelQueries({
        queryKey: ['jobStatus', jobId],
      });
      downloadFlowData.mutate();
    } else if (jobStatusQuery.data.status === 'ERROR') {
      setJobId(null);
      queryClient.cancelQueries({
        queryKey: ['jobStatus', jobId],
      });
      showErrorToast(formatMessage(messages.downloadFailed));
    }
  }

  const { mutate: downloadMutate, isPending } = downloadFlowData;

  const initiateDownload = useCallback(() => {
    setJobId(null);
    downloadMutate();
  }, [downloadMutate]);

  return useMemo(
    () => ({
      initiateDownload,
      isDownloading: isPending || Boolean(jobId),
    }),
    [initiateDownload, isPending, jobId]
  );
};
