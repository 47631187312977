import {
  APIEndpoints,
  type SecondaryFiltersOptionsAPIResponse,
  type SecondaryFiltersOptionsPayload,
  type SecondaryFiltersType,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

function getSecondaryFilterOptionsCacheKey(
  payload: SecondaryFiltersOptionsPayload
) {
  const {
    topLevelFilter,
    secondaryFilter: { filter, includeEntityIds },
    searchTerm,
  } = payload;
  return [
    'getSecondaryFilterOptions',
    `${topLevelFilter}-${filter}-${searchTerm}-${includeEntityIds.join('-')}}`,
  ].filter(Boolean);
}

export function useGetSecondaryFilterOptionsQuery(
  payload: SecondaryFiltersOptionsPayload
) {
  const [searchParams] = useSearchParams();

  return useInfiniteQuery<SecondaryFiltersOptionsAPIResponse>({
    queryKey: getSecondaryFilterOptionsCacheKey(payload),
    queryFn: async ({ pageParam }) => {
      const res = await assemblyAPI.post(APIEndpoints.getSecondaryFilters, {
        topLevelFilter: payload.topLevelFilter,
        secondaryFilter: [
          {
            filter: payload.secondaryFilter.filter,
            includeEntityIds: payload.secondaryFilter.includeEntityIds,
            meta: {
              from: pageParam || 0,
              limit: 20,
            },
          },
        ],
        searchTerm: payload.searchTerm,
      });
      return res.data;
    },
    initialPageParam: 0,
    getNextPageParam: (page) => {
      const currentSecondaryFilter: SecondaryFiltersType = Object.keys(
        page
      )[0] as SecondaryFiltersType;
      if (
        currentSecondaryFilter === 'APPS' ||
        currentSecondaryFilter === 'TASK_STATUS' ||
        currentSecondaryFilter === 'FILE_TYPE' ||
        currentSecondaryFilter === 'DEPARTMENT' ||
        currentSecondaryFilter === 'HOME_LOCATION' ||
        currentSecondaryFilter === 'WORK_LOCATION' ||
        currentSecondaryFilter === 'JOB_TITLE'
      ) {
        return undefined;
      }
      const currentSecondaryFilterPage = page[currentSecondaryFilter];
      const {
        total,
        metadata: {
          pagination: {
            cursor: { limit, from },
          },
        },
      } = currentSecondaryFilterPage;

      return from + limit <= total ? from + limit : undefined;
    },
    placeholderData: (previousData) => previousData,
    enabled: payload.enabled && searchParams.get('filterExpanded') === 'true',
  });
}
