import type { FlowDetail } from '@assembly-web/services';
import { useCallback } from 'react';

import type { FlowNavItemDetails } from '../../components/Nav/FlowNavItem';
import { useFlowActivities } from '../useFlowActivities';

export function useFlowNavItems() {
  const { data: flowActivities } = useFlowActivities();

  return useCallback(
    (flows: FlowDetail[]): FlowNavItemDetails[] => {
      return flows.map((flow: FlowDetail) => ({
        id: flow.flowId,
        name: flow.name,
        icon: flow.icon,
        hasUnreadPosts: flowActivities.unreadFlows.includes(flow.flowId),
        hasUnreadMentions: flowActivities.mentionedFlows.includes(flow.flowId),
      }));
    },
    [flowActivities]
  );
}
