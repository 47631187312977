import {
  config,
  SplitNames,
  useFeatureSplit,
  userAuthStore,
} from '@assembly-web/services';
import { AppLink, AssemblyLink, Heading } from '@assembly-web/ui';
import type { ReactNode } from 'react';
import { useEffect, useRef } from 'react';
import makeAsyncScript from 'react-async-script';
import { FormattedMessage } from 'react-intl';
import { useLocation, useMatches } from 'react-router-dom';

import { trackRegistrationShow } from '../../../services/analytics';
import { onBoardingState } from '../stores/onboardingStore';

const values = {
  tos: (text: string | string[] | ReactNode[]) => (
    <AppLink external to="https://www.joinassembly.com/terms-of-service">
      {text}
    </AppLink>
  ),
  privacy: (text: string | string[] | ReactNode[]) => (
    <AppLink external to="https://www.joinassembly.com/privacy-policy">
      {text}
    </AppLink>
  ),
};

const AccountCreationPageFooter = () => (
  <FormattedMessage
    defaultMessage="By clicking the 'Continue' button, you're agreeing to our <tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy>"
    id="DetQNA"
    values={values}
  />
);
const UserAccountCreationPageFooter = () => (
  <FormattedMessage
    defaultMessage="By clicking the 'Sign up' button, you're agreeing to our <tos>Terms of Service</tos> and <privacy>Privacy Policy</privacy>"
    id="OzVCpy"
    values={values}
  />
);

export function OnboardingLayout({
  shouldShowFooter = true,
  children,
}: {
  shouldShowFooter?: boolean;
  children: ReactNode;
}) {
  const { pathname } = useLocation();

  /**
   * We're using a vanilla zustand store here & subscribing to transient updates
   * within this component because we have to update the store's details in
   * loaders & actions that can't invoke a react zustand store.
   */
  const {
    name: companyNameFromStore,
    logo: logoFromStore,
    resetBrandingToDefaults,
  } = onBoardingState.getState();

  const name = useRef(companyNameFromStore);
  const logo = useRef(logoFromStore);

  useEffect(
    () =>
      onBoardingState.subscribe((state) => {
        name.current = state.name;
        logo.current = state.logo;
      }),
    []
  );

  useEffect(() => {
    return () => {
      const recaptchaBadge =
        document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaBadge.length) {
        recaptchaBadge[0].remove();
      }
    };
  }, []);

  useEffect(() => trackRegistrationShow(), [pathname]);

  const isAssemblyCreationPage = pathname === '/create-account';
  const isUserAccountCreationPage = pathname.endsWith('//TODO');

  const unknownPath = useMatches().some((x) => x.params['*']);

  const isLoginFlow = pathname.includes('login') || pathname.includes('/join');

  const isAccountCreationFlow =
    pathname.includes('create-account') || pathname.includes('signup');
  const isHeaderClickable = isLoginFlow || isAccountCreationFlow;

  const googleRecaptchaUrl = `https://www.google.com/recaptcha/enterprise.js?render=${config.recaptchaSiteKey}`;

  const { treatment: googleRecaptchaTreatment } = useFeatureSplit(
    SplitNames.GoogleRecaptcha
  );

  const AsyncScriptComponent = makeAsyncScript(googleRecaptchaUrl, {
    removeOnUnmount: true,
  })(() => <> </>);

  return (
    <>
      {!userAuthStore.getState().msTeamsContext && (
        <header className="flex w-full border-b-[1px] border-b-gray-4">
          {isHeaderClickable ? (
            <AssemblyLink
              to={unknownPath || isLoginFlow ? '/login' : '/create-account'}
              className="flex flex-shrink-0 cursor-pointer items-center pb-4 pl-4 pt-4 md:pl-7"
              onClick={() => {
                resetBrandingToDefaults();
              }}
            >
              <img className="block h-10 w-10" src={logo.current} alt="" />
              <Heading as="h6" className="pl-3">
                {name.current}
              </Heading>
            </AssemblyLink>
          ) : (
            <div className="flex flex-shrink-0 cursor-default items-center pb-4 pl-4 pt-4 md:pl-7">
              <img className="block h-10 w-10" src={logo.current} alt="" />
              <Heading as="h6" className="pl-3">
                {name.current}
              </Heading>
            </div>
          )}
        </header>
      )}

      {googleRecaptchaTreatment === 'on' && !pathname.startsWith('/a') && (
        <AsyncScriptComponent />
      )}
      <main className="mt-8">{children}</main>

      {Boolean(shouldShowFooter) &&
        Boolean(isAssemblyCreationPage || isUserAccountCreationPage) && (
          <footer className="w-full pb-10 text-center text-gray-9">
            <p className="mx-auto text-center md:w-[26rem]">
              {Boolean(isAssemblyCreationPage) && <AccountCreationPageFooter />}
              {Boolean(isUserAccountCreationPage) && (
                <UserAccountCreationPageFooter />
              )}
            </p>
          </footer>
        )}
    </>
  );
}
