import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { type MutationOptions, useMutation } from '@tanstack/react-query';

export type FlowCreationResponse = {
  data: {
    flowId: string;
  };
};

export function useTemplateMutation({
  options,
}: {
  options?: MutationOptions<
    unknown,
    unknown,
    {
      templateId: string;
      templateName: string;
      icon: string;
    }
  >;
}) {
  return useMutation({
    ...options,
    mutationFn: async ({
      templateId,
    }: {
      templateId: string;
      templateName: string;
      icon: string;
    }) => {
      return await assemblyAPI.post<FlowCreationResponse>(
        APIEndpoints.createFlowWithOneClick,
        {
          templateId,
        }
      );
    },
    onSuccess: (data, variables) => {
      options?.onSuccess?.(data, variables, {});
    },
    onError: (err: unknown, variables) => {
      options?.onError?.(err, variables, {});
    },
  });
}
