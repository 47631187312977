import { PopoutDrawerTemplate } from '../PopoutDrawerTemplate';
import type { ChatDrawer as ChatDrawerProps } from '../types';
import { ChatDrawerBody } from './ChatDrawerBody';

export function ChatDrawer(props: ChatDrawerProps) {
  return (
    <PopoutDrawerTemplate {...props}>
      <ChatDrawerBody {...props} />
    </PopoutDrawerTemplate>
  );
}
