import {
  APIEndpoints,
  assemblyAPI,
  type DownloadSecureFileResponse,
  type FileDownloadRequest,
} from '@assembly-web/services';
import { useMutation } from '@tanstack/react-query';

export function useGetDownloadableFile() {
  return useMutation({
    mutationFn: async (
      request: Omit<FileDownloadRequest, 'instanceId'> & { fileType: string }
    ) => {
      const res = await assemblyAPI.get<DownloadSecureFileResponse>(
        APIEndpoints.getFileDownloadURL({ ...request, instanceId: '' })
      );
      return res.data;
    },
  });
}
