import { logger, useSuspenseUserDetails } from '@assembly-web/services';
import {
  LeaderBoard as LeaderBoardImpl,
  useToastStore,
} from '@assembly-web/ui';
import { useCallback } from 'react';
import { defineMessages, type MessageDescriptor, useIntl } from 'react-intl';

import { useMultiDrawerStore } from '../../../stores/useMultiDrawerStore';
import {
  type DownloadDataJob,
  useDownloadLeaderBoardDataJobPolling,
} from '../../discover/hooks/recognition/useDownloadLeaderBoardData';
import { setFullScreenDrawerId } from '../../discover/services/drawers';
import { getDoraChatStore } from '../../discover/stores/doraChatStore';

const messages = defineMessages({
  topManagersTitle: {
    defaultMessage: 'Top 10 Managers recognition',
    id: '89QPyn',
  },
  topRecipientsTitle: {
    defaultMessage: 'Top 10 recognition recipients',
    id: 'ppIQ3f',
  },
  teamTopGiversTitle: {
    defaultMessage: 'Top 10 recognition givers',
    id: 'JIMuhs',
  },
  topManagersPrompt: {
    defaultMessage:
      'Please create a table listing the top 10 Managers sending recognition to their team by the number of recognition posts sent to their direct reports and the total {points} sent to their direct reports. Sort the table by posts for the last 3 months.',
    id: '6JnFqn',
  },
  teamTopRecipientsPrompt: {
    defaultMessage:
      'Please create a table listing the top 10 recognition recipients by the number of recognition posts received and the total {points} awarded to them, sorted by posts, for the last 3 months.',
    id: 'BqFYuU',
  },
  teamTopGiversPrompt: {
    defaultMessage:
      'Please create a table listing the top 10 recognition senders by the number of recognition posts sent and the total {points} they sent, sorted by posts, for the last 3 months.',
    id: 'eia/Qy',
  },
  preparingToDownload: {
    defaultMessage: 'Preparing to download data',
    id: '6JwYce',
  },
  downloadFailed: {
    defaultMessage: 'Download failed. Retry later.',
    id: '7LPql5',
  },
  downloadSuccess: {
    defaultMessage: 'Download success',
    id: 'E9nYXm',
  },
});

const map = {
  topRecipients: {
    title: messages.topRecipientsTitle,
    prompt: messages.teamTopRecipientsPrompt,
  },
  topGivers: {
    title: messages.teamTopGiversTitle,
    prompt: messages.teamTopGiversPrompt,
  },
  topManagers: {
    title: messages.topManagersTitle,
    prompt: messages.topManagersPrompt,
  },
};

export function LeaderBoard() {
  const { formatMessage } = useIntl();
  const { data: userDetails } = useSuspenseUserDetails();
  const points = userDetails.assembly.currency.pluralName;
  const { showSuccessToast, showErrorToast, showInfoToast } = useToastStore();
  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const updateDraftInputValue = useMultiDrawerStore(
    (store) => store.updateDraftInputValue
  );

  const createAndOpenDrawer = useCallback(
    (title: string, prompt: string) => {
      const drawerId = createDrawer({
        type: 'doraChat',
        title,
        data: {},
      });
      const { doraChatStore } = getDoraChatStore(`doraChat-${drawerId}`);
      doraChatStore.getState().setReportingCategory('recognition');
      setFullScreenDrawerId(drawerId);
      updateDraftInputValue(drawerId, prompt);
    },
    [createDrawer, updateDraftInputValue]
  );

  const clickHandler = useCallback(
    (type: keyof typeof map) => () => {
      const formatMessageWithPoints = (text: MessageDescriptor) =>
        formatMessage(text, { points });
      createAndOpenDrawer(
        formatMessageWithPoints(map[type].title),
        formatMessageWithPoints(map[type].prompt)
      );
    },
    [createAndOpenDrawer, formatMessage, points]
  );

  const { initiateDownload } = useDownloadLeaderBoardDataJobPolling({
    onSuccess: (data: DownloadDataJob) => {
      window.open(data.url, '_self', 'noreferrer, noopener');
      setTimeout(
        () => showSuccessToast(formatMessage(messages.downloadSuccess)),
        500
      );
    },
    onError: (error: unknown) => {
      const errorInfo = error instanceof Error ? error : undefined;
      showErrorToast(formatMessage(messages.downloadFailed));
      logger.error('Failed to download data ', errorInfo);
    },
  });

  const handleDownloadData = useCallback(() => {
    showInfoToast(formatMessage(messages.preparingToDownload));
    initiateDownload();
  }, [formatMessage, initiateDownload, showInfoToast]);

  return (
    <LeaderBoardImpl.Root>
      <LeaderBoardImpl.Title downloadData={handleDownloadData} />
      <LeaderBoardImpl.ButtonGroup>
        <LeaderBoardImpl.TopRecipients
          onClick={clickHandler('topRecipients')}
        />
        <LeaderBoardImpl.TopGivers onClick={clickHandler('topGivers')} />
        <LeaderBoardImpl.TopManagers onClick={clickHandler('topManagers')} />
      </LeaderBoardImpl.ButtonGroup>
    </LeaderBoardImpl.Root>
  );
}
