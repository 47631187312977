import {
  APIEndpoints,
  assemblyAPI,
  type CollectionItemsAPIResponse,
} from '@assembly-web/services';
import type { QueryClient, UseQueryResult } from '@tanstack/react-query';
import { queryOptions, useQuery } from '@tanstack/react-query';
import { arrayMoveMutable } from 'array-move';
import { produce } from 'immer';

const getCollectionItemsQueryOptions = (collectionId: string) =>
  queryOptions({
    queryKey: ['collectionItems', collectionId],
    queryFn: async () => {
      const response = await assemblyAPI.get<CollectionItemsAPIResponse>(
        APIEndpoints.listCollectionItems(collectionId)
      );
      return response.data;
    },
  });

export function rearrangeItemWithinCollection(
  queryClient: QueryClient,
  payload: {
    collectionId: string;
    itemId: string;
    beforeId?: string;
    afterId?: string;
    newLocationIndex: number;
  }
) {
  const collectionItemsQueryKey = ['collectionItems', payload.collectionId];

  const prevCollectionData =
    queryClient.getQueryData<CollectionItemsAPIResponse>(
      collectionItemsQueryKey
    );
  if (prevCollectionData?.data) {
    const newCollectionData = [...prevCollectionData.data];
    arrayMoveMutable(
      newCollectionData,
      newCollectionData.findIndex((item) => item.id === payload.itemId),
      payload.newLocationIndex
    );

    queryClient.setQueryData(collectionItemsQueryKey, {
      data: newCollectionData,
    });
  }
}

export function updateCollectionItem(
  queryClient: QueryClient,
  data: {
    action: 'add' | 'remove' | 'reorder';
    entityId: string;
    type: string;
    collectionId: string;
  }
) {
  const queryKey = getCollectionItemsQueryOptions(data.collectionId).queryKey;

  const previousCollectionItems =
    queryClient.getQueryData<CollectionItemsAPIResponse>(queryKey);

  if (data.action === 'add') {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });
  } else if (data.action === 'remove') {
    const updatedCollectionItems = produce(previousCollectionItems, (draft) => {
      if (draft?.data) {
        draft.data = draft.data.filter((item) => item.id !== data.entityId);
      }
    });
    queryClient.setQueryData(queryKey, updatedCollectionItems);
  }
}

export function useGetCollectionItemsQuery(
  collectionId: string
): UseQueryResult<CollectionItemsAPIResponse> {
  return useQuery(getCollectionItemsQueryOptions(collectionId));
}
