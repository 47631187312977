import { Button, Modal, TextStyle, useDeviceInfo } from '@assembly-web/ui';
import { CheckIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

const labels = defineMessages({
  title: {
    id: 'Fm+q7l',
    defaultMessage: 'Draft is auto-saved',
  },
  content: {
    id: 'h6pMOT',
    defaultMessage:
      'Your Give Recognition post will be pre-filled with your draft next time you start a Recognition post.',
  },
  delete: {
    id: '4RpVDe',
    defaultMessage: 'Delete Draft',
  },
  close: {
    id: 'rbrahO',
    defaultMessage: 'Close',
  },
});

export function DraftModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (param?: { isDeleteDraft: true }) => void;
}) {
  const { formatMessage } = useIntl();
  const { isMobileDevice } = useDeviceInfo();
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <div className="flex items-center gap-2">
          <CheckIcon className="m-1 h-6 w-6 rounded-full bg-success-3 p-1 text-success-7" />
          <TextStyle variant={isMobileDevice ? 'xl-bold' : '2xl-bold'}>
            {formatMessage(labels.title)}
          </TextStyle>
        </div>
      }
      ctaSection={
        <div className="flex w-full justify-end gap-3">
          <Button
            variation="dangerLite"
            className="w-1/2"
            onClick={() =>
              onClose({
                isDeleteDraft: true,
              })
            }
          >
            {formatMessage(labels.delete)}
          </Button>
          <Button
            variation="primary"
            onClick={() => onClose()}
            className="w-1/2"
          >
            {formatMessage(labels.close)}
          </Button>
        </div>
      }
    >
      <TextStyle>{formatMessage(labels.content)}</TextStyle>
    </Modal>
  );
}
