import {
  type FlowBuilderState,
  FlowFrequencyOptions,
  getBaseEmojiFromIcon,
  getContent,
  type GetFlowTemplateResponse,
  getTrigger,
  getVisibility,
  useUserDetails,
} from '@assembly-web/services';
import invariant from 'tiny-invariant';

import { useGetTemplateDetailsById } from '../../../../hooks/templates/useGetTemplateDetailsById';
import { useCurrentUserDetail } from '../../../../hooks/useCurrentUserDetail';
import type { FlowCreationDrawer as FlowCreationEditorProps } from '../../types';
import {
  defaultReminderAndStartTime,
  getResponseTimeUnitAndValue,
  getSchedulerConfigurationFromKind,
  getScheduleSettings,
} from '../utils/distribution';
import { formatUserToFlowCollaborator } from '../utils/user';

export const useFetchTemplate = (
  templateId: string,
  type: FlowCreationEditorProps['data']['type'],
  enabled = true
) => {
  const owner = useCurrentUserDetail();

  const { data: userDetails } = useUserDetails();
  invariant(userDetails, 'userDetails should be defined');

  const { timeZone } = userDetails.assembly;

  return useGetTemplateDetailsById(templateId, {
    enabled: enabled && type === 'template',
    select(data: GetFlowTemplateResponse): FlowBuilderState {
      return {
        currentSchedule: undefined,
        description: data.templateDetails.description.text,
        collaborators: [],
        emoji: getBaseEmojiFromIcon(
          data.templateDetails.description.icon?.value ?? ''
        ),
        remindersStartDate: null,
        flowId: window.crypto.randomUUID(),
        flowName: data.templateDetails.title,
        inEditMode: false,
        isFlowDataChangeConfirmed: false,
        showDataChangeConfirm: false,
        templateId: data.templateId,
        templateName: data.templateDetails.title,
        owner: [formatUserToFlowCollaborator(owner)],
        blockData: {
          PARTICIPANTS: {
            errors: null,
            participantsCriteria: undefined,
          },
          ACTION: {
            errors: null,
            actionType: 'SEND_FORM',
          },
          CONTENT: getContent(data.templateDetails),
          VISIBILITY: getVisibility(data.templateDetails),
          TRIGGER: getTrigger(data.templateDetails),
        },
        ...getSchedulerConfigurationFromKind(data.templateDetails.kind),
        ...getResponseTimeUnitAndValue(data.templateDetails.endTimeInMinutes),
        numberOfResponses: 1,
        remindersType: 'manual',
        canValidate: true,
        errors: null,
        remindersCount: null,
        remindersFrequency: FlowFrequencyOptions.EveryWeekday,
        remindersDueTime: defaultReminderAndStartTime,
        remindersTimeZone: timeZone,
        reminderSchedule: null,
        newUsersToInvite: [],
        ...getScheduleSettings(
          data.templateDetails.schedule?.rule ?? '',
          timeZone
        ),
        allowMemberLevelOccurrence: false,
        selectedMilestone: 'startDate',
        selectedMilestoneFrequency: 'everyDay',
        numberOfResponseForMilestone: 1,
      };
    },
  });
};
