import {
  usePusherChannel,
  usePusherEvent,
  usePusherEvents,
} from '@assembly-web/pusher';
import {
  type Icon,
  SocketEvents,
  useUserDetails,
} from '@assembly-web/services';
import { useQueryClient } from '@tanstack/react-query';

import { updateFlowReadStatus } from '../../../../hooks/useFlowActivities';
import {
  addFlowToNav,
  updateFlowDetailsInNav,
} from '../../../../hooks/useFlowsNotInCollection';

export function useFlowUpdateMemberEvents() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();

  const member = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  const assemblyChannel = usePusherChannel(
    `private-assembly-${userDetails?.assembly.assemblyId}`
  );

  usePusherEvents<{
    flowId: string;
    memberId: string;
  }>(member, [SocketEvents.flow.read], async (args) => {
    if (args.eventName === SocketEvents.flow.read && args.data?.flowId) {
      updateFlowReadStatus(args.data.flowId, queryClient);
    }
  });

  usePusherEvent<{ flowId: string; name: string; icon: Icon }>(
    assemblyChannel,
    SocketEvents.flow.edited,
    async (data) => {
      if (!data) return;

      updateFlowDetailsInNav({
        queryClient,
        flowId: data.flowId,
        updatedFlowIcon: data.icon,
        updatedFlowName: data.name,
      });
    }
  );

  usePusherEvent<{
    flowId: string;
    name: string;
    icon: Icon;
  }>(assemblyChannel, SocketEvents.flow.published, async (data) => {
    if (!data) return;
    addFlowToNav({
      queryClient,
      flowId: data.flowId,
      flowName: data.name,
      flowIcon: data.icon,
    });
  });
}
