import type {
  GlobalFilterOption,
  ItemToAdd,
  SearchPayload,
} from '@assembly-web/services';
import { defineMessages, useIntl } from 'react-intl';

import { useGetCollectionsQuery } from '../../hooks/useGetCollectionsQuery';
import { BaseCollectionModal } from './BaseCollectionModal';

const messages = defineMessages({
  folderHeader: {
    defaultMessage: 'Add to a folder',
    id: 'e5R2/b',
  },
  folderSubTitle: {
    defaultMessage: 'Add to an existing folder or create a new one',
    id: 'Tt96fb',
  },
  chooseFolder: {
    defaultMessage: 'Choose an existing folder',
    id: '3Dl10l',
  },
  addToFolder: {
    defaultMessage: 'Add to folder',
    id: 'O3N3Cb',
  },
  addingToFolder: {
    defaultMessage: 'Adding to folder',
    id: 'FhuXmN',
  },
});

type AddToCollectionModalProps = {
  filter: GlobalFilterOption;
  itemToAdd: ItemToAdd | null;
  onClose: () => void;
  onCreateCollection: () => void;
  open: boolean;
  pinCollection: boolean;
  searchTerm: string;
  query?: SearchPayload;
};

export function AddToCollectionModal(props: AddToCollectionModalProps) {
  const {
    pinCollection,
    onCreateCollection,
    onClose,
    open,
    itemToAdd,
    filter,
    searchTerm,
    query,
  } = props;
  const { formatMessage } = useIntl();

  const { data: collections } = useGetCollectionsQuery({ enabled: open });

  return (
    <BaseCollectionModal
      onCreateCollection={onCreateCollection}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.folderHeader)}
      bodyText={formatMessage(messages.folderSubTitle)}
      collectionLabel={formatMessage(messages.chooseFolder)}
      ctaText={formatMessage(messages.addToFolder)}
      ctaLoadingText={formatMessage(messages.addingToFolder)}
      filter={filter}
      itemToAdd={itemToAdd}
      searchTerm={searchTerm}
      collections={collections?.data}
      pinCollection={pinCollection}
      query={query}
    />
  );
}
