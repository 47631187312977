import { SplitNames, useFeatureSplit } from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useCreateFlow } from '../../../../stores/useFlowBuilderStore';
import { useMultiDrawerStore } from '../../../../stores/useMultiDrawerStore';
import type { FlowCreationData } from '../../components/Drawers/types';
import { trackFlowEditorAction } from '../../services/analytics';
import { setFullScreenDrawerId } from '../../services/drawers';

const msgs = defineMessages({
  createNewFlowTitle: {
    defaultMessage: 'Create a new flow',
    id: 'eOrH+o',
  },
});

type UseCreateWorkflowProps = {
  flowCreationOverrideEnabled?: boolean;
};

export const useInitializeFlow = () => {
  const createFlowEditor = useCreateFlow();
  const queryClient = useQueryClient();

  return useCallback(
    async (id: string, editFlow?: boolean) => {
      await queryClient.invalidateQueries({
        queryKey: ['flowDetails', id, 'builder'],
        type: 'all',
      });
      await createFlowEditor(id, editFlow);
      setFullScreenDrawerId(id);
    },
    [createFlowEditor, queryClient]
  );
};

export const useCreateWorkflow = ({
  flowCreationOverrideEnabled = false,
}: UseCreateWorkflowProps = {}) => {
  const navigate = useAssemblyNavigate();
  const { formatMessage } = useIntl();

  const { isTreatmentActive: isFlowCreationDrawerEnabled } = useFeatureSplit(
    SplitNames.FlowCreation
  );
  const { isTreatmentActive: isFlowCreationOverrideEnabled } = useFeatureSplit(
    SplitNames.FlowCreationOverride
  );

  const createDrawer = useMultiDrawerStore((store) => store.createDrawer);
  const initializeFlow = useInitializeFlow();

  return useCallback(
    (
      {
        id: feedId,
        type,
        overrideTreatmentOffCallback,
        view,
      }: {
        id?: string | undefined;
        type: FlowCreationData['type'];
        overrideTreatmentOffCallback?: () => void;
        view?: FlowCreationData['view'];
      } = {
        id: undefined,
        type: 'scratch',
      }
    ) => {
      if (
        isFlowCreationDrawerEnabled ||
        (flowCreationOverrideEnabled && isFlowCreationOverrideEnabled)
      ) {
        const id = createDrawer({
          type: 'flowCreation',
          title: formatMessage(msgs.createNewFlowTitle),
          data: {
            id: feedId ?? window.crypto.randomUUID(),
            type,
            view: view ?? (type === 'scratch' ? 'empty' : 'editor'),
          },
        });
        if (type !== 'scratch') {
          initializeFlow(id, true);
          trackFlowEditorAction('createFlowClicked');
        } else if (view === 'editor') {
          initializeFlow(id);
          trackFlowEditorAction('createFlowClicked');
        }
      } else if (overrideTreatmentOffCallback) {
        overrideTreatmentOffCallback();
      } else {
        navigate('/a/templates');
      }
    },
    [
      createDrawer,
      flowCreationOverrideEnabled,
      formatMessage,
      initializeFlow,
      isFlowCreationDrawerEnabled,
      isFlowCreationOverrideEnabled,
      navigate,
    ]
  );
};
