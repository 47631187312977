import {
  useGiveRecognitionFlowDetails,
  useParticipationModalContext,
} from '@assembly-web/participation';
import { SplitNames, useFeatureSplit } from '@assembly-web/services';
import { useAssemblyNavigate } from '@assembly-web/ui';
import { useCallback } from 'react';

export const useOpenRecognitionParticipation = () => {
  const { isTreatmentActive: isRecognitionParticipationV3Enabled } =
    useFeatureSplit(SplitNames.RecognitionParticipationV3);

  const navigate = useAssemblyNavigate();
  const { openModal } = useParticipationModalContext();

  const { data: giveRecognitionFlow } = useGiveRecognitionFlowDetails();

  return useCallback(
    (editPostId?: string) => {
      if (isRecognitionParticipationV3Enabled) {
        openModal(editPostId);
      } else {
        if (editPostId) {
          navigate(giveRecognitionFlow.getEditLink(editPostId));
        } else {
          navigate(giveRecognitionFlow.link);
        }
      }
    },
    [
      giveRecognitionFlow,
      isRecognitionParticipationV3Enabled,
      navigate,
      openModal,
    ]
  );
};
