import {
  Button,
  Checkbox,
  Modal,
  TextStyle,
  useToastStore,
} from '@assembly-web/ui';
import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';

import { useDoraFeedbackMutation } from '../../hooks/useDoraFeedbackMutation';
import { trackDiscoverAction } from '../../services/analytics';
import {
  aiRecognitionFeedbackMessages,
  doraFeedbackMessages,
  managerInsightsFeedbackMessages,
  messages,
} from '../dora/messages';
import type { DoraFeedbackPromptSource } from '../Drawers/types';

type DoraFeedbackModalProps = {
  modalSource: DoraFeedbackPromptSource;
  onClose: () => void;
  promptId: string | null;
};

type CheckboxValues = {
  notTrue?: boolean;
  notHelpful?: boolean;
  'harmful-unsafe'?: boolean;
  tookTooLong?: boolean;
  irrelevant?: boolean;
  incorrectDataOrAnalysis?: boolean;
  technicalIssues?: boolean;
  other?: boolean;
  exploringNewFeature?: boolean;
  unsureHowToPrompt?: boolean;
  preferManualMethod?: boolean;
};

type CheckboxKey = keyof CheckboxValues;
type AskDoraFeedbackRating = 'UNTRUE' | 'UNHELPFUL' | 'HARMFUL';
type ManagerReportingFeedbackRating =
  | 'TOO_LONG'
  | 'IRRELEVANT'
  | 'INCORRECT_DATA_OR_ANALYSIS'
  | 'TECHNICAL_ISSUES'
  | 'OTHER';
type AIRecognitionFeedbackRating =
  | 'EXPLORING_NEW_FEATURE'
  | 'UNSURE_HOW_TO_PROMPT'
  | 'UNHELPFUL'
  | 'PREFER_MANUAL_METHOD'
  | 'HARMFUL'
  | 'TECHNICAL_ISSUES'
  | 'OTHER';
type Rating =
  | AskDoraFeedbackRating
  | ManagerReportingFeedbackRating
  | AIRecognitionFeedbackRating;

function FeedbackRatingOption({
  onChange,
  id,
  label,
}: {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label: string;
}) {
  return (
    <label htmlFor={id} className="h-6 cursor-pointer text-sm text-gray-9">
      <Checkbox className="mr-2" id={id} onChange={onChange} />
      <span>{label}</span>
    </label>
  );
}

function getModalConfig(modalSource: DoraFeedbackPromptSource) {
  //Feedback for Dora Reporting
  if (modalSource === 'doraChat') {
    return {
      title: managerInsightsFeedbackMessages.title,
      subTitle: managerInsightsFeedbackMessages.subTitle,
      inputPlaceholder: managerInsightsFeedbackMessages.inputPlaceholder,
      submittedFeedbackSuccess: messages.feedbackSubmittedForAnswerMessage,
      ratingOptions: [
        {
          id: 'tookTooLong',
          label: managerInsightsFeedbackMessages.tookTooLong,
        },
        {
          id: 'irrelevant',
          label: managerInsightsFeedbackMessages.irrelevant,
        },
        {
          id: 'incorrectDataOrAnalysis',
          label: managerInsightsFeedbackMessages.incorrectDataOrAnalysis,
        },
        {
          id: 'technicalIssues',
          label: managerInsightsFeedbackMessages.technicalIssue,
        },
        {
          id: 'other',
          label: managerInsightsFeedbackMessages.other,
        },
      ],
    };
  }

  //Feedback for Dora AI Recognition
  if (modalSource === 'aiRecognition') {
    return {
      title: aiRecognitionFeedbackMessages.title,
      subTitle: aiRecognitionFeedbackMessages.subtitle,
      inputPlaceholder: aiRecognitionFeedbackMessages.inputPlaceholder,
      submittedFeedbackSuccess: messages.feedbackSubmittedForDraftMessage,
      ratingOptions: [
        {
          id: 'exploringNewFeature',
          label: aiRecognitionFeedbackMessages.exploringNewFeature,
        },
        {
          id: 'unsureHowToPrompt',
          label: aiRecognitionFeedbackMessages.unsureHowToPrompt,
        },
        {
          id: 'notHelpful',
          label: aiRecognitionFeedbackMessages.notHelpful,
        },
        {
          id: 'preferManualMethod',
          label: aiRecognitionFeedbackMessages.preferManualMethod,
        },
        {
          id: 'other',
          label: aiRecognitionFeedbackMessages.other,
        },
      ],
    };
  }
  //Feedback for Dora QnA
  return {
    title: doraFeedbackMessages.title,
    subTitle: doraFeedbackMessages.subTitle,
    inputPlaceholder: doraFeedbackMessages.inputPlaceholder,
    submittedFeedbackSuccess: messages.feedbackSubmittedForAnswerMessage,
    ratingOptions: [
      {
        id: 'harmful-unsafe',
        label: doraFeedbackMessages.harmful,
      },
      {
        id: 'notTrue',
        label: doraFeedbackMessages.notTrue,
      },
      {
        id: 'notHelpful',
        label: doraFeedbackMessages.notHelpful,
      },
    ],
  };
}

export function DoraFeedbackModal(props: DoraFeedbackModalProps) {
  const { promptId, modalSource, onClose } = props;
  const { showSuccessToast } = useToastStore();
  const { formatMessage } = useIntl();

  const [textAreaValue, setTextAreaValue] = useState('');
  const [checkboxValues, setCheckboxValues] = useState<CheckboxValues>({
    notTrue: false,
    notHelpful: false,
    'harmful-unsafe': false,
    tookTooLong: false,
    irrelevant: false,
    incorrectDataOrAnalysis: false,
    technicalIssues: false,
    other: false,
  });

  const modalConfig = getModalConfig(modalSource);

  const { mutate } = useDoraFeedbackMutation();

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues({
      ...checkboxValues,
      [e.target.id]: e.target.checked,
    });
  };

  const handleOnSendFeedbackButtonClick = useCallback(() => {
    trackDiscoverAction('ratingSendFeedbackClicked', {
      ...checkboxValues,
      openEndedText: textAreaValue,
    });
    onClose();
    if (promptId) {
      const ratingMap: Record<keyof CheckboxValues, Rating> = {
        notTrue: 'UNTRUE',
        notHelpful: 'UNHELPFUL',
        'harmful-unsafe': 'HARMFUL',
        tookTooLong: 'TOO_LONG',
        irrelevant: 'IRRELEVANT',
        incorrectDataOrAnalysis: 'INCORRECT_DATA_OR_ANALYSIS',
        technicalIssues: 'TECHNICAL_ISSUES',
        other: 'OTHER',
        exploringNewFeature: 'EXPLORING_NEW_FEATURE',
        unsureHowToPrompt: 'UNSURE_HOW_TO_PROMPT',
        preferManualMethod: 'PREFER_MANUAL_METHOD',
      };

      const ratings = Object.keys(ratingMap)
        .filter((key) => checkboxValues[key as CheckboxKey])
        .map((key) => ratingMap[key as CheckboxKey]);

      mutate({
        ratings: ratings,
        type_is: 'negative',
        prompt_id: promptId,
        feedback: textAreaValue,
      });
    }
    showSuccessToast(formatMessage(modalConfig.submittedFeedbackSuccess));
  }, [
    mutate,
    onClose,
    promptId,
    formatMessage,
    textAreaValue,
    checkboxValues,
    showSuccessToast,
    modalConfig,
  ]);

  return (
    <Modal title={formatMessage(modalConfig.title)} isOpen onClose={onClose}>
      <TextStyle variant="sm-regular" className="pb-4 pr-8 text-left">
        {formatMessage(modalConfig.subTitle)}
      </TextStyle>

      <textarea
        rows={3}
        onChange={(e) => setTextAreaValue(e.target.value)}
        placeholder={formatMessage(modalConfig.inputPlaceholder)}
        className="mb-1 w-full resize-none rounded-lg px-3 py-4 text-sm text-gray-8 placeholder:text-sm focus:!border-primary-6 focus:!ring-primary-6"
      />

      <div className="flex flex-col">
        {modalConfig.ratingOptions.map((option) => (
          <FeedbackRatingOption
            key={option.id}
            id={option.id}
            onChange={handleCheckboxChange}
            label={formatMessage(option.label)}
          />
        ))}
      </div>

      <section className="mt-3 flex items-center justify-end">
        <Button variation="primary" onClick={handleOnSendFeedbackButtonClick}>
          {formatMessage(messages.sendFeedbackLabel)}
        </Button>
      </section>
    </Modal>
  );
}
