import { SplitNames, useFeatureSplit } from '@assembly-web/services';
import { type ReactNode, Suspense } from 'react';

import { useIsRecognitionOnlyCustomer } from '../../hooks/useIsRecognitionOnlyCustomer';
import {
  FlowsNavSection,
  FlowsNavSectionSkeleton,
} from '../Nav/FlowsNavSection';
import {
  FoldersNavSection,
  FoldersNavSectionSkeleton,
} from '../Nav/Folder/FoldersNavSection';
import { MainNavSection, MainNavSectionSkeleton } from '../Nav/MainNavSection';
import { NavFooter } from '../Nav/NavFooter';
import { NavHeader } from '../Nav/NavHeader';
import { TabletAppHeader } from './AppHeader';
import { SecondaryContentSection } from './SecondaryContent/SecondaryContentSection';

export function TabletLayout({ children }: { children: ReactNode }) {
  const isRecognitionOnlyCustomer = useIsRecognitionOnlyCustomer();

  const { isTreatmentActive: isMSTeamsTestAccount } = useFeatureSplit(
    SplitNames.MSTeamsAppSubmission
  );

  return (
    <div className="theme-surface flex min-h-screen">
      {!isMSTeamsTestAccount && (
        <nav className="group/nav sticky top-0 flex h-screen w-[222px] min-w-[222px] flex-col overflow-hidden border-r-[0.75px] border-gray-5 bg-gray-1 p-4 pr-0 lg:w-[300px] lg:min-w-[300px]">
          <NavHeader />

          <div className="mb-4 mt-10 flex h-screen flex-col gap-8 overflow-y-auto overflow-x-hidden pr-4">
            <Suspense fallback={<MainNavSectionSkeleton />}>
              <MainNavSection
                isRecognitionOnlyCustomer={isRecognitionOnlyCustomer}
              />
            </Suspense>

            <div className="flex flex-col gap-4">
              {!isRecognitionOnlyCustomer && (
                <>
                  <Suspense fallback={<FoldersNavSectionSkeleton />}>
                    <FoldersNavSection />
                  </Suspense>

                  <Suspense fallback={<FlowsNavSectionSkeleton />}>
                    <FlowsNavSection />
                  </Suspense>
                </>
              )}
            </div>
            <NavFooter />
          </div>
        </nav>
      )}

      <main className="theme-surface flex-1 overflow-hidden">
        <TabletAppHeader />

        <div className="relative flex pt-[88px]">
          <div className="feed flex-grow overflow-x-hidden md:max-w-[758px]">
            <SecondaryContentSection className="mx-auto w-full" />

            {children}
          </div>
        </div>
      </main>
    </div>
  );
}
