import type { FlowStatusToDisplay } from '@assembly-web/services';
import { useUserDetails } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { createContext, type ReactNode, useCallback, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useChangeFlowStatusQuery } from '../hooks/useChangeFlowStatusQuery';

const messages = defineMessages({
  flowArchivedSuccessfully: {
    defaultMessage: 'Flow archived successfully!',
    id: 'Wn57bP',
  },
  flowUnArchivedSuccessfully: {
    defaultMessage: 'Flow unarchived successfully!',
    id: 'aJbMBb',
  },
});

export const FlowStatusContext = createContext<{
  cursorPosition: { top: number };
  updateCursorPosition: (pos: { top: number }) => void;
  isArchivingFlow: boolean;
  isUnArchivingFlow: boolean;
  isChangeFlowStatusModalOpen: boolean;
  flowStatusToDisplay: FlowStatusToDisplay;
  changeFlowStatus: () => void;
  setFlowStatusToDisplay: ({
    flowStatus,
    plainFlow,
    flowId,
  }: {
    flowStatus: FlowStatusToDisplay;
    plainFlow: string;
    flowId: string;
  }) => void;
  plainFlow: string;
  closeFlowStatusModal: () => void;
}>({
  cursorPosition: { top: 0 },
  updateCursorPosition: () => {},
  isArchivingFlow: false,
  isUnArchivingFlow: false,
  flowStatusToDisplay: 'archive',
  isChangeFlowStatusModalOpen: false,
  changeFlowStatus: () => {},
  setFlowStatusToDisplay({ flowStatus, plainFlow, flowId }) {
    console.log(flowStatus, plainFlow, flowId);
  },
  plainFlow: '',
  closeFlowStatusModal: () => {},
});

export const FlowStatusProvider = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  const { formatMessage } = useIntl();
  const [flowStatusToDisplay, setFlowStatusToDisplay] =
    useState<FlowStatusToDisplay>('archive');
  const [isChangeFlowStatusModalOpen, setChangeFlowStatusModalOpen] =
    useState(false);
  const [cursorPosition, setCursorPosition] = useState({ top: 0 });
  const [flowDetails, setFlowDetails] = useState({ plainFlow: '', flowId: '' });

  const handleChangeFlowStatusModalClose = () => {
    setChangeFlowStatusModalOpen(false);
  };

  const { mutate: archiveFlow, isPending: isArchivingFlow } =
    useChangeFlowStatusQuery({ flowStatus: 'archive' });

  const { mutate: unarchiveFlow, isPending: isUnArchivingFlow } =
    useChangeFlowStatusQuery({ flowStatus: 'unarchive' });

  const { showSuccessToast } = useToastStore();

  const { data: userDetails } = useUserDetails();

  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;

  const handleChangeFlowStatusClick = () => {
    if (flowStatusToDisplay === 'archive') {
      archiveFlow(
        { flowId: flowDetails.flowId },
        {
          onSuccess: () => {
            handleChangeFlowStatusModalClose();
            showSuccessToast(formatMessage(messages.flowArchivedSuccessfully));
          },
        }
      );
    } else {
      unarchiveFlow(
        { flowId: flowDetails.flowId },
        {
          onSuccess: () => {
            handleChangeFlowStatusModalClose();
            showSuccessToast(
              formatMessage(messages.flowUnArchivedSuccessfully)
            );
            const urlToNavigate = `/${workspaceSlugPath}/flows/${flowDetails.flowId}`;
            window.location.replace(urlToNavigate);
          },
        }
      );
    }
  };

  const handleSetFlowStatusToDisplay = ({
    flowStatus,
    plainFlow,
    flowId,
  }: {
    flowStatus: FlowStatusToDisplay;
    plainFlow: string;
    flowId: string;
  }) => {
    setFlowStatusToDisplay(flowStatus);
    setChangeFlowStatusModalOpen(true);
    setFlowDetails({ plainFlow, flowId });
  };

  const updateCursorPosition = useCallback(
    (pos: { top: number }) => {
      setCursorPosition(pos);
    },
    [setCursorPosition]
  );

  return (
    <FlowStatusContext.Provider
      value={{
        cursorPosition,
        updateCursorPosition,
        isArchivingFlow,
        isUnArchivingFlow,
        flowStatusToDisplay,
        isChangeFlowStatusModalOpen,
        changeFlowStatus: handleChangeFlowStatusClick,
        setFlowStatusToDisplay: handleSetFlowStatusToDisplay,
        plainFlow: flowDetails.plainFlow,
        closeFlowStatusModal: handleChangeFlowStatusModalClose,
      }}
    >
      {children}
    </FlowStatusContext.Provider>
  );
};
