import {
  frequencyIds,
  type MilestoneFrequencyType,
  milestoneIds,
  type MilestoneType,
} from '@assembly-web/services';
import { useMemo } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { defineMessages, useIntl } from 'react-intl';

export type MilestoneOption = {
  label: string;
  id: MilestoneType;
};

export type FrequencyOption = {
  label: string;
  id: MilestoneFrequencyType;
};

type Messages = Record<string, MessageDescriptor>;

const messages: Messages = defineMessages({
  everyDay: {
    defaultMessage: 'Every day',
    id: '1ooK0d',
  },
  everyWeek: {
    defaultMessage: 'Every week',
    id: 'g7bjQR',
  },
  everyMonth: {
    defaultMessage: 'Every month',
    id: 'YaEHga',
  },
  everyQuarter: {
    defaultMessage: 'Every quarter',
    id: 'QT7hEe',
  },
  everyYear: {
    defaultMessage: 'Every year',
    id: '561yCs',
  },
  startDate: {
    defaultMessage: "On the person's start date",
    id: 'KZl0g4',
  },
  oneWeekAfterStartDate: {
    defaultMessage: "1 week after the person who can post's start date",
    id: 'WA0yd0',
  },
  oneMonthAfterStartDate: {
    defaultMessage:
      "1 month (30 days) after the person who can post's start date",
    id: 'Mc/AIy',
  },
  twoMonthsAfterStartDate: {
    defaultMessage:
      "2 months (60 days) after the person who can post's start date",
    id: '+y2zOL',
  },
  threeMonthsAfterStartDate: {
    defaultMessage:
      "3 months (90 days) after the person who can post's start date",
    id: 'zgHCsy',
  },
  oneYearAfterStartDate: {
    defaultMessage: "1 year after the person who can post's start date",
    id: 'wLrxp2',
  },
});

type MilestoneOptions = {
  milestoneOptions: MilestoneOption[];
  frequencyOptions: FrequencyOption[];
};

export function useGetMilestonesOptions(): MilestoneOptions {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    return {
      milestoneOptions: milestoneIds.map((id) => ({
        id,
        label: formatMessage(messages[id]),
      })),
      frequencyOptions: frequencyIds.map((id) => ({
        id,
        label: formatMessage(messages[id]),
      })),
    };
  }, [formatMessage]);
}
