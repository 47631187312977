import { usePusherChannel, usePusherEvent } from '@assembly-web/pusher';
import type { ImportantActivitiesApiResponse } from '@assembly-web/services';
import {
  ActivityCardTypeEnum,
  logger,
  useUserDetails,
} from '@assembly-web/services';
import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

import { useGetImportantActivitiesQuery } from '../../../activity/hooks/useGetImportantActivitiesQuery';
import { getActivityByCardIdQuery } from '../../queries/getActivityByCardIdQuery';

type ActivityUpdatedEvent = {
  cardId: string;
  memberId: string;
  assemblyId: string;
};

type FollowedOrUnfollowedPostEventArgs = {
  postId?: string;
  responseId?: string;
  subscriptionStatus: 'SUBSCRIBE' | 'UNSUBSCRIBE';
};

export function useImportantActivitiesEvents() {
  const queryClient = useQueryClient();
  const { data: userDetails } = useUserDetails();
  const { refetch } = useGetImportantActivitiesQuery();

  const channel = usePusherChannel(
    `private-member-${userDetails?.member.memberId}`
  );

  usePusherEvent(channel, 'CARD_ADDED', async () => {
    try {
      await refetch();
    } catch (e) {
      logger.error('Refetch error on card addition', {}, e as Error);
    }
  });

  usePusherEvent(
    channel,
    'FOLLOWED_OR_UNFOLLOWED_POST',
    async (data: FollowedOrUnfollowedPostEventArgs | undefined) => {
      try {
        const queryCacheKey = ['importantCards'];
        const previousCards =
          queryClient.getQueryData<
            InfiniteData<ImportantActivitiesApiResponse>
          >(queryCacheKey);

        const updatedActivities = produce(previousCards, (draft) => {
          draft?.pages.forEach((page) => {
            page.data.data = page.data.data.map((card) => {
              switch (card.type) {
                case ActivityCardTypeEnum.PostThreadReplies:
                case ActivityCardTypeEnum.OwnerPostReply: {
                  if (card.entity.post.postId === data?.postId) {
                    card.entity.isMuted =
                      data.subscriptionStatus !== 'SUBSCRIBE';
                  }
                  break;
                }

                case ActivityCardTypeEnum.ResponseMentions: {
                  if (card.entity.responseId === data?.responseId) {
                    card.entity.isMuted =
                      data.subscriptionStatus !== 'SUBSCRIBE';
                  }
                  break;
                }

                case ActivityCardTypeEnum.PostMentions:
                case ActivityCardTypeEnum.AnniversaryCelebrationActivity:
                case ActivityCardTypeEnum.BirthdayCelebrationActivity: {
                  if (card.entity.postId === data?.postId) {
                    card.entity.isMuted =
                      data.subscriptionStatus !== 'SUBSCRIBE';
                  }
                  break;
                }

                case ActivityCardTypeEnum.ResponseThreadReplies:
                case ActivityCardTypeEnum.OwnerResponseReply: {
                  if (card.entity.response.responseId === data?.responseId) {
                    card.entity.isMuted =
                      data.subscriptionStatus !== 'SUBSCRIBE';
                  }
                  break;
                }
                default:
                  return card;
              }

              return card;
            });
          });
        });

        queryClient.setQueryData(queryCacheKey, updatedActivities);
      } catch (e) {
        logger.error('Refetch error on  follow/ unfollow post', {}, e as Error);
      }
    }
  );

  usePusherEvent(channel, 'CARD_DELETED', async () => {
    try {
      await refetch();
    } catch (e) {
      logger.error('Refetch error on card deletion', {}, e as Error);
    }
  });

  usePusherEvent<ActivityUpdatedEvent>(
    channel,
    'CARD_UPDATED',
    async (data) => {
      if (data) {
        const { cardId } = data;
        try {
          const response = await queryClient.fetchQuery(
            getActivityByCardIdQuery(cardId)
          );

          const queryCacheKey = ['importantCards'];
          const previousCards =
            queryClient.getQueryData<
              InfiniteData<ImportantActivitiesApiResponse>
            >(queryCacheKey);

          const updatedActivities = produce(previousCards, (draft) => {
            draft?.pages.forEach((page) => {
              page.data.data = page.data.data.map((card) => {
                return card.cardId === cardId ? response : card;
              });
            });
          });

          queryClient.setQueryData(queryCacheKey, updatedActivities);
        } catch (e) {
          logger.error('Update card error', {}, e as Error);
        }
      }
    }
  );
}
