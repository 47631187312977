import { type ReactNode, Suspense, useState } from 'react';
import { Drawer as MobileDrawer } from 'vaul';

import { useIsRecognitionOnlyCustomer } from '../../hooks/useIsRecognitionOnlyCustomer';
import {
  FlowsNavSection,
  FlowsNavSectionSkeleton,
} from '../Nav/FlowsNavSection';
import {
  FoldersNavSection,
  FoldersNavSectionSkeleton,
} from '../Nav/Folder/FoldersNavSection';
import { MainNavSection, MainNavSectionSkeleton } from '../Nav/MainNavSection';
import { NavFooter } from '../Nav/NavFooter';
import { UserMenu } from '../Nav/UserMenu';
import { MobileAppHeader } from './AppHeader';

export function MobileLayout({ children }: { children: ReactNode }) {
  const isRecognitionOnlyCustomer = useIsRecognitionOnlyCustomer();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <MobileDrawer.Root open={showMobileMenu} onOpenChange={setShowMobileMenu}>
      <div className="theme-surface flex min-h-screen flex-col">
        <MobileAppHeader />

        <MobileDrawer.Portal>
          <MobileDrawer.Overlay className="fixed inset-0 bg-gray-10/40" />
          <MobileDrawer.Content
            className="fixed bottom-0 left-0 right-0 h-5/6 bg-gray-1 outline-none"
            onClick={(e) => {
              try {
                const target = e.target as HTMLElement;

                if (target.closest('[data-closable-element]')) {
                  setShowMobileMenu(false);
                }
              } catch {
                // Do nothing
              }
            }}
          >
            <nav className="h-full overflow-y-auto p-6">
              <Suspense fallback={<MainNavSectionSkeleton />}>
                <MainNavSection
                  isRecognitionOnlyCustomer={isRecognitionOnlyCustomer}
                />
              </Suspense>
              <div className="flex flex-col gap-4">
                {!isRecognitionOnlyCustomer && (
                  <>
                    <Suspense fallback={<FoldersNavSectionSkeleton />}>
                      <FoldersNavSection />
                    </Suspense>

                    <Suspense fallback={<FlowsNavSectionSkeleton />}>
                      <FlowsNavSection />
                    </Suspense>
                  </>
                )}
              </div>
              <NavFooter />
              <div className="mt-8">
                <UserMenu />
              </div>
            </nav>
          </MobileDrawer.Content>
        </MobileDrawer.Portal>
        {children}
      </div>
    </MobileDrawer.Root>
  );
}
