import type {
  CollectionsAPIResponse,
  SearchIndexApiResponse,
  SearchPayload,
} from '@assembly-web/services';
import {
  APIEndpoints,
  assemblyAPI,
  getSearchCacheKey,
  logger,
} from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { defineMessages, useIntl } from 'react-intl';

import {
  hiddenFoldersQueryOptions,
  removeHiddenFolderFromNav,
} from '../../../hooks/folder/useHiddenFolders';
import { trackDiscoverError } from '../services/analytics';
import { navItemsQueryKey, removeFolderFromNav } from './nav/useNavData';

const messages = defineMessages({
  deleteError: {
    defaultMessage: "Failed to delete ''{collectionName}'. Please try again.",
    id: 'qDzzQV',
  },
  deleteSuccess: {
    defaultMessage: "Successfully deleted ''{collectionName}'.",
    id: 'VoKHtG',
  },
});

type CollectionDeletePayload = {
  name: string;
  collectionId: string;
  query?: SearchPayload;
};

export function useCollectionsDeleteMutation() {
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();
  const { showErrorToast, showSuccessToast } = useToastStore();

  return useMutation({
    mutationFn: async (payload: CollectionDeletePayload) => {
      return assemblyAPI.put(
        APIEndpoints.deleteCollection(payload.collectionId)
      );
    },
    onMutate: (payload) => {
      const hiddenFolders = queryClient.getQueryData(
        hiddenFoldersQueryOptions.queryKey
      );
      const isHiddenFolder = hiddenFolders?.pages[0].data.some(
        (folder) => folder.id === payload.collectionId
      );
      if (isHiddenFolder) {
        removeHiddenFolderFromNav(queryClient, payload.collectionId);
      } else {
        removeFolderFromNav(queryClient, payload.collectionId);
      }
    },
    onSuccess: (data, payload) => {
      const queryKey = ['collections'];
      const previousCollections =
        queryClient.getQueryData<CollectionsAPIResponse>(queryKey);
      const updatedCollections = produce(previousCollections, (draft) => {
        if (draft) {
          draft.data = draft.data.filter((collection) => {
            return collection.collectionId !== payload.collectionId;
          });
        }
      });

      if (payload.query?.searchTerm) {
        const searchCacheKey = getSearchCacheKey(payload.query);
        const previousCollections =
          queryClient.getQueryData<InfiniteData<SearchIndexApiResponse>>(
            searchCacheKey
          );
        const updatedSearchCollections = produce(
          previousCollections,
          (draft) => {
            draft?.pages.forEach((page) => {
              page.data.data = page.data.data.filter((card) => {
                return !(
                  card.type === 'collection' && card.id === payload.collectionId
                );
              });
            });
          }
        );
        queryClient.setQueryData(searchCacheKey, updatedSearchCollections);
      }

      showSuccessToast(
        formatMessage(messages.deleteSuccess, {
          collectionName: payload.name,
        })
      );
      queryClient.setQueryData(queryKey, updatedCollections);
      queryClient.invalidateQueries({
        queryKey: navItemsQueryKey,
      });
    },
    onError: (err: unknown, payload) => {
      const errorInfo = err instanceof Error ? err : undefined;

      logger.error(
        'Error deleting collection',
        { ...payload, error: errorInfo },
        errorInfo
      );

      trackDiscoverError({ action: 'deleteCollection' });

      showErrorToast(
        formatMessage(messages.deleteError, {
          collectionName: payload.name,
        })
      );
    },
  });
}
