import { useDebounce } from '@assembly-web/services';
import type { FilterType, Viewer } from '@assembly-web/ui';
import { AnnouncementInsightsModal } from '@assembly-web/ui';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { useAnnouncementInsightsViewersQuery } from '../../hooks/useAnnouncementInsightsViewersQuery';
import { trackAnnouncementAction } from '../../services/analytics';

export function AnnouncementsInsights({
  onClose,
  announcementId,
}: {
  onClose: () => void;
  announcementId: string;
}) {
  const [announcementInsightsPayload, setAnnouncementInsightsPayload] =
    useState<{ searchKeyword: string; filterType: FilterType }>({
      searchKeyword: '',
      filterType: 'seen',
    });
  const [totalViews, setTotalViews] = useState<number | null>(null);
  const [uniqueViewers, setUniqueViewers] = useState<number | null>(null);
  const queryClient = useQueryClient();

  const debouncedSearchValue = useDebounce(
    announcementInsightsPayload.searchKeyword,
    500
  );

  const searchKeyword = useMemo(() => {
    return announcementInsightsPayload.searchKeyword
      ? debouncedSearchValue
      : '';
  }, [announcementInsightsPayload.searchKeyword, debouncedSearchValue]);

  const handleSearchChange = (props: {
    searchValue: string;
    filterType: 'seen' | 'unseen';
  }) => {
    if (props.filterType !== announcementInsightsPayload.filterType) {
      trackAnnouncementAction('insightsFiltered');
    }
    setAnnouncementInsightsPayload({
      searchKeyword: props.searchValue.trim(),
      filterType: props.filterType,
    });
  };

  const {
    isPending,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    data: announcementInsightsViewers,
  } = useAnnouncementInsightsViewersQuery({
    announcementId: announcementId,
    searchValue: searchKeyword,
    filterType: announcementInsightsPayload.filterType,
  });

  useEffect(() => {
    if (debouncedSearchValue) {
      trackAnnouncementAction('insightsSearched');
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (announcementInsightsViewers) {
      setTotalViews(announcementInsightsViewers.pages[0].data.data.viewsCount);
      setUniqueViewers(
        announcementInsightsViewers.pages[0].data.data.uniqueViewsCount
      );
    }
  }, [announcementInsightsViewers]);

  const viewers: Viewer[] = useMemo(() => {
    if (!announcementInsightsViewers) return [];
    return announcementInsightsViewers.pages.reduce<Viewer[]>((acc, page) => {
      return [
        ...acc,
        ...page.data.data.members.map((viewer) => ({
          name: viewer.member.name,
          memberID: viewer.member.memberID,
          imageUrl: viewer.member.image,
          cannotViewAnymore: viewer.isCurrentViewer === false,
        })),
      ];
    }, []);
  }, [announcementInsightsViewers]);

  return (
    <AnnouncementInsightsModal
      isOpen={Boolean(announcementId)}
      viewers={viewers}
      totalViews={totalViews}
      hasNextPage={hasNextPage}
      onLoadMore={fetchNextPage}
      uniqueViewers={uniqueViewers}
      isLoading={isPending || isFetching}
      onSearchChange={handleSearchChange}
      isFetchingNextPage={isFetchingNextPage}
      onClose={() => {
        setUniqueViewers(null);
        setTotalViews(null);
        onClose();
        setAnnouncementInsightsPayload({
          searchKeyword: '',
          filterType: 'seen',
        });
        queryClient.removeQueries({
          queryKey: [
            'getAnnouncementInsightsViewers',
            announcementId,
            searchKeyword,
            announcementInsightsPayload.filterType,
          ],
        });
      }}
    />
  );
}
