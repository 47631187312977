import {
  getCanUserCreateFlows,
  MemberRole,
  useUserDetails,
} from '@assembly-web/services';

import { useIsRecognitionOnlyCustomer } from '../../../hooks/useIsRecognitionOnlyCustomer';
import { useActionBar } from './useActionBarQuery';

export const useActionBarOptions = () => {
  const { data: currentUserDetails, isPending: isPendingUserDetails } =
    useUserDetails();

  const isRecognitionOnly = useIsRecognitionOnlyCustomer();

  const { data, isPending: isPendingActionBarQuery } = useActionBar();

  const isAdminOrManager =
    (currentUserDetails?.member.role.includes(MemberRole.Admin) ?? false) ||
    (currentUserDetails?.member.profile.isManager ?? false);

  const hasFlowsToParticipate =
    data.flowsToAnswerAnytime.total > 0 ||
    data.flowsToAnswerNow.total > 0 ||
    data.flowsToDoCount.count > 0;

  const hasFlowsToView = data.flowsToView.length > 0;

  return {
    isPending: isPendingUserDetails || isPendingActionBarQuery,
    isAdminOrManager,
    hasFlowsToParticipate,
    canPostAnnouncement:
      currentUserDetails?.member.permissions.canPostAnnouncements,
    canGenerateReport:
      isAdminOrManager &&
      ((!hasFlowsToView && !hasFlowsToParticipate) || hasFlowsToView),
    canCreateWorkflow: currentUserDetails
      ? getCanUserCreateFlows(currentUserDetails)
      : false,
    canAskDora: true,
    hasFlowsToSummarize: hasFlowsToView,
    giveRecognition: isRecognitionOnly ? 'primary' : 'secondary',
    toDoFlows: data.flowsToDoCount.count,
  } as const;
};
