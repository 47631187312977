import { useSuspenseUserDetails } from '@assembly-web/services';
import { SelectDropdown, TextStyle, Tooltip } from '@assembly-web/ui';
import {
  GlobeAltIcon,
  InformationCircleIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

const labels = defineMessages({
  sharingWith: {
    id: 'D4C9Xy',
    defaultMessage: 'Sharing with:',
  },
  private: {
    id: '7NPsts',
    defaultMessage: 'Private post',
  },
  everyone: {
    id: '2XDuH4',
    defaultMessage: 'Everyone',
  },
  privateToolTip: {
    id: '7TAALH',
    defaultMessage:
      'Your post will be visible to admins, those tagged in your recognition post, and yourself.',
  },
  visibilityPermission: {
    id: 'Vgnv4w',
    defaultMessage: 'Visibility Permission',
  },
});

enum VisibilityPermission {
  Everyone = 'everyone',
  Private = 'private',
}

export function VisibilitySelector() {
  const { control } = useFormContext();
  const { formatMessage } = useIntl();
  const { data: userDetails } = useSuspenseUserDetails();

  const isPrivateRecognitionAllowed =
    userDetails.assembly.settings.privateMessage.enabled;

  const recognitionPermissionOptions = [
    {
      id: VisibilityPermission.Private,
      value: VisibilityPermission.Private,
      label: formatMessage(labels.private),
      icon: <LockClosedIcon className="h-4 w-4" />,
      tooltip: formatMessage(labels.privateToolTip),
    },
    {
      id: VisibilityPermission.Everyone,
      value: VisibilityPermission.Everyone,
      label: formatMessage(labels.everyone),
      icon: <GlobeAltIcon className="h-4 w-4" />,
    },
  ];
  return (
    <div className="flex items-center gap-1">
      <TextStyle>{formatMessage(labels.sharingWith)}</TextStyle>
      {isPrivateRecognitionAllowed ? (
        <Controller
          control={control}
          name="isPrivate"
          render={({ field: { onChange, value: isPrivate } }) => {
            return (
              <SelectDropdown
                label={''}
                aria-label={formatMessage(labels.visibilityPermission)}
                variant="secondary"
                value={
                  isPrivate
                    ? VisibilityPermission.Private
                    : VisibilityPermission.Everyone
                }
                options={recognitionPermissionOptions}
                onSelect={(selectedOption) => {
                  onChange(selectedOption === VisibilityPermission.Private);
                }}
                renderOption={(option) => (
                  <div className="flex items-center gap-2">
                    {option.icon}
                    <TextStyle>{option.label}</TextStyle>
                    {Boolean(option.tooltip) && (
                      <Tooltip tooltipText={option.tooltip}>
                        <InformationCircleIcon className="h-4 w-4 text-primary-6" />
                      </Tooltip>
                    )}
                  </div>
                )}
              />
            );
          }}
        />
      ) : (
        <div className="flex items-center gap-2 rounded-2xl bg-primary-1 px-3 py-1">
          <GlobeAltIcon className="h-4 w-4" />
          <TextStyle variant="sm-medium">
            {formatMessage(labels.everyone)}
          </TextStyle>
        </div>
      )}
    </div>
  );
}
