import {
  type AssemblySearchResult,
  GlobalFilterOption,
  isTruthy,
  type SearchIndexResult,
  UserActivitySortType,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { Button, CardSkeletonLoader } from '@assembly-web/ui';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import { useGetSearchResultPayload } from '../../../hooks/useGetSearchResultsPayload';
import { EmptySearchResults } from '../../discover/components/EmptySearchResults';
import { useUserFeedUpdatedEvents } from '../../discover/hooks/domainEvents/useUserFeedUpdatedEvents';
import { useGetSecondaryFilters } from '../../discover/hooks/secondaryFilters/useGetSecondaryFilters';
import { useGetUserFeed } from '../../discover/hooks/useGetUserFeed';
import { useGlobalFilter } from '../../discover/hooks/useGlobalFilter';
import { useSearchIndex } from '../../discover/hooks/useSearchIndex';
import type { GetUserFeedPayload } from '../../discover/queries/getUserFeedQuery';
import { RecognitionFeedEmptySearchState } from '../EmptySearchState';
import { RecognitionFeedEmptyState } from '../EmptyState';
import { RecognitionFeedDetails } from './RecognitionFeedDetails';

const messages = defineMessages({
  seeNewActivity: {
    defaultMessage: 'See new activity',
    id: '0c4bcT',
  },
});

const recognitionTypesForPusher = ['post', 'groupedComment'];

export function RecognitionFeedContainer() {
  const { data: userDetails } = useSuspenseUserDetails();
  const filter = useGlobalFilter();
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get('search') ?? '';
  const { formatMessage } = useIntl();

  const {
    fromRef,
    postContentType,
    mentionedMemberIds,
    dateCreated,
    dueDate,
    isSecondaryFiltersApplied,
  } = useGetSecondaryFilters();

  const userFeedPayload: GetUserFeedPayload = {
    filters: {
      type: [filter],
    },
    userActivitySortType: UserActivitySortType.Relevance,
    secondaryFilters: {
      flowStatus: ['ACTIVE'],
      entityIn: ['post'],
      fromRef,
      postContentType,
      mentionedMemberIds,
      dateCreated,
      dueDate,
    },
    excludeRecognitionFlow: userDetails.member.status === 'observer',
    populateCardDetails: true,
  };
  const {
    data: userFeedResponse,
    isPending: isLoadingUserFeed,
    isFetchingNextPage: isFetchingUserFeed,
    hasNextPage: hasNextUserFeedPage,
    fetchNextPage: fetchNextUserFeedPage,
    refetch: refetchUserFeed,
    isError: isUserFeedError,
  } = useGetUserFeed(userFeedPayload);

  const searchResultPayload = useGetSearchResultPayload();

  const {
    data: searchResults,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isPending,
    isError: isSearchError,
  } = useSearchIndex({
    ...searchResultPayload,
    enabled: Boolean(searchTerm),
  });

  const userFeedCardData = useMemo(() => {
    if (!userFeedResponse) {
      return [];
    }
    const results = userFeedResponse.pages.reduce<AssemblySearchResult[]>(
      (acc, page) => [...acc, ...page.data.sort((x, y) => y.score - x.score)],
      []
    );
    return results;
  }, [userFeedResponse]);

  const searchResultsCardData = useMemo(() => {
    if (!searchResults) {
      return [];
    }
    return searchResults.pages.reduce<SearchIndexResult[]>(
      (acc, page) => [
        ...acc,
        ...page.data.data.sort((x, y) => y.score - x.score),
      ],
      []
    );
  }, [searchResults]);

  const feedSortMenuRef = useRef<HTMLDivElement | null>(null);
  const [showSeeNewActivityButton, setShowSeeNewActivityButton] =
    useState<boolean>(false);
  const [isUserViewingFeedSection, setIsUserViewingFeedSection] =
    useState<boolean>(false);

  useEffect(() => {
    const checkDistance = () => {
      if (feedSortMenuRef.current) {
        const rect = feedSortMenuRef.current.getBoundingClientRect();
        setIsUserViewingFeedSection(rect.top < 102);
      }
    };

    window.addEventListener('scroll', checkDistance);

    return () => {
      window.removeEventListener('scroll', checkDistance);
    };
  }, []);

  const isSearching = searchTerm && (isPending || isFetchingNextPage);

  const handleSeeNewActivityButtonClick = async () => {
    feedSortMenuRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
    await refetchUserFeed();
    setShowSeeNewActivityButton(false);
  };

  useUserFeedUpdatedEvents({
    feedUpdated: (type) => {
      if (type?.some((item) => recognitionTypesForPusher.includes(item))) {
        setShowSeeNewActivityButton(true);
      }
    },
  });

  const lastPageRef = useRef<HTMLDivElement | null>(null);
  useInViewport(
    lastPageRef,
    {},
    {
      disconnectOnLeave: false,
    },
    {
      onEnterViewport: async () => {
        if (searchTerm) {
          if (hasNextPage && !isFetchingNextPage) {
            await fetchNextPage();
          }
        } else {
          if (hasNextUserFeedPage && !isFetchingUserFeed) {
            await fetchNextUserFeedPage();
          }
        }
      },
    }
  );

  const feedData = searchTerm ? searchResultsCardData : userFeedCardData;

  const payload = searchTerm ? searchResultPayload : userFeedPayload;

  const renderEmptyState = () => {
    return searchTerm &&
      (searchResultsCardData.length === 0 || isSearchError) ? (
      <RecognitionFeedEmptySearchState />
    ) : (
      (userFeedCardData.length === 0 || isUserFeedError) && (
        <RecognitionFeedEmptyState />
      )
    );
  };

  return (
    <>
      {isTruthy(showSeeNewActivityButton) && (
        <header
          ref={feedSortMenuRef}
          className="relative flex w-full items-center justify-center"
        >
          <Button
            className={twJoin(
              'z-50 cursor-pointer',
              isUserViewingFeedSection ? 'fixed top-[100px]' : 'absolute'
            )}
            variation="secondaryEmphasized"
            onClick={handleSeeNewActivityButtonClick}
            size="small"
          >
            <div className="h-4 w-4">
              {isUserViewingFeedSection ? (
                <ArrowUpCircleIcon title="arrow-up-icon" />
              ) : (
                <ArrowDownCircleIcon title="arrow-down-icon" />
              )}
            </div>
            {formatMessage(messages.seeNewActivity)}
          </Button>
        </header>
      )}
      <RecognitionFeedDetails payload={payload} feedData={feedData} />
      {isLoadingUserFeed || isFetchingUserFeed || isSearching ? (
        <CardSkeletonLoader />
      ) : isSecondaryFiltersApplied ? (
        feedData.length === 0 && (
          <EmptySearchResults
            filter={GlobalFilterOption.Recognition}
            searchTerm={searchTerm}
            userDetails={userDetails}
            isSecondaryFiltersApplied={isSecondaryFiltersApplied}
          />
        )
      ) : (
        renderEmptyState()
      )}
      {Boolean(hasNextUserFeedPage) && (
        <div ref={lastPageRef} className="h-14" />
      )}
    </>
  );
}
