import {
  checkIfCommentKindMatches,
  config,
  copyToClipboard,
  formatAssemblySearchResult,
  type ItemToAdd,
  type ResponseDetails,
  type SearchIndexResult,
  type SearchPayload,
  useSuspenseUserDetails,
} from '@assembly-web/services';
import { useAssemblyNavigate, useToastStore } from '@assembly-web/ui';
import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import { usePosts } from '../../../hooks/usePosts';
import { useReplies } from '../../../hooks/useReplies';
import type { PostData } from '../../../types/postsAndReplies';
import { CommentCard } from '../../discover/components/cards/CommentCard';
import { GroupedCommentCard } from '../../discover/components/cards/GroupedCommentCard';
import { messages } from '../../discover/components/cards/messages';
import { PostCard } from '../../discover/components/cards/PostCard';
import type { ToolbarItemClickArgs } from '../../discover/components/cards/type';
import { ToolbarItemId } from '../../discover/components/cards/utils';
import { CollectionModalsContext } from '../../discover/contexts/CollectionModalsContext';
import { ModalsContext } from '../../discover/contexts/ModalsContext';
import { useOpenRecognitionParticipation } from '../../discover/hooks/participation/useOpenRecognitionParticipation';
import { useGlobalFilter } from '../../discover/hooks/useGlobalFilter';
import { useUpdateAssemblyPostNotificationMutationQuery } from '../../discover/hooks/useUpdateAssemblyPostNotificationPreferenceMutationQuery';
import type { GetUserFeedPayload } from '../../discover/queries/getUserFeedQuery';
import {
  mapToolbarItemToAction,
  trackCardAction,
} from '../../discover/services/analytics';
import { getFlowOrRecognitionPostLinkToCopy } from '../../discover/services/common';
import { isAdminMember } from '../../discover/services/member';

export function RecognitionFeedDetails({
  feedData,
  payload,
}: {
  feedData: SearchIndexResult[];
  payload: GetUserFeedPayload | SearchPayload;
}) {
  const { data: userDetails } = useSuspenseUserDetails();
  const filter = useGlobalFilter();
  const { formatMessage } = useIntl();
  const recognize = useOpenRecognitionParticipation();

  const trackCardActionEvent = (
    id: string,
    cardParams: {
      cardPosition: number;
      cardType: string;
      flowName?: string;
      collectionName?: string;
    }
  ) => {
    const actionName = mapToolbarItemToAction(id as ToolbarItemId);
    trackCardAction(actionName, {
      isNotification: false,

      ...cardParams,
    });
  };

  const navigate = useAssemblyNavigate();
  const { showSuccessToast, showErrorToast } = useToastStore();
  const { openAddToCollectionModal } = useContext(CollectionModalsContext);
  const workspaceSlugPath = userDetails.assembly.workspaceSlugPath;

  const { onReplyClicked } = useReplies();
  const { onPostClicked } = usePosts();
  const { mutate: updateAssemblyPostNotificationPreference } =
    useUpdateAssemblyPostNotificationMutationQuery();

  const { onDeleteReplyOrPostClick: onDeletePostClick } =
    useContext(ModalsContext);

  const handleOnToolbarMenuItemClick = useCallback(
    async (args: ToolbarItemClickArgs) => {
      switch (args.id) {
        case ToolbarItemId.GoToLink:
          window.open(args.url, '_blank', 'noopener,noreferrer');
          break;
        case ToolbarItemId.GoToFlow:
        case ToolbarItemId.GoToMember:
        case ToolbarItemId.GoToPostForFileCard:
        case ToolbarItemId.GoToNotebook:
          navigate(args.url);
          break;
        case ToolbarItemId.CopyLink:
        case ToolbarItemId.CopyLinkForFileCard:
          // used on search
          {
            const baseUrl = config.domains.app;
            const shouldCreateV3Link =
              'type' in args &&
              (args.type === 'post' || args.type === 'comment');
            const linkToCopy = shouldCreateV3Link
              ? getFlowOrRecognitionPostLinkToCopy({
                  commentId: 'commentId' in args ? args.commentId : '',
                  postId: 'postId' in args && args.postId ? args.postId : '',
                  type: 'post',
                })
              : `${args.url}`;
            await copyToClipboard(`${baseUrl}${linkToCopy}`);
            showSuccessToast(formatMessage(messages.copiedToClipboard));
          }
          break;
        case ToolbarItemId.CopyWebLink:
          {
            await copyToClipboard(args.url);
            showSuccessToast(formatMessage(messages.copiedToClipboard));
          }
          break;
        case ToolbarItemId.EditPost:
          if ('postId' in args) {
            recognize(args.postId);
          }
          break;
        case ToolbarItemId.EditReply:
          'postId' in args &&
            args.postId &&
            onReplyClicked({
              type: 'post',
              postId: args.postId,
              commentId: 'commentId' in args ? args.commentId : '',
              editSelectedCommentId: 'commentId' in args ? args.commentId : '',
            } satisfies PostData);
          break;
        case ToolbarItemId.AddToCollection:
          if ('type' in args) {
            let item: ItemToAdd;
            if (args.type === 'link') {
              item = {
                entityId: args.entityId,
                icon: args.cardIcon,
                type: args.type,
                link: {
                  url: args.url,
                  title: args.title,
                },
              };
            } else {
              item = {
                icon: args.cardIcon,
                name: args.title,
                type: args.type,
                entityId: args.entityId,
              };
              if ('responseId' in args && args.type === 'file') {
                item.responseId = args.responseId;
              }
            }
            openAddToCollectionModal(item);
          }
          break;
        case ToolbarItemId.ManageInteractionSettings: {
          let responseDetailsPayload: ResponseDetails | undefined;
          if (
            'entityId' in args &&
            'allowReplies' in args &&
            'allowReactions' in args &&
            'plainFlow' in args &&
            'author' in args
          ) {
            responseDetailsPayload = {
              flowId: '',
              responseId: args.responseId ?? args.entityId,
              allowReactions: args.allowReactions ?? false,
              allowReplies: args.allowReplies ?? false,
              flowName: args.plainFlow ?? '',
              author: args.author ?? '',
              emoticon: '',
            };
            trackCardAction('editPostInteractionsClicked', {
              isRepliesDisabled: !args.allowReplies,
              isReactionsDisabled: !args.allowReactions,
            });
            window.postMessage(
              {
                type: 'OPEN_CONFIGURE_POST_INTERACTION_SETTINGS_MODAL',
                payload: responseDetailsPayload,
              },
              window.location.origin
            );
          }
          break;
        }
        case ToolbarItemId.PostNotificationPreference:
          {
            const handleNotificationPreferenceChangeSuccess = () => {
              showSuccessToast(
                formatMessage(messages.notificationPreferenceSuccess, {
                  action:
                    'isMuted' in args && args.isMuted
                      ? 'followed'
                      : 'unfollowed',
                  author:
                    'firstName' in args && args.firstName ? args.firstName : '',
                })
              );
            };
            const handleNotificationPreferenceChangeError = () => {
              showErrorToast(
                formatMessage(messages.notificationPreferenceError, {
                  action:
                    'isMuted' in args && args.isMuted ? 'follow' : 'unfollow',
                })
              );
            };
            const postType =
              'type' in args && args.type === 'response' ? 'RESPONSE' : 'POST';
            const flowId = 'recognition';
            const responseId =
              'responseId' in args && args.responseId
                ? args.responseId
                : 'entityId' in args
                  ? args.entityId
                  : '';
            updateAssemblyPostNotificationPreference(
              {
                postType,
                flowId,
                responseId,
                type:
                  'isMuted' in args && args.isMuted
                    ? 'SUBSCRIBE'
                    : 'UNSUBSCRIBE',
              },
              {
                onSuccess: handleNotificationPreferenceChangeSuccess,
                onError: handleNotificationPreferenceChangeError,
              }
            );
          }
          break;
        case ToolbarItemId.GoToPost:
          if ('postId' in args && args.postId) {
            const openPostPayload: PostData = {
              type: 'post',
              postId: args.postId,
            };
            if ('commentId' in args && args.commentId) {
              onReplyClicked({
                ...openPostPayload,
                commentId: args.commentId,
              });
            }
            onPostClicked(openPostPayload);
          }

          break;
        case ToolbarItemId.DeleteAssemblyPost:
          {
            const flowId = 'recognition';
            const responseId = 'entityId' in args ? args.entityId : '';
            onDeletePostClick({
              replyId: null,
              flowId,
              responseId,
              hasTrophies: 'hasTrophies' in args ? args.hasTrophies : false,
            });
          }
          break;
      }
    },
    [
      formatMessage,
      navigate,
      onDeletePostClick,
      onPostClicked,
      onReplyClicked,
      openAddToCollectionModal,
      recognize,
      showErrorToast,
      showSuccessToast,
      updateAssemblyPostNotificationPreference,
    ]
  );

  return feedData.map((result, index) => {
    const commonProps = {
      filter,
      workspaceName: userDetails.assembly.name,
      workspaceSlugPath,
      cardId: result.id,
      cardPosition: index,
      currentUserId: userDetails.member.memberId,
      isAdmin: Boolean(isAdminMember),
      trackCardActionEvent,
      postResponse: result.cardDetails,
      payload,
      currencyDetails: userDetails.assembly.currency,
    };
    if (result.type === 'post') {
      const card = { ...formatAssemblySearchResult(result) };
      return (
        <PostCard
          key={result.id}
          {...commonProps}
          {...card}
          onClick={() => {
            trackCardAction('cardClicked', {
              cardType: 'flow',
              flowName: card.flow,
              isNotification: false,
              cardPosition: index,
              cardDestinationUrl: `/${workspaceSlugPath}${card.urlSlug}`,
            });
          }}
          onPostClick={() => {
            onPostClicked({
              type: 'post',
              postId: card.postId,
            });
          }}
          workspaceSlugPath={workspaceSlugPath}
          disableNavigation
          onToolbarMenuItemClick={handleOnToolbarMenuItemClick}
        />
      );
    } else if (result.type === 'groupedComment') {
      if (checkIfCommentKindMatches(result, 'post')) {
        const card = {
          ...formatAssemblySearchResult(result),
        };
        return (
          <GroupedCommentCard
            {...commonProps}
            {...card}
            key={result.id}
            userDetails={userDetails}
            onToolbarMenuItemClick={handleOnToolbarMenuItemClick}
          />
        );
      }
    } else if (result.type === 'comment') {
      if (checkIfCommentKindMatches(result, 'post')) {
        const card = {
          ...formatAssemblySearchResult(result),
        };
        return (
          <CommentCard
            key={result.id}
            {...commonProps}
            {...card}
            userDetails={userDetails}
            postId={card.postId}
            onClick={() => {
              trackCardAction('cardClicked', {
                cardType: 'reply',
                flowName: card.flow,
                flowId: card.flowId,
                isNotification: false,
                cardPosition: index,
                cardDestinationUrl: `/${workspaceSlugPath}/post/flow/recognition?postId=${card.postId}&commentId=${card.commentId}&type=recognition&showReplies=true`,
                searchQuery: '',
              });
            }}
            onToolbarMenuItemClick={handleOnToolbarMenuItemClick}
            onReplyClick={() => {
              const openReplyPayload: PostData = {
                type: 'post',
                postId: card.postId,
                commentId: card.commentId,
              };
              onReplyClicked(openReplyPayload);
            }}
            disableNavigation
          />
        );
      }
    }
    return;
  });
}
