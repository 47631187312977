import { config, logger, SSOProvider } from '@assembly-web/services';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

type GooglePlatformScriptLoaderOptions = {
  width?: string;
};

/**
 * A hook to load the Google client script & initialize it
 * @returns {boolean} A flag that indicates whether the Google api is loaded successfully
 */
export function useGooglePlatformScriptLoader(
  options: GooglePlatformScriptLoaderOptions = {
    width: '100%',
  }
) {
  const location = useLocation();
  const [googleScriptLoaded, setGoogleScriptLoaded] = useState(false);

  const { locale } = useIntl();

  useEffect(() => {
    if (!googleScriptLoaded) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        try {
          google.accounts.id.initialize({
            client_id: config.sso.gSuite.clientId,
            callback: (response) => {
              const baseURL = `${config.domains.app}/sso-sign-in/${SSOProvider.Google}`;
              window.location.href = `${baseURL}?idToken=${response.credential}`;
            },
          });

          const googleSignInButton = document.getElementById(
            'google-signin-button'
          );

          if (googleSignInButton) {
            const submitButton = document.querySelector('[type=submit]');
            google.accounts.id.renderButton(googleSignInButton, {
              theme: 'outline',
              size: 'large',
              type: 'standard',
              logo_alignment: 'center',
              locale,
              text: location.pathname.startsWith('/login')
                ? 'signin_with'
                : 'signup_with',
              width: submitButton
                ? `${submitButton.clientWidth}px`
                : options.width,
            });
          }

          setGoogleScriptLoaded(true);
        } catch {
          logger.error('Unable to initialize google script');
        }
      };

      document.body.appendChild(script);
    }
  }, [googleScriptLoaded, locale, location.pathname, options.width]);
  return googleScriptLoaded;
}
