import '@assembly-web/ui/lib/ui-styles.css';
import '@total-typescript/ts-reset';
import './services/polyfill';

import { PusherProvider } from '@assembly-web/pusher';
import {
  config,
  fetchUserDetails,
  getMobilePlatformFromURL,
  getUserDetailsQuery,
  initializeSentry,
  isTruthy,
  type MemberAPIResponse,
  queryClientOptions,
  ReactQueryDevTool,
  routeConstants,
  userAuthStore,
} from '@assembly-web/services';
import { TailwindBreakpointIndicator } from '@assembly-web/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import mixpanel from 'mixpanel-browser';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Intl } from './components/Intl';
import { Router } from './Router';
import { initializeMSTeamsContext } from './services/msTeamsInitialization';

(async function RenderApp() {
  // NOTE: we initialize the call without awaiting here to avoid blocking the following code
  const initialUserDetailsPromise: Promise<MemberAPIResponse> =
    fetchUserDetails();

  const container = document.getElementById('assembly_app');

  if (!container) {
    return;
  }

  await initializeSentry();

  if (config.env === 'prod') {
    mixpanel.init(config.mixpanelToken);
  }

  await initializeMSTeamsContext();

  const searchParams = new URLSearchParams(window.location.search);

  if (config.isMobileDevMode) {
    const jwtToken = decodeURIComponent(
      searchParams.get('mobileAuthToken') ?? ''
    );
    const refreshToken = decodeURIComponent(
      searchParams.get('mobileRefreshToken') ?? ''
    );
    if (jwtToken) {
      userAuthStore.getState().setJwtToken(jwtToken);
    }
    if (refreshToken) {
      userAuthStore.getState().setRefreshToken(refreshToken);
    }
  }

  const mobilePlatform = getMobilePlatformFromURL();
  if (mobilePlatform) {
    userAuthStore.getState().setIsLoginViaMobileApp(true);
    userAuthStore.getState().setMobilePlatform(mobilePlatform);

    if (searchParams.get('isMobileAppV3') === 'true') {
      userAuthStore.getState().setIsMobileAppV3(true);
    }
    if (
      routeConstants.legacyExperiencePaths.some((path) =>
        window.location.href.includes(path)
      )
    ) {
      userAuthStore.getState().setCloseDrawerOnNavigateBack(true);
    }
  }

  const queryClient = new QueryClient(queryClientOptions);

  try {
    const initialUserDetails = await initialUserDetailsPromise;
    const { queryKey } = getUserDetailsQuery();
    queryClient.setQueryData(queryKey, initialUserDetails);
  } catch (error) {
    //
  }

  createRoot(container).render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Intl>
          <HelmetProvider>
            <Helmet
              titleTemplate="%s | Assembly"
              defaultTitle="Assembly - Empower your team"
            />
            <PusherProvider {...config.pusher}>
              <ReactQueryDevTool />
              <Router />
            </PusherProvider>
          </HelmetProvider>
        </Intl>
      </QueryClientProvider>

      {isTruthy(import.meta.env.VITE_SHOW_TW_BPI) && (
        <TailwindBreakpointIndicator />
      )}
    </StrictMode>
  );
})();
