import { Avatar, AvatarSize, TextStyle } from '@assembly-web/ui';
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

import type { ChatDrawer } from '../types';

const messages = defineMessages({
  directMessageToLabel: {
    defaultMessage: 'Direct message to {firstName} {lastName}',
    id: 'rxbuEl',
  },
});

export function ChatDrawerHeader({
  data: { memberId, firstName, image, lastName },
}: ChatDrawer) {
  const { formatMessage } = useIntl();

  return (
    <section className="flex flex-1 items-center gap-2 overflow-hidden">
      <ChatBubbleOvalLeftEllipsisIcon className="h-6 w-6 flex-shrink-0 stroke-current text-gray-1" />
      <span className="block h-4 w-[1px] flex-shrink-0 bg-gray-7" />
      <div className="flex flex-1 items-center gap-1 overflow-hidden">
        <Avatar
          image={image}
          name={firstName}
          memberID={memberId}
          size={AvatarSize.Small}
        />
        <TextStyle
          as="span"
          variant="xs-regular"
          className="truncate text-gray-1"
        >
          {formatMessage(messages.directMessageToLabel, {
            firstName,
            lastName,
          }).trim()}
        </TextStyle>
      </div>
    </section>
  );
}
