import { closeIcon } from '@assembly-web/assets';
import { TextField } from '@assembly-web/ui';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { defineMessages, useIntl } from 'react-intl';

export const messages = defineMessages({
  flows: {
    id: '3Pbj9T',
    defaultMessage: 'Flows',
  },
  files: {
    id: 'm4vqJl',
    defaultMessage: 'Files',
  },
  searchAll: {
    id: 'jVgvg4',
    defaultMessage: 'Search All',
  },
  noResultsFound: {
    defaultMessage:
      'We couldn’t find any files for “{searchTerm}”. Please try a different search.',
    id: '6bpBvy',
  },
  added: {
    defaultMessage: 'Added!',
    id: '7I3Tsn',
  },
  removed: {
    defaultMessage: 'Removed!',
    id: '3BQ0Gt',
  },
  searchPlaceholder: {
    defaultMessage: 'Search for the item to add and click the +',
    id: 'FG/Qag',
  },
  back: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
});

export function SkeletonLoader() {
  return (
    <div className="flex animate-pulse items-center gap-x-2 p-1.5 pl-2.5">
      <div className="h-4 w-4 rounded-full bg-gray-3 pl-1.5" />
      <div className="h-6 w-full rounded-md bg-gray-3 p-1.5" />
    </div>
  );
}

export function NoResultsFound({ searchTerm }: { searchTerm: string }) {
  const { formatMessage } = useIntl();
  return (
    <div className="flex items-start gap-x-2 rounded-md bg-primary-1 p-1.5 text-primary-7">
      <InformationCircleIcon className="h-5 w-5 flex-shrink-0" />
      <div className="text-sm">
        {formatMessage(messages.noResultsFound, { searchTerm })}
      </div>
    </div>
  );
}

export function SearchBar({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="relative w-full overflow-hidden rounded-lg border border-primary-4 text-sm">
      <TextField
        aria-label={formatMessage(messages.searchAll)}
        inputClassName="border-0 text-sm"
        onChange={(e) => setSearchTerm(e.target.value)}
        value={searchTerm}
        connectedLeft={<MagnifyingGlassIcon className="h-4 w-4 text-gray-9" />}
        connectedRight={
          searchTerm.length > 0 ? (
            <button
              type="button"
              className="flex h-full cursor-pointer items-center justify-center p-2"
              onClick={() => setSearchTerm('')}
            >
              <img src={closeIcon} alt="" />
            </button>
          ) : null
        }
      />

      {!searchTerm ? (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-start gap-x-1 pl-5 text-gray-8">
          <span className="pl-4">
            {formatMessage(messages.searchPlaceholder)}
          </span>
        </div>
      ) : null}
    </div>
  );
}
