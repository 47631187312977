import { PlanStatus } from '@assembly-web/services';

import { useGetPlanFeatures } from '../modules/discover/hooks/useGetPlanFeaturesQuery';

export function useIsRecognitionOnlyCustomer() {
  const planFeatures = useGetPlanFeatures();

  return planFeatures.data.features.some(
    (feature) =>
      feature.id === 'ALLOW_CORE_FLOWS' &&
      feature.status === PlanStatus.Disabled
  );
}
