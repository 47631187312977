import type { Member } from '@assembly-web/services';
import {
  CriteriaRuleType,
  MemberState,
  PermissionType,
} from '@assembly-web/services';
import type { CriteriaItemPermission } from '@assembly-web/ui';
import { AvatarSize } from '@assembly-web/ui';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  ClipboardDocumentListIcon,
  HomeIcon,
  IdentificationIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { UserIcon } from '@heroicons/react/24/solid';
import type { ReactNode } from 'react';
import type { IntlShape } from 'react-intl';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  viewerLabel: {
    defaultMessage: 'Viewer',
    id: 'IAqayC',
  },
  collaboratorLabel: {
    defaultMessage: 'Collaborator',
    id: 'P1AKC5',
  },
  removeRuleLabel: {
    defaultMessage: 'Remove rule',
    id: 'E8UYvP',
  },
  exclusionTitle: {
    defaultMessage: 'Exclude from access rules',
    id: '6Z4zqY',
  },
  exclusionLabel: {
    defaultMessage: 'Excluded',
    id: 'sCOZQ4',
  },
  exclusionSubtitleForFolder: {
    defaultMessage: 'Can’t view or collaborate on folder',
    id: 'IRm4Ly',
  },
  selectableLabel: {
    defaultMessage: 'Selectable person',
    id: '/UU4/8',
  },
  removePersonLabel: {
    defaultMessage: 'Remove person',
    id: 'onUk2A',
  },
  removeLabel: {
    defaultMessage: 'Remove',
    id: 'G/yZLu',
  },
  ownerTooltipText: {
    defaultMessage:
      'Owners can always edit, share, and view the folder. These permissions cannot be modified.',
    id: 'ZEL1cR',
  },
  ownerLabel: {
    defaultMessage: 'Owner',
    id: 'zINlao',
  },
});

export const CriteriaCardIconMap: Record<string, ReactNode> = {
  department: (
    <BriefcaseIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
  homeLocation: <HomeIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />,
  workLocation: (
    <BuildingOfficeIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
  jobTitle: (
    <IdentificationIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
  manager: (
    <ClipboardDocumentListIcon
      className="h-6 w-6 text-gray-1"
      aria-hidden="true"
    />
  ),
  pending: <UserPlusIcon className="h-6 w-6 text-gray-9" aria-hidden="true" />,
  active: <UserCircleIcon className="h-6 w-6 text-gray-9" aria-hidden="true" />,
  everyone: (
    <UserGroupIcon className="h-6 w-6 text-gray-1" aria-hidden="true" />
  ),
};

export function getPermissionOptionsWithoutExclusion(
  formatMessage: IntlShape['formatMessage']
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: [
          {
            id: PermissionType.Viewer,
            label: formatMessage(messages.viewerLabel),
            displayLabel: formatMessage(messages.viewerLabel),
          },
          {
            id: PermissionType.Collaborator,
            label: formatMessage(messages.collaboratorLabel),
            displayLabel: formatMessage(messages.collaboratorLabel),
          },
        ],
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removeRuleLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

function getPositiveOptionsWithExclusion(
  formatMessage: IntlShape['formatMessage']
) {
  return [
    {
      id: PermissionType.Viewer,
      label: formatMessage(messages.viewerLabel),
      displayLabel: formatMessage(messages.viewerLabel),
    },
    {
      id: PermissionType.Collaborator,
      label: formatMessage(messages.collaboratorLabel),
      displayLabel: formatMessage(messages.collaboratorLabel),
    },
    {
      id: PermissionType.Excluded,
      label: formatMessage(messages.exclusionTitle),
      displayLabel: formatMessage(messages.exclusionLabel),
      subText: formatMessage(messages.exclusionSubtitleForFolder),
    },
  ];
}

export function getNonMemberPermissionOptions(
  formatMessage: IntlShape['formatMessage']
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: getPositiveOptionsWithExclusion(formatMessage),
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removeRuleLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

export function getMemberPermissionOptions(
  formatMessage: IntlShape['formatMessage']
): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: getPositiveOptionsWithExclusion(formatMessage),
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeRuleLabel),
            displayLabel: formatMessage(messages.removePersonLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

export function getPermissionOptions({
  type,
  isOwner,
  formatMessage,
}: {
  type: CriteriaRuleType;
  isOwner?: boolean;
  formatMessage: IntlShape['formatMessage'];
}): CriteriaItemPermission {
  if (isOwner) {
    return {
      isLocked: true,
      tooltipText: formatMessage(messages.ownerTooltipText),
    };
  }

  if (type === CriteriaRuleType.Member || type === CriteriaRuleType.Email) {
    return getMemberPermissionOptions(formatMessage);
  }

  if (type === CriteriaRuleType.Everyone) {
    return getPermissionOptionsWithoutExclusion(formatMessage);
  }

  return getNonMemberPermissionOptions(formatMessage);
}

export function getOwnerAndCollaboratorPermissionOptions({
  formatMessage,
}: {
  formatMessage: IntlShape['formatMessage'];
}): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: [
          {
            id: PermissionType.Owner,
            label: formatMessage(messages.ownerLabel),
            displayLabel: formatMessage(messages.ownerLabel),
          },
          {
            id: PermissionType.Collaborator,
            label: formatMessage(messages.collaboratorLabel),
            displayLabel: formatMessage(messages.collaboratorLabel),
          },
        ],
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removeLabel),
            displayLabel: formatMessage(messages.removeLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

export function getPeopleSelectorPermissionOptions({
  formatMessage,
}: {
  formatMessage: IntlShape['formatMessage'];
}): CriteriaItemPermission {
  return {
    isLocked: false,
    dropdownOptions: [
      {
        id: 'positive',
        options: [
          {
            id: PermissionType.Custom,
            label: formatMessage(messages.selectableLabel),
            displayLabel: formatMessage(messages.selectableLabel),
          },
        ],
      },
      {
        id: 'negative',
        options: [
          {
            id: PermissionType.RemoveRule,
            label: formatMessage(messages.removePersonLabel),
            displayLabel: formatMessage(messages.removePersonLabel),
            isDelete: true,
          },
        ],
      },
    ],
  };
}

export const ViewerPermission = {
  id: PermissionType.Viewer,
  label: 'Viewer',
  displayLabel: 'Viewer',
};

export const OwnerPermission = {
  id: PermissionType.Owner,
  label: 'Owner',
  displayLabel: 'Owner',
  icon: <UserIcon className="h-4 w-4 text-gray-8" aria-hidden="true" />,
};

export const ExcludedPermission = {
  id: PermissionType.Excluded,
  label: 'Excluded',
  displayLabel: 'Excluded',
};

export const CollaboratorPermission = {
  id: PermissionType.Collaborator,
  label: 'Collaborator',
  displayLabel: 'Collaborator',
};

export const mapMemberData = (member: Member) => {
  const { memberID, name, email, image, memberState, role } = member;
  const data = {
    id: memberID,
    title: name,
    subtitle: email,
    state: member.memberState,
    ...(memberState === MemberState.Active
      ? {
          avatar: {
            image,
            memberID,
            name,
            size: AvatarSize.ExtraLarge,
          },
        }
      : {
          customIcon: {
            icon: CriteriaCardIconMap[MemberState.Active.toLowerCase()],
            iconBackgroundColor:
              memberState === MemberState.Pending ? 'bg-gray-4' : '',
          },
        }),
    permission: undefined,
    role,
  };

  return { id: memberID, data };
};
