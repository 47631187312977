import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import { useQueries, type UseQueryResult } from '@tanstack/react-query';

import type { categoryLists, TemplateListsApiResponse } from '../types';

export const useCategorizedTemplatesQuery = (
  categories: categoryLists[]
): UseQueryResult<
  TemplateListsApiResponse & {
    category: string;
  }
>[] => {
  return useQueries({
    queries: categories.map((category) => ({
      queryKey: ['templates', category.key],
      queryFn: async () => {
        try {
          const { data } = await assemblyAPI.post(
            APIEndpoints.listTemplates,
            {
              limit: 100,
              cursor: '',
              filter: {
                categories: [category.key],
              },
            },
            {
              params: {
                keyword: '',
              },
            }
          );
          return [
            {
              data,
              category: category.key,
            },
          ];
        } catch (error) {
          console.log(error);
          return [];
        }
      },
    })),
  });
};
