import {
  APIEndpoints,
  assemblyAPI,
  type GetChatMessagesResponse,
} from '@assembly-web/services';
import type {
  ConversationCardMemberDetails,
  MemberDetailsForViewProfile,
  Reply,
} from '@assembly-web/ui';
import {
  type DefinedInitialDataInfiniteOptions,
  type InfiniteData,
  useInfiniteQuery,
} from '@tanstack/react-query';

export type GetChatMessagesPayload = {
  memberId: string;
};

function formatChatMessages(
  data: InfiniteData<GetChatMessagesResponse> | undefined
) {
  if (!data) {
    return [];
  }

  return data.pages
    .reduce<Reply[]>((acc, page) => {
      return [
        ...acc,
        ...page.data.map((reply) => {
          const isEdited = Boolean(reply.editedAt);
          let memberDetails: ConversationCardMemberDetails<
            MemberDetailsForViewProfile & {
              isAnonymous: false;
              name: string;
            }
          >;
          if ('anonymousIdentifier' in reply.fromMember) {
            memberDetails = {
              isAnonymous: true,
              createdAt: reply.createdAt,
              memberID: reply.fromMember.anonymousIdentifier,
            };
          } else {
            const {
              name,
              image,
              memberID,
              lastName,
              pronouns,
              firstName,
              memberState,
              email,
              department,
              jobTitle,
            } = reply.fromMember;
            memberDetails = {
              isAnonymous: false,
              createdAt: reply.createdAt,
              name,
              image,
              memberID,
              lastName,
              pronouns,
              firstName,
              memberState,
              email,
              department,
              jobTitle,
            };
          }
          return {
            cardId: reply.commentID,
            reactions: reply.reactions,
            pointsEach: reply.pointsEach,
            messageContent:
              reply.version === 2 ? (reply.messageHtml ?? '') : '',
            memberDetails: {
              ...memberDetails,
            },
            isEdited,
            version: reply.version,
            gifURL: reply.gifURL,
            canEdit: reply.canEdit,
            taggedUsers: reply.taggedUsers,
            boost: reply.boost,
            messageNode: undefined,
          };
        }),
      ];
    }, [])
    .sort(
      (a, b) =>
        new Date(a.memberDetails.createdAt).getTime() -
        new Date(b.memberDetails.createdAt).getTime()
    );
}

export function useGetMessagesInfiniteQuery(
  payload: GetChatMessagesPayload,
  options?: DefinedInitialDataInfiniteOptions<GetChatMessagesResponse>
) {
  const { memberId } = payload;

  const result = useInfiniteQuery<GetChatMessagesResponse>({
    ...options,
    queryKey: ['messages', memberId],
    queryFn: async ({ pageParam = '' }) => {
      const { data } = await assemblyAPI.get<GetChatMessagesResponse>(
        APIEndpoints.getChatMessagesByMemberId(memberId),
        {
          params: {
            ...(pageParam ? { cursor: pageParam, limit: 99 } : { limit: 99 }),
          },
        }
      );

      return data;
    },
    initialPageParam: 0,
    getNextPageParam: (page) => page.metadata.pagination.cursor.next,
    getPreviousPageParam: (page) => page.metadata.pagination.cursor.previous,
    refetchOnWindowFocus: false,
    enabled: options?.enabled ?? true,
  });

  const total = result.data?.pages[0]?.total ?? 0;

  return {
    chats: formatChatMessages(result.data),
    total,
    ...result,
  };
}
