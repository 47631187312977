import {
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';

export function useIsChatEnabled() {
  const { isTreatmentActive: isChatTreatmentActive } = useFeatureSplit(
    SplitNames.Chat
  );

  const { data: userDetails } = useUserDetails();

  return Boolean(
    isChatTreatmentActive && userDetails?.assembly.settings.chat.enabled
  );
}
