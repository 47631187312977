import {
  type FlowDetailsErrorMessages,
  SplitNames,
  useFeatureSplit,
} from '@assembly-web/services';
import {
  Button,
  CreationChoice,
  FlowBuilderError,
  FlowEditorContainers,
  SectionAccordion,
  TextStyle,
} from '@assembly-web/ui';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { getTimeZones } from '@vvo/tzdb';
import { AxiosError } from 'axios';
import { type ReactNode, useEffect, useMemo, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';

import {
  useDataExists,
  useGetDeadlineType,
  useGetDueDate,
  useGetFlowBuilderState,
  useGetFlowResponseType,
  useGetRemindersTimeZone,
  useGetRemindersType,
  useGetRepeatFrequency,
  useGetResponseFrequencyTimeZone,
  useGetResponseFrequencyType,
  useSetFlow,
} from '../../../../../stores/useFlowBuilderStore';
import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import { useInitializeFlow } from '../../../hooks/flowsEditor/useCreateWorkflow';
import { trackFlowEditorAction } from '../../../services/analytics';
import type { FlowCreationDrawer as FlowCreationEditorProps } from '../types';
import { Blocks } from './components/Blocks';
import { Distributions } from './components/distribution/Distributions';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { OwnerAndCollaboratorsShareSheet } from './components/OwnerAndCollaboratorsShareSheet';
import { PermissionsFooter } from './components/PermissionsFooter';
import { Validate } from './components/Validate';
import {
  EditorDataProvider,
  useEditorDataContext,
} from './context/EditorDataContext';
import { useClearPersistedPermission } from './hooks/useClearPersistedPermission';
import { useFetchFlow } from './hooks/useFetchFlowForEdit';
import { useFetchTemplate } from './hooks/useFetchTemplate';
import { useFlowData } from './hooks/useFlowData';
import { useFlowFrequencyOptions } from './hooks/useGetFlowFrequencyOptions';
import { useGetTimeOptions } from './hooks/useGetTimeOptions';
import {
  useExpandedAccordionValue,
  useSetExpandedAccordionValue,
} from './store/useEditorMiscState';
import { defaultReminderAndStartTime } from './utils/distribution';

const messages = defineMessages({
  title: {
    defaultMessage: 'Flow Configuration',
    id: 'jC4NFV',
  },
  permissionTitle: {
    defaultMessage: 'Flow Permissions',
    id: 'g08Gyg',
  },
  back: {
    defaultMessage: 'Back',
    id: 'cyR7Kh',
  },
});

function Accordion({ children }: { children: ReactNode }) {
  const { id } = useEditorDataContext();

  const set = useSetExpandedAccordionValue(id);
  const expandedValue = useExpandedAccordionValue(id);

  return (
    <SectionAccordion.Root
      className="flex-1 @[700px]/body:px-12"
      onValueChange={set}
      value={expandedValue}
    >
      {children}
    </SectionAccordion.Root>
  );
}

function ChoicePage() {
  const navigate = useNavigate();

  const { id } = useEditorDataContext();

  const updateDrawer = useMultiDrawerStore((store) => store.updateDrawerField);
  const deleteDrawer = useMultiDrawerStore((store) => store.deleteDrawer);

  const createFlowFromScratch = useInitializeFlow();

  const handleNavigateToTemplate = () => {
    navigate('/a/templates');
    deleteDrawer(id);
    trackFlowEditorAction('templateLibraryClicked');
  };
  const handleCreateFlowFromScratch = () => {
    createFlowFromScratch(id);
    updateDrawer(id, (state) => {
      if (state.type !== 'flowCreation') return state;
      state.data.view = 'editor';
    });
    trackFlowEditorAction('startFromScratchClicked');
  };

  return (
    <>
      <CreationChoice.HeaderContainer>
        <CreationChoice.Title />
        <CreationChoice.SubTitle />
      </CreationChoice.HeaderContainer>
      <CreationChoice.StaggeredTwoColumnRow>
        <CreationChoice.TemplateLibraryChoice
          onClick={handleNavigateToTemplate}
        />
        <CreationChoice.FromScratchChoice
          onClick={handleCreateFlowFromScratch}
        />
      </CreationChoice.StaggeredTwoColumnRow>
    </>
  );
}

function EditorPage() {
  const { isTreatmentActive: isFlowDistributionTreatmentActive } =
    useFeatureSplit(SplitNames.FlowDistribution);

  const { id } = useEditorDataContext();

  const dueDate = useGetDueDate(id);
  const deadlineType = useGetDeadlineType(id);
  const reminderType = useGetRemindersType(id);
  const repeatFrequency = useGetRepeatFrequency(id);
  const flowResponseType = useGetFlowResponseType(id);
  const dueTime = useGetFlowBuilderState(id, 'dueTime');
  const responseFrequencyType = useGetResponseFrequencyType(id);
  const responseFrequencyTimeZone = useGetResponseFrequencyTimeZone(id);

  const remindersTimeZone = useGetRemindersTimeZone(id);
  const remindersDueTime = useGetFlowBuilderState(id, 'remindersDueTime');
  const remindersFrequency = useGetFlowBuilderState(id, 'remindersFrequency');

  const frequencyTimeOptions = useGetTimeOptions();
  const timeZonesWithUtc = getTimeZones({ includeUtc: true });
  const frequencyOptions = useFlowFrequencyOptions(dueDate.toDate('UTC'));

  const selectedRepeatFrequency = frequencyOptions.find(
    (option) => option.id === repeatFrequency
  );

  const selectedRepeatTimeFrequency =
    frequencyTimeOptions.find((option) => option.id === dueTime) ??
    frequencyTimeOptions.find(
      (option) => option.id === defaultReminderAndStartTime
    );

  const selectedRemindersDueTime =
    frequencyTimeOptions.find((option) => option.id === remindersDueTime) ??
    frequencyTimeOptions.find(
      (option) => option.id === defaultReminderAndStartTime
    );

  const selectedRemindersFrequency = frequencyOptions.find(
    (option) => option.id === remindersFrequency
  );

  const selectedRemindersTimeZone = timeZonesWithUtc.find(
    (option) => option.name === remindersTimeZone
  );

  const selectedResponseFrequencyTimeZone = timeZonesWithUtc.find(
    (option) => option.name === responseFrequencyTimeZone
  );

  const reminderOptions = useMemo(() => {
    if (flowResponseType === 'anytime' && reminderType === 'automate') {
      return {
        frequency: selectedRemindersFrequency?.label,
        timeZone: selectedRemindersTimeZone?.abbreviation,
        time: selectedRemindersDueTime?.label ?? defaultReminderAndStartTime,
      };
    }

    return {
      frequency:
        responseFrequencyType === 'recurring'
          ? selectedRepeatFrequency?.label
          : dueDate.toDate('UTC').toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
            }),
      timeZone: selectedResponseFrequencyTimeZone?.abbreviation,
      time: selectedRepeatTimeFrequency?.label ?? defaultReminderAndStartTime,
    };
  }, [
    dueDate,
    reminderType,
    flowResponseType,
    responseFrequencyType,
    selectedRepeatFrequency?.label,
    selectedRemindersDueTime?.label,
    selectedRemindersFrequency?.label,
    selectedRepeatTimeFrequency?.label,
    selectedRemindersTimeZone?.abbreviation,
    selectedResponseFrequencyTimeZone?.abbreviation,
  ]);

  return (
    <>
      <FlowEditorContainers.EditorContainer>
        <Header />
        <Accordion>
          <SectionAccordion.BlocksItem>
            <Blocks />
          </SectionAccordion.BlocksItem>
          {Boolean(isFlowDistributionTreatmentActive) && (
            <SectionAccordion.DistributionsItem
              onClick={() => {
                trackFlowEditorAction('distributionSectionOpened');
              }}
              time={reminderOptions.time}
              reminderType={reminderType}
              deadlineType={deadlineType}
              responseType={flowResponseType}
              timeZone={reminderOptions.timeZone ?? ''}
              frequency={reminderOptions.frequency ?? ''}
              responseFrequencyType={responseFrequencyType}
            >
              <Distributions />
            </SectionAccordion.DistributionsItem>
          )}
        </Accordion>
      </FlowEditorContainers.EditorContainer>
      <Footer />
      <Validate />
    </>
  );
}

function FlowPermissions() {
  const { formatMessage } = useIntl();

  const handleCloseClick = useClearPersistedPermission();

  useEffect(() => {
    window.addEventListener('beforeunload', handleCloseClick);
    return () => {
      window.removeEventListener('beforeunload', handleCloseClick);
    };
  }, [handleCloseClick]);

  return (
    <>
      <FlowEditorContainers.Header>
        <TextStyle variant="base-medium">
          {formatMessage(messages.title)}
        </TextStyle>
      </FlowEditorContainers.Header>
      <div className="flex flex-1 flex-col gap-4 overflow-hidden pt-6 [&>div]:px-2 [&>div]:@[700px]/body:px-12">
        <div className="flex items-center justify-between">
          <TextStyle variant="base-regular" subdued>
            {formatMessage(messages.permissionTitle)}
          </TextStyle>
          <Button
            variation="tertiaryEmphasized"
            size="large"
            onClick={handleCloseClick}
          >
            <ChevronLeftIcon className="h-4 w-4" />
            {formatMessage(messages.back)}
          </Button>
        </div>
        <OwnerAndCollaboratorsShareSheet />
      </div>
      <PermissionsFooter />
    </>
  );
}

export function FlowCreationEditor(props: FlowCreationEditorProps) {
  const {
    id,
    data: { type, view },
  } = props;

  const dataExists = useDataExists(id);
  const isFetched = useRef(type === 'scratch');
  const setFlow = useSetFlow();

  const {
    fetchStatus: flowFetchStatus,
    status: flowLoadingState,
    data: flowData,
    error: flowError,
  } = useFetchFlow(id, type, !dataExists);

  const {
    fetchStatus: templateFetchStatus,
    status: templateLoadingState,
    data: templateData,
    error: templateError,
  } = useFetchTemplate(id, type, !dataExists);

  const {
    fetchStatus: newestPostsFetchStatus,
    status: newestPostsLoadingState,
  } = useFlowData(id, type);

  if (!isFetched.current && !dataExists) {
    if (
      (type === 'edit' || type === 'duplicate') &&
      flowLoadingState === 'success'
    ) {
      setFlow(id, flowData);
      isFetched.current = true;
    } else if (type === 'template' && templateLoadingState === 'success') {
      setFlow(id, templateData);
      isFetched.current = true;
    }
  }

  if (!isFetched.current && dataExists) {
    isFetched.current = true;
  }

  const isLoading =
    (flowLoadingState === 'pending' && flowFetchStatus === 'fetching') ||
    (templateLoadingState === 'pending' &&
      templateFetchStatus === 'fetching') ||
    (newestPostsLoadingState === 'pending' &&
      newestPostsFetchStatus === 'fetching');

  const error: FlowDetailsErrorMessages | undefined = (() => {
    if (flowError instanceof AxiosError) {
      return (flowError.response?.data as { message: FlowDetailsErrorMessages })
        .message;
    }
    if (templateError instanceof AxiosError) {
      return (
        templateError.response?.data as { message: FlowDetailsErrorMessages }
      ).message;
    }
  })();

  return (
    <EditorDataProvider
      value={useMemo(
        () => ({
          id,
          isLoading,
          type,
        }),
        [id, isLoading, type]
      )}
    >
      <FlowEditorContainers.Root
        className={twJoin(view === 'empty' && 'mx-auto w-[min(512px,100%)]')}
      >
        {error ? (
          <FlowBuilderError type={type} />
        ) : view === 'empty' ? (
          <ChoicePage />
        ) : view === 'permissions' ? (
          <FlowPermissions />
        ) : (
          <EditorPage />
        )}
      </FlowEditorContainers.Root>
    </EditorDataProvider>
  );
}
