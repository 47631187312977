import { type Icon, SplitNames, useFeatureSplit } from '@assembly-web/services';
import { FilePreviewerContext, useAssemblyNavigate } from '@assembly-web/ui';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FilePreviewModalContext } from '../contexts/FilePreviewModalContext';
import { useGetDownloadableFile } from './useGetDownloadableFile';

export const useOpenFlowsPreviewer = () => {
  const { mutate } = useGetDownloadableFile();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useAssemblyNavigate();

  const { openModal: openV3Modal } = useContext(FilePreviewerContext);

  const { openModal } = useContext(FilePreviewModalContext);
  const { isTreatmentActive: isV3FilePreviewerEnabled } = useFeatureSplit(
    SplitNames.FilePreviewerV3
  );

  return ({
    blockId,
    fileName,
    flowId,
    responseId,
    workspaceSlug,
    fileType,
    memberID,
    memberName,
    memberImage,
    dateShared,
    sharedIn,
    sharedInIcon,
  }: {
    blockId: string;
    fileName: string;
    flowId: string;
    responseId: string;
    workspaceSlug?: string;
    fileType: string;
    memberID: string;
    memberName: string;
    memberImage?: string;
    dateShared: string | number | Date;
    sharedIn: string;
    sharedInIcon?: Icon;
  }) => {
    if (isV3FilePreviewerEnabled) {
      mutate(
        { flowId, responseId, blockId, fileName, fileType },
        {
          onSuccess: (data) => {
            openV3Modal({
              file: {
                fileName: fileName,
                locationUrl: data.url,
                fileType: fileType,
                memberID,
                memberName,
                memberImage,
                handleGoToPost: () => {
                  searchParams.set('flowId', flowId);
                  searchParams.set('responseId', responseId);
                  setSearchParams(searchParams.toString());
                },
                dateShared,
                sharedIn,
                sharedInIcon,
                handleSharedInClick: () => {
                  const urlSlug = `/flows/${flowId}`;
                  navigate(
                    urlSlug.includes('recognition')
                      ? `/a/${workspaceSlug}/flows/recognition`
                      : `/a/${workspaceSlug}${urlSlug}`
                  );
                },
              },
              showGoToPost: true,
              isChallengeClaim: false,
            });
          },
        }
      );
    } else {
      openModal({
        blockId,
        flowId,
        workspaceSlugPath: workspaceSlug ?? '',
        fileName,
        responseId,
      });
    }
  };
};
