import {
  DoraAPIEndpoints,
  type DoraChatHistoryAPIResponse,
  type DoraErrorServerSideEvent,
  type DoraReportingCategory,
  logger,
} from '@assembly-web/services';
import { config } from '@assembly-web/services';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useQueryClient } from '@tanstack/react-query';

import { useMultiDrawerStore } from '../../../../../stores/useMultiDrawerStore';
import {
  type DoraThreadDetails,
  getDoraThreadDetailsQueryKey,
} from '../../../hooks/dora/getDoraThreadQueryDetails';
import { useChatReportTitle } from '../../../hooks/dora/useChatReportTitle';
import { doraChatThreadsSummaryQueryKey } from '../../../hooks/dora/useChatThreadsSummary';
import { getDoraChatHistoryQueryKey } from '../../../queries/getDoraChatHistoryQuery';
import type { createDoraDrawerStore } from '../../../stores/doraChatStore';

export function useGetDoraChatReportResponse(
  drawerId: string,
  doraChatStore: ReturnType<typeof createDoraDrawerStore>,
  abortController: AbortController,
  reportingType: DoraReportingCategory
) {
  const { mutateAsync: generateChatTitle } = useChatReportTitle();
  const queryClient = useQueryClient();

  const findAndUpdateDrawerField = useMultiDrawerStore(
    (store) => store.findAndUpdateDrawerField
  );

  const findDrawer = useMultiDrawerStore((store) => store.findDrawer);

  return (prompt: string, threadId?: string) => {
    let messages: {
      content: string;
      createdAt: string;
      imageUrl?: string;
      promptId?: string;
    }[] = [];

    const updateError = (error: string) => {
      doraChatStore.getState().setErrorType(error);

      findAndUpdateDrawerField(drawerId, (draft) => {
        draft.hasNewActivity = true;
        draft.newActivityType = 'warning';
      });
    };

    const signal = abortController.signal;
    const url = new URL(config.domains.doraApi);

    let curStreamedMessage = '';

    url.pathname = DoraAPIEndpoints.chatReportDora;
    url.searchParams.append('text', prompt);

    url.searchParams.append(
      'reportType',
      doraChatStore.getState().reportingInsights?.activeCategory ?? ''
    );

    if (threadId) {
      url.searchParams.append('threadId', threadId);
    }
    if (reportingType === 'flow_responses') {
      const flowData = findDrawer(drawerId);

      url.searchParams.append('reportType', 'flow_responses');
      if (flowData?.type === 'flow_responses') {
        url.searchParams.append('flowId', flowData.data.flowId);
      }
    }

    doraChatStore.getState().addPrompt(prompt);

    fetchEventSource(url.toString(), {
      credentials: 'include',

      async onopen(resp) {
        if (!resp.ok) {
          let responseBody;

          if (resp.headers.get('content-type') === 'application/json') {
            responseBody = await resp.json();
          } else {
            responseBody = await resp.text();
          }

          logger.error(
            `Dora request for GET ${DoraAPIEndpoints.chatReportDora} failed`,
            {
              question: prompt,
              responseBody,
              statusCode: resp.status,
              url: resp.url,
            }
          );

          throw new Error('Dora Report Chat');
        }
      },

      onerror(err) {
        updateError('UNEXPECTED_ERROR');
        throw err;
      },

      async onmessage(msg) {
        if (signal.aborted) {
          return;
        }

        if (msg.event === 'error') {
          const response = JSON.parse(msg.data) as DoraErrorServerSideEvent;

          logger.error(
            `Error event received from GET ${DoraAPIEndpoints.chatReportDora}`,
            { data: response, message: msg, question: prompt }
          );

          updateError(response.error);
        } else if (msg.event === 'update') {
          const contents = JSON.parse(msg.data) as {
            data: string;
          };

          const isNewMessage = curStreamedMessage.length === 0;

          curStreamedMessage += contents.data;

          doraChatStore
            .getState()
            .updateResponse(curStreamedMessage, isNewMessage);
        } else if (msg.event === 'message') {
          const contents = JSON.parse(msg.data) as {
            data: { imagePath?: string; text: string };
          };

          let fullContent = contents.data.text;

          if (contents.data.imagePath) {
            if (fullContent) {
              fullContent += '\n\n';
            }

            fullContent += `![](${config.domains.doraApi}${contents.data.imagePath})`;
          }

          curStreamedMessage = '';

          messages.push({
            content: fullContent,
            createdAt: new Date().toISOString(),
          });

          doraChatStore.getState().endMessageResponse();
        } else if (msg.event === 'additional_info') {
          const contents = JSON.parse(msg.data) as {
            data: { thread_id: string; prompt_id: string };
          };

          messages = messages.map((message) => ({
            ...message,
            promptId: contents.data.prompt_id,
          }));

          const threadIdExists = Boolean(
            doraChatStore.getState().reportingInsights?.threads?.[0]
          );

          const category =
            doraChatStore.getState().reportingInsights?.activeCategory;

          if (!threadIdExists && category) {
            doraChatStore
              .getState()
              .saveThread(contents.data.thread_id, category);
          }
        } else if (msg.event === 'end') {
          doraChatStore.getState().closeStream();

          const threadId =
            doraChatStore.getState().reportingInsights?.threads?.[0].id;

          if (!threadId) {
            return;
          }

          const queryKey = getDoraChatHistoryQueryKey(threadId);

          const chatHistory =
            queryClient.getQueryData<DoraChatHistoryAPIResponse>(queryKey) ??
            [];

          const updatedChatHistory = [
            ...chatHistory,
            {
              content:
                doraChatStore.getState().reportingInsights?.recentBlock
                  ?.prompt ?? '',
              role: 'user',
            },
            ...messages.map((message) => {
              return {
                content: message.content,
                createdAt: new Date().toISOString(),
                imageUrl: message.imageUrl,
                role: 'assistant',
                promptId: message.promptId,
              };
            }),
          ];

          queryClient.setQueryData(queryKey, updatedChatHistory);

          const threadDetailsQueryKey = getDoraThreadDetailsQueryKey(threadId);

          const threadDetailsData = queryClient.getQueryData<DoraThreadDetails>(
            threadDetailsQueryKey
          );

          const updatedThreadDetails = {
            ...threadDetailsData,
            promptCount: threadDetailsData
              ? threadDetailsData.promptCount + 1
              : 1,
          };

          queryClient.setQueryData(threadDetailsQueryKey, updatedThreadDetails);

          await Promise.all([
            queryClient.invalidateQueries({
              queryKey: getDoraChatHistoryQueryKey(threadId),
            }),
            queryClient.invalidateQueries({
              queryKey: doraChatThreadsSummaryQueryKey,
            }),
          ]);

          doraChatStore.getState().resetBlock();

          findAndUpdateDrawerField(drawerId, (draft) => {
            draft.hasNewActivity = true;
            draft.newActivityType = 'info';
          });

          const isEndOfFirstPrompt =
            updatedChatHistory.filter((message) => message.role === 'user')
              .length === 1;

          if (isEndOfFirstPrompt) {
            await generateChatTitle({ threadId, drawerId });
          }
        }
      },

      openWhenHidden: true,
      signal,
    });
  };
}
