import noop from 'lodash/noop';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import invariant from 'tiny-invariant';

type RewardsLocalSearchContext = {
  searchValue: string;
  setSearchValue: (value: string) => void;
  clearSearchValue: () => void;
};

export const RewardsLocalSearchContext =
  createContext<RewardsLocalSearchContext>({
    searchValue: '',
    setSearchValue: noop,
    clearSearchValue: noop,
  });

export function RewardsLocalSearchProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [searchValue, setSearchValue] = useState('');

  const clearSearchValue = useCallback(() => {
    setSearchValue('');
  }, []);

  const contextValue = useMemo(
    () => ({
      searchValue,
      setSearchValue,
      clearSearchValue,
    }),
    [searchValue, setSearchValue, clearSearchValue]
  );

  return (
    <RewardsLocalSearchContext.Provider value={contextValue}>
      {children}
    </RewardsLocalSearchContext.Provider>
  );
}

export function useRewardsLocalSearch(): RewardsLocalSearchContext {
  const context = useContext(RewardsLocalSearchContext);

  invariant(
    context,
    'useRewardsLocalSearch must be used within a RewardsLocalSearchProvider'
  );

  return context;
}
