import { useUserDetails } from '@assembly-web/services';
import {
  IdContextProvider,
  RepliesLoader as ChatsLoader,
  useDeviceInfo,
  useIdContext,
} from '@assembly-web/ui';
import {
  type ElementRef,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import { twJoin } from 'tailwind-merge';

import { useNavigateToUserFeed } from '../../../../../hooks/useNavigateToUserFeed';
import { useGetMessagesInfiniteQuery } from '../../../hooks/chat/useGetMessagesInfiniteQuery';
import type { ChatDrawer } from '../types';
import { ChatEditorContainer } from './ChatEditorContainer';
import { ChatList } from './ChatList';

const FooterContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const id = useIdContext();

  return (
    <section id={id} className={className}>
      {children}
    </section>
  );
};

function Root({ children }: { children: ReactNode }) {
  const isMobile = useDeviceInfo().deviceType === 'mobile';

  const chatDrawerRef = useRef<ElementRef<'section'>>(null);
  const [isRemoveScrollEnabled, setIsRemoveScrollEnabled] = useState(false);

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      if (chatDrawerRef.current?.contains(e.target as Node)) {
        setIsRemoveScrollEnabled(true);
      } else {
        setIsRemoveScrollEnabled(false);
      }
    };

    document.addEventListener('wheel', handleWheel, { passive: true });
    return () => {
      document.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <IdContextProvider>
      <section ref={chatDrawerRef} className="h-full">
        <RemoveScroll
          enabled={!isMobile && isRemoveScrollEnabled}
          className="flex h-full flex-col overflow-hidden bg-gray-1"
        >
          {children}
        </RemoveScroll>
      </section>
    </IdContextProvider>
  );
}

function Content({ memberId, toName }: { memberId: string; toName: string }) {
  const chatContainerRef = useRef<ElementRef<'section'>>(null);

  const [showShadowForChatEditor, setShowShadowForChatEditor] = useState(false);

  const handleShadowForRepliesEditor = useCallback((container: HTMLElement) => {
    const { scrollTop, scrollHeight, clientHeight } = container;
    const isScrollAtEnd =
      Math.abs(scrollTop + clientHeight - scrollHeight) <= 10;
    setShowShadowForChatEditor(!isScrollAtEnd);
  }, []);

  const {
    chats,
    total,
    isError,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    isInitialLoading,
    fetchPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useGetMessagesInfiniteQuery({
    memberId,
  });

  const { data: userDetails } = useUserDetails();

  const { navigate: navigateToUserFeed } = useNavigateToUserFeed();

  const handleOnPostWithRepliesScroll = useCallback(() => {
    const { current: container } = chatContainerRef;
    if (container) {
      handleShadowForRepliesEditor(container);
    }
  }, [handleShadowForRepliesEditor]);

  if (isInitialLoading) {
    return (
      <section className="flex flex-grow flex-col overflow-auto px-6 pb-4">
        <ChatsLoader />
      </section>
    );
  }

  return (
    <>
      <section
        ref={chatContainerRef}
        onScroll={handleOnPostWithRepliesScroll}
        className="flex flex-grow flex-col overflow-x-hidden px-6 pb-4"
      >
        <ChatList
          total={total}
          chats={chats}
          toName={toName}
          isError={isError}
          handleReactionClick={() => {}}
          fetchNextPage={fetchNextPage}
          containerRef={chatContainerRef}
          hasNextPage={Boolean(hasNextPage)}
          isInitialLoading={isInitialLoading}
          onMemberClick={navigateToUserFeed}
          fetchPreviousPage={fetchPreviousPage}
          isFetchingNextPage={isFetchingNextPage}
          hasPreviousPage={Boolean(hasPreviousPage)}
          isFetchingPreviousPage={isFetchingPreviousPage}
          currentUserId={userDetails?.member.memberId ?? ''}
        />
      </section>
      <FooterContainer
        className={twJoin(
          'z-10 flex max-h-[80%] flex-shrink-0 flex-col gap-2 bg-gray-1 px-6 pb-4 pt-3 transition-all duration-300',
          showShadowForChatEditor && 'shadow-base-up'
        )}
      >
        <ChatEditorContainer
          toName={toName}
          toMemberId={memberId}
          containerRef={chatContainerRef}
        />
      </FooterContainer>
    </>
  );
}

const Container = { Root, Content };

export function ChatDrawerBody({ data }: ChatDrawer) {
  return (
    <Container.Root>
      <Container.Content
        memberId={data.memberId}
        toName={`${data.firstName} ${data.lastName}`.trim()}
      />
    </Container.Root>
  );
}
