import {
  APIEndpoints,
  type ChallengeAPIResponse,
} from '@assembly-web/services';
import { assemblyAPI } from '@assembly-web/services';
import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

export function useGetChallengeDetailsByIdQuery(
  challengeId: string,
  options?: UseQueryOptions<ChallengeAPIResponse>
) {
  return useQuery({
    queryKey: ['challenge', challengeId],
    queryFn: async () => {
      const { data } = await assemblyAPI.get<ChallengeAPIResponse>(
        APIEndpoints.getChallengeById(challengeId)
      );

      return data;
    },
    enabled: Boolean(challengeId),
    ...options,
  });
}
