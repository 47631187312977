import { SVGReactComponents, templatesBgGradient } from '@assembly-web/assets';
import {
  logger,
  mapHexCodeToEmoticon,
  SplitNames,
  useFeatureSplit,
  useUserDetails,
} from '@assembly-web/services';
import {
  AssemblyLink,
  TemplateCard,
  TextStyle,
  useAssemblyNavigate,
  useToastStore,
} from '@assembly-web/ui';
import { V3Modal } from '@assembly-web/ui/lib/UI/Shared/Modal/V3Modal';
import { useQueryClient } from '@tanstack/react-query';
import { type ReactNode, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { twJoin, twMerge } from 'tailwind-merge';

import { addFlowToNav } from '../../../hooks/useFlowsNotInCollection';
import { trackTemplatesAction } from '../../../services/analytics';
import { useCreateWorkflow } from '../../discover/hooks/flowsEditor/useCreateWorkflow';
import { useActionBarOptions } from '../../discover/hooks/useActionBarOptions';
import {
  type FlowCreationResponse,
  useTemplateMutation,
} from '../queries/useTemplateMutation';
import type { Template } from '../types';
import { mapBgColor } from './TemplateList';

const message = defineMessages({
  templatesFor: {
    id: 'zvGHA9',
    defaultMessage: 'Templates for “{key}”',
  },
  flowCreationSuccess: {
    id: 'vIPz5K',
    defaultMessage: 'Flow created successfully',
  },
  flowCreationError: {
    id: 'hyiT4x',
    defaultMessage: 'Error creating flow. Please try again',
  },
  templateModalTitle: {
    defaultMessage: 'Flows are a paid feature',
    id: 'tYh0PX',
  },
  bookADemo: {
    defaultMessage: 'book a demo',
    id: '3FZv9e',
  },
});

export function TemplateLoader() {
  return (
    <div className="h-[112px] w-[350px] animate-pulse cursor-pointer gap-2 rounded-lg border border-primary-3 bg-gray-1 p-4">
      <div className="flex h-full gap-4">
        <div className="h-8 w-8 rounded bg-gray-4"></div>
        <div className="flex h-full w-full flex-col justify-between gap-6">
          <div className="h-5 w-3/4 rounded bg-gray-4"></div>
          <div className="h-3 w-2/4 rounded bg-gray-4"></div>
        </div>
      </div>
    </div>
  );
}

export function CategorizedTemplatesLoader() {
  return (
    <>
      <div className="flex gap-4">
        <div className="h-12 w-12 rounded bg-gray-4"></div>
        <div className="flex h-full w-full flex-col justify-between gap-6">
          <div className="h-4 w-2/4 rounded bg-gray-4"></div>
          <div className="h-2 w-1/4 rounded bg-gray-4"></div>
        </div>
      </div>
      <TemplateLoader />
    </>
  );
}

export function TemplatesBackground({ showBg = true }) {
  return (
    <img
      src={templatesBgGradient}
      alt="logo"
      className={twJoin(
        'absolute w-full opacity-30',
        showBg && 'h-full bg-primary-3'
      )}
    />
  );
}

export function SearchContainer({
  search,
  children,
  className,
}: {
  search: string;
  children: React.ReactNode;
  className?: string;
}) {
  const { formatMessage } = useIntl();
  return (
    <div className={twMerge(className, 'flex flex-col gap-8')}>
      <TextStyle variant="xl-medium" className="px-4">
        {formatMessage(message.templatesFor, { key: search })}
      </TextStyle>
      {children}
    </div>
  );
}

export function V3ModalTemplate({
  setShowModal,
  showModal,
  onModalClose,
}: {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  onModalClose?: () => void;
}) {
  const { formatMessage } = useIntl();
  return (
    <V3Modal
      title={
        <div className="flex items-center gap-x-4">
          <SVGReactComponents.UpgradeDollar className="h-8 w-8 flex-shrink-0" />
          <TextStyle variant="2xl-bold">
            {formatMessage(message.templateModalTitle)}
          </TextStyle>
        </div>
      }
      onClose={() => {
        setShowModal(false);
        onModalClose?.();
      }}
      isOpen={showModal}
      closeButtonSize="large"
    >
      <TextStyle variant="base-regular">
        <FormattedMessage
          defaultMessage="To add our Flows feature to your plan, please reach out to your account manager for more information or {bookDemo} with our sales team."
          id="oq2V2F"
          values={{
            bookDemo: (
              <AssemblyLink
                className="text-primary-6 underline"
                to="https://www.joinassembly.com/book-demo"
                target="_blank"
              >
                {formatMessage(message.bookADemo)}
              </AssemblyLink>
            ),
          }}
        />
      </TextStyle>
    </V3Modal>
  );
}

export function TemplateTile({
  templates,
  categoryColorMap,
}: {
  templates: Template[];
  categoryColorMap: Record<
    string,
    {
      color: string;
      icon: ReactNode;
    }
  >;
}) {
  const [showModal, setShowModal] = useState(false);

  const { formatMessage } = useIntl();
  const navigate = useAssemblyNavigate();
  const { showSuccessToast, showErrorToast } = useToastStore();
  const { isTreatmentActive: isFlowCreationDrawerEnabled } = useFeatureSplit(
    SplitNames.FlowCreation
  );
  const createFlowEditorDrawer = useCreateWorkflow();

  const queryClient = useQueryClient();

  const { giveRecognition } = useActionBarOptions();

  const { data: userDetails } = useUserDetails();
  const workspaceSlugPath = userDetails?.assembly.workspaceSlugPath;
  const onFlowCreationSuccess = (
    res: unknown,
    variables: {
      templateId: string;
      templateName: string;
      icon: string;
    }
  ) => {
    if (typeof res === 'object' && res !== null) {
      const data = res as FlowCreationResponse;
      navigate(
        `/${workspaceSlugPath}/flows/${data.data.flowId}?flow-created-from-template=true&show-share-sheet=true`
      );
      addFlowToNav({
        queryClient,
        flowId: data.data.flowId,
        flowIcon: { kind: 'HEX_CODE' as const, value: variables.icon },
        flowName: variables.templateName,
      });
    }
    showSuccessToast(formatMessage(message.flowCreationSuccess));
  };

  const onFlowCreationError = (
    err: unknown,
    payload: {
      templateId: string;
    }
  ) => {
    showErrorToast(formatMessage(message.flowCreationError));
    const errorInfo = err instanceof Error ? err : undefined;
    logger.error('Failed to create flow from one click', payload, errorInfo);
  };

  const { mutate: createOneClickFlow, isPending: isFlowCreationInProgress } =
    useTemplateMutation({
      options: {
        onSuccess: onFlowCreationSuccess,
        onError: onFlowCreationError,
      },
    });

  const navigateToTemplate = (templateId: string) => {
    navigate(
      `/${workspaceSlugPath}/template/${templateId}?from=templateGallery`
    );
  };

  const onUseTemplate = (template: Template) => {
    trackTemplatesAction('useTemplateClicked', {
      templateId: template.templateId,
      category: template.category,
    });

    if (giveRecognition === 'primary') {
      setShowModal(true);
    } else {
      const { templateId, title: templateName, icon } = template;

      createFlowEditorDrawer({
        id: templateId,
        type: 'template',
        overrideTreatmentOffCallback() {
          createOneClickFlow({
            templateId,
            templateName,
            icon: icon.value,
          });
        },
      });

      if (isFlowCreationDrawerEnabled) {
        navigate('/a/discover');
      }
    }
  };

  const onPreview = (template: Template) => {
    navigateToTemplate(template.templateId);
    trackTemplatesAction('previewTemplateClicked', {
      templateId: template.templateId,
      category: template.category,
    });
  };

  return (
    <>
      {templates.map((template, index) => {
        const icon = mapHexCodeToEmoticon(template.icon.value);
        const categoryColor = categoryColorMap[template.category];
        const bgColor = mapBgColor[categoryColor.color];
        return (
          <div className="place-self-center" key={index}>
            <TemplateCard
              key={template.templateId}
              title={template.title}
              icon={icon}
              bgColor={bgColor}
              blockCount={(JSON.parse(template.blocks) as unknown[]).length}
              onUseTemplate={() => onUseTemplate(template)}
              onPreview={() => onPreview(template)}
              isFlowCreationInProgress={isFlowCreationInProgress}
            />
          </div>
        );
      })}
      <V3ModalTemplate setShowModal={setShowModal} showModal={showModal} />
    </>
  );
}
