import {
  getDepartmentsQuery,
  getUserDetailsQuery,
} from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import { defer, type LoaderFunctionArgs } from 'react-router-dom';

import { hiddenFoldersQueryOptions } from '../hooks/folder/useHiddenFolders';
import { flowActivitiesQueryOptions } from '../hooks/useFlowActivities';
import { flowsNotInCollectionQueryOptions } from '../hooks/useFlowsNotInCollection';
import { navDataQueryOptions } from '../modules/discover/hooks/nav/useNavData';
import { actionBarQueryOptions } from '../modules/discover/hooks/useActionBarQuery';
import { foldersQueryOptions } from '../modules/discover/hooks/useGetCollectionsQuery';
import { planFeaturesQueryOptions } from '../modules/discover/hooks/useGetPlanFeaturesQuery';
import { getAnnouncementsQuery } from '../modules/discover/queries/getAnnouncementsQuery';

export function rootDataLoader(queryClient: QueryClient) {
  return async function loader({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);

    const isHomePage = url.pathname === '/a/';

    return defer({
      userDetailsQueryResponse: queryClient.fetchQuery(getUserDetailsQuery()),

      planFeaturesQueryResponse: queryClient.fetchQuery(
        planFeaturesQueryOptions
      ),

      actionBarQueryResponse: queryClient.fetchQuery(actionBarQueryOptions),

      getNavDataQueryResponse:
        queryClient.fetchInfiniteQuery(navDataQueryOptions),

      getHiddenFoldersQueryResponse: queryClient.fetchInfiniteQuery(
        hiddenFoldersQueryOptions
      ),

      flowActivities: queryClient.fetchQuery(flowActivitiesQueryOptions),

      flowsNotInCollection: queryClient.fetchQuery(
        flowsNotInCollectionQueryOptions
      ),

      folders: queryClient.fetchQuery(foldersQueryOptions),

      departments: queryClient.fetchQuery(getDepartmentsQuery()),

      ...(isHomePage && {
        announcementsQueryResponse: queryClient.fetchInfiniteQuery(
          getAnnouncementsQuery()
        ),
      }),
    });
  };
}
