import {
  APIEndpoints,
  assemblyAPI,
  type FlowDetail,
  type Icon,
} from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { checkIfFlowExistsInFolders } from '../modules/discover/hooks/nav/useNavData';

export const flowsNotInCollectionQueryOptions = queryOptions({
  queryKey: ['flowsNotInCollection'],
  queryFn: async () => {
    const {
      data: { data },
    } = await assemblyAPI.get<{ data: FlowDetail[] }>(
      APIEndpoints.getFlowsNotInCollection
    );

    return data;
  },
});

export function addFlowToNav({
  queryClient,
  flowName,
  flowIcon,
  flowId,
  isExistingFlow = false,
}: {
  queryClient: QueryClient;
  flowName: string;
  flowIcon: Icon;
  flowId: string;
  isExistingFlow?: boolean;
}) {
  const navQueryKey = flowsNotInCollectionQueryOptions.queryKey;
  const navQueryCache: FlowDetail[] | undefined =
    queryClient.getQueryData(navQueryKey);

  if (isExistingFlow && checkIfFlowExistsInFolders({ queryClient, flowId })) {
    return;
  }

  const updatedLeftNavData = [
    {
      flowId: flowId,
      name: flowName,
      isShortcut: true,
      kind: '',
      state: '',
      createdAt: '',
      updatedAt: '',
      icon: flowIcon,
      isFlowOwner: true,
      isFlowParticipant: true,
      isFlowViewer: true,
      flowAccessRoles: [],
      occurrence: {},
      allowPrivateResponse: true,
      allowMemberLevelOccurrence: false,
      creationSource: '',
      responseSettings: {
        anonymity: {
          state: 'DISABLED',
        },
      },
      showScheduleCard: true,
      collaborators: [],
      activityState: 'ACTIVE',
    },
    ...(navQueryCache ?? []),
  ];
  queryClient.setQueryData(navQueryKey, updatedLeftNavData);
}

export function updateFlowDetailsInNav({
  queryClient,
  updatedFlowName,
  updatedFlowIcon,
  flowId,
}: {
  queryClient: QueryClient;
  updatedFlowName: string;
  updatedFlowIcon: Icon;
  flowId: string;
}) {
  const navQueryKey = flowsNotInCollectionQueryOptions.queryKey;
  const navQueryCache: FlowDetail[] | undefined =
    queryClient.getQueryData(navQueryKey);

  const updatedLeftNavData = navQueryCache?.map((flow) => {
    if (flow.flowId === flowId) {
      return {
        ...flow,
        name: updatedFlowName,
        icon: updatedFlowIcon,
      };
    }

    return flow;
  });

  queryClient.setQueryData(navQueryKey, updatedLeftNavData);
}

export function removeFlowFromNav(queryClient: QueryClient, flowId: string) {
  const navQueryKey = flowsNotInCollectionQueryOptions.queryKey;

  const navQueryCache: FlowDetail[] | undefined =
    queryClient.getQueryData(navQueryKey);

  const updatedLeftNavData = navQueryCache?.filter(
    (flow) => flow.flowId !== flowId
  );
  queryClient.setQueryData(navQueryKey, updatedLeftNavData);
}

export function useFlowsNotInCollection() {
  return useSuspenseQuery(flowsNotInCollectionQueryOptions);
}
