import {
  APIEndpoints,
  assemblyAPI,
  type FlowActivity,
} from '@assembly-web/services';
import type { QueryClient } from '@tanstack/react-query';
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query';

export const flowActivitiesQueryOptions = queryOptions({
  queryKey: ['flowActivities'],
  queryFn: async () => {
    const {
      data: { data: unreadFlowActivities },
    } = await assemblyAPI.get<{ data: FlowActivity[] }>(
      APIEndpoints.getUnreadFlowActivities
    );

    return {
      unreadFlows: unreadFlowActivities
        .filter((flowActivity) => flowActivity.hasUnreadPost)
        .map((flowActivity) => flowActivity.flowId),
      mentionedFlows: unreadFlowActivities
        .filter((flowActivity) => flowActivity.hasUnreadMentions)
        .map((flowActivity) => flowActivity.flowId),
    };
  },
});

export function updateFlowReadStatus(flowId: string, queryClient: QueryClient) {
  const queryKey = flowActivitiesQueryOptions.queryKey;

  const queryCache:
    | { unreadFlows: string[]; mentionedFlows: string[] }
    | undefined = queryClient.getQueryData(queryKey);

  const updatedCache = queryCache?.unreadFlows.filter((id) => id !== flowId);

  queryClient.setQueryData(queryKey, {
    unreadFlows: updatedCache ?? [],
    mentionedFlows: queryCache?.mentionedFlows ?? [],
  });
}

export function useFlowActivities() {
  return useSuspenseQuery(flowActivitiesQueryOptions);
}
