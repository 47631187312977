import { type DoraReportingCategory, isTruthy } from '@assembly-web/services';
import {
  Banner,
  Button,
  DoraReportChartPreviewer,
  DoraReportChartTitle,
  downloadDoraReportChart,
  LoadingSpinner,
  TextStyle,
} from '@assembly-web/ui';
import {
  ArrowDownTrayIcon,
  ArrowsPointingOutIcon,
  BookmarkIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { renderToString } from 'react-dom/server';
import { defineMessages, type IntlShape, useIntl } from 'react-intl';
import { twMerge } from 'tailwind-merge';
import type { RequireAllOrNone } from 'type-fest';

import { trackDoraAction } from '../../../services/analytics';
import { DoraFeedbackSection } from '../../dora/DoraFeedbackSection';
import { DoraLoadingLabel } from '../../dora/DoraLoadingLabel';
import { AnimateChat } from '../shared/dora/AnimateChat';
import { DoraMessageBubble } from '../shared/dora/DoraMessageBubble';
import { DoraStackedMessages } from '../shared/dora/DoraStackedMessages';

const messages = defineMessages({
  typingMessage: { defaultMessage: 'Typing', id: 'N2bqd9' },
  feedbackLabel: {
    defaultMessage: 'Was my response helpful?',
    id: '0sYPgY',
  },
  chartBannerTitle: {
    defaultMessage: '🔥 Hot tip: Finding this chart hard to read?',
    id: 'GhiF+0',
  },
  chartBannerSubtitle: {
    defaultMessage:
      'Try asking Dora for different colors, formatting, timeframe, or to only include the top or bottom 10 people/groups in the chart',
    id: '70LoiO',
  },
  downloadChartTooltip: {
    defaultMessage: 'Download chart',
    id: 'Jh4kBL',
  },
  savePromptCTA: {
    defaultMessage: 'Save prompt',
    id: '/35ErQ',
  },
  savingPromptCTA: {
    defaultMessage: 'Saving prompt',
    id: 'bjUHAT',
  },
  savedPromptCTA: {
    defaultMessage: 'Saved',
    id: 'fsB/4p',
  },
});

const imgContainerRegex = /<!-- start -->([\s\S]*?)<!-- end -->/;

function wrapInImageContainer({
  htmlResponse,
  formatMessage,
  reportCategory,
  isSavedPrompt,
  isSavingPrompt,
}: {
  htmlResponse: string;
  formatMessage: IntlShape['formatMessage'];
  reportCategory: DoraReportingCategory;
  isSavedPrompt: boolean;
  isSavingPrompt: boolean;
}) {
  const contentBetweenTagsMatch = htmlResponse.match(imgContainerRegex);
  const contentBetweenTags = contentBetweenTagsMatch
    ? contentBetweenTagsMatch[1].trim()
    : '';

  const parser = new DOMParser();
  const doc = parser.parseFromString(contentBetweenTags, 'text/html');

  const imgElement = doc.querySelector('img');

  const wrappedHTML = renderToString(
    <div className="h-full w-full rounded-lg border border-gray-5 bg-gray-1">
      <div className="inline-flex w-full items-center justify-between border border-x-0 border-t-0 border-b-gray-5 px-2 py-1">
        <DoraReportChartTitle
          formatMessage={formatMessage}
          reportCategory={reportCategory}
        />
        <div className="flex w-fit gap-2">
          {imgElement !== null && (
            <>
              {reportCategory !== 'flow_responses' && (
                <Button
                  data-save-prompt
                  variation={isSavedPrompt ? 'primary' : 'secondaryLite'}
                  className={twMerge(
                    'flex h-6 w-max items-center !gap-1 px-1 py-1',
                    isSavedPrompt &&
                      'disabled:!bg-primary-6 disabled:!text-gray-1'
                  )}
                  disabled={isSavedPrompt || isSavingPrompt}
                >
                  {isSavingPrompt ? (
                    <LoadingSpinner className="h-3 w-3" />
                  ) : (
                    <BookmarkIcon
                      className={twMerge(
                        'h-4 w-4',
                        isSavedPrompt && 'fill-gray-1'
                      )}
                    />
                  )}
                  <TextStyle variant="sm-regular">
                    {formatMessage(
                      isSavingPrompt
                        ? messages.savingPromptCTA
                        : isSavedPrompt
                          ? messages.savedPromptCTA
                          : messages.savePromptCTA
                    )}
                  </TextStyle>
                </Button>
              )}
              <button
                data-download-url={imgElement.getAttribute('src') ?? ''}
                title={formatMessage(messages.downloadChartTooltip)}
              >
                <ArrowDownTrayIcon className="h-4 w-4" />
              </button>
              <button data-zoom-url={imgElement.getAttribute('src')}>
                <ArrowsPointingOutIcon className="h-4 w-4" />
              </button>
            </>
          )}
        </div>
      </div>
      {imgElement !== null && (
        <img
          data-dora-chart={imgElement.getAttribute('data-dora-chart')}
          alt={imgElement.getAttribute('alt') ?? ''}
          className={imgElement.getAttribute('class') ?? ''}
          src={imgElement.getAttribute('src') ?? ''}
        />
      )}
    </div>
  );

  return htmlResponse.replace(imgContainerRegex, wrappedHTML);
}

export function DoraChatReportAnswer(
  props: {
    reportingCategory: DoraReportingCategory;
    htmlResponses: string[];
    isAnswerSeen: boolean;
    isLoading: boolean;
    shouldAnimate: boolean;
    showFeedbackControl: boolean;
  } & RequireAllOrNone<
    {
      rawResponse: string;
      promptId: string;
      isSavingPrompt: boolean;
      isSavedPrompt: boolean;
      onSavePrompt: () => void;
    },
    | 'isSavedPrompt'
    | 'isSavingPrompt'
    | 'onSavePrompt'
    | 'promptId'
    | 'rawResponse'
  >
) {
  const {
    htmlResponses,
    isAnswerSeen,
    isLoading,
    shouldAnimate = false,
    showFeedbackControl,
    reportingCategory,

    promptId,
    isSavedPrompt,
    isSavingPrompt,
    onSavePrompt,
  } = props;

  const { formatMessage } = useIntl();
  const ref = useRef<HTMLDivElement>(null);
  const [isChartPreviewerOpen, setIsChartPreviewerOpen] = useState(false);
  const [chartImageURL, setChartImageURL] = useState<string>('');
  const [showTextResponseToolbar, setShowTextResponseToolbar] = useState(false);

  const containsImageReport = htmlResponses.some((htmlResponse) =>
    htmlResponse.includes('<img data-dora-chart="" ')
  );

  useEffect(() => {
    function handleChartZoom(event: MouseEvent) {
      if (!event.target) {
        return;
      }

      const zoomButton = (event.target as Element).closest(
        'button[data-zoom-url]'
      );

      if (zoomButton) {
        setChartImageURL(zoomButton.getAttribute('data-zoom-url') ?? '');
        setIsChartPreviewerOpen(true);
        trackDoraAction('fullScreenChartClicked');
        return;
      }

      const downloadButton = (event.target as Element).closest(
        'button[data-download-url]'
      );

      if (downloadButton) {
        trackDoraAction('downloadChartImageClicked');
        return downloadDoraReportChart(
          downloadButton.getAttribute('data-download-url') ?? '',
          reportingCategory
        );
      }

      const savePromptButton = (event.target as Element).closest(
        'button[data-save-prompt]'
      );

      if (savePromptButton && !props.isSavedPrompt) {
        onSavePrompt?.();
      }
    }

    const containerElement = ref.current;

    if (containerElement) {
      containerElement.addEventListener('click', handleChartZoom);

      return () =>
        containerElement.removeEventListener('click', handleChartZoom);
    }
  }, [onSavePrompt, props.isSavedPrompt, ref, reportingCategory]);

  const chatMessages = htmlResponses.map((htmlResponse, index) => (
    <AnimateChat
      key={index}
      shouldAnimateOnMount={Boolean(
        shouldAnimate && index === htmlResponses.length - 1 && !isAnswerSeen
      )}
    >
      <DoraMessageBubble
        onMouseOver={() => flushSync(() => setShowTextResponseToolbar(true))}
        onMouseLeave={() => flushSync(() => setShowTextResponseToolbar(false))}
        onFocus={() => flushSync(() => setShowTextResponseToolbar(true))}
        onBlur={(e) => {
          if (
            (e.relatedTarget && e.currentTarget.contains(e.relatedTarget)) ||
            !e.target.parentElement
          ) {
            return;
          }

          flushSync(() => setShowTextResponseToolbar(false));
        }}
      >
        {htmlResponse.includes('<img data-dora-chart="" ') ? (
          <div className="space-y-3">
            <span
              className="space-y-2 text-base font-normal"
              dangerouslySetInnerHTML={{
                __html: wrapInImageContainer({
                  htmlResponse,
                  reportCategory: reportingCategory,
                  formatMessage,
                  isSavedPrompt: Boolean(isSavedPrompt),
                  isSavingPrompt: Boolean(props.isSavingPrompt),
                }),
              }}
            />

            <Banner status="info" className="rounded-lg">
              <TextStyle
                className="text-primary-9"
                variant="xs-medium"
                html={formatMessage(messages.chartBannerTitle)}
              />
              <TextStyle
                className="text-primary-9"
                variant="xs-regular"
                html={formatMessage(messages.chartBannerSubtitle)}
              />
            </Banner>
          </div>
        ) : (
          <>
            {Boolean(
              index === htmlResponses.length - 1 &&
                showFeedbackControl &&
                showTextResponseToolbar &&
                !containsImageReport
            ) && (
              <Button
                onClick={onSavePrompt}
                variation={isSavedPrompt ? 'primary' : 'secondaryLite'}
                className={twMerge(
                  'absolute right-0 top-1 flex h-6 w-max items-center !gap-1 px-1 py-1',
                  isSavedPrompt &&
                    'disabled:!bg-primary-6 disabled:!text-gray-1'
                )}
                disabled={isSavedPrompt || isSavingPrompt}
              >
                {isSavingPrompt ? (
                  <LoadingSpinner className="h-3 w-3" />
                ) : (
                  <BookmarkIcon
                    className={twMerge(
                      'h-4 w-4',
                      isSavedPrompt && 'fill-gray-1'
                    )}
                  />
                )}
                <TextStyle variant="sm-regular">
                  {formatMessage(
                    isSavingPrompt
                      ? messages.savingPromptCTA
                      : isSavedPrompt
                        ? messages.savedPromptCTA
                        : messages.savePromptCTA
                  )}
                </TextStyle>
              </Button>
            )}
            <TextStyle as="span" className="space-y-2" html={htmlResponse} />
          </>
        )}
      </DoraMessageBubble>
    </AnimateChat>
  ));

  if (isLoading) {
    chatMessages.push(
      <AnimateChat
        shouldAnimateOnMount={Boolean(shouldAnimate && !isAnswerSeen)}
      >
        <DoraMessageBubble>
          <DoraLoadingLabel label={formatMessage(messages.typingMessage)} />
        </DoraMessageBubble>
      </AnimateChat>
    );
  }

  return (
    <DoraStackedMessages
      shouldAnimateAvatar={Boolean(shouldAnimate && htmlResponses.length === 1)}
    >
      <div
        className="col-start-2 flex flex-col items-start gap-y-1 self-start antialiased"
        ref={ref}
      >
        {chatMessages}
      </div>

      {Boolean(onSavePrompt) && (
        <DoraReportChartPreviewer
          isOpen={isChartPreviewerOpen}
          imageURL={chartImageURL}
          reportCategory={reportingCategory}
          onClose={() => setIsChartPreviewerOpen(false)}
          onDownloadChart={() => trackDoraAction('downloadChartImageClicked')}
          onSavePrompt={() => onSavePrompt?.()}
          isSavedPrompt={Boolean(isSavedPrompt)}
          isSavingPrompt={Boolean(props.isSavingPrompt)}
        />
      )}

      {isTruthy(showFeedbackControl) &&
        isTruthy(promptId) &&
        isTruthy(props.rawResponse) && (
          <div className="col-start-2 flex self-start">
            <DoraMessageBubble>
              <DoraFeedbackSection
                feedbackSource="doraChat"
                feedbackLabel={formatMessage(messages.feedbackLabel)}
                promptId={promptId}
                markdownResponse={props.rawResponse}
              />
            </DoraMessageBubble>
          </div>
        )}
    </DoraStackedMessages>
  );
}
