import { config, SSOProvider, userAuthStore } from '@assembly-web/services';
import mixpanel from 'mixpanel-browser';
import type { Primitive, RequireAllOrNone, ValueOf } from 'type-fest';

import { onBoardingState } from '../modules/onboarding/stores/onboardingStore';

export const RegistrationMethods = {
  [SSOProvider.Google]: 'Google',
  [SSOProvider.ADP]: 'ADP',
  [SSOProvider.Slack]: 'Slack',
  [SSOProvider.Office365]: 'Office365',
  Email: 'Email',
};

export type UserProps = {
  $distinct_id: string;
  $email: string;
  $name: string;
  'Allowance Points Available': number;
  'Allowance Type': string;
  'Company Plan Type': string;
  'Registration Date': string;
  'Registration Method': ValueOf<typeof RegistrationMethods>;
  'Total Allowance': number;
  'Total Points Given': number;
  'User Status': string;
  active: number;
  Anniversary: string;
  archived: number;
  Birthday: string;
  canSendPrivate: boolean;
  completed: number;
  Department: string;
  Email: string;
  isAdmin: boolean;
  isManager: boolean;
  isPaid: boolean;
  Name: string;
  planType: string;
  Platform: string;
  numWorkspacesActive: number;
};

type ActionProps = {
  userProps?: Partial<UserProps>;
} & RequireAllOrNone<{
  eventName: string;
  eventProps: Record<string, Primitive>;
}>;

function debugMixpanel({
  methodName,
  eventName,
  eventProps,
}: {
  methodName: string;
  eventName?: string;
  eventProps: Record<string, Primitive>;
}) {
  if (eventName) {
    console.log(
      `%c👓 mixpanel:%c${methodName}:%c${eventName}`,
      'color: dodgerblue; font-weight: bold;',
      'color: mediumseagreen; font-weight: bold;',
      'color: tomato; font-weight: bold;',
      eventProps
    );
  } else {
    console.log(
      `%c👓 mixpanel:%c${methodName}`,
      'color: dodgerblue; font-weight: bold;',
      'color: mediumseagreen; font-weight: bold;',
      eventProps
    );
  }
}

export function registerUserForTracking(userId: string, assemblyId: string) {
  if (config.env !== 'prod') {
    debugMixpanel({
      methodName: 'registerUserForTracking',
      eventProps: { userId, assemblyId },
    });
    return;
  }

  mixpanel.identify(userId);
  mixpanel.register({ assemblyID: assemblyId });
}

export function registerUserPropsForTracking(userProps: Partial<UserProps>) {
  if (config.env !== 'prod') {
    debugMixpanel({
      methodName: 'registerUserPropsForTracking',
      eventProps: userProps,
    });
    return;
  }

  mixpanel.register(userProps);
  mixpanel.identify(userProps.$distinct_id);
}

export function trackAction(props: ActionProps) {
  if (config.env !== 'prod') {
    const { eventName, eventProps } = props;
    debugMixpanel({
      methodName: 'trackAction',
      eventName,
      eventProps,
    });
    return;
  }

  if (props.userProps) {
    registerUserPropsForTracking(props.userProps);
  }

  mixpanel.track(props.eventName, props.eventProps);
}

export function trackRegistrationShow() {
  return trackAction({
    eventName: 'registration.events',
    eventProps: {
      url: window.location.href,
      event: 'show',
    },
  });
}

type RegistrationAction =
  | 'assemblyCreated'
  | 'assemblyMemberCreated'
  | 'codeSubmitted'
  | 'createNewWorkspaceClicked'
  | 'emailEntered'
  | 'logIntoExistingClicked'
  | 'logInWithDifferentEmailClicked'
  | 'signUpInsteadClicked'
  | 'ssoClicked'
  | 'workspaceClicked'
  | 'workspaceRejected'
  | 'emailProviderClicked';

type OnboardingAction =
  | 'managesPeopleSelected'
  | 'roleSelected'
  | 'addToBrowserClicked';

type TemplatesAction =
  | 'applyFilter'
  | 'search'
  | 'startFromScratch'
  | 'previewTemplateClicked'
  | 'useTemplateClicked'
  | 'seeAllTemplatesClicked'
  | 'exitTemplateLibrary';

export type TrackEventProps = Record<
  string,
  Primitive | string[] | Record<string, Primitive>
>;

export function trackRegistrationAction(
  actionName: RegistrationAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'registration.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackRegistrationError(
  actionName: RegistrationAction,
  props: TrackEventProps
) {
  return trackAction({
    eventName: 'registration.events',
    eventProps: {
      url: window.location.href,
      event: 'error',
      action: actionName,
      ...props,
    },
  });
}

export function trackOnboardingAction(
  actionName: OnboardingAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'onboarding.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      ...props,
    },
  });
}

export function trackTemplatesAction(
  actionName: TemplatesAction,
  props?: TrackEventProps
) {
  return trackAction({
    eventName: 'template.events',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: actionName,
      v3enabled: true,
      ...props,
    },
  });
}

export function trackPageViewAction(pageName: string) {
  let { platform } = onBoardingState.getState();
  const { isLoginViaMobileApp } = userAuthStore.getState();
  const pageURL = window.location.href;
  const referrer = document.referrer;
  if (platform === 'ios' || platform === 'android') {
    platform = isLoginViaMobileApp ? 'mobile_app' : 'web';
  }

  return trackAction({
    eventName: 'page_view',
    eventProps: {
      url: window.location.href,
      event: 'action',
      action: 'page_view',
      'Page Name': pageName,
      Platform: platform,
      'Page URL': pageURL,
      'Referrer URL': referrer,
    },
  });
}
