import { APIEndpoints, assemblyAPI } from '@assembly-web/services';
import {
  useQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from '@tanstack/react-query';

export type TeamRecognitionInsightsApiResponse = {
  usersToRecognize: {
    firstName: string;
    lastName: string;
    image: string;
    memberID: string;
    lastRecognizedDate?: Date;
  }[];
  percentageOfUsersRecognizedThisMonth: number;
};

export function useTeamRecognitionInsightsQuery(
  options?: UseQueryOptions<TeamRecognitionInsightsApiResponse>
): UseQueryResult<TeamRecognitionInsightsApiResponse> {
  return useQuery({
    ...options,
    queryKey: ['teamRecognitionInsights'],
    queryFn: async () => {
      const response =
        await assemblyAPI.get<TeamRecognitionInsightsApiResponse>(
          APIEndpoints.getTeamRecognitionInsights
        );
      return response.data;
    },
  } as UseQueryOptions<TeamRecognitionInsightsApiResponse>);
}
