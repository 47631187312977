import { useActionBarUpdatesEvents } from './useActionBarUpdatesEvents';
import { useAdminSettingsUpdateEvents } from './useAdminSettingsUpdateEvents';
import { useAnnouncementUpdatesEvents } from './useAnnouncementUpdatesEvents';
import { useCollectionUpdatesEvents } from './useCollectionUpdatesEvents';
import { useFlowResponseUpdatesEvents } from './useFlowResponseUpdatesEvents';
import { useFlowUpdateMemberEvents } from './useFlowUpdateMemberEvents';
import { useImportantActivitiesEvents } from './useImportantActivitiesEvents';
import { useNotificationPreferenceUpdatedEvents } from './useNotificationPreferenceUpdatedEvents';
import { usePostInteractionSettingsUpdatesEvents } from './usePostInteractionSettingsUpdatesEvents';
import { usePostUpdatesEvents } from './usePostUpdatesEvents';

/**
 * Registers all global domain events for the logged in user.
 */
export function useGlobalDomainEvents() {
  useAdminSettingsUpdateEvents();
  usePostUpdatesEvents();
  useFlowResponseUpdatesEvents();
  useActionBarUpdatesEvents();
  useImportantActivitiesEvents();
  useCollectionUpdatesEvents();
  useAnnouncementUpdatesEvents();
  useNotificationPreferenceUpdatedEvents();
  usePostInteractionSettingsUpdatesEvents();
  useFlowUpdateMemberEvents();
}
