import {
  Button,
  EditorLoader,
  OverflowText,
  TextStyle,
} from '@assembly-web/ui';
import { PencilIcon } from '@heroicons/react/24/solid';
import { useRef } from 'react';
import { defineMessage, defineMessages, useIntl } from 'react-intl';

import {
  useGetFlowCollaborators,
  useGetFlowOwner,
} from '../../../../../../stores/useFlowBuilderStore';
import { useMultiDrawerStore } from '../../../../../../stores/useMultiDrawerStore';
import { trackFlowEditorAction } from '../../../../services/analytics';
import { useEditorDataContext } from '../context/EditorDataContext';
import { useAnyOccurrenceInProgress } from '../hooks/useAnyOccurrenceInProgress';

const messages = defineMessages({
  owner: {
    defaultMessage: 'Owner',
    id: 'zINlao',
  },
  collaborators: {
    defaultMessage: 'Collaborators',
    id: '9Jstnt',
  },
  noCollaborators: {
    defaultMessage: 'No collaborators have been added yet',
    id: 'QVjBNM',
  },
});

function Owner() {
  const { formatMessage } = useIntl();

  const { id } = useEditorDataContext();

  const owner = useGetFlowOwner(id);

  return (
    <div className="flex flex-1 flex-col gap-1">
      <TextStyle as="span" variant="sm-medium">
        {formatMessage(messages.owner)}
      </TextStyle>
      <TextStyle as="span" variant="sm-regular">
        {owner?.name ?? <EditorLoader className="h-5" />}
      </TextStyle>
    </div>
  );
}

function Collaborators() {
  const measureRef = useRef<HTMLDivElement | null>(null);

  const { formatMessage } = useIntl();

  const { id, isLoading } = useEditorDataContext();

  const collaborators = useGetFlowCollaborators(id);

  const tooltipText = collaborators
    ?.map((collaborator) => collaborator.name)
    .join(', ');

  return (
    <div ref={measureRef} className="flex min-w-0 flex-1 flex-col gap-1">
      <TextStyle as="span" variant="sm-medium">
        {formatMessage(messages.collaborators)}
      </TextStyle>
      {isLoading ? (
        <EditorLoader className="h-5" />
      ) : collaborators && collaborators.length > 0 ? (
        <OverflowText
          variant="sm-regular"
          className="truncate"
          key={collaborators.map((collaborator) => collaborator.email).join('')}
          tooltipText={tooltipText}
        >
          {collaborators.map((collaborator) => collaborator.name).join(', ')}
        </OverflowText>
      ) : (
        <TextStyle variant="sm-italic">
          {formatMessage(messages.noCollaborators)}
        </TextStyle>
      )}
    </div>
  );
}

export function FlowOwnerAndCollaborators() {
  const { formatMessage } = useIntl();

  const { id, isLoading } = useEditorDataContext();
  const updateDrawer = useMultiDrawerStore((store) => store.updateDrawerField);

  const occurrenceInProgress = useAnyOccurrenceInProgress();

  const handleClick = () => {
    trackFlowEditorAction('editOwnerCollaboratorClicked');
    updateDrawer(id, (state) => {
      if (state.type !== 'flowCreation') return state;
      state.data.view = 'permissions';
    });
  };

  return (
    <div className="flex flex-col gap-6 @[700px]/body:flex-row @[700px]/body:items-center @[700px]/body:gap-2">
      <div className="flex min-w-0 flex-1 flex-col gap-6 @[700px]/body:flex-row @[700px]/body:gap-2">
        <Owner />
        <Collaborators />
      </div>
      <Button
        className="w-max @[700px]/body:self-end"
        variation="secondaryEmphasized"
        size="large"
        onClick={handleClick}
        disabled={isLoading || occurrenceInProgress}
      >
        <PencilIcon className="h-4 w-4" />
        {formatMessage(
          defineMessage({
            defaultMessage: 'Edit Owners & Collaborators',
            id: 'cdM3t3',
          })
        )}
      </Button>
    </div>
  );
}
