import { APIEndpoints, assemblyAPI, logger } from '@assembly-web/services';
import { useToastStore } from '@assembly-web/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { defineMessages, useIntl } from 'react-intl';

import { rearrangeItemWithinFolder } from './nav/useNavData';
import { rearrangeItemWithinCollection } from './useGetCollectionItemsQuery';

type Payload = {
  collectionId: string;
  itemId: string;
  beforeId?: string;
  afterId?: string;
  newLocationIndex: number;
};

const messages = defineMessages({
  failedToReorderItem: {
    defaultMessage: 'Failed to reorder item. Please try again later.',
    id: '3GJE7H',
  },
});

export function useCollectionItemReorderMutation() {
  const queryClient = useQueryClient();
  const { showErrorToast } = useToastStore();
  const { formatMessage } = useIntl();

  return useMutation({
    mutationFn: async (payload: Payload) => {
      const { beforeId, afterId, collectionId, itemId } = payload;
      await assemblyAPI.put(
        APIEndpoints.reorderCollectionItems(collectionId, itemId),
        omitBy({ beforeId, afterId }, isNil)
      );
    },

    onMutate: (payload: Payload) => {
      rearrangeItemWithinFolder(queryClient, payload);
      rearrangeItemWithinCollection(queryClient, payload);
    },

    onError: (payload: Payload) => {
      showErrorToast(formatMessage(messages.failedToReorderItem));
      logger.error('Failed to reorder collection item.', payload);
    },
  });
}
