import {
  Avatar,
  AvatarSize,
  Challenge,
  OverflowCard,
  TextStyle,
} from '@assembly-web/ui';
import dayjs from 'dayjs';
import { defineMessages, useIntl } from 'react-intl';

import { useGetRepliesSummaryForChallenge } from '../../../hooks/challenges/useChallengeRepliesQueries';
import { useGetChallengeDetailsByIdQuery } from '../../../hooks/challenges/useGetChallengeDetailsByIdQuery';
import type { ChallengesDrawer } from '../types';

const messages = defineMessages({
  replyToThisPost: {
    defaultMessage: 'Reply to this post',
    id: 'AKLw8w',
  },
  lastReply: {
    defaultMessage: 'Last reply {timeAgo}',
    id: 'nZtCXw',
  },
  replies: {
    defaultMessage: '{replyCount} replies',
    id: 'oiloP2',
  },
  challengeLabel: {
    defaultMessage: 'Challenge: {challengeName}',
    id: 'AI/b9S',
  },
});

export function ChallengesDrawerOverflowCard(
  props: ChallengesDrawer & { onClick: () => void; onClose: () => void }
) {
  const { onClose, onClick, data } = props;
  const { challengeId } = data;

  const { formatMessage } = useIntl();

  const { data: challengeDetails, isPending: isChallengeDetailsPending } =
    useGetChallengeDetailsByIdQuery(challengeId);

  const { data: replySummaryData, isPending: isRepliesSummaryPending } =
    useGetRepliesSummaryForChallenge(challengeId);

  const isPending = isChallengeDetailsPending || isRepliesSummaryPending;

  if (isPending) {
    return (
      <div className="group-hover:shadow-base-downflex animate-puls flex w-full items-start rounded-lg border border-gray-5 p-3 group-hover:border-gray-6">
        <div className="h-10 w-10 flex-shrink-0 rounded-md bg-gray-5" />
        <div className="flex grow flex-col gap-1 pl-3">
          <div className="h-4 w-full max-w-[325px] rounded bg-gray-5" />
          <div className="h-5 w-full max-w-[179px] rounded bg-gray-5" />
        </div>
      </div>
    );
  }

  const replyCount = replySummaryData?.count ?? 0;

  const title = formatMessage(messages.challengeLabel, {
    challengeName: challengeDetails?.title ?? '',
  });

  const avatar = (
    <Avatar
      isRounded={false}
      image={Challenge}
      memberID={challengeId}
      size={AvatarSize.Large}
      className="flex-shrink-0"
      name={challengeDetails?.title ?? ''}
    />
  );

  const content = (
    <div>
      <TextStyle
        as="span"
        variant="xs-medium"
        className="mr-1.5 text-primary-6"
      >
        {formatMessage(messages.replies, {
          replyCount,
        })}
      </TextStyle>
      <TextStyle as="span" variant="xs-regular" className="text-gray-8">
        {formatMessage(
          replyCount <= 0 ? messages.replyToThisPost : messages.lastReply,
          {
            timeAgo: dayjs(replySummaryData?.lastRepliedAt ?? '').fromNow(),
          }
        )}
      </TextStyle>
    </div>
  );

  return (
    <OverflowCard
      title={title}
      avatar={avatar}
      onClose={onClose}
      onClick={onClick}
      content={content}
    />
  );
}
