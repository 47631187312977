import {
  APIEndpoints,
  assemblyAPI,
  type ThemePreset,
  type UserDetails,
} from '@assembly-web/services';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export function useAssemblySettingsMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: {
      theme: { preset?: ThemePreset; backgroundColor?: string | null };
    }) =>
      assemblyAPI.put(
        APIEndpoints.assemblySettings,
        payload.theme.preset !== 'neutral'
          ? payload
          : {
              theme: {
                backgroundColor: payload.theme.backgroundColor,
              },
            }
      ),
    onMutate: (payload: {
      theme: { preset?: ThemePreset; backgroundColor?: string | null };
    }) => {
      const queryKey = ['userDetails'];
      const userDetails: UserDetails | undefined =
        queryClient.getQueryData(queryKey);
      if (userDetails && payload.theme.preset) {
        queryClient.setQueryData(queryKey, {
          ...userDetails,
          assembly: {
            ...userDetails.assembly,
            settings: {
              ...userDetails.assembly.settings,
              theme:
                payload.theme.preset !== 'neutral'
                  ? payload.theme
                  : {
                      backgroundColor: payload.theme.backgroundColor,
                    },
            },
          },
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['userDetails'] });
    },
  });
}
